import { Button, Card, Modal, Row, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	currentQuestionid,
	playlist_setting_forall_question,
	question_delete_form_array,
} from "../../redux/reducers/PlaylistSlice";
import Playlistquestionsettings from "./Playlistquestionsettings";
import { CloseCircleFilled, EditFilled } from "@ant-design/icons";
import parse from "html-react-parser";
import { ParseHtmlToText } from "../../utlis/parseHtmlTotext/ParseHtmlToText";
import PlaylistQuestionEditModal from "../modal/PlaylistQuestionEditModal";
import { Link } from "react-router-dom";
import BulkQuestionEditModal from "../modal/BulkQuestionEditModal";

interface questionEdit {
	open: boolean;
	QuestionId: any;
}

const PlaylistQuestion = ({ isBlurred, setIsBlurred }) => {
	const [isQuestionEditModalOpen, setQuestionEditModalOpen] =
		useState<questionEdit>({
			open: false,
			QuestionId: "",
		});
	const [isBulkEditModalOpen, setBulkEditModalOpen] =
		useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	let [questions, setQuestions] = useState<any>();

	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);

	const close = (id: number) => {
		dispatch(question_delete_form_array(id));
	};
	const questionSettings = (index) => {
		dispatch(currentQuestionid(index));
	};
	const applyforall = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		dispatch(playlist_setting_forall_question());
		messageApi.open({
			type: "success",
			content: "Applied",
		});
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const editQuestion = (questionId) => {
		setQuestionEditModalOpen({
			open: true,
			QuestionId: questionId,
		});
	};
	const hasNonEditable = questions?.some(
		(item) => item.is_editable === false
	);
	const is_bulkEditable = hasNonEditable ? false : true;

	useEffect(() => {
		if (playlist.mode === 0) {
			setQuestions(playlist.question_ids);
		}
	}, [playlist?.question_ids]);
	useEffect(() => {
		if (playlist.mode === 1) {
			setQuestions(playlist.playlist_to_edit.question_ids);
		}
	}, [playlist?.playlist_to_edit?.question_ids]);

	return (
		<>
			<Card className="playlist-settings-card">
				{is_bulkEditable ? (
					<Row gutter={[24, 6]}>
						<Tooltip placement="topLeft" title="All Question Edit">
							<Button
								onClick={() => setBulkEditModalOpen(true)}
								style={{
									backgroundColor: " #add7fb",
									borderRadius: "20px",
									color: "initial",
								}}
							>
								Bulk Question Edit
							</Button>
						</Tooltip>
					</Row>
				) : (
					""
				)}
				<Row gutter={[24, 6]} className="playlist-tag">
					{questions?.map((element: any, index) => {
						const textContent = ParseHtmlToText(element?.title); // ParseHtmlToText function in utils for html parse
						// let title: any = parse(element?.title);
						return (
							<React.Fragment key={index}>
								<Tooltip
									// title={title}
									placement="topLeft"
									title={textContent}
									key={index}
									className="ques_tag_trim_tooltip"
								>
									<div
										key={index}
										style={{
											position: "relative",
											zIndex: "90",
										}}
									>
										<>
											<span>
												<Button
													onClick={() =>
														questionSettings(index)
													}
													className="playlist-question-button"
													style={{
														backgroundColor:
															playlist.mode === 0
																? +playlist?.questionIndex ===
																  index
																	? "#a18dff"
																	: "white"
																: +playlist
																		?.playlist_to_edit
																		?.questionIndex ===
																  index
																? "#a18dff"
																: "white",
														padding: "4px",
													}}
												>
													{/* {typeof title === "object" &&
										title.props.children.substring(0, 9)}
									{typeof title === "string" &&
										title.substring(0, 9)} */}
													<p className="ques_tag_trim">
														{/* {typeof title === "object" &&
											title?.props?.children}
										{typeof title === "string" && title} */}
														{textContent}
													</p>
												</Button>
											</span>
											<span
												style={{
													position: "absolute",
													top: "0",
													right: "0",
													zIndex: "99",
												}}
											>
												<Link
													to=""
													// className="playlist-question-button"
													onClick={() => close(index)}
												>
													<CloseCircleFilled />
												</Link>
											</span>

											{element?.is_editable && (
												<span
													style={{
														position: "absolute",
														top: "0",
														left: "0",
														zIndex: "99",
													}}
												>
													<Link
														to=""
														onClick={() =>
															editQuestion(
																element?.q_id
															)
														}
													>
														<EditFilled />
													</Link>
												</span>
											)}
										</>
									</div>
								</Tooltip>
							</React.Fragment>
						);
					})}
				</Row>
				<Playlistquestionsettings />

				<Row gutter={[24, 6]}>
					<Tooltip
						placement="topLeft"
						title="Playlist settings apply for all question"
					>
						<Button
							className="applyforall-button"
							onClick={applyforall}
						>
							Apply for all
						</Button>
					</Tooltip>
				</Row>
			</Card>
			{contextHolder}
			<Modal
				open={isModalOpen}
				footer={[
					<Button key="back" onClick={handleCancel}>
						No
					</Button>,
					<Button key="submit" type="primary" onClick={handleOk}>
						Yes
					</Button>,
				]}
			>
				<Row>
					<p>Do you want apply playlist settings for all question </p>
				</Row>
			</Modal>

			{isQuestionEditModalOpen?.QuestionId?.length !== 0 && (
				<PlaylistQuestionEditModal
					isQuestionEditModalOpen={isQuestionEditModalOpen}
					setQuestionEditModalOpen={setQuestionEditModalOpen}
				/>
			)}
			{isBulkEditModalOpen ? (
				<BulkQuestionEditModal
					isBulkEditData={isBulkEditModalOpen}
					setBulkEditModalOpen={setBulkEditModalOpen}
					isBlurred={isBlurred}
					setIsBlurred={setIsBlurred}
				/>
			) : (
				""
			)}
		</>
	);
};

export default PlaylistQuestion;
