import React from "react";

const SvgBulbOn = () => {
    return (
        <div>
            <svg
                width="28"
                height="28"
                viewBox="17 4 72 72"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                >
                    <g strokeWidth="2">
                        <path
                            d="m52.55 23.75c0 3.985-1.785 5.908-3.754 10.5-.5028 1.172-4.211 13.38-4.211 13.38h-17.17s-2.981-11.67-3.546-12.62c-2.37-3.998-4.419-6.91-4.419-11.26 0-9.141 7.41-16.55 16.55-16.55 9.141 0 16.55 7.41 16.55 16.55z"
                            style={{ fill: "yellow" }}
                        />
                        <path d="m36 47.22v-11.94" />
                        <path d="m41.97 35.28h-11.94" />
                    </g>
                    <path
                        d="m44.16 58.79c0 3.24-3.651 5.867-8.155 5.867s-8.155-2.627-8.155-5.867z"
                        fill="none"
                        strokeWidth="1.696"
                    />
                    <path d="m27.99 54.98 16.02-3.47" strokeWidth="1.965" />
                    <path d="m38.2 56.07 5.78-1.18" strokeWidth="2" />
                    <path d="m28.02 51.6 5.78-1.18" strokeWidth="2" />
                </g>
            </svg>
        </div>
    );
};

export default SvgBulbOn;
