import React, { useEffect, useState } from "react";
import { Button, Card, Col, Empty, Pagination, Row, Tooltip } from "antd";
import Backtop from "./Backtop";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getquestion } from "../redux/reducers/questionSlice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HomeSearch from "../components/Search/HomeSearch";
import ColapsableCard from "../components/ColapsableCard";
import { PlaylistFetch, ViewPlaylist } from "../redux/reducers/PlaylistSlice";
import { QuestionArray } from "../redux/reducers/SearchQuestionSlice";
import { ApiConnector } from "../axios/apiClient";
import { LIKE, PLAYLISTLIKE } from "../axios/AllBaseUrl";
import ColapsableList from "../components/MyPlaylist/Newplaylistui/ColapsableList";
import { searchPlaylistLike } from "../redux/reducers/SearchPlaylistSlice";
import CustomToast from "../utlis/toast/CustomToast";
import SearchPlaylistViewCard from "../components/SearchPlaylistViewCard";
import AddFloatingButton from "../components/MyPlaylist/AddFloatingButton";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import Loader from "../utlis/Loader/Loader";
import SuggestedQuestion from "../components/create/SuggestedQuestion";
import { PageDataInterface } from "../utlis/Interface/homeSearch";
import { useNavigate } from "react-router-dom";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 8,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1200 },
		items: 5,
	},
	laptop: {
		breakpoint: { max: 1199, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1020, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};
const Homepage = () => {
	let navigate = useNavigate();
	let flag = FlagLocalhost();
	const dispatch = useDispatch();
	let [showQuestion, setShowQuestion] = useState<boolean>(false);
	let [buttonType, setButtonType] = useState(0);
	const [searchType, setSearchType] = useState(2);
	const [searchTags, setSearchTags] = useState<any>([]);
	let [eyePlaylist, setEyePlaylist] = useState<String>("");
	const [playlistID, setPlaylistID] = useState();
	const [pageData, setPageData] = useState<PageDataInterface>({
		page: 1,
		pageSize: 10,
	});
	const [playlistPageData, setPlaylistPageData] = useState<PageDataInterface>(
		{
			page: 1,
			pageSize: 10,
		}
	);
	const playlistSearch: any = useSelector(
		(state: RootState) => state.playlistSearch
	);
	const search = useSelector((state: RootState) => state.search);

	const playlist = useSelector((state: RootState) => state.playlist);
	let toggleIcon = async (id, liked, totalLike) => {
		let res: any = await ApiConnector("post", `${LIKE}`, {
			question_id: id,
		});
		if (res.data.ok) {
			dispatch(
				QuestionArray({ id: id, liked: liked, totalLike: totalLike })
			);
		}
	};

	let clickButton = (id) => {
		// 2=== playlis , 1=== Question
		setButtonType(id);
		setSearchType(id);
		setSearchTags([]);
	};
	let clickPlaylist = (id) => {
		dispatch(ViewPlaylist(id));
		setShowQuestion(true);
	};
	let likePlaylist = async (id, liked, totalLike, flag, name) => {
		let res = await ApiConnector("post", `${PLAYLISTLIKE}`, {
			playlist_id: id,
		});
		if (flag === 1) {
			CustomToast("success", `Thank you for liked  ${name}`);
		}
		if (res.data.ok) {
			dispatch(
				searchPlaylistLike({
					id: id,
					liked: liked,
					totalLike: totalLike,
				})
			);
		}
	};
	const handlePageChange = (page, pageSize) => {
		setPageData({ page: page, pageSize: pageSize });
	};
	const handlePlaylistPageChange = (page, pageSize) => {
		setPlaylistPageData({ page: page, pageSize: pageSize });
	};
	const playListShareUrl = `https://adhyandigital.com/`;
	// const playListShareUrl = `https://adhyandigital.com/viewQBPL/#/receive/${shareId}`
	// useEffect(() => {
	// 	dispatch(PlaylistFetch());
	// }, []);

	if (playlistSearch?.tokenExpiredError) {
		localStorage.removeItem("token");
		flag?.isFromEvidya === 1
			? window.open("https://evidyadigital.com/login.php", "_self")
			: navigate("/");
	}

	return (
		<>
			{/* <Seacrh /> */}
			{/* <MultiSearch /> */}

			{/* <div> */}
			<Row gutter={24} className="front-search">
				<Col span={3}></Col>
				<Col span={18} className="search-front">
					<HomeSearch
						searchMode={searchType}
						setShowQuestion={setShowQuestion}
						searchTags={searchTags}
						setSearchTags={setSearchTags}
						pageData={pageData}
						playlistPageData={playlistPageData}
					/>
				</Col>
				<Col span={3}></Col>
			</Row>
			<Row gutter={[24, 0]} className="front_button" justify="center">
				<Col xs={12} xl={4} md={8}>
					<Tooltip placement="left" title=" Question Search">
						<Button
							type="primary"
							size="large"
							className="Myquestionbutton"
							style={
								buttonType === 1
									? {
											background: "rgba(0, 203, 244, 1)",
									  }
									: {}
							}
							onClick={() => clickButton(1)}
						>
							{/* <Link to="/create"> */}
							{/* Myquestionbutton_onclick */}
							Question
							{/* </Link> */}
						</Button>
					</Tooltip>
				</Col>
				<Col xs={12} xl={4} md={8}>
					<Tooltip placement="right" title="Playlist Search">
						<Button
							type="primary"
							size="large"
							className="Myquestionbutton"
							style={
								buttonType === 2
									? {
											background: "rgba(0, 203, 244, 1)",
									  }
									: {}
							}
							onClick={() => clickButton(2)}
						>
							{/* <Link to="/playlist"> */}
							Playlist
							{/* </Link> */}
						</Button>
					</Tooltip>
				</Col>
			</Row>

			<Row gutter={[24, 0]} className="front">
				<Col xxl={2} xl={2} md={2} xs={0}></Col>
				<Col xxl={20} xl={20} md={20} xs={24}>
					<Card
						style={{
							width: "100%",
							height: "500px",
							backgroundColor: "transparent",
							boxShadow: "0 0px 0px rgb(0 0 0 / 0%)",
						}}
					>
						{searchType === 1 && (
							<>
								<Row gutter={[24, 0]}>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
									>
										{search?.loading && <Loader />}
										{searchTags?.length > 0 &&
											search?.apiCall &&
											search?.search?.length === 0 && (
												<Empty />
											)}
										{search?.search?.length > 0 && (
											<SuggestedQuestion
												handlePageChange={
													handlePageChange
												}
												pageData={pageData}
											/>
										)}
									</Col>
								</Row>
							</>
						)}
						{searchType === 2 && (
							<>
								{playlistSearch?.playListSearch?.length !==
									0 && (
									<Row style={{ marginBottom: "6px" }}>
										<Pagination
											current={
												playlistSearch?.currentPage
											}
											total={playlistSearch?.totalItems}
											showSizeChanger
											pageSize={
												playlistPageData
													? playlistPageData?.pageSize
													: 10
											}
											pageSizeOptions={[10, 20, 50, 100]}
											showTotal={(total, range) =>
												`Showing ${range[0]}-${range[1]} of ${total} playlist`
											}
											// items in ${playlistSearch?.totalPages} pages
											onChange={(page, pageSize) =>
												handlePlaylistPageChange(
													page,
													pageSize
												)
											}
										/>
									</Row>
								)}
								<Row gutter={[24, 0]}>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
									>
										{searchType === 2 &&
											playlistSearch?.loading && (
												<Loader />
											)}
										{searchTags?.length > 0 &&
											searchType === 2 &&
											playlistSearch?.apiCall &&
											playlistSearch?.playListSearch
												?.length === 0 && <Empty />}
										<Carousel
											responsive={responsive}
											// showDots={true}
											ssr={true} // means to render carousel on server-side.
											// infinite={true}
											additionalTransfrom={0}
											arrows
											autoPlaySpeed={1000}
											keyBoardControl={true}
											customTransition="all .5"
											transitionDuration={500}
											containerClass="carousel-container"
											dotListClass="custom-dot-list-style"
											// sliderClass="react-multi-carousel-track"
											// customTransition="transform 300ms ease-in-out"
										>
											{/* {playlistSearch?.playListSearch
												?.length === 0 && (
												<div>
													<Empty />
												</div>
											)} */}

											{searchType === 2 &&
												playlistSearch?.loading ===
													false &&
												playlistSearch?.playListSearch?.map(
													(elm, index) => {
														return (
															<React.Fragment
																key={index}
															>
																<SearchPlaylistViewCard
																	elm={elm}
																	index={
																		index
																	}
																	setShowQuestion={
																		setShowQuestion
																	}
																	likePlaylist={
																		likePlaylist
																	}
																	playlist_id={
																		elm?._id
																	}
																	eyePlaylist={
																		eyePlaylist
																	}
																	setEyePlaylist={
																		setEyePlaylist
																	}
																	playlistID={
																		playlistID
																	}
																	setPlaylistID={
																		setPlaylistID
																	}
																/>
															</React.Fragment>
														);
													}
												)}
										</Carousel>
									</Col>
								</Row>
							</>
						)}
						{searchType === 2 && showQuestion === true && (
							<Row style={{ marginTop: "15px" }}>
								<Col xxl={24} xl={24} md={24} xs={24}>
									{!playlist.loading &&
										playlist.playlistQuestions?.q_array
											?.length === 0 && (
											<h1>
												<b>
													No questions in this
													playlist !
												</b>
											</h1>
										)}
									{!playlist.loading &&
										playlist.playlistQuestions?.q_array?.map(
											(value, index) => (
												<ColapsableList
													key={index}
													value={value}
												/>
											)
										)}
								</Col>
							</Row>
						)}
					</Card>

					<Col xxl={2} xl={2} md={2} xs={2}></Col>
				</Col>
			</Row>
			<Backtop />
			<Row
				gutter={[24, 0]}
				style={{ position: "fixed", bottom: "4vh", right: "4vw" }}
			>
				{flag?.isFromEvidya === 1 && <AddFloatingButton />}
			</Row>
		</>
	);
};

export default Homepage;
