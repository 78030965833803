import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Col, Row, Input, Button, Radio, Modal } from "antd";
import "../../assets/styles/main.css";
import SvgDelete from "../svg/SvgDelete";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import UploadCustom from "./UploadCustom";
import {
	ANIMATION,
	FILL_IN_THE_BLANK,
	TRUE_FALSE,
} from "../../utlis/constants/questionType";
import CustomToast from "../../utlis/toast/CustomToast";
import Editor from "./Editor";

const { confirm } = Modal;

const RadioOption = ({ index, id, elem }) => {
	let questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [ques, setQues] = useState<any>({});
	let [quesTrans, setQuesTrans] = useState<any>({});
	// console.log(elem);
	let [error, setError] = useState<any>({});
	let [optionCorrect, setOptionCorrect] = useState<any>();

	const showPromiseConfirm = () => {
		confirm({
			title: "Do you want to delete this item?",
			icon: <ExclamationCircleOutlined />,
			content: "When clicked the OK button, Option will be deleted",
			onOk() {
				if (state.mode === 0) {
					dispatch({ type: "main_del_option", option_id: id });
				}
				if (state.mode === 1) {
					dispatch({ type: "sub_del_option", option_id: id });
				}
				if (state.mode === 2) {
					dispatch({
						type: "edit_sub_del_option",
						id: state.edit_id,
						option_id: id,
					});
				}
			},
			onCancel() {},
		});
	};

	const animation_option_length = (e: any) => {
		if (ques.question_type === ANIMATION) {
			if (e.target.value.length === 20) {
				CustomToast("error", "Cannot Add More Than 20 Characters");
			}
		}
	};

	const getOptionTitle = (e: any) => {
		if (state.mode === 0) {
			dispatch({ type: "optionTitle", value: e.target.value, id: id });
			animation_option_length(e);
		}
		if (state.mode === 1) {
			dispatch({
				type: "sub_optionTitle",
				value: e.target.value,
				id: id,
			});
			animation_option_length(e);
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_sub_option_title",
				value: e.target.value,
				id: state.edit_id,
				option_id: id,
			});
			animation_option_length(e);
		}
	};

	let get_error_in_edit_mode = () => {
		if (
			state.edit_id !== null &&
			state?.edit_mode_validation?.length !== 0
		) {
			if (
				Object.keys(state?.edit_mode_validation[state?.edit_id])
					?.length !== 0
			) {
				setError(state?.edit_mode_validation[state?.edit_id]);
			} else {
				setError({});
			}
		}
	};

	useEffect(() => {
		get_error_in_edit_mode();
	}, [state.edit_id, state.edit_mode_validation]);
	let questionData = () => {
		let currentQState = state.mode;
		if (currentQState === 0) {
			setQues(state.question);
			setQuesTrans(state.question_traslation);
		}
		if (currentQState === 1) {
			setQues(state.subQuestion);
			setQuesTrans(state.sub_question_traslation);
		}
		if (currentQState === 2) {
			setQues(state.question.subQuestions[state.edit_id]);
			setQuesTrans(
				state.question.subQuestions[state.edit_id].question_traslation
			);
		}
		// let quData = currentQState == 0 ? state.question : state.subQuestion;
		// let quTransData =
		// 	currentQState == 0
		// 		? state.question_traslation
		// 		: state.sub_question_traslation;
		// setQues(quData);
		// setQuesTrans(quTransData);
	};
	useEffect(() => {
		questionData();
	}, [
		state.edit_id,
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	const correctAnswer = (id: any) => {
		if (state.mode === 0) {
			dispatch({ type: "optionIscorrect", value: id });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_optionIscorrect", value: id });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_suboption_iscorrect",
				value: id,
				id: state.edit_id,
			});
		}
	};

	const optionRender = () => {
		const currentQState = state.mode; // 0 = main , 1 = subQ , 2 = edit
		if (currentQState === 0) {
			setOptionCorrect(null);
			state.question.options.map((elem, index) => {
				if (elem.isCorrect === 1) {
					setOptionCorrect(index);
				}
			});
		}
		if (currentQState === 1) {
			setOptionCorrect(null);
			state.subQuestion.options.map((elem, index) => {
				if (elem.isCorrect === 1) {
					setOptionCorrect(index);
				}
			});
		}
		if (currentQState === 2) {
			setOptionCorrect(null);
			state.question.subQuestions[state.edit_id].options.map(
				(elem, index) => {
					if (elem.isCorrect === 1) {
						setOptionCorrect(index);
					}
				}
			);
		}
	};
	useEffect(() => {
		optionRender();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	return (
		<>
			<Col xs={24} xl={24} key={index}>
				<Row gutter={[24, 0]} justify="start" style={{ rowGap: "2px" }}>
					<Col xs={2} xl={2}>
						{ques.question_type !== FILL_IN_THE_BLANK && (
							<Radio
								// value={id}
								name="opt"
								checked={id === optionCorrect ? true : false}
								style={{ width: "100%" }}
								onChange={() => {
									correctAnswer(id);
								}}
								className={
									state.valid.correct_answer ||
									error.correct_answer
										? "optionChoice"
										: ""
								}
							/>
						)}
					</Col>

					<Col xs={14} xl={10} className="add-option-input">
						{/* <Input
							style={{
								color: "black",
								marginBottom: `${
									ques.question_type === ANIMATION
										? "10px"
										: ""
								}`,
							}}
							bordered={
								ques.question_type === TRUE_FALSE
									? false
									: true
							}
							disabled={
								ques.question_type !== TRUE_FALSE
									? false
									: true
							}
							status={
								state.valid[`option${id}`] ||
								error[`option${id}`]
									? "error"
									: ""
							}
							onChange={getOptionTitle}
							placeholder={`Eg: Option ${index + 1}`}
							value={elem.OptionTranslation[0].title}
							maxLength={
								ques.question_type === ANIMATION ? 20 : 100
							}
						/> */}
						{/* {ques.question_type === TRUE_FALSE ? ( */}
						{ques.question_type === TRUE_FALSE && (
							<span>{elem?.OptionTranslation[0]?.title}</span>
						)}
						{ques.question_type !== TRUE_FALSE && (
							<Editor
								class_name={
									state.valid[`option${id}`] ||
									error[`option${id}`]
										? "editorError"
										: ""
								}
								value={elem?.OptionTranslation[0]?.title}
								type="option"
								id={id}
								ques_type={ques?.question_type}
							/>
						)}
						{/* ) : (
							<Editor
								value={elem.OptionTranslation[0].title}
								type="option"
								id={id}
							/>
						)} */}
					</Col>

					<Col xs={4} xl={3}>
						{ques.question_type !== TRUE_FALSE && (
							<Button
								className="label"
								style={{
									float: "right",
									fontWeight: "bold",
								}}
								type="link"
								onClick={showPromiseConfirm}
							>
								<SvgDelete />
							</Button>
						)}
					</Col>

					<Col xs={4} xl={9} className="upload-option-image">
						{ques.question_type === TRUE_FALSE ||
						ques.question_type === ANIMATION ||
						ques.question_type === FILL_IN_THE_BLANK ? null : (
							<UploadCustom where="option" id={id} />
						)}
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default RadioOption;
