import React from "react";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Input, Tooltip } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { InputRef } from "antd";
import { Tag } from "antd";
import { useRef } from "react";
import "../../App.css";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	addPlaylistTag,
	edit_playlist_in_edit,
	edit_playlist_tag,
	tag_delete_form_array,
} from "../../redux/reducers/PlaylistSlice";
import InvalidMsg from "../create/InvalidMsg";
const { Text } = Typography;

const PlaylistTags = () => {
	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [tagsHandler, setTagsHandler] = useState([]);
	const inputRef = useRef<InputRef>(null);
	const editInputRef = useRef<InputRef>(null);
	const dispatched = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);

	const calTagsRender = () => {
		let mode = playlist.mode;
		// const tagList: any = playlist.tag;
		let tagList = mode === 0 ? playlist.tag : playlist.playlist_to_edit.tag;
		setTagsHandler(tagList);
	};
	useEffect(() => {
		calTagsRender();
	}, [playlist.tag]);

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
		calTagsRender();
	}, [inputValue, tagsHandler]);

	const handleClose = (tag) => {
		if (playlist.mode === 0 && playlist.isFromEvidya === 0) {
			// dispatched(tag_delete_form_array(tag));
			const newTags = playlist.tag?.filter((elme) => elme !== tag);
			dispatched(tag_delete_form_array(newTags));
		}
		if (playlist.mode === 1 && playlist.isFromEvidya === 0) {
			// dispatched(edit_playlist_in_edit(index));

			const newTags = playlist.playlist_to_edit.tag?.filter(
				(elme) => elme !== tag
			);
			dispatched(edit_playlist_in_edit(newTags));
		}
		if (playlist.mode === 1 && playlist.isFromEvidya === 1) {
			const newTags = playlist.playlist_to_edit.tag?.filter(
				(elme) => elme !== tag
			);
			dispatched(edit_playlist_in_edit(newTags));
		}
		if (playlist.isFromEvidya === 1) {
			// dispatched(tag_delete_form_array(tag));
			const newTags = playlist.tag?.filter((elme) => elme !== tag);
			dispatched(tag_delete_form_array(newTags));
		}
	};

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// console.log( e.target.value);

		let tagValue = e.target.value.toLowerCase();
		let newValue = tagValue.replace(/\s/g, "");
		setInputValue(newValue);
	};

	const handleInputConfirm = () => {
		if (inputValue && playlist.tag.indexOf(inputValue) === -1) {
			// console.log(playlist.isFromEvidya);
			if (playlist.mode === 0 && playlist.isFromEvidya === 0) {
				dispatched(addPlaylistTag(inputValue));
			}
			if (playlist.mode === 1 && playlist.isFromEvidya === 0) {
				dispatched(edit_playlist_tag(inputValue));
			}
			if (playlist.mode === 1 && playlist.isFromEvidya === 1) {
				dispatched(edit_playlist_tag(inputValue));
			}
			if (playlist.isFromEvidya === 1) {
				dispatched(addPlaylistTag(inputValue));
			}
		}

		setInputVisible(false);
		setInputValue("");
	};

	const handleEditInputConfirm = () => {
		setInputValue("");
	};

	// useEffect(() => {
	// 	dispatch(addPlaylistTag());
	// }, [playlist.mode]);

	const returnInput = (index: number = 0, defaultInputMode = 1) => {
		return (
			<Input
				allowClear
				ref={inputRef}
				type="text"
				size="large"
				className="tag-input"
				value={inputValue}
				maxLength={20}
				onChange={handleInputChange}
				onBlur={
					defaultInputMode
						? handleInputConfirm
						: handleEditInputConfirm
				}
				onPressEnter={
					defaultInputMode
						? handleInputConfirm
						: handleEditInputConfirm
				}
			/>
		);
	};

	const tagElem = (tag: string, isLongTag: boolean, index: number) => {
		return (
			<Tag
				className="edit-tag"
				key={tag}
				closable={true}
				color="geekblue"
				style={{ fontSize: "14px" }}
				onClose={() => handleClose(tag)}
			>
				<span
					onDoubleClick={(e) => {
						e.preventDefault();
					}}
				>
					{isLongTag ? `${tag.slice(0, 20)}...` : tag}
				</span>
			</Tag>
		);
	};

	return (
		<>
			<div className="width-100" style={{ padding: "5px" }}>
				<div className="question-mandatory-div">
					<Text type="danger" className="question-mandatory-label">
						*
					</Text>
					<Text className="question-mandatory-label">
						Playlist Tags
					</Text>
				</div>
			</div>
			<div className="width-100">
				{tagsHandler?.map((tag: any, index) => {
					const isLongTag = tag?.length > 20 ?? false;
					return isLongTag ? (
						<Tooltip title={tag} key={tag}>
							{tagElem(tag, isLongTag, index)};
						</Tooltip>
					) : (
						tagElem(tag, isLongTag, index)
					);
				})}
				{inputVisible ? (
					returnInput(0)
				) : (
					<Tag
						className="site-tag-plus"
						onClick={showInput}
						color={playlist.valid.tag ? "error" : "geekblue"}
					>
						<span>
							<TagOutlined /> New Tag
						</span>
					</Tag>
				)}
			</div>
			<div style={{ height: "16px" }}>
				{"tag" in playlist.valid && (
					<InvalidMsg msg={playlist.valid?.tag} />
				)}
			</div>
		</>
	);
};

export default PlaylistTags;
