import React from "react";

const SvgEdit = () => {
	return (
		<div>
			<svg
				fill="#ffffff"
				height="40"
				viewBox="0 0 30 30"
				width="40"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="m8 12h8v2h-8zm2 8h-4v-16h7v5h5v3.1l2-2v-2.1l-6-6h-8a2 2 0 0 0 -2 2v16a2 2 0 0 0 2 2h4zm-2-2h4.1l.9-.9v-1.1h-5zm12.2-5c.1 0 .3.1.4.2l1.3 1.3c.2.2.2.6 0 .8l-1 1-2.1-2.1 1-1c.1-.1.2-.2.4-.2m0 3.9-6.1 6.1h-2.1v-2.1l6.1-6.1z" />
			</svg>
		</div>
	);
};

export default SvgEdit;
