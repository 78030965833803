import React, { useContext } from "react";
import { Modal, Button } from "antd";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	playlist_edit_mode,
	push_questioninto_mainquestion,
	resetplaylistStoredata,
} from "../../redux/reducers/PlaylistSlice";
import { useNavigate } from "react-router-dom";

const CreatePlaylistAlertModal = ({ data }) => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	const dispatch_redux = useDispatch();
	const playlist = useSelector((state: RootState) => state.playlist);
	const navigate = useNavigate();

	const handleOk = () => {
		dispatch_redux(resetplaylistStoredata());
		dispatch_redux(playlist_edit_mode(0));
		data.map((val: { id: String; title: String; qu_type: String }) => {
			dispatch_redux(
				push_questioninto_mainquestion({
					id: val.id,
					title: val.title,
					qu_type: val.qu_type,
				})
			);
		});
		dispatch({ type: "open_create_playlist_modal", value: false });
		navigate("/createPlaylist");
	};

	const handleCancel = () => {
		dispatch({ type: "open_create_playlist_modal", value: false });
	};
	return (
		<>
			<Modal
				title="Create playlist"
				open={state.is_create_playlist_modal_open_in_qu_create}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="no" onClick={handleCancel}>
						No
					</Button>,
					<Button key="yes" type="primary" onClick={handleOk}>
						Yes
					</Button>,
				]}
			>
				<p>Did you want to create a playlist with these questions ?</p>
			</Modal>
		</>
	);
};

export default CreatePlaylistAlertModal;
