import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Layout,
	Button,
	Row,
	Col,
	Typography,
	Form,
	Input,
	Switch,
} from "antd";
import loginbg from "../assets/images/login_img.svg";

import { ApiConnector } from "../axios/apiClient";
import CustomToast from "../utlis/toast/CustomToast";
import { USER_LOGIN } from "../axios/AllBaseUrl";

const { Title } = Typography;
const { Content } = Layout;

interface Formdata {
	email: string;
	password: string;
	remember: boolean;
}
const SignIn = () => {
	let navigate = useNavigate();
	let localData: any = localStorage.getItem("user_data");
	const myObj = JSON.parse(localData);

	const [data, setData] = useState<Formdata>({
		email: myObj?.email,
		password: myObj?.password,
		remember: true,
	});

	function onChange(checked: any) {
		setData({ ...data, remember: checked });
	}
	const handle = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};
	const Login = async () => {
		if (data.email.length === 0 || data.password.length === 0) {
			CustomToast("error", "Email and password cant be blank");
		} else {
			let res = await ApiConnector("post", `${USER_LOGIN}`, {
				user_email: data.email,
				password: data.password,
			});
			if (res.data.ok === false) {
				CustomToast("error", res.data.why);
			}
			if (res.data.ok === true) {
				CustomToast("success", res.data.why);
				navigate("/home", { replace: true });
				localStorage.setItem(
					"token",
					JSON.stringify(res?.data?.data?.data)
				);
			}
			if (res.data.ok === true && data.remember === true) {
				localStorage.setItem(
					"user_data",
					JSON.stringify({
						email: data.email,
						password: data.password,
					})
				);
			}
			if (res.data.ok === true && data.remember === false) {
				localStorage.setItem(
					"user_data",
					JSON.stringify({ email: "", password: "" })
				);
			}
		}
	};
	return (
		<>
			{/* <Layout className="layout-default layout-signin">
				<Content className="signin"> */}
			<Row
				gutter={[36, 0]}
				justify="center"
				align="middle"
				className="signin-row"
			>
				<Col className="sign-img" xxl={6} xl={10} md={8} xs={16}>
					<img src={loginbg} alt="" />
				</Col>

				<Col className="signin-col" xxl={8} xl={14} md={14} xs={16}>
					<Title className="mb-15">Sign In</Title>
					<Title className="font-regular text-muted" level={5}>
						Enter your email and password to sign in
					</Title>
					<Form layout="vertical" className="row-col">
						<Form.Item
							className="username"
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message:
										"Please enter a valid email address",
								},
							]}
						>
							<Input
								placeholder="Email"
								name="email"
								onChange={handle}
								defaultValue={data?.email}
							/>
						</Form.Item>

						<Form.Item
							className="username"
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
							]}
						>
							<Input.Password
								name="password"
								type="password"
								placeholder="Password"
								onChange={handle}
								defaultValue={data?.password}
							/>
						</Form.Item>

						<Form.Item
							name="remember"
							className="aligin-center"
							valuePropName="checked"
						>
							<Switch defaultChecked onChange={onChange} />
							Remember me
							<Link
								style={{ padding: "0 0 0 42px" }}
								className="login-form-forgot"
								to="/forgotpassword_mail"
							>
								Forgot password?
							</Link>
						</Form.Item>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								style={{
									width: "100%",
								}}
								onClick={Login}
							>
								SIGN IN
							</Button>
						</Form.Item>
						<p className="font-semibold text-muted">
							Don't have an account?{" "}
							<Link to="/signup" className="text-dark font-bold">
								Sign Up
							</Link>
						</p>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default SignIn;
{
	/* </Content>
			</Layout> */
}
