import React, { useEffect, useState } from "react";
import { Col, Empty, Row, message } from "antd";
import { useNavigate } from "react-router-dom";
import ExamDashboardSearch from "../components/PlaylistCheck/ExamDashboardSearch";
import CheckPlaylistViewCard from "../components/PlaylistCheck/CheckPlaylistViewCard";
import { ApiConnector } from "../axios/apiClient";
import {
	CLASS_SECTION,
	DELETEPLAYLISTPUBLISHED,
	EDITPLAYLISTPUBLISHED,
	FETCHPUBLISHEDPLAYLIST,
	PLAYLIST_QUESTION_TIME_CHECK,
	PLAYLISTPUBLISHED,
	PLAYLISTSTATUS,
	TEACHERPUBLISHEDPLAYLIST,
} from "../axios/AllBaseUrl";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import CustomToast from "../utlis/toast/CustomToast";
import PlaylistPublishedModal from "../components/modal/PlaylistPublishedModal";
import EditPublishedPlaylistModal from "../components/modal/EditPublishedPlaylistModal";
import DynamicDeleteModal from "../components/modal/DynamicDeleteModal";

const ConductedExamsDashboard = () => {
	const incomingdata = FlagLocalhost();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [initialPlaylistDatat, setInitialPlaylistData] = useState<any>([]);

	let [playlistData, setPlaylistData] = useState<any>([]);
	// console.log("playlistData", playlistData);

	let [playlistStatus, setPlaylistStatus] = useState<any>([
		{
			exam_playlist_id: "",
			status: 0,
		},
	]);
	const [messageApi, contextHolder] = message.useMessage();
	const [editModalData, setEditModalData] = useState<any>({
		isOpen: false,
		playlistId: "",
		examData: {},
	});
	const [publishmodal, setPublishmodal] = useState(false);
	const [deleteId, setDeleteId] = useState<any>({
		deleteId: "",
		exam_playlist_id: "",
		PlaylistName: "",
	});
	const [playlistID, setPlaylistID] = useState();
	let [selectClass, setSelectClass] = useState<any>([{}]);
	let [classId, setClassId] = useState<string>("");
	let [selectSection, setSelectSection] = useState<any>([{}]);
	let [sectionId, setSectionId] = useState<any>("");

	let [startTime, setStartTime] = useState<any>("");
	// console.log("startTime", startTime);
	let [endTime, setEndTime] = useState<any>("");
	// console.log("endTime", endTime);
	let [startDate, setStartDate] = useState<any>("");
	// console.log("startDate", startDate);
	let [endDate, setEndDate] = useState<any>("");
	// console.log("endDate", endDate);
	// console.log("editModalData", editModalData);
	let [deleteModal, setDeleteModal] = useState<any>("");
	let [reload, setReload] = useState<boolean>(true);
	let [questionTime, setQuestionTime] = useState<any>([
		{
			exam_playlist_id: "",
			time_check: 1,
		},
	]);
	let [editId, setEditId] = useState("");

	const navigate = useNavigate();
	const navigateToStudentList = (
		id,
		name,
		marks,
		start_date,
		otherSidePlaylistData
	) => {
		navigate("/attendStudentList", {
			state: {
				playlist_id: id,
				playlist_name: name,
				playlist_fullMarks: marks,
				start_date: start_date,
				otherSidePlaylistData: otherSidePlaylistData,
			},
		});
	};
	const onSearch = (value: string) => console.log(value);
	const handleSearch = (name: string) => {
		if (name?.trim() === "") {
			setPlaylistData(initialPlaylistDatat);
			return;
		}
		const filteredStudents = playlistData?.filter((playlist) =>
			playlist?.playlist_name
				?.toLowerCase()
				?.includes(name?.toLowerCase())
		);
		setPlaylistData(filteredStudents);
	};

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
		handleSearch(event.target.value);
	};
	// console.log("incomingdata?.user_id", incomingdata?.user_id);
	// console.log(" incomingdata?.school_id", incomingdata?.school_id);
	// console.log(
	// 	" incomingdata?.academic_year_id",
	// 	incomingdata?.academic_year_id
	// );

	// console.log(" incomingdata?.user_type_id", incomingdata?.user_type_id);
	let fetchPlaylist = async () => {
		let res = await ApiConnector("post", `${FETCHPUBLISHEDPLAYLIST}`, {
			// user_id: "81",
			// school_id: "43",
			// academic_year_id: "2",
			// type_id: "4",

			// evidya user_id

			user_id: incomingdata?.user_id,
			school_id: incomingdata?.school_id,
			academic_year_id: incomingdata?.academic_year_id,
			type_id: incomingdata?.user_type_id,
		}); //local
		// console.log("res ", res);

		if (res.data.ok) {
			setPlaylistData(res.data.data);
			setInitialPlaylistData(res.data.data);
		}
		// console.log("res", res);
	};
	const Published = (id, examData) => {
		// console.log("examData", examData);

		setStartTime(examData?.start_time);
		setEndTime(examData?.end_time);
		setEndDate(examData?.end_date);
		setStartDate(examData?.start_date);
		setPlaylistID(id);
		// setSelectClass([{}]);
		// setSelectSection([{}]);
		setSectionId(examData?.section_id);
		setClassId(examData?.class_id);
		setEditId(examData?.id);

		// setPublishmodal(true);
		if (incomingdata?.isFromEvidya === 1 && id) {
			setPublishmodal(true);
		} else {
			messageApi.open({
				type: "info",
				content: "Published For our Teacher's Only ",
			});
		}
	};
	const publishCancel = () => {
		setEditId("");
		setStartTime("");
		setEndTime("");
		setEndDate("");
		setStartDate("");
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId([]);
		setPublishmodal(false);
	};
	const Playlistpublish = async () => {
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");
		let res = await ApiConnector("post", `${EDITPLAYLISTPUBLISHED}`, {
			playlist_id: playlistID,
			class_id: classId,
			section_id: sectionId,
			teacher_id: incomingdata?.user_id,
			start_date: startDate,
			end_date: endDate,
			start_time: startTime,
			end_time: endTime,
			exam_playlist_id: editId,
		});
		// console.log(" playlist publish res", res);
		if (res?.data?.ok) {
			setPublishmodal(false);
			messageApi.open({
				type: "success",
				content: "Edit Published Playlist successfully",
			});
			setReload(true);
		} else {
			messageApi.open({
				type: "error",
				content: "Edit Published Playlist failed",
			});
		}
	};
	let classIdSet = (e) => {
		setSelectSection([{}]);
		setSectionId("");
		setClassId(e.target.value);
	};
	let sectionIdSet = (e) => {
		setSectionId(e.target.value);
	};

	const handleEndTime = (e) => {
		setEndTime(e);
	};
	const handleStartTime = (e) => {
		setStartTime(e);
	};
	let fetchClass = async () => {
		try {
			setSelectClass([{}]);
			// setSelectSection([{}]);
			// setSectionId("");
			let res = await ApiConnector("post", `${CLASS_SECTION}`, {
				schoolId: incomingdata?.school_id,
				userId: incomingdata?.user_id,
				userTypeId: incomingdata?.user_type_id,
				// schoolId: "81",
				// userId: "43",
				// userTypeId: "4",
			});
			if (res?.data?.ok) {
				setSelectClass(res?.data?.data?.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	let deleteData = (del_id, exam_playlist_id, name) => {
		setDeleteId({
			deleteId: del_id,
			exam_playlist_id: exam_playlist_id,
			PlaylistName: name,
		});
		setDeleteModal(true);
	};
	const deleteConfirm = async () => {
		try {
			let res = await ApiConnector("post", `${DELETEPLAYLISTPUBLISHED}`, {
				playlist_id: deleteId?.deleteId,
				exam_playlist_id: deleteId?.exam_playlist_id,
				teacher_id: incomingdata?.user_id,
			});

			// let res = await ApiConnector("post", `${PLAYLISTSTATUS}`, {
			// 	// user_id: "81", //local
			// 	user_id: incomingdata?.user_id,
			// 	playlist_id: deleteId?.deleteId,
			// 	status: 0,
			// });
			// console.log("deleteConfirm", res);

			if (res?.data?.ok) {
				setDeleteModal(false);
				messageApi.open({
					type: "success",
					content: "Delete Published Playlist successfully",
				});
				setReload(true);
			} else {
				messageApi.open({
					type: "error",
					content: "Delete Published Playlist failed",
				});
			}
		} catch (error) {}
	};
	const deleteCancel = () => {
		setDeleteModal(false);
	};

	let StatusCheck = async (playlist_id, status, mode, exam_playlist_id) => {
		// console.log(playlist_id, status);
		if (mode === 1) {
			setPlaylistStatus((prevMarksArray) => {
				const index = playlistStatus.findIndex(
					(item) => item?.exam_playlist_id === exam_playlist_id
				);

				if (index !== -1) {
					// If the playlist_id exists, update its marks
					const updatedArray = [...playlistStatus];
					// updatedArray[index] = {
					updatedArray[index].exam_playlist_id = exam_playlist_id;
					updatedArray[index].status = status;
					// };
					return updatedArray;
				} else {
					// If the playlist_id doesn't exist, add a new object
					return [
						...prevMarksArray,
						{
							exam_playlist_id: exam_playlist_id,
							status: status,
						},
					];
				}
			});
		}
		if (mode === 0) {
			setPlaylistStatus((prevMarksArray) => {
				const index = playlistStatus.findIndex(
					(item) => item?.exam_playlist_id === exam_playlist_id
				);

				if (index !== -1) {
					// If the exam_playlist_id exists, update its marks
					const updatedArray = [...playlistStatus];
					// updatedArray[index] = {
					updatedArray[index].exam_playlist_id = exam_playlist_id;
					updatedArray[index].status = status;
					// };
					return updatedArray;
				} else {
					// If the exam_playlist_id doesn't exist, add a new object
					return [
						...prevMarksArray,
						{
							exam_playlist_id: exam_playlist_id,
							status: status,
						},
					];
				}
			});
			let res = await ApiConnector("post", `${PLAYLISTSTATUS}`, {
				// user_id: "81", //local
				user_id: incomingdata?.user_id,
				playlist_id: playlist_id,
				status: status,
				exam_playlist_id: exam_playlist_id,
			});

			if (res?.data?.ok && status === 1) {
				CustomToast("success", "Playlist Active For Student");
			}
			if (res?.data?.ok && status === 2) {
				CustomToast("error", "Playlist Inactive For Student");
			}
		}
	};

	let QuestionTimeCheck = async (
		playlist_id,
		check,
		mode,
		exam_playlist_id
	) => {
		//mode for useEffect api call off
		if (mode === 1) {
			setQuestionTime((prevQuestionTime) => {
				const index = questionTime.findIndex(
					(item) => item?.exam_playlist_id === exam_playlist_id
				);

				if (index !== -1) {
					// If the playlist_id exists, update its marks
					const updatedArray = [...playlistStatus];
					// updatedArray[index] = {
					updatedArray[index].exam_playlist_id = exam_playlist_id;
					updatedArray[index].time_check = check;
					// };
					return updatedArray;
				} else {
					// If the exam_playlist_id doesn't exist, add a new object
					return [
						...prevQuestionTime,
						{
							exam_playlist_id: exam_playlist_id,
							time_check: check,
						},
					];
				}
			});
		} else {
			setQuestionTime((prevQuestionTime) => {
				const index = questionTime.findIndex(
					(item) => item?.exam_playlist_id === exam_playlist_id
				);

				if (index !== -1) {
					// If the exam_playlist_id exists, update its marks
					const updatedArray = [...playlistStatus];
					// updatedArray[index] = {
					updatedArray[index].exam_playlist_id = exam_playlist_id;
					updatedArray[index].time_check = check;
					// };
					return updatedArray;
				} else {
					// If the exam_playlist_id doesn't exist, add a new object
					return [
						...prevQuestionTime,
						{
							exam_playlist_id: exam_playlist_id,
							time_check: check,
						},
					];
				}
			});
			let res = await ApiConnector(
				"post",
				`${PLAYLIST_QUESTION_TIME_CHECK}`,
				{
					// user_id: "81", //local
					teacher_id: incomingdata?.user_id,
					playlist_id: playlist_id,
					time_check: check,
					exam_playlist_id: exam_playlist_id,
				}
			);

			if (res?.data?.ok && check === 1) {
				CustomToast("success", "Question time checked true");
			}
			if (res?.data?.ok && check === 0) {
				CustomToast("warning", "Question time checked false");
			}
		}
	};
	// console.log("playlistStatus", playlistStatus);

	// console.log("playlistData ", playlistData);
	useEffect(() => {
		setReload(false);
		fetchPlaylist();
	}, [incomingdata?.user_id, reload]);
	useEffect(() => {
		// setClassId("");
		// setSelectClass([{}]);
		// setSelectSection([{}]);
		fetchClass();
	}, [publishmodal]);
	useEffect(() => {
		// setSelectSection([{}]);
		// setSectionId("");
		if (incomingdata?.isFromEvidya === 1) {
			if (classId) {
				// setSelectSection([{}]);
				// setSectionId("");
				selectClass?.map((elm) => {
					if (classId == elm.id) {
						setSelectSection(elm.mappedSections);
					}
				});
			}
		}
	}, [publishmodal, classId]);

	return (
		<>
			<div style={{ padding: "3rem" }}>
				{contextHolder}
				<Row gutter={12}>
					<Col span={12} style={{ margin: "0 auto 2rem auto" }}>
						<ExamDashboardSearch
							onSearch={onSearch}
							placeholder="Search Exam..."
							handleChange={handleChange}
							searchTerm={searchTerm}
						/>
					</Col>
				</Row>
				{playlistData?.length === 0 && <Empty />}
				{playlistData?.length !== 0 && (
					<Row gutter={24}>
						{playlistData?.map((value, index) => {
							return (
								<React.Fragment key={index}>
									<Col xl={6} lg={8} md={12} sm={12}>
										<CheckPlaylistViewCard
											value={value}
											navigateToStudentList={
												navigateToStudentList
											}
											StatusCheck={StatusCheck}
											playlistStatus={playlistStatus}
											Published={Published}
											deleteData={deleteData}
											QuestionTimeCheck={
												QuestionTimeCheck
											}
											questionTime={questionTime}
										/>
									</Col>
								</React.Fragment>
							);
						})}
					</Row>
				)}
			</div>
			<EditPublishedPlaylistModal
				publishmodal={publishmodal}
				publishCancel={publishCancel}
				Playlistpublish={Playlistpublish}
				classIdSet={classIdSet}
				classId={classId}
				selectClass={selectClass}
				sectionIdSet={sectionIdSet}
				selectSection={selectSection}
				sectionId={sectionId}
				startDate={startDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startTime={startTime}
				setStartTime={setStartTime}
				setEndTime={setEndTime}
				endTime={endTime}
				endDate={endDate}
				handleEndTime={handleEndTime}
				handleStartTime={handleStartTime}
			/>
			<DynamicDeleteModal
				deleteModal={deleteModal}
				deleteConfirm={deleteConfirm}
				deleteCancel={deleteCancel}
				deleteMSg={`Are you sure you want to delete  ${deleteId.PlaylistName}? `}
			/>
		</>
	);
};

export default ConductedExamsDashboard;
