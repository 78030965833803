import "react-quill/dist/quill.snow.css";
import { Card, Col, Row, Form, Space, Button, Tooltip } from "antd";

import React, { useContext, useEffect, useState } from "react";
import AddTags from "./AddTags";
import AddQuestionDetails from "./AddQuestionDetails";

import createQuesContext from "../../context/createQuestion/createQuesContext";
import AddSubQuesCard from "./AddSubQuesCard";
import Main_question from "./Main_question";

import SvgPlus from "../svg/SvgPlus";
import SvgDelete from "../svg/SvgDelete";

import CustomToast from "../../utlis/toast/CustomToast";
import Validate from "../../utlis/validateQuestionform/Validate";
import Paginations from "./Paginations";

import Preview from "../Preview/Preview";
import {
	ANIMATION,
	FILL_IN_THE_BLANK,
	IQ,
	MCQ,
} from "../../utlis/constants/questionType";

const CreateQuestionComponent = (prop: any) => {
	const { pageData } = prop;
	const questiondata: any = useContext(createQuesContext);
	let { state, dispatch } = questiondata;

	let [ques, setQues] = useState<any>({});
	let [quesTrans, setQuesTrans] = useState<any>({});
	// let [valid, setValid] = useState<any>({});

	let questionData = () => {
		let currentQState = state.mode;
		let quData = currentQState == 0 ? state.question : state.subQuestion;
		let quTransData =
			currentQState == 0
				? state.question_traslation
				: state.sub_question_traslation;
		setQues(quData);
		setQuesTrans(quTransData);
	};
	useEffect(() => {
		questionData();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	let quesionToSubquestion = () => {
		// let validateRes = validateQuestionData();
		// questionData();
		if (state.mode === 2) {
			let res: object[] = state.question?.subQuestions?.map(
				(subq, index) => {
					let validateRes = Validate(
						subq,
						subq.question_traslation[0]
					);
					return validateRes;
				}
			);
			dispatch({ type: "edit_validation", value: res });
			let error_res: Number[] = res?.map((error, index) => {
				if (Object.keys(error)?.length !== 0) {
					return 1;
				} else {
					return 0;
				}
			});
			if (!error_res.includes(1)) {
				dispatch({ type: "editToSubQues" });
			} else {
				for (const key in res[state.edit_id]) {
					CustomToast("error", res[state.edit_id][key]);
				}
			}
		}
		if (state.mode === 0 || state.mode === 1) {
			let validateRes = Validate(ques, quesTrans);
			dispatch({ type: "validateForm", value: validateRes });
			// console.log(validateRes);

			if (Object.keys(validateRes).length !== 0) {
				for (const key in validateRes) {
					CustomToast("error", validateRes[key]);
				}
			} else {
				if (state.mode === 0) {
					// dispatch({ type: "addTranslationMainQues" });
					dispatch({ type: "subquestionForm" });
					// dispatch({ type: "clearSetOptionvalue" });
				}
				if (state.mode === 1) {
					dispatch({ type: "fullSubQuestion" });
					dispatch({ type: "addSubInMainQuestion" });
					dispatch({ type: "cleanOldData" });
					// dispatch({ type: "clearSetOptionvalue" });
				}
				if (
					state.subQuestion.question_type === MCQ ||
					state.subQuestion.question_type === IQ
				) {
					dispatch({ type: "onchangeSubMcq" });
					// dispatch({ type: "clearSetOptionvalue" });
				}
				if (state.subQuestion.question_type === FILL_IN_THE_BLANK) {
					dispatch({ type: "onchangeSubFillInTheBlank" });
				}
				if (state.subQuestion.question_type === ANIMATION) {
					dispatch({ type: "onchangeSubAnimation" });
				}
			}
		}
	};

	return (
		<>
			<Space
				direction="vertical"
				size="middle"
				style={{ display: "flex" }}
			>
				<Card bordered={false} className="criclebox h-full width-100">
					<div className="width-100">
						<h3>
							{state.mode === 0
								? "Create New Question"
								: state.mode === 1
								? "Create Sub Question"
								: "Edit question"}
						</h3>
						<AddTags pageData={pageData} />

						<AddQuestionDetails />
					</div>
				</Card>
				{state.mode !== 0 && (
					<AddSubQuesCard
						heading=" Questions"
						number="Question Number"
						question="Your Primary Question is here"
					/>
				)}
				<Card bordered={false} className="criclebox h-full width-100">
					<div className="width-100">
						<Form layout="vertical" className="row-col full-width">
							<Row justify="space-between" align="middle">
								<Col xl={24} xxl={24}>
									<Space
										direction="vertical"
										style={{
											display: "block",
											height: "80px",
										}}
									>
										{/* {state.mode !== 2 && ( */}
										<Tooltip
											placement="left"
											title="Add Sub Question"
										>
											<Button
												className="AddSubQues-btn"
												onClick={
													// SetSubShow(true);
													quesionToSubquestion
												}
												shape="circle"
												type="link"
												size="large"
											>
												<SvgPlus />
											</Button>
										</Tooltip>
										{/* )} */}
										{/* <Tooltip
											placement="left"
											title="Delete"
										> */}
										<Button
											className="AddSubQues-btn-delete"
											shape="circle"
											type="link"
											size="large"
										>
											<SvgDelete />
										</Button>
										{/* </Tooltip> */}
										{state.question.subQuestions.length >
											0 && (
											// state.question.subQuestions
											// 	.length !== undefined &&
											<Paginations />
										)}
									</Space>
									<Main_question />
								</Col>
							</Row>
						</Form>
					</div>
				</Card>
			</Space>
			<Row>
				<div className="submit-btns">
					<Col>
						<Preview />
					</Col>
				</div>
			</Row>
		</>
	);
};

export default CreateQuestionComponent;
