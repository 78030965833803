import { Navigate } from "react-router-dom";

const hasToken = () => {
	let isToken = localStorage.getItem("token");

	if (isToken === "undefined") {
		return false;
	}

	return isToken;
};

export const ProtectedSignInRouting = ({ children }) => {
	let isToken = hasToken();
	return <>{!isToken ? children : <Navigate to={"/home"} />}</>;
};

export const ProtectedRouting = ({ children }) => {
	let isToken = hasToken();
	return <>{isToken ? children : <Navigate to={"/"} />}</>;
	// return <>{isToken === undefined ? <Navigate to={"/"} /> : children}</>;
};
