/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import React, { useState } from "react";

function Footer() {
	const { Footer: AntFooter } = Layout;
	let d = new Date();
	let [year] = useState(d.getFullYear());

	return (
		<AntFooter>
			<Row
				className="just width-100"
				style={{
					position: "fixed",
					bottom: "0px",
					left: "0px",
					width: "100%",
				}}
			>
				<Col className="width-100" style={{ textAlign: "center" }}>
					<div className="copyright width-100">
						&copy; {year},{<HeartFilled />}
						<a
							href="#!"
							className="font-weight-bold"
							target="_blank"
						>
							Adhayan Digital Pvt. Ltd.
						</a>
					</div>
				</Col>
				{/* <Col xs={24} md={12} lg={12}>
					<div className="footer-menu">
						<ul>
							<li className="nav-item">
								<a
									href="#pablo"
									className="nav-link text-muted"
									target="_blank"
								>
									Creative Tim
								</a>
							</li>
							<li className="nav-item">
								<a
									href="#pablo"
									className="nav-link text-muted"
									target="_blank"
								>
									About Us
								</a>
							</li>
							<li className="nav-item">
								<a
									href="#pablo"
									className="nav-link text-muted"
									target="_blank"
								>
									Blog
								</a>
							</li>
							<li className="nav-item">
								<a
									href="#pablo"
									className="nav-link pe-0 text-muted"
									target="_blank"
								>
									License
								</a>
							</li>
						</ul>
					</div>
				</Col> */}
			</Row>
		</AntFooter>
	);
}

export default Footer;
