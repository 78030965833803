import React, { useState, useRef, useEffect } from "react";

const MathSymbol = ({ onChange }) => {
	const editorRef = useRef<HTMLDivElement | null>(null);
	const [content, setContent] = useState<string>("");
	const [fontSize, setFontSize] = useState<string>("2");

	const handleChange = () => {
		if (editorRef.current) {
			setContent(editorRef.current.innerHTML);
			onChange(editorRef.current.innerHTML);
		}
	};

	const handleInsertSymbol = (symbol: string) => {
		if (!editorRef.current) return;
		if (editorRef.current) {
			document.execCommand("styleWithCSS", false, "true");

			const fontSizeStyle = `font-size: "${fontSize}px"`;
			const html = `<span style={{${fontSizeStyle}, border: "1px solid black", fontWeight: "bold"}}>${symbol}</span>`;

			document.execCommand("insertHTML", false, html);
		}
	};
	const handleInsertFraction = () => {
		const numerator = prompt("Enter the numerator:");
		const denominator = prompt("Enter the denominator:");

		if (numerator && denominator) {
			const fraction = `<span><sup>${numerator}</sup>/<sub>${denominator}</sub></span>`;
			handleInsertSymbol(fraction);
		}
	};
	let SymbolsArray = [
		"&#176;",
		"&radic;",
		"&times;",
		"&divide;",
		"&plusmn;",
		"&pi;",
		"&Sigma;",
		"&int;",
		"&#x2264;",
		"&#x2265;",
		"&#8895;",
		"&#x25AD;",
		"&#9634;",
		"&#x25EF;",
		"&#x2B21;",
		"&#x2606;",
		"&alpha;",
		"&beta;",
		"&gamma;",
		"&lambda;",
		"&theta;",
		"&Omega;",
		"&ne;",
		"&mu;",
		"&sigma;",
		"&#8745;",
		"&#8746;",
	];

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.innerHTML = "";
			// setContent(editorRef.current.innerHTML);
		}
	}, []);
	return (
		<>
			{/* <div className="text-editor-container"> */}
			{/* <aside style={{ fontSize: "0.85rem", fontWeight: "600" }}>
				Symbols:
			</aside> */}
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center",
					gap: "10px",
					fontSize: "1.25rem",
					border: "1px solid #c8c8c8",
					// borderRadius: "6px",
					padding: "8px",
				}}
			>
				<button onClick={handleInsertFraction}>
					<strong>&#189;</strong>
				</button>
				{SymbolsArray?.map((value, index) => {
					return (
						<React.Fragment key={index}>
							<button onClick={() => handleInsertSymbol(value)}>
								<strong
									dangerouslySetInnerHTML={{ __html: value }}
								/>
							</button>
						</React.Fragment>
					);
				})}
			</div>
			{/* <div style={{ height: "10px" }}></div> */}
			{
				<div
					ref={editorRef}
					style={{ fontSize: "24px" }}
					// contentEditable
					// className="editor"
					// className={className}
					onInput={handleChange}
					// onInput={onChange}
				/>
			}
		</>
	);
};

export default MathSymbol;
