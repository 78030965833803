import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
	FloatingMenu,
	Directions,
	MainButton,
	ChildButton,
} from "react-floating-button-menu";
import {
	CloseOutlined,
	CustomerServiceOutlined,
	DiffOutlined,
	FormOutlined,
	LikeOutlined,
	MenuUnfoldOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import LikedSvg from "../svg/LikedSvg";
import SvgHome from "../svg/SvgHome";

const AddFloatingButton = () => {
	let [show, setShow] = useState<boolean>(false);
	let [navi, setNavi] = useState<number>();
	let navigate = useNavigate();
	const click = () => {
		navigate("/create");
		setNavi(0);
	};
	const playlistbutton = () => {
		navigate("/playlist");
		setNavi(1);
	};
	const createplaylistbutton = () => {
		navigate("/createPlaylist");
		setNavi(2);
	};
	const likeClick = () => {
		navigate("/liked_page");
		setNavi(3);
	};
	const homeClick = () => {
		navigate("/home");
		setNavi(4);
	};
	return (
		<>
			{/* <div className="floating-button">
				<Tooltip placement="leftBottom" title={text}>
					<Button type="primary" shape="round" onClick={click}>
						Create
					</Button>
				</Tooltip>
			</div> */}
			<FloatingMenu
				slideSpeed={500}
				direction={Directions.Up}
				spacing={8}
				isOpen={show}
			>
				<MainButton
					iconResting={<FormOutlined />}
					iconActive={<CloseOutlined style={{ fontSize: 24 }} />}
					background="white"
					onClick={() => setShow(!show)}
					size={56}
				/>
				{/* {navi === 1 && navi === 2&&} */}
				<ChildButton
					icon={
						<Tooltip placement="left" title="Create New Question">
							<PlusOutlined />
						</Tooltip>
					}
					background="#00305A"
					size={50}
					onClick={click}
				/>
				<ChildButton
					icon={
						<Tooltip placement="left" title="Create Playlist">
							<DiffOutlined />
						</Tooltip>
					}
					background="#00305A"
					size={50}
					onClick={createplaylistbutton}
				/>
				<ChildButton
					icon={
						<Tooltip placement="left" title="My Playlist">
							<MenuUnfoldOutlined />
						</Tooltip>
					}
					background="#00305A"
					size={50}
					onClick={playlistbutton}
				/>
				<ChildButton
					icon={
						<Tooltip placement="left" title="Liked Page">
							<LikedSvg />
						</Tooltip>
					}
					background="#00305A"
					size={50}
					onClick={likeClick}
				/>
				<ChildButton
					icon={
						<Tooltip placement="left" title="Home Page">
							<SvgHome />
						</Tooltip>
					}
					background="#00305A"
					size={50}
					onClick={homeClick}
				/>
			</FloatingMenu>
		</>
	);
};

export default AddFloatingButton;
