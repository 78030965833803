import React, { useEffect, useState } from "react";
// import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
import { TimePicker } from "react-ios-time-picker";

const CustomTimePicker = ({ changeTime, startTime }) => {
	// const timePickerRef = useRef(null);
	const currentDate = new Date();
	const hours = currentDate.getHours();
	const minutes = currentDate.getMinutes();
	const seconds = currentDate.getSeconds();
	let time = `${hours}:${minutes}`;
	const [value, setValue] = useState<any>(startTime);
	// console.log("time", value);
	// useEffect(() => {
	// 	if (startTime.length === 0) {
	// 		// console.log(startTime.length);

	// 		setValue(time);
	// 	}
	// }, [startTime]);
	useEffect(() => {
		setValue(startTime);
	}, [startTime]);

	return (
		<>
			<TimePicker
				// ref={timePickerRef}
				onChange={changeTime}
				// value={value?.length > 0 ? value : undefined}
				// minTime={startTime}
				placeHolder={"Select Time"}
				//selected={startDate}
			/>
		</>
	);
};

export default CustomTimePicker;
