import { createSlice } from "@reduxjs/toolkit";
import { FetchPlaylistThunk, ViewPlaylistThunk } from "../thunk/RootThunk";
import FlagLocalhost from "../../Localhost/FlagLocalhost";

export interface one_question_id_interface {
    q_id: string;
    title: string;
    marks: Number;
    has_negetive_marks: boolean;
    negative: Number;
    is_cal_level: boolean;
    cal_level: Number;
    percentage: string;
    set_time: string;
    is_set_time: boolean;
    qu_type: string;
}
export interface playlist_file {
    file_type: String;
    url: String;
    file_name: String;
}
export interface assData {
    classId: String;
    chapId: String;
    topicId: String;
    subjectId: String;
}
export interface edit_data {
    _id: String;
    playlist_name: String;
    full_marks: string;
    playlist_time: String;
    tag: String[];
    file: playlist_file;
    questionIndex: string;
    one_question_id: one_question_id_interface;
    question_ids: one_question_id_interface[];
}
export interface PlaylistState {
    valid: any;
    questionIndex: string;
    mode: Number;
    playlistName: string;
    tag: string[];
    file: playlist_file;
    question_ids: one_question_id_interface[];
    loading: boolean;
    playlist_loading: boolean;
    one_question_id: one_question_id_interface;
    error: any;
    playlistData: [];
    playlists: object[];
    playlist_time: string;
    full_marks: string;
    playlist_to_edit: edit_data;
    isFromEvidya: Number;
    assDataID: assData;
    playlistQuestions: any;
    currentPage: number;
    totalPages: number;
    totalItems: number;
    tokenExpiredError: boolean;

}
let flag = FlagLocalhost();

let tag = flag
    ? [
        flag?.shareCName,
        flag?.shareChapName,
        flag?.shareSName,
        flag?.shareTopicName,
    ]
    : [];
const tagLower = tag?.map((item) => item?.toLowerCase());
const trimmedTag = tagLower?.map((item) => item?.trim());
const filteredTag = trimmedTag?.filter(
    (item) => item !== "" && item !== undefined
);

export const initialState: PlaylistState = {
    playlist_time: "",
    full_marks: "",
    valid: {},
    questionIndex: "0",
    mode: 0, // 0 = add playlist mode , 1= edit playlist mode
    playlistName:
        flag?.isFromEvidya === 1 && flag?.where === 0 ? flag?.assTitle : "",
    // playlistName: "",
    tag:
        flag?.isFromEvidya === 1 &&
            flag?.where === 0 &&
            flag?.assTitle &&
            flag?.shareChapName
            ? filteredTag
            : [],
    // tag: [],
    file: {
        file_type: "",
        url: "",
        file_name: "",
    },
    question_ids: [],
    loading: false,
    playlist_loading: false,
    error: null,
    one_question_id: {
        q_id: "",
        title: "",
        marks: 0,
        has_negetive_marks: false,
        negative: 0,
        is_cal_level: false,
        cal_level: 0,
        percentage: "",
        is_set_time: false,
        set_time: "",
        qu_type: "",
    },
    playlistData: [],
    playlists: [],
    playlistQuestions: {},
    playlist_to_edit: {
        _id: "",
        playlist_name: "",
        full_marks: "",
        playlist_time: "",
        tag: [],
        file: { file_type: "", url: "", file_name: "" },
        questionIndex: "0",
        one_question_id: {
            q_id: "",
            title: "",
            marks: 0,
            has_negetive_marks: false,
            negative: 0,
            is_cal_level: false,
            cal_level: 0,
            percentage: "",
            is_set_time: false,
            set_time: "",
            qu_type: "",
        },
        question_ids: [],
    },
    isFromEvidya: flag?.isFromEvidya === 1 ? flag?.isFromEvidya : 0,
    assDataID: {
        classId: "",
        chapId: "",
        topicId: "",
        subjectId: "",
    },
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    tokenExpiredError: false
};

export const PlaylistFetch: any = FetchPlaylistThunk;

export const ViewPlaylist: any = ViewPlaylistThunk;

export const PlaylistSlice = createSlice({
    name: "playlist",
    initialState,
    reducers: {

        FromEvidya: (state, action) => {
            // console.log(action.payload);

            state.isFromEvidya = action.payload.isFromEvidya;

            // .assData.isFromEvidya
            state.assDataID = {
                classId: action.payload.classId,
                chapId: action.payload.chapId,
                topicId: action.payload.topicId,
                subjectId: action.payload.subjectId,
            };
            // state.error=action.payload
        },
        playlist_timer: (state, action) => {
            if (state.mode === 0) {
                let roundTime = (Math.round(action.payload * 100) / 100).toFixed(2)
                state.playlist_time = roundTime;
            }
            if (state.mode === 1) {
                let roundTime = (Math.round(action.payload * 100) / 100).toFixed(2)
                state.playlist_to_edit.playlist_time = roundTime;
            }
        },
        playlist_fullmarks: (state, action) => {
            if (state.mode === 0) {
                state.full_marks = action.payload;
            }
            if (state.mode === 1) {
                state.playlist_to_edit.full_marks = action.payload;
            }
        },
        currentQuestionid: (state, action) => {
            if (state.mode === 0) {
                state.questionIndex = action.payload;
            }
            if (state.mode === 1) {
                state.playlist_to_edit.questionIndex = action.payload;
            }
        },
        pictureadded: (state, action) => {
            if (state.mode === 0) {
                state.file = action.payload;
            }
            if (state.mode === 1) {
                state.playlist_to_edit.file = action.payload;
            }
        },
        remove_playlist_file: (state) => {
            state.file = { file_type: "", url: "", file_name: "" };
        },
        playlist_edit_mode: (state, action) => {
            state.mode = action.payload;
        },
        questionadded: (state, action) => {
            if (state.mode === 0) {
                state.one_question_id.q_id = action.payload;
            }
            if (state.mode === 1) {
                state.playlist_to_edit.one_question_id.q_id = action.payload;
            }
        },
        question_delete_form_array: (state, action) => {
            if (state.mode === 0) {
                state.question_ids.splice(action.payload, 1);
            }
            if (state.mode === 1) {
                state.playlist_to_edit.question_ids.splice(action.payload, 1);
            }
        },
        oneQuestionMarks: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.marks = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.marks = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionisNegativemarks: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.has_negetive_marks = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.has_negetive_marks = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionNegativemarks: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.negative = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.negative = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionisCalculation: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.is_cal_level = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.is_cal_level = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionCalculate: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.cal_level = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.cal_level = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionPercentage: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.percentage = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.percentage = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionis_settime: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.is_set_time = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.is_set_time = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        oneQuestionsettime: (state, action) => {
            if (state.mode === 0) {
                let all_Q = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[action.payload.id]
                );
                one_Q.set_time = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[action.payload.id]
                );
                one_Q.set_time = action.payload.value;
                all_Q.splice(action.payload.id, 1, one_Q);
                state.playlist_to_edit.question_ids = all_Q;
            }
        },

        other_site_tag: (state, action) => {
            state.tag = action.payload;
        },
        push_questioninto_mainquestion: (state, action) => {
            if (state.mode === 0) {
                state.one_question_id.q_id = action.payload.id;
                state.one_question_id.title = action.payload.title;
                state.one_question_id.qu_type = action.payload.qu_type;
                state.question_ids = [
                    ...state.question_ids,
                    state.one_question_id,
                ];
            }
            if (state.mode === 1) {
                state.playlist_to_edit.one_question_id.q_id = action.payload.id;
                state.playlist_to_edit.one_question_id.title =
                    action.payload.title;
                state.playlist_to_edit.one_question_id.qu_type =
                    action.payload.qu_type;
                state.playlist_to_edit.question_ids = [
                    ...state.playlist_to_edit.question_ids,
                    state.playlist_to_edit.one_question_id,
                ];
            }

            // state.question_ids = [...state.question_ids[state.questionIndex].title, state.one_question_id[state.questionIndex].title]
        },
        push_title: (state, action) => {
            if (state.mode === 0) {
                state.one_question_id.title = action.payload;
                state.question_ids = [
                    ...state.question_ids[state.questionIndex].title,
                    state.one_question_id[state.questionIndex].title,
                ];
            }
            if (state.mode === 1) {
                state.playlist_to_edit.one_question_id.title = action.payload;
                state.playlist_to_edit.question_ids = [
                    ...state.playlist_to_edit.question_ids[
                        state.playlist_to_edit.questionIndex
                    ].title,
                    state.playlist_to_edit.one_question_id[
                        state.playlist_to_edit.questionIndex
                    ].title,
                ];
            }
        },
        playlistname: (state, action) => {
            if (state.mode === 0) {
                state.playlistName = action.payload;
            }
            if (state.mode === 1) {
                state.playlist_to_edit.playlist_name = action.payload;
            }
        },

        addPlaylistTag: (state, action) => {
            state.tag = [...state.tag, action.payload];
        },
        tag_delete_form_array: (state, action) => {
            state.tag = action.payload;
        },
        playlist_setting_forall_question: (state) => {
            if (state.mode === 0) {
                let all_Q: any = [...state.question_ids];
                let one_Q: any = Object.assign(
                    state.question_ids[state.questionIndex]
                );
                state.question_ids.map((arr, index) => {
                    all_Q[index].marks = one_Q.marks;
                    all_Q[index].has_negative_marks = one_Q.has_negative_marks;
                    all_Q[index].negative = one_Q.negative;
                    all_Q[index].is_cal_level = one_Q.is_cal_level;
                    all_Q[index].cal_level = one_Q.cal_level;
                    all_Q[index].percentage = one_Q.percentage;
                    all_Q[index].is_set_time = one_Q.is_set_time;
                    all_Q[index].set_time = one_Q.set_time;
                });
                state.question_ids = all_Q;
            }
            if (state.mode === 1) {
                let all_Q: any = [...state.playlist_to_edit.question_ids];
                let one_Q: any = Object.assign(
                    state.playlist_to_edit.question_ids[
                    state.playlist_to_edit.questionIndex
                    ]
                );
                state.playlist_to_edit.question_ids.map((arr, index) => {
                    all_Q[index].marks = one_Q.marks;
                    all_Q[index].has_negative_marks = one_Q.has_negative_marks;
                    all_Q[index].negative = one_Q.negative;
                    all_Q[index].is_cal_level = one_Q.is_cal_level;
                    all_Q[index].cal_level = one_Q.cal_level;
                    all_Q[index].percentage = one_Q.percentage;
                    all_Q[index].is_set_time = one_Q.is_set_time;
                    all_Q[index].set_time = one_Q.set_time;
                });
                state.playlist_to_edit.question_ids = all_Q;
            }
        },
        validatePlaylist: (state, action) => {
            state.valid = action.payload;
        },
        edit_playlist_old_data: (state, action) => {

            state.playlist_to_edit._id = action.payload.elm._id;
            state.playlist_to_edit.full_marks = action.payload.elm.full_marks;
            state.playlist_to_edit.playlist_name =
                action.payload.elm.playlist_name;
            state.playlist_to_edit.playlist_time =
                action.payload.elm.playlist_time;
            state.playlist_to_edit.tag = action.payload.elm.tag;
            state.playlist_to_edit.file.file_name =
                action.payload.elm.file.file_name;
            state.playlist_to_edit.file.file_type =
                action.payload.elm.file.file_type;
            state.playlist_to_edit.file.url = action.payload.elm.file.url;
            state.playlist_to_edit.question_ids = [
                ...action.payload.elm.questions,
            ];
        },
        edit_playlist_time: (state, action) => {
            state.playlist_to_edit.playlist_time = action.payload;
        },

        edit_playlist_tag: (state, action) => {
            state.playlist_to_edit.tag = [
                ...state.playlist_to_edit.tag,
                action.payload,
            ];
        },
        edit_playlist_in_edit: (state, action) => {
            state.playlist_to_edit.tag = action.payload;
        },
        edit_playlist_picture: (state, action) => {
            state.playlist_to_edit.file = action.payload;
        },
        edit_playlist_question_ids: (state, action) => {
            state.playlist_to_edit.question_ids = [
                ...state.playlist_to_edit.question_ids,
                action.payload,
            ];
        },
        remove_playlist_file_edit: (state) => {
            state.playlist_to_edit.file = {
                file_type: "",
                url: "",
                file_name: "",
            };
        },

        resetplaylistStoredata: (state) => {
            state.playlistName = initialState.playlistName;
            state.full_marks = initialState.full_marks;
            state.playlist_time = initialState.playlist_time;
            state.tag = initialState.tag;
            state.question_ids = initialState.question_ids;
            state.one_question_id = initialState.one_question_id;
            state.file = initialState.file;
            state.valid = initialState.valid;
            state.error = initialState.error;
            state.loading = initialState.loading;
        },

        setPlaylistArray: (state, action) => {
            let all_Q: any = [...state.playlistQuestions.q_array];
            let newArray = all_Q?.map((value, index) => {
                if (action.payload.id === value?.q_data?._id) {
                    // console.log(all_Q[index]?.q_data?.liked, action.payload?.liked);

                    if (action?.payload?.liked) {
                        return {
                            ...value,
                            q_data: { ...value.q_data, liked: false, totalLike: value.q_data.totalLike - 1 },
                        }
                    }
                    if (action.payload?.liked === false) {
                        return {
                            ...value,
                            q_data: { ...value.q_data, liked: true, totalLike: value.q_data.totalLike + 1 },

                        }
                    }

                } else {
                    return { ...value }
                }

            })
            state.playlistQuestions.q_array = newArray


        },
        // playlistLike: (state, action) => {
        //     let all_playlist: any = [...state.playlistData];
        //     all_playlist[0]?.data?.data?.map((value, index) => {
        //         if (action.payload.id === value?._id) {
        //             if (action.payload?.liked === true) {
        //                 all_playlist[0].data.data[index].liked = false
        //             }
        //             if (action.payload?.liked === false) {
        //                 all_playlist[0].data.data[index].liked = true
        //                 all_playlist[0].data.data[index].totalLike = action.payload?.totalLike + 1
        //             }
        //             if (action.payload?.totalLike > 0 && action.payload?.liked === true) {
        //                 all_playlist[0].data.data[index].totalLike = action.payload?.totalLike - 1
        //             }
        //         }
        //     })
        // },

        playlistLike: (state, action) => {
            let all_playlist: any = [...state.playlistData];
            const playlistIndex = all_playlist[0]?.data?.data?.findIndex(
                (value) => action.payload.id === value?._id
            );
            if (playlistIndex !== -1) {
                const playlistItem = all_playlist[0]?.data?.data[playlistIndex];
                if (action.payload.liked === true) {
                    playlistItem.liked = false;
                } else if (action.payload.liked === false) {
                    playlistItem.liked = true;
                    playlistItem.totalLike = action.payload.totalLike + 1;
                }

                if (action.payload.totalLike > 0 && action.payload.liked === true) {
                    playlistItem.totalLike = action.payload.totalLike - 1;
                }
            }
        },
    },
    extraReducers: {
        [PlaylistFetch.pending]: (state: any) => {
            state.playlist_loading = true;
            state.playlistData = [];
        },
        [PlaylistFetch.fulfilled]: (state: any, action: any) => {
            // console.log("action.payload", action.payload.data.data.playlistData);

            state.playlist_loading = false;
            state.currentPage = action.payload?.data.data.currentPage;
            state.totalPages = action.payload?.data.data.totalPages;
            state.totalItems = action.payload?.data.data.totalItems;
            state.playlistData = [action.payload.data.data.playlistData];

            if (action.payload.data.ok === false && action.payload.data.http$.status === 401) {
                state.tokenExpiredError = true
            }
        },
        [PlaylistFetch.rejected]: (state: any, action: any) => {
            state.playlist_loading = false;
            state.error = action.payload;
        },

        [ViewPlaylist.pending]: (state: any) => {
            state.loading = true;
        },
        [ViewPlaylist.fulfilled]: (state: any, action: any) => {
            state.loading = false;
            state.playlistQuestions = action.payload;
        },
        [ViewPlaylist.rejected]: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    questionadded,
    oneQuestionMarks,
    oneQuestionisNegativemarks,
    oneQuestionNegativemarks,
    oneQuestionCalculate,
    push_questioninto_mainquestion,
    playlistname,
    addPlaylistTag,
    oneQuestionisCalculation,
    oneQuestionis_settime,
    oneQuestionsettime,
    question_delete_form_array,
    playlist_edit_mode,
    currentQuestionid,
    oneQuestionPercentage,
    push_title,
    playlist_setting_forall_question,
    pictureadded,
    tag_delete_form_array,
    validatePlaylist,
    resetplaylistStoredata,
    playlist_timer,
    playlist_fullmarks,
    edit_playlist_old_data,
    // edit_playlist_name,
    // edit_playlist_full_marks,
    edit_playlist_time,
    edit_playlist_tag,
    edit_playlist_in_edit,
    remove_playlist_file,
    edit_playlist_picture,
    remove_playlist_file_edit,
    FromEvidya,
    other_site_tag,
    setPlaylistArray,
    playlistLike
} = PlaylistSlice.actions;
export default PlaylistSlice.reducer;