import { Button, Card, Popover } from "antd";
import React from "react";
import CancelSvg from "../svg/CancelSvg";

const HeaderExam = ({
	open,
	handleOpenChange,
	hide,
	marksArray,
	obtainedMark,
	playlistData,
	studentName,
	checkExamValue,
}) => {
	const content = (
		<div style={{ width: "100%" }}>
			{marksArray?.map((elm, index) => {
				return (
					<React.Fragment key={index}>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								fontSize: "1rem",
								padding: "5px 0.5rem",
								color: "#666666",
								borderBottom: "1px dashed #c8c8c8",
							}}
						>
							{elm?.questionNumber.length !== 0 && (
								<>
									<span>Question {elm?.questionNumber}:</span>
									<span>{elm?.marks}</span>
								</>
							)}
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
	const close = (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "end",
				alignItems: "center",
			}}
		>
			<Button
				type="link"
				onClick={hide}
				style={{ width: "24px", height: "24px" }}
			>
				<CancelSvg />
			</Button>
		</div>
	);
	return (
		<>
			<Card
				bordered={false}
				style={{
					textAlign: "left",
					fontSize: "1.25rem",
					color: "#6c757d",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexFlow: "row wrap",
					}}
				>
					<h3
						style={{
							margin: "0",
							color: "#6c757d",
							fontSize: "1.25rem",
						}}
					>
						{playlistData?.playlist_name}
					</h3>
					<aside style={{ fontSize: "0.85rem", fontWeight: "500" }}>
						Name: <span>{studentName}</span>
						{/* <span>29/08/2023</span> */}
					</aside>
					{checkExamValue?.userData?.Class && (
						<aside
							style={{ fontSize: "0.85rem", fontWeight: "500" }}
						>
							<span>Class/Sec: </span>
							<span>
								{checkExamValue?.userData?.Class}/
								{checkExamValue?.userData?.Section}
							</span>
						</aside>
					)}
					{checkExamValue?.userData?.Roll_No && (
						<aside
							style={{ fontSize: "0.85rem", fontWeight: "500" }}
						>
							<span>Roll No. : </span>
							<span>{checkExamValue?.userData?.Roll_No}</span>
						</aside>
					)}
					<aside
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "1.5rem",
							fontSize: "0.85rem",
							fontWeight: "500",
						}}
					>
						<aside>
							Full Marks:{" "}
							<span>{playlistData?.playlist_fullMarks}</span>
						</aside>
						<aside>
							<Popover
								content={content}
								title={close}
								trigger="click"
								open={open}
								onOpenChange={handleOpenChange}
							>
								<Button type="link" style={{ padding: "0" }}>
									<aside
										style={{
											fontSize: "0.85rem",
											fontWeight: "500",
											color: "#6c757d",
										}}
									>
										Marks Obtained:{" "}
										<span>{obtainedMark}</span>
									</aside>
								</Button>
							</Popover>
						</aside>
					</aside>
				</div>
			</Card>
		</>
	);
};

export default HeaderExam;
