import { Button, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { ApiConnector } from "../../axios/apiClient";
import {
	EDIT_QUESTION_BY_ID,
	GET_QUESTION_BY_ID,
} from "../../axios/AllBaseUrl";
import { ParseHtmlToText } from "../../utlis/parseHtmlTotext/ParseHtmlToText";
import AddTags from "../create/AddTags";
import CustomToast from "../../utlis/toast/CustomToast";

const PlaylistQuestionEditModal = ({
	isQuestionEditModalOpen,
	setQuestionEditModalOpen,
}) => {
	const [questionData, setQuestionData] = useState<any>({});
	const [editTag, setEditTag] = useState([]);
	const handleOk = () => {
		// setQuestionEditModalOpen(false);  api call and false
	};
	const handleCancel = () => {
		setQuestionEditModalOpen({
			open: false,
			QuestionId: "",
		});
	};

	const fetchQuestion = async () => {
		try {
			let res = await ApiConnector(
				"get",
				`${GET_QUESTION_BY_ID}/${isQuestionEditModalOpen?.QuestionId}`
			);
			setQuestionData(res?.data?.data);
			setEditTag(res?.data?.data?.tags);
		} catch (error) {
			console.log("error in modal question fetch", error);
		}
	};

	const edit = async () => {
		try {
			let editData = {
				questionId: isQuestionEditModalOpen?.QuestionId,
				tags: editTag,
			};
			let editApiCall = await ApiConnector(
				"post",
				`${EDIT_QUESTION_BY_ID}`,
				{ ...editData }
			);
			if (editApiCall.data.ok) {
				setEditTag([]);
				setQuestionEditModalOpen({
					open: false,
					QuestionId: "",
				});
				CustomToast("success", editApiCall.data.why);
			} else {
				CustomToast("error", editApiCall.data.why);
			}
		} catch (error) {
			console.log("error in modal question tag edit", error);
		}
	};

	useEffect(() => {
		if (isQuestionEditModalOpen?.QuestionId?.length !== 0) {
			fetchQuestion();
		}
	}, [isQuestionEditModalOpen?.QuestionId]);
	return (
		<>
			<Modal
				title="Edit Question Tag"
				open={isQuestionEditModalOpen.open}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="no" onClick={handleCancel}>
						No
					</Button>,
					<Button key="yes" type="primary" onClick={edit}>
						Yes
					</Button>,
				]}
			>
				<p>Question Type: {questionData?.question_type}</p>
				{questionData?.tags && <p>Tag : </p>}
				<AddTags
					editTag={editTag}
					setEditTag={setEditTag}
					isFromEdit={true}
				/>
				{questionData &&
				questionData !== undefined &&
				questionData?.question_traslation ? (
					<p>
						{`Question Title  : ${ParseHtmlToText(
							questionData?.question_traslation[0]?.title
						)}`}
					</p>
				) : (
					""
				)}
			</Modal>
		</>
	);
};

export default PlaylistQuestionEditModal;
