import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	// CREATEPLAYLIST,
	FETCHPLAYLIST,
	FETCH_QUESTION,
	GET_PLAYLIST_DATA,
	ALLSEARCH,
	SUGGESTION_QUESTION,
	PLAYLISTSEARCH,
} from "../../axios/AllBaseUrl";
import { ApiConnector } from "../../axios/apiClient";

export const getquestionThunk: any = createAsyncThunk(
	"question/getquestion",
	async (tags: object = []) => {
		const res = await ApiConnector("get", `${FETCH_QUESTION}`, {
			tags: tags,
		});
		if (res.data.ok) {
			return res
		}
		if (res.data.http$.status == 401 && res.data.ok === false) {
			// console.log(res);

			return res
		}
		// return res;
	}
);

export const SearchQuestionThunk: any = createAsyncThunk(
	"search/postSearch",
	async (data: {
		tags: string[], allSearch: number, page: number,
		showItems: number,
	}) => {
		// allSearch ===1 : all Search api || allSearch ===0 : tag seacrh 

		// if (data.allSearch === 1) {			
		let res = await ApiConnector("post", `${ALLSEARCH}`, {
			tags: data?.tags,
			page: data?.page,
			showItems: data?.showItems
		});
		// if (res.data.ok) {
		return res.data.data;
		// }
		// if (res.data.http$.status == 401 && res.data.ok === false) {
		// 	console.log(res.data);
		// 	return res.data
		// }
		// return res.data.data;
		// }
		// if (data.allSearch === 0) {
		// 	let res = await ApiConnector("post", `${SUGGESTION_QUESTION}`, {
		// 		tags: data.tags,
		// 		page: 1,
		// 		showItems: 10
		// 	});
		// 	return res.data.data;
		// }
	}
);

export const PlaylistSearchThunk: any = createAsyncThunk(
	"playListSearch/playList",
	async (data: any) => {
		let res = await ApiConnector("post", `${PLAYLISTSEARCH}`, {
			tags: data.tags,
			page: data.page,
			showItems: data.showItems
		});
		if (res.data.ok) {
			return res.data;
		}
		if (res.data.http$.status === 401 && res.data.ok === false) {
			console.log(res.data);
			return res.data

		}


	}
);

export const FetchPlaylistThunk: any = createAsyncThunk(
	"playlistData/PlaylistFetch",
	async (data: any) => {
		return await ApiConnector("post", `${FETCHPLAYLIST}`, {
			tag: data?.tag,
			page: data?.page,
			showItems: data?.showItems
		});
	}
);

export const ViewPlaylistThunk: any = createAsyncThunk(
	"playlists/ViewPlaylist",
	async (id: any) => {
		let res = await ApiConnector("get", `${GET_PLAYLIST_DATA}/${id}`);
		return res?.data?.data
	}
);
