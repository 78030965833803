import { Button, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
	ANIMATION_CHOOSE_URL,
	CREATEPLAYLIST,
	EVIDYA_URL,
	PLAYLIST_DATA_OPERATION,
} from "../../axios/AllBaseUrl";
import { ApiConnector } from "../../axios/apiClient";
import { useNavigate } from "react-router-dom";
import CustomToast from "../../utlis/toast/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PlaylistValidate from "../../utlis/PlaylistValidate/PlaylistValidate";
import {
	resetplaylistStoredata,
	validatePlaylist,
} from "../../redux/reducers/PlaylistSlice";
import base64 from "base-64";
import { RightCircleTwoTone } from "@ant-design/icons";
import { Base64 } from "js-base64";
import { encode, decode } from "js-base64";

const DonePlaylist = ({isBlurred,setIsBlurred}) => {
	const playlist = useSelector((state: RootState) => state.playlist);
	const incomingString = localStorage.getItem("evidya_data")!;
	const incomingdata = JSON.parse(incomingString);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [animationarr, setAnimationarr] = useState<any>([]);
	const [encoded_data, setEncoded_data] = useState<any>();

	const show = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		setIsBlurred("blur(2px)");
		if (playlist.mode === 0 && playlist.isFromEvidya !== 1) {
			let data = {
				playlist_name: playlist.playlistName,
				playlist_time: playlist.playlist_time,
				full_marks: playlist.full_marks,
				tag: playlist.tag,
				file: playlist.file,
				questions: playlist.question_ids,
				chapter_id: "",
				topic_id: "",
				class: "",
				section: "",
				start_date: "",
				end_date: "",
				start_time: "",
				end_time: "",
				playlist_type: animationarr.toString().includes(false) ? 0 : 1,
			};
			// console.log(data);

			let validateData = PlaylistValidate(data);

			dispatch(validatePlaylist(validateData));

			let createPlaylist = async (data) => {
				try {
					let newdata = {
						playlist_name: playlist.playlistName,
						playlist_time: playlist.playlist_time,
						full_marks: playlist.full_marks,
						tag: playlist.tag,
						file: playlist.file,
						questions: playlist.question_ids,
						chapter_id: "",
						topic_id: "",
						class: "",
						section: "",
						start_date: "",
						end_date: "",
						start_time: "",
						end_time: "",
						playlist_type: 0,
					};
					if (data.playlist_type === 1) {
						let res = await ApiConnector(
							"post",
							`${CREATEPLAYLIST}`,
							newdata
						);
						return res;
					}
				} catch (error) {
					console.log(error);
				}
			};

			if (Object.keys(validateData)?.length === 0) {
				let res = await ApiConnector("post", `${CREATEPLAYLIST}`, data);
				let normalPlaylist = await createPlaylist(data);
				if (res.data.http$.status === 401 && res.data.ok === false) {
					localStorage.removeItem("token");
					navigate("/");
					setIsBlurred("");
				}

				if (res.data.ok === false) {
					setIsBlurred("");
					//   Object.keys(res.data.error).forEach((e) => {
					CustomToast("error", "Something went wrong");
					//   });
				}
				if (res.data.ok === true) {
					let get_token = localStorage.getItem("token");

					let data_set = {
						mode: 1,
						flag: "2",
						playlist_id: res.data.data.data._id,
						data: playlist.question_ids,
						tags: data.tag,
						token: get_token,
					};
					let str_val = JSON.stringify(data_set);
					let encode = Base64.encode(str_val);
					if (!animationarr.toString().includes(false)) {
						window.open(
							`${ANIMATION_CHOOSE_URL}/${encode}`,
							"_blank"
						);
						setIsBlurred("");
						dispatch(resetplaylistStoredata());
					} else {
						setIsBlurred("");
						dispatch(resetplaylistStoredata());
						CustomToast("success", res.data.why);
						navigate("/playlist");
					}
				}
			} else {
				setIsBlurred("");
				CustomToast("error", "Please fill out all required fields");
			}
		}
		if (playlist.mode === 1 && playlist.isFromEvidya !== 1) {
			let data = {
				playlist_name: playlist.playlist_to_edit.playlist_name,
				playlist_time:
					playlist.playlist_to_edit.playlist_time?.toString(),
				full_marks: playlist.playlist_to_edit.full_marks?.toString(),
				tag: playlist.playlist_to_edit.tag,
				file: playlist.playlist_to_edit.file,
				question_ids: playlist.playlist_to_edit.question_ids,
			};

			let validateData = PlaylistValidate(data);

			dispatch(validatePlaylist(validateData));

			if (Object.keys(validateData).length === 0) {
				let res = await ApiConnector(
					"put",
					`${PLAYLIST_DATA_OPERATION}/${playlist.playlist_to_edit._id}`,
					data
				);
				if (res.data.http$.status === 401 && res.data.ok === false) {
					localStorage.removeItem("token");
					navigate("/");
					setIsBlurred("");
				}

				if (res.data.ok === false) {
					setIsBlurred("");
					Object.keys(res.data.error).forEach((e) => {
						CustomToast("error", res.data.error[e]);
					});
					CustomToast("error", "Something went wrong");
				}
				if (res.data.ok === true) {
					setIsBlurred("");
					CustomToast("success", res.data.why);
					navigate("/playlist");
				}
			}
		}
		if (playlist.mode === 0 && playlist.isFromEvidya === 1) {
			let data = {
				playlist_name: playlist.playlistName,
				playlist_time: playlist.playlist_time,
				full_marks: playlist.full_marks,
				tag: playlist.tag,
				file: playlist.file,
				questions: playlist.question_ids,
				chapter_id: incomingdata.assChId,
				topic_id: incomingdata.assTId,
				class: incomingdata.assClassId,
				section: incomingdata.assSecId,
				start_date: incomingdata.assStartDt,
				end_date: incomingdata.assEndDt,
				start_time: incomingdata.assStartTime,
				end_time: incomingdata.assEndTime,
			};

			let validateData = PlaylistValidate(data);

			dispatch(validatePlaylist(validateData));

			if (Object.keys(validateData).length === 0) {
				let res = await ApiConnector("post", `${CREATEPLAYLIST}`, data);
				// console.log("res", res);
				dispatch(resetplaylistStoredata());
				if (res.data.http$.status === 401 && res.data.ok === false) {
					localStorage.removeItem("token");
					navigate("/");
					setIsBlurred("");
				}
				if (res.data.ok === false) {
					setIsBlurred("");
					Object.keys(res.data.error).forEach((e) => {
						CustomToast("error", res.data.error[e]);
					});
				}
				if (res.data.ok === true) {
					setIsBlurred("");
					CustomToast("success", res.data.why);
					navigate("/playlist");
					// console.log(playlist);

					let encodeData = {
						playlistId: res.data.data._id,
						classId: playlist.assDataID.classId,
						chapId: playlist.assDataID.chapId,
						topicId: playlist.assDataID.topicId,
						subjectId: playlist.assDataID.subjectId,
					};

					const m = JSON.stringify(encodeData);
					const encoded = base64.encode(m);

					// window.location.replace(`${EVIDYA_URL}${encoded}`);
				}
			} else {
				setIsBlurred("");
				CustomToast("error", "Please fill out all required fields");
			}
		}
		if (playlist.mode === 1 && playlist.isFromEvidya === 1) {
			let data = {
				playlist_name: playlist.playlist_to_edit.playlist_name,
				playlist_time: playlist.playlist_to_edit.playlist_time,
				full_marks: playlist.playlist_to_edit.full_marks?.toString(),
				tag: playlist.playlist_to_edit.tag,
				file: playlist.playlist_to_edit.file,
				question_ids: playlist.playlist_to_edit.question_ids,
			};
			let validateData = PlaylistValidate(data);
			dispatch(validatePlaylist(validateData));
			if (Object.keys(validateData).length === 0) {
				let res = await ApiConnector(
					"put",
					`${PLAYLIST_DATA_OPERATION}/${playlist.playlist_to_edit._id}`,
					data
				);
				if (res.data.http$.status === 401 && res.data.ok === false) {
					localStorage.removeItem("token");
					navigate("/");
					setIsBlurred("");
				}
				if (res.data.ok === false) {
					setIsBlurred("");
					Object.keys(res.data.error).forEach((e) => {
						CustomToast("error", res.data.error[e]);
					});
				}
				if (res.data.ok === true) {
					setIsBlurred("");
					CustomToast("success", res.data.why);
					navigate("/playlist");
				}
			}
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const set_anime_arr = () => {
		if (
			(playlist.mode === 0 && playlist.isFromEvidya !== 1) ||
			(playlist.mode === 0 && playlist.isFromEvidya === 1)
		) {
			let new_arr = playlist.question_ids?.map((value, index) => {
				if (value.qu_type === "ANIMATION") {
					return true;
				} else {
					return false;
				}
			});
			setAnimationarr(new_arr);
		} else {
			setAnimationarr([false]);
		}
	};

	useEffect(() => {
		set_anime_arr();
	}, [playlist]);
	useEffect(() => {
		setIsBlurred("");
	}, []);

	// console.log("playlist", playlist.question_ids);

	// console.log("playlist", animationarr);

	return (
		<>
			{animationarr.toString().includes(false) ? (
				<Button
					type="default"
					onClick={show}
					className="playlist-done-button"
				>
					OK
				</Button>
			) : (
				<>
					<Button
						type="default"
						className="playlist-next-button"
						icon={<RightCircleTwoTone />}
						onClick={handleOk}
					>
						NEXT
					</Button>
				</>
			)}

			<Modal
				title="Add to Playlist"
				open={isModalOpen}
				onOk={isBlurred?.length > 0 ? () => {} : handleOk}
				onCancel={handleCancel}
				style={{ filter: isBlurred }}
			>
				<Row>
					{/* {playlist.question_ids.length !== 0 && ( */}
					{/* <p>Do you want to continue with your playlist </p> */}
					<p>Finish Playlist</p>
					{/* )} */}
					{/* {playlist.question_ids.length === 0 && (
						<p>Do you want to continue with blank playlist </p>
					)} */}
				</Row>
				<Row></Row>
			</Modal>
		</>
	);
};

export default DonePlaylist;
