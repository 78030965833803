import {
	DownloadOutlined,
	EditOutlined,
	EllipsisOutlined,
	EyeFilled,
	EyeOutlined,
	LikeFilled,
	LikeOutlined,
	ShareAltOutlined,
} from "@ant-design/icons";
import { Card, Tag, Tooltip, Image, message, Modal, Button } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { ApiConnector } from "../axios/apiClient";
import {
	CLASS_SECTION,
	DOWNLOAD_PLAYLIST,
	PLAYLISTPUBLISHED,
} from "../axios/AllBaseUrl";
import CustomToast from "../utlis/toast/CustomToast";
import { searchPlaylistLike } from "../redux/reducers/SearchPlaylistSlice";
import { useDispatch, useSelector } from "react-redux";
import {
	edit_playlist_old_data,
	playlist_edit_mode,
	ViewPlaylist,
} from "../redux/reducers/PlaylistSlice";
import ShareIcon from "./Share/ShareIcon";
import Publish from "./svg/Publish";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import PlaylistPublishedModal from "./modal/PlaylistPublishedModal";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import axios from "axios";

const SearchPlaylistViewCard = ({
	elm,
	index,
	setShowQuestion,
	likePlaylist,
	playlist_id,
	eyePlaylist,
	setEyePlaylist,
	playlistID,
	setPlaylistID,
}) => {
	let flag = FlagLocalhost();
	let navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	let [selectClass, setSelectClass] = useState<any>([{}]);
	let [classId, setClassId] = useState<string>("");
	let [selectSection, setSelectSection] = useState<any>([{}]);
	let [sectionId, setSectionId] = useState<any>("");
	const [publishmodal, setPublishmodal] = useState(false);
	const [copied, setCopied] = useState(false);
	let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	let [shareId, setShareId] = useState("");
	let [startTime, setStartTime] = useState<any>("");
	// console.log("startTime", startTime);
	let [endTime, setEndTime] = useState<any>("");
	// console.log("endTime", endTime);
	let [startDate, setStartDate] = useState<any>("");
	// console.log("startDate", startDate);
	let [endDate, setEndDate] = useState<any>("");
	// console.log("endDate", endDate);
	let [questionTimeCheckedStatus, setQuestionTimeCheckedStatus] =
		useState<boolean>(true);
	let [playlistTime, setPlaylistTime] = useState<number>(0);
	const playlist: any = useSelector((state: RootState) => state.playlist);

	const handleCancel = () => {
		setCopied(false);
		setIsModalOpen(false);
	};
	const handleOk = () => {
		setCopied(false);
		setIsModalOpen(false);
	};

	const dispatch = useDispatch();
	let playlistShare = (id) => {
		setShareId(id);
		setIsModalOpen(true);
	};
	// const playListShareUrl = `https://adhyandigital.com/`;
	const playListShareUrl = `https://adhyandigital.com/viewQBPL/#/receive/${shareId}`;
	let clickPlaylist = (id) => {
		// console.log("clickPlaylist", id);
		if (id !== eyePlaylist) {
			setEyePlaylist(id);
		}
		dispatch(ViewPlaylist(id));
		setShowQuestion(true);
	};
	const Published = (id, timeData) => {
		setStartTime("");
		setEndTime("");
		setEndDate("");
		setStartDate("");
		setPlaylistTime(timeData);
		setPlaylistID(id);
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");

		// setPublishmodal(true);
		if (flag?.isFromEvidya === 1 && id) {
			setPublishmodal(true);
		} else {
			messageApi.open({
				type: "info",
				content: "Published For our Teacher's Only ",
			});
		}
	};
	const publishCancel = () => {
		setStartTime("");
		setEndTime("");
		setEndDate("");
		setStartDate("");
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId([]);
		setPublishmodal(false);
	};
	const Playlistpublish = async () => {
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");
		setPublishmodal(false);

		// console.log("playlistID", playlistID);
		// console.log("flag.user_id", flag.user_id);
		// console.log("classId", classId);

		let res = await ApiConnector("post", `${PLAYLISTPUBLISHED}`, {
			playlist_id: playlistID,
			class_id: classId,
			section_id: sectionId,
			// subject_id: "3",
			school_id: flag.school_id,
			academic_year_id: flag.academic_year_id,
			teacher_id: flag.user_id,
			start_date: startDate,
			end_date: endDate,
			start_time: startTime,
			end_time: endTime,
			time_check: questionTimeCheckedStatus ? 1 : 0,
		});
		// console.log(" playlist publish res", res);

		if (res?.data?.ok) {
			messageApi.open({
				type: "success",
				content: "Published",
			});
		} else {
			messageApi.open({
				type: "error",
				content: "Published failed",
			});
		}
	};

	let fetchClass = async () => {
		try {
			setSelectClass([{}]);
			setSelectSection([{}]);
			setSectionId("");
			let res = await ApiConnector("post", `${CLASS_SECTION}`, {
				schoolId: flag?.school_id,
				userId: flag?.user_id,
				userTypeId: flag?.user_type_id,
				// schoolId: "81",
				// userId: "43",
				// userTypeId: "4",
			});
			if (res?.data?.ok) {
				setSelectClass(res?.data?.data?.data);
			}
		} catch (error) {
			console.error(error);
		}
	};
	let classIdSet = (e) => {
		setSelectSection([{}]);
		setSectionId("");
		setClassId(e.target.value);
	};
	let sectionIdSet = (e) => {
		setSectionId(e.target.value);
	};
	const handleEndTime = (e) => {
		setEndTime(e);
	};
	const handleStartTime = (e) => {
		setStartTime(e);
	};
	const editme = (elm) => {
		dispatch(
			edit_playlist_old_data({
				elm,
			})
		);
		dispatch(playlist_edit_mode(1));
		navigate("/createPlaylist");
	};

	const excelDownloadPlayList = async (downLoadId, playlist_name) => {
		let playlistId = downLoadId.toString();
		try {
			axios({
				url: `${DOWNLOAD_PLAYLIST}/${playlistId}`,
				method: "get",
				responseType: "blob",
			})
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", `${playlist_name}.xlsx`);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					CustomToast("error", "Something goes wrong !");
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (playlistTime === 0) {
			setQuestionTimeCheckedStatus(false);
		}
		setQuestionTimeCheckedStatus(true);
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");
		setClassId("");
		if (flag?.isFromEvidya === 1 && publishmodal) {
			fetchClass();
		}
	}, [publishmodal]);
	useEffect(() => {
		setSelectSection([{}]);
		setSectionId("");
		if (flag?.isFromEvidya === 1) {
			if (classId) {
				setSelectSection([{}]);
				setSectionId("");
				selectClass?.map((elm) => {
					if (classId == elm.id) {
						setSelectSection(elm.mappedSections);
					}
				});
			}
		}
	}, [publishmodal, classId]);
	return (
		<>
			<div key={index}>
				<Card
					className="playlist-card"
					style={{
						width: 250,
					}}
					cover={
						// 	src="https://i.postimg.cc/Gh9MvfQf/island-paradise-wallpaper-800x480.jpg"
						elm?.file?.url &&
						elm?.file?.url?.trim().length !== 0 ? (
							<Image
								className="home_card_img"
								src={elm?.file.url}
							/>
						) : (
							<Image
								className="home_card_img"
								// src="https://i.postimg.cc/nzFLWQyn/question.png"
								src="https://i.postimg.cc/fb6KBjwc/1000-F-407319626-af-RAfgdi-Hlddri-Ww-Vp-Ras-EWNk-KHk-Wu-Vx.jpg"
							/>
						)
					}
					actions={[
						<Tooltip
							placement="bottom"
							title={
								<>
									{elm?.tag && <p>Tag : </p>}
									{elm?.tag?.map((value, i) => {
										return (
											<Tag color="processing" key={i}>
												{value}
											</Tag>
										);
									})}
									{elm?.questions?.length && (
										<p>
											Total Questions :
											{` ${elm?.questions?.length}`}
										</p>
									)}
									{elm?.full_marks && (
										<p>
											Full Marks :{` ${elm?.full_marks}`}
										</p>
									)}
									{elm?.playlist_time && (
										<p>
											Time limit :
											{` ${elm?.playlist_time}`}
										</p>
									)}
								</>
							}
						>
							<EllipsisOutlined key="ellipsis" />
						</Tooltip>,
						<Tooltip placement="top" title="Published Playlist">
							<div
								//  className="publish-button"
								onClick={() =>
									Published(playlist_id, elm?.playlist_time)
								}
							>
								<Publish />
							</div>
						</Tooltip>,
						<>
							{elm?.is_editable === true ? (
								<Tooltip
									placement="top"
									title="Download Playlist"
								>
									<span
										style={{
											color: "#851151",
											fontSize: "18px",
										}}
									>
										<DownloadOutlined
											onClick={() =>
												excelDownloadPlayList(
													elm._id,
													elm?.playlist_name
												)
											}
										/>
									</span>
								</Tooltip>
							) : (
								<Tooltip placement="top" title="Total Question">
									{elm?.questions?.length}
								</Tooltip>
							)}
						</>,
						<>
							{elm?.is_editable === true ? (
								<Tooltip placement="top" title="Edit Playlist">
									<div
										//  className="publish-button"
										onClick={() => editme(elm)}
									>
										<EditOutlined />
									</div>
								</Tooltip>
							) : (
								<ShareAltOutlined
									key="share"
									onClick={() => playlistShare(playlist_id)}
								/>
							)}
						</>,
						<>
							<span>
								{elm.liked === true ? (
									<LikeFilled
										style={{
											color: "#2f70ab",
										}}
										key="liked"
										onClick={() =>
											likePlaylist(
												playlist_id,
												elm.liked,
												elm.totalLike,
												2,
												elm.playlist_name
											)
										}
									/>
								) : (
									<LikeOutlined
										key="like"
										onClick={() =>
											likePlaylist(
												playlist_id,
												elm.liked,
												elm.totalLike,
												1,
												elm.playlist_name
											)
										}
									/>
								)}
								{elm.totalLike > 0 ? elm.totalLike : ""}
							</span>
						</>,
						<>
							{playlist_id === eyePlaylist ? (
								<EyeFilled
									key="eye1"
									style={{ color: "#2f70ab" }}
								/>
							) : (
								<EyeOutlined
									key="eye"
									onClick={() => clickPlaylist(playlist_id)}
								/>
							)}
						</>,
					]}
				>
					<Tooltip placement="top" title={elm?.playlist_name}>
						<Meta
							title={elm?.playlist_name}
							// description={elm?.tag?.map((value, i) => (
							// 	<>
							// 		{i < 2 && (
							// 			<>
							// 				<Tag color="blue" key={i}>
							// 					{value?.substring(0, 15)}
							// 				</Tag>
							// 			</>
							// 		)}
							// 	</>
							// ))}
						/>
					</Tooltip>
				</Card>
				<ShareIcon
					isModalOpen={isModalOpen}
					handleCancel={handleCancel}
					url={playListShareUrl}
					handleOk={handleOk}
					copied={copied}
					setCopied={setCopied}
				/>
				{contextHolder}
				<Modal
					// title=" Publish "
					open={publishmodal}
					onCancel={publishCancel}
					footer={[
						<Button
							className="published-button-item"
							key="back"
							onClick={Playlistpublish}
						>
							Publish Playlist
						</Button>,
					]}
				>
					<p> Select Class</p>
					<select
						className="published-select-modal"
						// allowClear
						onChange={(e) => {
							classIdSet(e);
						}}
						style={{
							height: "38px",
							width: " -webkit-fill-available",
						}}
					>
						<option key="1" value="">
							{" "}
							Select Class
						</option>
						{selectClass &&
							selectClass?.map((elm, index) => {
								return (
									<option key={index} value={elm?.id}>
										{elm?.name}
									</option>
								);
							})}
					</select>
					<p>Select Sections</p>
					<select
						// mode="multiple"
						className="published-select-modal"
						onChange={(e) => {
							sectionIdSet(e);
						}}
						style={{
							height: "38px",
							width: " -webkit-fill-available",
						}}
						// allowClear
					>
						<option key="1" value="">
							{" "}
							Select Section
						</option>
						{selectSection &&
							selectSection?.map((elm, index) => {
								return (
									<option key={index} value={elm?.csId}>
										{elm?.secName}
									</option>
								);
							})}
					</select>
				</Modal>
			</div>
			<PlaylistPublishedModal
				publishmodal={publishmodal}
				publishCancel={publishCancel}
				Playlistpublish={Playlistpublish}
				classIdSet={classIdSet}
				classId={classId}
				selectClass={selectClass}
				sectionIdSet={sectionIdSet}
				selectSection={selectSection}
				sectionId={sectionId}
				startDate={startDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startTime={startTime}
				setStartTime={setStartTime}
				setEndTime={setEndTime}
				handleEndTime={handleEndTime}
				handleStartTime={handleStartTime}
				endTime={endTime}
				endDate={endDate}
				questionTimeCheckedStatus={questionTimeCheckedStatus}
				setQuestionTimeCheckedStatus={setQuestionTimeCheckedStatus}
				playlistTime={playlistTime}
			/>
		</>
	);
};

export default SearchPlaylistViewCard;
