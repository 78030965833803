import React from "react";
import { Typography } from "antd";
const { Text } = Typography;

const InvalidMsg = ({ msg }) => {
	return (
		<>
			<Text className="" style={{ fontSize: "12px", color: "red" }}>
				{msg}
			</Text>
		</>
	);
};

export default InvalidMsg;
