import { Button, Card, Row, Col } from "antd";
import { Typography } from "antd";
import React from "react";
import ColapsableList from "./ColapsableList";
import SuggestedQuestion from "../../create/SuggestedQuestion";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Loader from "../../../utlis/Loader/Loader";
const { Text } = Typography;

const PlaylistPreview = (prop: any) => {
	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);
	const { isShown } = prop;
	// console.log(
	// 	"playlist.playlists[0]?.data?.data?.q_array",
	// 	playlist.playlists[0]?.data?.data?.q_array
	// );

	return (
		<>
			{!isShown && (
				<div className="btn-hover">
					<Button
						type="dashed"
						className="hover-me-to-preview"
						ghost
						disabled
					>
						Click to view Questions
					</Button>
				</div>
			)}

			{isShown && (
				// className="hover-playlist"
				<Card className="hover-playlist" bordered={false}>
					<Row gutter={[24, 6]}>
						<Col xl={24} xs={24} md={24} lg={24}>
							{/* <ColapsableList /> */}
							{playlist.loading && (
								<Row
									style={{
										height: "75px",
										marginTop: "25px",
									}}
								>
									<Loader />
								</Row>
							)}

							{/* {playlist.loading && <Loader />} */}
							{!playlist.loading &&
								playlist.playlistQuestions?.q_array?.length ===
									0 && (
									<h1>
										<b>No questions in this playlist !</b>
									</h1>
								)}
							{!playlist.loading &&
								playlist.playlistQuestions?.q_array?.map(
									(value, index) => (
										<ColapsableList
											key={index}
											value={value}
										/>
									)
								)}
							{/* <SuggestedQuestion/> */}
						</Col>
					</Row>
				</Card>
			)}
		</>
	);
};

export default PlaylistPreview;
