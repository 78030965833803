import React, { useState } from "react";
import ColapsableCard from "../../ColapsableCard";
import { ApiConnector } from "../../../axios/apiClient";
import { LIKE } from "../../../axios/AllBaseUrl";
import { useDispatch } from "react-redux";
import { setPlaylistArray } from "../../../redux/reducers/PlaylistSlice";
const ColapsableList = (prop: any) => {
	const { value, index } = prop;
	const dispatch = useDispatch();
	let toggleIcon = async (id, liked, totalLike) => {
		let res: any = await ApiConnector("post", `${LIKE}`, {
			question_id: id,
		});
		if (res.data.ok) {
			dispatch(
				setPlaylistArray({ id: id, liked: liked, totalLike: totalLike })
			);
		}
	};

	// console.log("value in colap play", value);

	return (
		<>
			<ColapsableCard
				onChange={() => {}}
				index={index}
				value={value?.q_data}
				Option={value?.q_options}
				Questionadded={() => {}}
				toggleIcon={toggleIcon}
				image={value?.q_files}
				question_setting={value?.question_setting}
			/>
		</>
	);
};

export default ColapsableList;
