import React from "react";
import { Card, Col, Row, Image, Tag, Button, Tooltip } from "antd";
import fallback from "../../assets/images/fallback.gif";

const ViewOtherSitePlaylist = ({
	index,
	value,
	attendPlaylist,
	viewResult,
}) => {
	// console.log("value?.obtainedMark", value);

	return (
		<>
			<Card key={index} className="studentPlaylistView">
				<Row gutter={24}>
					<Col xl={6} xs={6} md={6}>
						{value?.file?.is_active === true &&
						value?.file?.url &&
						value?.file?.url?.trim().length !== 0 ? (
							<Image
								className="studentPlaylistView_img"
								src={value?.file.url}
							/>
						) : (
							<Image
								// className="studentPlaylistView_img"
								src={fallback}
							/>
						)}
					</Col>
					<Col xl={18} xs={18} md={18}>
						<Row>
							<Col xl={24} xs={24} md={24}>
								<Tooltip
									placement="top"
									title={value?.playlist_name}
								>
									<h1
										className="h1"
										style={{
											textAlign: "left",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										<strong>{value?.playlist_name}</strong>
									</h1>
								</Tooltip>
							</Col>
							{/* <Col xl={3} xs={5} md={5}></Col> */}
							<Col xl={24} xs={24} md={24}>
								{value?.full_marks && (
									<h6 style={{ textAlign: "left" }}>
										Full Marks :{" "}
										<Tag
											color="blue"
											key={index}
											style={{ fontSize: "16px" }}
										>
											{value?.full_marks}
										</Tag>
									</h6>
								)}
							</Col>
							<Col xl={24} xs={24} md={24}>
								{value?.obtainedMark !== undefined ? (
									<h6 style={{ textAlign: "left" }}>
										Obtained Marks :{" "}
										<Tag
											color="blue"
											key={index}
											style={{ fontSize: "16px" }}
										>
											{value?.obtainedMark}
										</Tag>
									</h6>
								) : (
									""
								)}
							</Col>
						</Row>
						{/* <Row style={{ rowGap: "0px" }}>
							<Col xl={3} xs={5} md={5}></Col>
							<Col xl={21} md={19} xs={19}>
								{value?.full_marks && (
									<p style={{ textAlign: "left" }}>
										Number of Questions :{" "}
										{value?.full_marks}
									</p>
								)}
							</Col>
						</Row> */}
						<Row style={{ rowGap: "0px" }}>
							{/* <Col xl={3} xs={5} md={5}></Col> */}

							{value?.playlist_time &&
							value?.playlist_time !== undefined &&
							value?.playlist_time > 0 ? (
								<>
									<Col xl={24} xs={24} md={24}>
										<h6 style={{ textAlign: "left" }}>
											Duration :
											{`${value?.playlist_time} minute`}
										</h6>
									</Col>
								</>
							) : (
								""
							)}

							<Col xl={24} xs={24} md={24}>
								{" "}
								<h6 style={{ textAlign: "left" }}>
									<Button
										style={{
											backgroundColor: "#7b81c3",
										}}
										onClick={() =>
											value?.obtainedMark !== undefined
												? viewResult(
														value?._id,
														value?.playlist_name,
														value?.full_marks,
														value?.user_id,
														value?.examData[0]
												  )
												: attendPlaylist(
														value?._id,
														value?.examData[0]
												  )
										}
										// disabled={
										// 	(value?.obtainedMark ===
										// 		undefined &&
										// 		value?.examStatus === true) ||
										// 	(value?.examData[0].status === 2 &&
										// 		value?.examStatus === false)
										// 		? true
										// 		: false
										// }
										disabled={value?.disableAttendButton}
									>
										{/* {value?.obtainedMark !== undefined
											? "Result"
											: "Attend"} */}
										{value?.attendButtonName}
									</Button>
								</h6>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default ViewOtherSitePlaylist;
