import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface SuggestState {
    suggest: object;
    loading: boolean;
    error: any;
    tagdata: object
}

export const initialState: SuggestState = {
    suggest: [],
    loading: false,
    error: null,
    tagdata: ["math"]
};


export const SuggestionQuestionSlice = createSlice({
    name: "suggest",
    initialState,
    // reducers: {},
    reducers: {
        SearchTags: (state, action) => {
            state.tagdata = [action.payload]
        },
        SuggestionFetch: (state: any, action) => {
            state.loading = true;
        },
        SuggestionFulfilled: (state: any, action) => {
            state.loading = false;
            state.suggest = [action.payload];
        },
        SuggestionRejected: (state: any, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }

});


export const { SuggestionFetch, SuggestionFulfilled, SuggestionRejected } = SuggestionQuestionSlice.actions;
export default SuggestionQuestionSlice.reducer;