import React from "react";

const CancelSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="24"
				height="24"
				fill="none"
				stroke="currentColor"
				strokeLinejoin="round"
				// strokeLinejoin="round"
			>
				<path d="M7 17L16.8995 7.10051" />
				<path d="M7 7.00001L16.8995 16.8995" />
			</svg>
		</>
	);
};

export default CancelSvg;
