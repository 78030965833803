import React from "react";
import { Pie } from "@ant-design/charts";

const SingleTagPie = ({tag}) => {
    // console.log(tag);
    
    const data = [
      {
        Day: 'Monday',
        Tag:'mcq',
        value: 27,
      },
      {
        Day: 'Tuesday',
        value: 25,
      },
      {
        Day: 'Wednesday',
        value: 18,
      },
      {
        Day: 'Thrusday',
        value: 15,
      },
      {
        Day: 'Friday',
        value: 10,
      },
      {
        Day: 'Saturday',
        value: 5,
      },
      {
        Day: 'Sunday',
        value: 5,
      },
    ];
    const config:any = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'Day',
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: 'inner',
        offset: '-50%',
        content: '{value}',
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 14,
          },
          content: tag,
        },
      },
    };
    return <Pie {...config} />;
  };
export default SingleTagPie;