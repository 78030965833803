import "react-quill/dist/quill.snow.css";
import { Col, Row, Form, Select } from "antd";

import React, { useContext, useEffect, useState } from "react";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { ApiConnector } from "../../axios/apiClient";
import { QUESTION_TYPE } from "../../axios/AllBaseUrl";
import { Typography } from "antd";
import InvalidMsg from "./InvalidMsg";
import {
    ANIMATION,
    FILL_IN_THE_BLANK,
    IQ,
    MATCHING,
    MCQ,
    RESEARCH,
    SHORT_ANSWER,
    TRUE_FALSE,
} from "../../utlis/constants/questionType";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const AddQuestionDetails = () => {
    const questiondata: any = useContext(createQuesContext);
    let { dispatch, state } = questiondata;
    let navigate = useNavigate();
    let [quesType, setQuesType] = useState([] as any[]);

    let [ques, setQues] = useState<any>({});
    // let [quesTrans, setQuesTrans] = useState<any>({});

    const WhichTypeQuestion = () => {
        let currentQState = state.mode;
        let quData;
        if (currentQState === 0) {
            quData = state.question;
        }
        if (currentQState === 1) {
            quData = state.subQuestion;
        }
        if (currentQState === 2) {
            quData = state.question.subQuestions[state.edit_id];
        }
        // quData = currentQState === 0 && state.question;
        // quData = currentQState === 1 && state.subQuestion;

        // quData =
        // 	currentQState === 2 && state.question.subQuestions[state.edit_id];
        // console.log(quData);

        setQues(quData);
    };
    useEffect(() => {
        WhichTypeQuestion();
    }, [
        state.mode,
        state.question,
        state.subQuestion,
        state.question_traslation,
        state.sub_question_traslation,
        state.edit_id,
    ]);
    const getlevel = (value: string) => {
        if (state.mode === 0) {
            dispatch({ type: "question_level", value: value });
        }
        if (state.mode === 1) {
            dispatch({ type: "sub_question_level", value: value });
        }
        if (state.mode === 2) {
            dispatch({
                type: "edit_sub_question_level",
                value: value,
                id: state.edit_id,
            });
        }
    };

    const getType = (value: string) => {
        if (state.mode === 0) {
            dispatch({ type: "question_type", value: value });
        }
        if (state.mode === 1) {
            dispatch({ type: "sub_question_type", value: value });
        }
        if (state.mode === 2) {
            dispatch({
                type: "edit_sub_question_type",
                value: value,
                id: state.edit_id,
            });
        }
        if (state.mode === 0 && (value === MCQ || value === IQ)) {
            dispatch({ type: "onchangeMcq" });
        }
        if (state.mode === 1 && (value === MCQ || value === IQ)) {
            dispatch({ type: "onchangeSubMcq" });
        }
        if (state.mode === 2 && value === MCQ) {
            dispatch({ type: "edit_sub_mcq", id: state.edit_id });
        }
		if (state.mode === 0 && (value === ANIMATION)) {
			dispatch({ type: "onchangeAnimation" });
		}
		if (state.mode === 1 && (value === ANIMATION)) {
			dispatch({ type: "onchangeSubAnimation" });
		}
        if (state.mode === 2 && value === IQ) {
            dispatch({ type: "edit_sub_iq", id: state.edit_id });
        }

        if (state.mode === 0 && value === TRUE_FALSE) {
            dispatch({ type: "onchangeTF" });
        }
        if (state.mode === 1 && value === TRUE_FALSE) {
            dispatch({ type: "onchangeSubTF" });
        }
        if (state.mode === 2 && value === TRUE_FALSE) {
            dispatch({ type: "edit_sub_truefalse", id: state.edit_id });
        }

        if (
            state.mode === 0 &&
            (value === SHORT_ANSWER || value === MATCHING || value === RESEARCH)
        ) {
            dispatch({ type: "delOptionValues" });
            dispatch({ type: "delSubOptionValues" });
        }
        if (state.mode === 2 && value === SHORT_ANSWER) {
            dispatch({ type: "edit_sub_short_answer", id: state.edit_id });
        }
        if (state.mode === 2 && value === RESEARCH) {
            dispatch({ type: "edit_sub_research", id: state.edit_id });
        }
        if (state.mode === 2 && value === MATCHING) {
            dispatch({ type: "edit_sub_matching", id: state.edit_id });
        }

        if (
            state.mode === 1 &&
            (value === SHORT_ANSWER || value === MATCHING || value === RESEARCH)
        ) {
            dispatch({ type: "delSubOptionValues" });
        }
        if (state.mode === 0 && value === FILL_IN_THE_BLANK) {
            dispatch({ type: "delOptionValues" });
            dispatch({ type: "delSubOptionValues" });
        }
        if (state.mode === 1 && value === FILL_IN_THE_BLANK) {
            dispatch({ type: "delSubOptionValues" });
        }
        if (state.mode === 2 && value === FILL_IN_THE_BLANK) {
            dispatch({ type: "edit_sub_research", id: state.edit_id });
        }
    };
    const { Option } = Select;

    const getAllQuestionType = async () => {
        let res: any = await ApiConnector("get", `${QUESTION_TYPE}`);
        if (res.data.http$.status === 401 && res.data.ok === false) {
            localStorage.removeItem("token");
            navigate("/");
        }
        setQuesType(res.data.data);
    };

    useEffect(() => {
        dispatch({
            type: "sub_question_level_type",
            level_value: state.question.question_level,
            type_value: state.question.question_type,
        });
    }, [state.mode]);
    useEffect(() => {
        getAllQuestionType();
    }, []);

    return (
        <>
            <div className="width-100">
                <Form layout="vertical" className="row-col full-width">
                    <Row justify="space-between" align="middle">
                        <Col span={11}>
                            <div className="question-mandatory-div">
                                <Text
                                    type="danger"
                                    className="question-mandatory-label"
                                >
                                    *
                                </Text>
                                <Text className="question-mandatory-label">
                                    Question Type
                                </Text>
                            </div>
                            <Select
                                status={
                                    state.valid?.question_type ? "error" : ""
                                }
                                className="question-select-box"
                                value={ques.question_type}
                                onChange={getType}
                            >
                                {quesType?.map((elem, index) => {
                                    return (
                                        <Option
                                            key={index}
                                            value={elem.question_type}
                                        >
                                            {elem.question_type}
                                        </Option>
                                    );
                                })}
                            </Select>
                            <div style={{ height: "16px" }}>
                                {state.valid?.question_type && (
                                    <InvalidMsg
                                        msg={state.valid.question_type}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col span={11}>
                            <div className="question-mandatory-div">
                                <Text
                                    type="danger"
                                    className="question-mandatory-label"
                                >
                                    *
                                </Text>
                                <Text className="question-mandatory-label">
                                    Question Level
                                </Text>
                            </div>
                            <Select
                                status={
                                    state.valid?.question_level ? "error" : ""
                                }
                                className="question-select-box"
                                value={ques.question_level}
                                onChange={getlevel}
                            >
                                <Option value="easy">EASY</Option>
                                <Option value="medium">MEDIUM</Option>
                                <Option value="hard">HARD</Option>
                            </Select>
                            <div style={{ height: "16px" }}>
                                {state.valid?.question_level && (
                                    <InvalidMsg
                                        msg={state.valid.question_level}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default AddQuestionDetails;
