import React from "react";

const PencilSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 100 100"
				width={32}
				height={32}
				fill="currentColor"
			>
				<rect
					x="14.42"
					y="52.33"
					transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.6344 52.2268)"
					width="72.62"
					height="7.03"
				/>
				<rect
					x="7.84"
					y="45.76"
					transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.9109 45.6513)"
					width="72.62"
					height="7.03"
				/>
				<polygon points="8.88,91.12 15.99,88.56 11.44,84.01 " />
				<polygon points="68.95,19.52 72.52,23.09 80.48,31.05 83.43,28.1 72.11,16.36 " />
				<path d="M20.15,79.85c-0.03-0.03-0.06-0.07-0.09-0.1l-5.21-5.21l-2.57,7.12l6.05,6.05l7.12-2.57l-5.21-5.21  C20.22,79.91,20.19,79.88,20.15,79.85z" />
				<path d="M80.86,9.21c-0.44-0.44-1.16-0.44-1.6,0l-5.55,5.55L85.03,26.5l5.76-5.76c0.44-0.44,0.44-1.16,0-1.6L80.86,9.21z" />
			</svg>
		</>
	);
};

export default PencilSvg;
