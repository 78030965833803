import { Button, Card, Col, Row } from "antd";
import React, { useContext } from "react";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import SvgDelete from "../svg/SvgDelete";
import SvgEdit from "../svg/SvgEdit";
const parse = require("html-react-parser");

const AddSubQuesCard = (prop: any) => {
	const { heading, number, question } = prop;
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	return (
		<div>
			<Card className="subques-card">
				<Row gutter={[24, 6]}>
					<Col xl={21} xs={20} md={20} className="subquestion-title">
						{parse(state.question_traslation.title)}
					</Col>
					<Col xl={2} xs={2} md={2}>
						<Button
							type="link"
							style={{
								float: "right",
								padding: 0,
								marginTop: "5px",
							}}
							onClick={() =>
								dispatch({ type: "mainquestion_edit_mode" })
							}
						>
							<SvgEdit />
						</Button>
					</Col>
					<Col xl={1} xs={2} md={2}>
						<Button
							type="link"
							style={{
								float: "right",
								padding: 0,
								color: "white",
							}}
						>
							<SvgDelete />
						</Button>
					</Col>
				</Row>
			</Card>
		</div>
	);
};

export default AddSubQuesCard;
