import { Route, Routes } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "antd/dist/antd.less";
import "./App.less";

import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import AddQuestion from "./pages/AddQuestion";
import Create from "./pages/Create";
import {
	ProtectedSignInRouting,
	ProtectedRouting,
} from "./utlis/protected/ProtectedRouting";
import Home from "./pages/Home";
import Reset from "./pages/Reset";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPassword_mail from "./pages/ForgotPassword_mail";
import MyQuestionBank from "./pages/MyQuestionBank";
import Homepage from "./pages/Homepage";
import Preview from "./components/Preview/Preview";
import CreatePlaylist from "./components/MyPlaylist/CreatePlaylist";
import Playlist from "./components/MyPlaylist/Newplaylistui/Playlist";
import VerifyPlaylist from "./VerifyRoute/VerifyPlaylist";
import LikedPage from "./pages/LikedPage";
import FetchOtherSitePlaylist from "./pages/FetchOtherSitePlaylist";
import DraggableText from "./pages/DraggableText";
import MyCanvas from "./pages/MyCanvas";
import MyCanva from "./pages/MyCanva";
import PlaylistCheck from "./pages/CheckPlayList";
import ConductedExamsDashboard from "./pages/ConductedExamsDashboard";
import ExamAttendedStudentList from "./pages/ExamAttendedStudentList";
import CheckPlayList from "./pages/CheckPlayList";
import TestCanva from "./pages/TestCanva";
import StudentResult from "./pages/StudentResult";
import VerifyExamDashBoard from "./VerifyRoute/VerifyExamDashBoard";
import AttendStudentList from "./pages/AttendStudentList";
import Attend from "./pages/Attend";
import TextEditor from "./pages/TextEditor";

const App = () => {
	return (
		<>
			<div className="App">
				<Routes>
					<Route
						path="/"
						element={
							<ProtectedSignInRouting children={<SignIn />} />
						}
					/>
					<Route
						path="/signup"
						element={
							<ProtectedSignInRouting children={<SignUp />} />
						}
					/>

					<Route
						path="/addquestion"
						element={
							<ProtectedRouting
								children={<Main children={<AddQuestion />} />}
							/>
						}
					/>
					<Route
						path="/forgotpassword"
						element={<ForgotPassword />}
					/>
					<Route
						path="/forgotpassword_mail"
						element={<ForgotPassword_mail />}
					/>
					<Route
						path="/reset"
						element={<Main children={<Reset />} />}
					/>
					<Route
						path="/create"
						element={
							<ProtectedRouting
								children={<Main children={<Create />} />}
							/>
						}
					/>
					<Route
						path="/home"
						element={
							<ProtectedRouting
								children={<Main children={<Homepage />} />}
							/>
						}
					/>
					<Route
						path="/preview"
						element={
							<ProtectedRouting
								children={<Main children={<Preview />} />}
							/>
						}
					/>

					<Route
						path="/createPlaylist"
						element={
							<ProtectedRouting
								children={
									<Main children={<CreatePlaylist />} />
								}
							/>
						}
					/>

					<Route
						path="/my_question_bank"
						element={
							<ProtectedRouting
								children={
									<Main children={<MyQuestionBank />} />
								}
							/>
						}
					/>
					<Route
						path="/liked_page"
						element={
							<ProtectedRouting
								children={<Main children={<LikedPage />} />}
							/>
						}
					/>
					{/* Dashbord  */}
					{/* <Route
						path="/dashboard"
						element={
							<ProtectedRouting
								children={<Main children={<Home />} />}
							/>
						}
					/> */}
					{/* Playlist */}
					<Route
						path="/playlist"
						element={
							<ProtectedRouting
								children={<Main children={<Playlist />} />}
							/>
						}
					/>

					{/* for evidya  */}
					<Route path="/verify/:data" element={<VerifyPlaylist />} />

					{/* <Route
						path="/mycanvas"
						element={
							// <DraggableText initialX={100} initialY={100} />
							// <MyCanvas />
							<MyCanva
								initialX={100}
								initialY={100}
								answerText="Hi Rohan"
								setMarksArray={undefined}
							/>
						}
					/> */}

					<Route
						path="/testCanva"
						element={
							<TestCanva
								initialX={100}
								initialY={100}
								answerText="Hi Rohan"
							/>
						}
					></Route>
					<Route
						path="/verifyExamDashBoard/:data"
						element={<VerifyExamDashBoard />}
					/>
					<Route
						path="/conductedExamsDashboard"
						element={
							<ProtectedRouting
								children={<ConductedExamsDashboard />}
							/>
						}
					/>
					<Route
						path="/examAttendedStudentList"
						element={
							<ProtectedRouting
								children={<ExamAttendedStudentList />}
							/>
						}
					/>
					<Route
						path="/checkPlayList"
						element={
							<ProtectedRouting children={<CheckPlayList />} />
						}
					/>
					<Route
						path="/fetchStudentPlaylist"
						element={
							<ProtectedRouting
								children={<FetchOtherSitePlaylist />}
							/>
						}
					/>
					<Route
						path="/studentResult"
						element={
							<ProtectedRouting children={<StudentResult />} />
						}
					/>
					<Route
						path="/attendStudentList"
						element={
							<ProtectedRouting
								children={<AttendStudentList />}
							/>
						}
					/>
					<Route
						path="/attend"
						element={<ProtectedRouting children={<Attend />} />}
					/>
					<Route
						path="/textEditor"
						element={
							<TextEditor
								word="Hi user"
								onChange={undefined}
								deleteData={undefined}
								className="editor"
								editorRef={undefined}
							/>
						}
					/>
				</Routes>
			</div>
		</>
	);
};

export default App;
