import { Row, Space, Button, Col } from 'antd'
import React from 'react'
import SingleTagPie from '../chart/SingleTagPie'
import SingleTagYearly from '../chart/SingleTagYearly'
import TrendingTags from '../chart/TrendingTags'

const TagAnalysis = () => {
  return (
    <>
    <div className='tag-analysis-graph'>
        <Row>
            <Space>
                <Button color="#f50" className='trending-tag-for-chart' >#Trending</Button>
            </Space>
        </Row>
        <Row className='trending-graph' justify='center'>
            <TrendingTags />
        </Row>
        <Row>
            <Button className='trending-tag-for-chart'>#Weekly Analysis</Button>
        </Row>
        <Row>
            <Col className='Single-tag-analysis' xl={12} xs={24} md={12}>
                <SingleTagPie tag="math"/>
            </Col>
            <Col className='Single-tag-analysis' xl={12} xs={24} md={12}>
                <SingleTagPie tag="relation"/>
            </Col>
        </Row>
        <Row>
            <Col className='Single-tag-analysis' xl={12} xs={24} md={12}>
                <SingleTagPie tag="function"/>
            </Col>
            <Col className='Single-tag-analysis' xl={12} xs={24} md={12}>
                <SingleTagPie tag="mcq"/>
            </Col>
        </Row>
        <Row>
            <Button className='trending-tag-for-chart'>#Monthly Analysis</Button>
        </Row>
        <Row justify='center'>
            <SingleTagYearly />
        </Row>
    </div>
    </>
  )
}

export default TagAnalysis