import { UpOutlined } from "@ant-design/icons";
import { BackTop, Button, Row } from "antd";
import React from "react";

const Backtop = () => {
	// back top
	const style: React.CSSProperties = {
		height: 48,
		width: 48,
		borderRadius: 100,
		backgroundColor: "#00305a",
		color: "#fff",
		fontSize: 16,
		textAlign: "center",
        // style={{ right: "350px" }}
	};
	return (
		<>
			<Row>
				<BackTop>
					<Button
						style={style}
						icon={<UpOutlined style={{ marginLeft: "5px" }} />}
					></Button>
				</BackTop>
			</Row>
		</>
	);
};

export default Backtop;
