import React, { useEffect, useState } from "react";
import { ApiConnector } from "../../axios/apiClient";
import { EDITREMARKS, REMARKSSUBMIT } from "../../axios/AllBaseUrl";
import S3fileUpload from "../../utlis/canvas/S3fileUpload";
import CustomToast from "../../utlis/toast/CustomToast";
import { Button, Col, Row } from "antd";
import HeaderExam from "./HeaderExam";
import QNA from "./QNA";
import MarksObtained from "./MarksObtained";

const CheckExamPaper = ({
	checkExamValue,
	setAnswerId,
	setExamPaperView,
	questionData,
	setQuestionData,
	setStudentData,
	answerID,
	setInitialStudent,
}) => {
	let [marksArray, setMarksArray] = useState<any>([
		{
			questionNumber: "",
			marks: "",
			blob: "",
			answer_id: "",
			question_id: "",
		},
	]);
	const [open, setOpen] = useState(false);
	let [obtainedMark, setObtainedMark] = useState(0);

	// console.log("marksArray", marksArray);

	let onchangeMarksValue = (
		QuestionNumber,
		Marks,
		answer_id,
		question_id,
		blob
	) => {
		setMarksArray((prevMarksArray) => {
			// Find the index of the object with the matching questionNumber
			const index = prevMarksArray?.findIndex(
				(item) => item.questionNumber === QuestionNumber
			);

			if (index !== -1) {
				// If the questionNumber exists, update its marks
				const updatedArray = [...prevMarksArray];
				// updatedArray[index] = {
				updatedArray[index].questionNumber = QuestionNumber;
				updatedArray[index].marks = Marks;
				updatedArray[index].answer_id = answer_id;
				updatedArray[index].question_id = question_id;
				// };
				return updatedArray;
			} else {
				// If the questionNumber doesn't exist, add a new object
				return [
					...prevMarksArray,
					{
						questionNumber: QuestionNumber,
						marks: Marks,
						blob: blob,
						answer_id: answer_id,
						question_id: question_id,
					},
				];
			}
		});
	};
	let totalObtained = () => {
		// Initialize a variable to store the total sum
		let total = 0;
		// Loop through the array and add each "a" value to the total (converting them to numbers)
		for (let i = 0; i < marksArray?.length; i++) {
			total += Number(marksArray[i]?.marks);
		}
		setObtainedMark(total);
	};

	const hide = () => {
		setOpen(false);
	};
	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	}

	let handleSubmit = async () => {
		let answerArray = marksArray?.slice(1)?.map((value) => {
			let markeValue = {
				// user_id: "635f89fff2e60d1667aca7e0",
				// playlist_id: "650d39568b7eff5ab2ebc193",
				playlist_id: checkExamValue?.playlistData?.playlist_id,
				user_id: checkExamValue?.user_id,
				answer_id: value?.answer_id,
				remarksURL: value?.blob,
				// ?.size !== undefined
				// 	? await S3fileUpload(value)
				// 	: "",
				marks: value?.marks,
				question_id: value?.question_id,
				exam_playlist_id:
					checkExamValue?.playlistData?.otherSidePlaylistData?.id,
			};
			return markeValue;
		});
		let res = await ApiConnector("post", REMARKSSUBMIT, {
			answerData: answerArray,
			StudentName: checkExamValue?.userData?.Student_Name,
			StudentId: checkExamValue?.userData?.Student_Id,
			PlaylistName: checkExamValue?.playlistData?.playlist_name,
		});
		if (res.data.ok) {
			setStudentData((prevData) =>
				prevData.map((user) =>
					user?._id === answerID ? { ...user, remarks: true } : user
				)
			);
			setInitialStudent((prevData) =>
				prevData.map((user) =>
					user?._id === answerID ? { ...user, remarks: true } : user
				)
			);
			CustomToast("success", res.data.why);
			setExamPaperView(false);
		} else {
			CustomToast("error", res.data.why);
		}
		// console.log("answerArray", answerArray);
		// console.log("answerArray res", res);
		return res;
	};

	let handleEditSubmit = async () => {
		let answerArray = marksArray?.slice(1)?.map((value) => {
			let markeValue = {
				// user_id: "635f89fff2e60d1667aca7e0",
				// playlist_id: "650d39568b7eff5ab2ebc193",
				playlist_id: checkExamValue?.playlistData?.playlist_id,
				user_id: checkExamValue?.user_id,
				answer_id: value?.answer_id,
				remarksURL: value?.blob,
				// ?.size !== undefined
				// 	? await S3fileUpload(value)
				// 	: "",
				marks: value?.marks,
				question_id: value?.question_id,
				exam_playlist_id:
					checkExamValue?.playlistData?.otherSidePlaylistData?.id,
			};
			return markeValue;
		});
		// console.log("edit answerArray", answerArray);

		let res = await ApiConnector("put", EDITREMARKS, answerArray);
		// console.log("edit res", res);
		if (res.data.ok) {
			CustomToast("success", res.data.why);
			setExamPaperView(false);
		} else {
			CustomToast("error", res.data.why);
		}
		// console.log("answerArray", answerArray);
		// console.log("answerArray res", res);
		return res;
	};

	useEffect(() => {
		setAnswerId(checkExamValue?.user_id);
	}, [checkExamValue]);
	useEffect(() => {
		if (marksArray) {
			totalObtained();
		}
	}, [marksArray]);
	useEffect(() => {
		if (checkExamValue?.mode !== 0) {
			totalObtained();
		}
	}, [checkExamValue]);

	if (checkExamValue?.mode === 1 && checkExamValue?.remarks === false) {
		return (
			<>
				<Row
					gutter={24}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						margin: 0,
					}}
				>
					<Col style={{ fontWeight: "bold", fontSize: "24px" }}>
						You need to check the answer first
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			{questionData && (
				<div style={{ width: "100%", padding: "3rem" }}>
					<HeaderExam
						open={open}
						handleOpenChange={handleOpenChange}
						hide={hide}
						marksArray={marksArray}
						obtainedMark={obtainedMark}
						playlistData={checkExamValue?.playlistData}
						studentName={checkExamValue?.studentName}
						checkExamValue={checkExamValue}
					/>

					<Row
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "start",
							paddingTop: "1.5rem",
						}}
					>
						<Col
							span={16}
							style={{
								backgroundColor: "#ffffff",
								borderRadius: "0.5rem",
								padding: "1rem",
							}}
						>
							{questionData?.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<QNA
											QuestionType={
												value?.questions?.question_type
											}
											value={value}
											QuestionNumber={index + 1}
											onchangeMarksValue={
												onchangeMarksValue
											}
											setMarksArray={setMarksArray}
											marksArray={marksArray}
											editState={checkExamValue}
										/>
									</React.Fragment>
								);
							})}
						</Col>
						<Col
							span={7}
							style={{
								backgroundColor: "#ffffff",
								borderRadius: "0.5rem",
								padding: "1rem",
								textAlign: "left",
								justifyContent: "end",
							}}
						>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<h3
									style={{
										fontSize: "1.25rem",
										color: "#a0a0a0",
									}}
								>
									Marks Obtained
								</h3>

								<h3
									style={{
										fontSize: "1.25rem",
										color: "#a8dadc",
										backgroundColor: "#084b83",
										padding: "2px 8px",
										borderRadius: "0.25rem",
										margin: "0",
									}}
								>
									{/* 11.5 */}
									{obtainedMark}
								</h3>
							</div>
							{marksArray?.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<MarksObtained
											value={value}
											index={index}
										/>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
					<Row>
						<Col>
							{checkExamValue?.mode === 0 && (
								<Button onClick={handleSubmit}>Submit</Button>
							)}
							{checkExamValue?.mode === 1 && (
								<Button onClick={handleEditSubmit}>
									Edit Submit
								</Button>
							)}
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default CheckExamPaper;
