import React, { useContext } from "react";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Input, Tooltip } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { InputRef } from "antd";
import { Tag } from "antd";
import { useRef } from "react";
import "../../App.css";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { Typography } from "antd";
import InvalidMsg from "./InvalidMsg";
import { useDispatch, useSelector } from "react-redux";

import { postSearch } from "../../redux/reducers/SearchQuestionSlice";
// import { postSearch } from "../../redux/reducers/SearchQuestionSlice";
// import { SuggestionQuestionFetch } from "../../redux/saga/SagaFunctions";
const { Text } = Typography;

const AddTags = (prop) => {
	const { pageData, isFromEdit = false, editTag = [], setEditTag } = prop;
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [error, setError] = useState<any>({});

	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [tagsHandler, setTagsHandler] = useState([]);
	const [editInputIndex, setEditInputIndex] = useState(-1);
	const [editInputValue, setEditInputValue] = useState("");
	const inputRef = useRef<InputRef>(null);
	const editInputRef = useRef<InputRef>(null);
	const dispatched = useDispatch();
	// saga
	// const suggestions = useSelector(
	// 	(state: RootState) => state.suggestion.suggest
	// );
	// console.log(suggestions[0]?.data?.data);

	// console.log("isFromEdit", isFromEdit);
	// console.log("editTag", editTag);
	// console.log("tagsHandler", tagsHandler);

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
		if (isFromEdit == false) {
			calTagsRender();
		}
	}, [inputValue, tagsHandler]);

	const handleClose = (removedTag: string) => {
		if (isFromEdit == false && state.mode === 0) {
			const newTags = state.question.tags.filter(
				(tag) => tag !== removedTag
			);
			dispatch({ type: "modifyTag", value: newTags });
		}
		if (isFromEdit == false && state.mode === 1) {
			const newTags = state.subQuestion.tags.filter(
				(tag) => tag !== removedTag
			);
			dispatch({ type: "modifySubQuesTag", value: newTags });
		}
		if (isFromEdit == false && state.mode === 2) {
			const newTags = state.question.subQuestions[
				state.edit_id
			].tags.filter((tag) => tag !== removedTag);
			dispatch({
				type: "edit_subques_tag",
				value: newTags,
				id: state.edit_id,
			});
		}
		if (isFromEdit == true) {
			const newTags = editTag?.filter((tag) => tag !== removedTag);
			setEditTag(newTags);
		}
	};

	const calTagsRender = () => {
		const currentQState = state.mode; // 0 = main , 1 = subQ, 2 = edit
		const tagList =
			currentQState === 0
				? state.question.tags
				: currentQState === 1
				? state.subQuestion.tags
				: state.question.subQuestions[state.edit_id].tags;
		setTagsHandler(tagList);
	};
	useEffect(() => {
		if (isFromEdit == false) {
			calTagsRender();
		}
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);
	useEffect(() => {
		if (isFromEdit == true) {
			setTagsHandler(editTag);
		}
	}, [editTag]);

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let tagValue = e.target.value.toLowerCase();
		// let newValue = tagValue.replace(/[^\w]+/g, "");
		let newValue = tagValue.replace(/\s/g, "");
		setInputValue(newValue);
	};

	const handleInputConfirm = () => {
		if (
			isFromEdit == false &&
			state.mode === 0 &&
			inputValue &&
			state.question.tags.indexOf(inputValue) === -1
		) {
			dispatch({ type: "addTag", value: inputValue });
		}
		if (
			isFromEdit == false &&
			state.mode === 1 &&
			inputValue &&
			state.subQuestion.tags.indexOf(inputValue) === -1
		) {
			dispatch({ type: "addSubQuesTag", value: inputValue });
		}

		if (
			isFromEdit == false &&
			state.mode === 2 &&
			inputValue &&
			state.question.subQuestions[state.edit_id].tags.indexOf(
				inputValue
			) === -1
		) {
			dispatch({
				type: "edit_sub_questions_tags",
				value: inputValue,
				id: state.edit_id,
			});
		}
		if (
			isFromEdit == true &&
			inputValue &&
			editTag.indexOf(inputValue) === -1
		) {
			setEditTag([...editTag, inputValue]);
		}

		setInputVisible(false);
		setInputValue("");
	};

	const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditInputValue(e.target.value);
		dispatch({ type: "SuggestionFetch", payload: e.target.value });
	};

	const handleEditInputConfirm = () => {
		if (isFromEdit == false && state.mode === 0) {
			const newTags = [...state.question.tags];
			newTags[editInputIndex] = editInputValue;
			dispatch({ type: "modifyTag", value: newTags });
		}
		if (isFromEdit == false && state.mode === 1) {
			const newTags = [...state.subQuestion.tags];
			newTags[editInputIndex] = editInputValue;
			dispatch({ type: "modifySubQuesTag", value: newTags });
		}

		setEditInputIndex(-1);
		setInputValue("");
	};

	useEffect(() => {
		if (isFromEdit == false) {
			dispatch({ type: "tagMainToSub" });
		}
		// sagaRun("math");  saga run
	}, [state.mode]);
	useEffect(() => {
		// if (tagsHandler && tagsHandler.length !== 0) {
		if (isFromEdit == false) {
			dispatched(
				postSearch({
					tags: tagsHandler,
					allSearch: 0,
					page: pageData?.page,
					showItems: pageData?.pageSize,
				})
			);
		}
	}, [tagsHandler, pageData]);

	const returnInput = (index: number = 0, defaultInputMode = 1) => {
		return (
			<Input
				allowClear
				ref={inputRef}
				type="text"
				size="large"
				className="tag-input"
				value={inputValue}
				// onChange={
				// 	defaultInputMode ? handleInputChange : handleEditInputChange
				// }
				onChange={handleInputChange}
				onBlur={
					defaultInputMode
						? handleInputConfirm
						: handleEditInputConfirm
				}
				onPressEnter={
					defaultInputMode
						? handleInputConfirm
						: handleEditInputConfirm
				}
				maxLength={20}
			/>
		);
	};

	const tagElem = (tag: string, isLongTag: boolean, index: number) => {
		return (
			<Tag
				className="edit-tag"
				key={tag}
				closable={true}
				color="geekblue"
				style={{ fontSize: "14px" }}
				onClose={() => handleClose(tag)}
			>
				<span
					onDoubleClick={(e) => {
						setEditInputIndex(index);
						setEditInputValue(tag);
						e.preventDefault();
					}}
				>
					{isLongTag ? `${tag.slice(0, 20)}...` : tag}
				</span>
			</Tag>
		);
	};

	let get_error_in_edit_mode = () => {
		if (
			state.edit_id !== null &&
			state?.edit_mode_validation?.length !== 0
		) {
			if (
				Object.keys(state?.edit_mode_validation[state?.edit_id])
					?.length !== 0
			) {
				setError(state?.edit_mode_validation[state?.edit_id]);
			} else {
				setError({});
			}
		}
	};

	useEffect(() => {
		if (isFromEdit == false) {
			get_error_in_edit_mode();
		}
	}, [state.edit_id, state.edit_mode_validation]);

	return (
		<>
			{isFromEdit == false && (
				<div className="width-100" style={{ padding: "5px" }}>
					<div className="question-mandatory-div">
						<Text
							type="danger"
							className="question-mandatory-label"
						>
							*
						</Text>
						<Text className="question-mandatory-label">
							Add Tags
						</Text>
					</div>
				</div>
			)}
			<div className="width-100">
				{tagsHandler.map((tag: any, index) => {
					const isLongTag = tag.length > 20 ?? false;
					if (editInputIndex === index) {
						returnInput(index, 0);
					}
					// return tagElem(tag, false, index);
					return isLongTag ? (
						<Tooltip title={tag} key={tag}>
							{tagElem(tag, isLongTag, index)};
						</Tooltip>
					) : (
						tagElem(tag, isLongTag, index)
					);
				})}
				{inputVisible ? (
					returnInput(0)
				) : (
					<Tag
						className="site-tag-plus"
						onClick={showInput}
						color={
							state.valid?.tags || error?.tags
								? "error"
								: "geekblue"
						}
						// color="geekblue"
					>
						<span>
							<TagOutlined /> New Tag
						</span>
					</Tag>
				)}

				{/* <AddTitle setshow={setShow} /> */}
			</div>
			<div style={{ height: "16px" }}>
				{(state.valid?.tags || error?.tags) && (
					<InvalidMsg msg={state.valid.tags || error?.tags} />
				)}
			</div>
		</>
	);
};

export default AddTags;
