import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { HashRouter } from "react-router-dom";
import App from "./App";
import CreateQuestionState from "./context/createQuestion/CreateQuestionState";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<CreateQuestionState>
			<HashRouter>
				<App />
			</HashRouter>
		</CreateQuestionState>
	</Provider>
	// </React.StrictMode>
);
