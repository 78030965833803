import React from "react";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { Card, Input, Row, Col, Image, Button, Tooltip, Avatar } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import EditIconSvg from "../components/svg/EditIconSvg";
import CheckExamSvg from "../components/svg/CheckExamSvg";
import { Scrollbars } from "react-custom-scrollbars-2";

const Attend = () => {
	const [isOpen, setIsOpen] = React.useState(true);
	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);
	};
	return (
		<>
			<button onClick={toggleDrawer}>Show</button>
			<Drawer
				open={isOpen}
				onClose={toggleDrawer}
				direction="left"
				className="bla bla bla"
				style={{ backgroundColor: "#001529", width: "310px" }}
			>
				<div className="logo" />
				<div style={{ padding: "10px" }}>
					<Input
						placeholder="Search"
						prefix={
							<SearchOutlined
								style={{ color: "rgba(0,0,0,.25)" }}
							/>
						}
					/>
				</div>

				<Scrollbars
					style={{
						width: "300px",
						height: "90vh",
					}}
				>
					<div style={{ padding: "10px", overflow: "hidden" }}>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
							(value, index) => {
								return (
									<>
										<Card
											size="small"
											style={{ width: "100%" }}
										>
											<Row
												gutter={24}
												style={{ columnGap: "0px" }}
											>
												<Col
													xxl={4}
													xl={4}
													md={4}
													sm={4}
												>
													<Avatar
														icon={<UserOutlined />}
													/>
												</Col>
												<Col
													xxl={14}
													xl={14}
													md={14}
													sm={14}
												>
													<p
														style={{
															margin: "0px",
                                                            textAlign:"left",
														}}
													>
														<strong>
															Masud Biswas
														</strong>
													</p>

													<p
														style={{
															margin: "0px",
                                                            textAlign:"left",
														}}
													>
														<strong>
															Roll No . : 100
														</strong>
													</p>
												</Col>
												<Col
													xxl={4}
													xl={4}
													md={4}
													sm={4}
												>
													<div
														style={{
															display: "flex",
														}}
													>
														<Tooltip
															placement="topLeft"
															title="Check "
														>
															<Button type="link" style={{padding:"0"}}>
																<CheckExamSvg />
															</Button>
														</Tooltip>
														<Tooltip
															placement="topLeft"
															title="Edit "
														>
															<Button type="link" style={{padding:"0"}}>
																<EditIconSvg />
															</Button>
														</Tooltip>
													</div>
												</Col>
											</Row>
										</Card>
									
									</>
								);
							}
						)}
					</div>
				</Scrollbars>
                <Card
											size="small"
											// title="Card Title"
											style={{ width: "100%" }}
										>
											{/* <p>Card Content</p> */}
											<Row gutter={24}>
												<Col
													xxl={4}
													xl={4}
													md={4}
													sm={4}
												>
													<Image
														width={60}
														src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
													/>
												</Col>
												<Col
													xxl={20}
													xl={20}
													md={20}
													sm={20}
												>
													{/* <Row
														style={{
															rowGap: "-1px",
														}}
													>
														<Col
															xxl={24}
															xl={24}
															md={24}
															sm={24}
														> */}
													<div className="StudentNameCheck">
														<b>
															<p
																style={{
																	margin: "0px",
																}}
															>
																{" "}
																Masud Biswas
															</p>
														</b>
														{/* </Col>
													</Row> */}
														{/* <Row
														style={{
															rowGap: "1px",
														}}
													>
														<Col
															xxl={24}
															xl={24}
															md={24}
															sm={24}
														> */}
														<b>
															<p
																style={{
																	margin: "0px",
																}}
															>
																Roll No. : 100
															</p>
														</b>
													</div>
													{/* </Col>
													</Row> */}
													<Row
														style={{
															justifyContent:
																"end",
														}}
													>
														<Col
														// style={{
														// 	marginRight: "10px",
														// }}
														>
															<Tooltip
																placement="topLeft"
																title="Check "
															>
																<Button type="link">
																	<CheckExamSvg />
																</Button>
															</Tooltip>
														</Col>
														<Col
														// style={{
														// 	paddingRight: "10px",
														// }}
														>
															{" "}
															<Tooltip
																placement="topLeft"
																title="Edit "
															>
																<Button type="link">
																	<EditIconSvg />
																</Button>
															</Tooltip>
														</Col>
													</Row>
												</Col>
											</Row>
										</Card>
			</Drawer>
		</>
	);
};

export default Attend;
