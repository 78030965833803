import { useReducer, useState } from "react";
import CreateQuesContext from "./createQuesContext";

interface quesTranslaion {
	langCode: string;
	title: string;
	description: string;
	longHint: string;
	shortHint: string;
}

interface option {
	isCorrect: string;
}

interface questionInterface {
	question_level: string;
	question_type: string;
	question_traslation: quesTranslaion[];
	options: option[];
	tags: string[];
}

type theme = {
	children: React.ReactNode;
};

const createQuestion_initialstate: any = {
	question: {
		question_level: "",
		question_type: "",
		question_traslation: [],
		options: [],
		files: [],
		tags: [],
		word_limit: "",
		word_limit_checked: false,
		subQuestions: [],
	},
	question_traslation: {
		langCode: "",
		title: "",
		description: "",
		longHint: "",
		shortHint: "",
	},
	oneOption: {
		isCorrect: 0,
		OptionTranslation: [
			{
				langCode: "en",
				title: "",
				imageUrl: "",
			},
		],
	},
	subQuestion: {
		question_level: "",
		question_type: "",
		question_traslation: [],
		files: [],
		options: [],
		tags: [],
		word_limit: "",
		word_limit_checked: false,
	},
	sub_question_traslation: {
		langCode: "",
		title: "",
		description: "",
		longHint: "",
		shortHint: "",
	},
	mode: 0,
	optionValue: "",
	valid: {},
	edit_id: null,
	edit_main_flag: 0,
	edit_question_data: {},
	editor_value: "",
	blank_count: [],
	sub_blank_count: [],
	edit_mode_validation: [],
	is_create_playlist_modal_open_in_qu_create: false,
};

const reducer = (state: any, action: any) => {
	// console.log(state, action);

	switch (action.type) {
		case "addTag":
			return {
				...state,
				question: {
					...state.question,
					tags: [...state.question.tags, action.value],
				},
			};
		case "tagMainToSub":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					tags: [...state.question.tags],
				},
			};
		case "addSubQuesTag":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					tags: [...state.subQuestion.tags, action.value],
				},
			};
		case "modifyTag":
			return {
				...state,
				question: {
					...state.question,
					tags: action.value,
				},
			};
		case "modifySubQuesTag":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					tags: action.value,
				},
			};

		case "question_type":
			return {
				...state,
				question: {
					...state.question,
					question_type: action.value,
				},
				// subQuestion: {
				// 	...state.subQuestion,
				// 	question_type: action.value,
				// },
			};
		case "question_level":
			return {
				...state,
				question: {
					...state.question,
					question_level: action.value,
				},
			};
		case "sub_question_level_type":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					question_level: action.level_value,
					question_type: action.type_value,
				},
			};
		case "sub_question_level":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					question_level: action.value,
				},
			};
		case "title":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					title: action.value,
				},
			};
		case "sub_title":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					title: action.value,
				},
			};
		case "description":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					description: action.value,
				},
			};
		case "sub_description":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					description: action.value,
				},
			};

		case "langCode":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					langCode: action.value,
				},
			};
		case "sub_langCode":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					langCode: action.value,
				},
			};
		case "longHint":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					longHint: action.value,
				},
			};
		case "sub_longHint":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					longHint: action.value,
				},
			};
		case "shortHint":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					shortHint: action.value,
				},
			};
		case "sub_shortHint":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					shortHint: action.value,
				},
			};
		case "setAddOption":
			return {
				...state,
				question: {
					...state.question,
					options: [...state.question.options, state.oneOption],
				},
			};
		case "sub_setAddOption":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [...state.subQuestion.options, state.oneOption],
				},
			};

		case "optionIscorrect":
			let newArr: any = [];
			state.question.options.map((elem, index, arr) => {
				let temp: any = Object.assign({}, elem);
				if (index === action.value) {
					temp.isCorrect = 1;
				} else {
					temp.isCorrect = 0;
				}
				newArr.push(temp);
			});
			return {
				...state,
				question: { ...state.question, options: newArr },
			};
		case "sub_optionIscorrect":
			let sub_newArr: any = [];
			state.subQuestion.options.map((elem, index, arr) => {
				let temp: any = Object.assign({}, elem);
				if (index === action.value) {
					temp.isCorrect = 1;
				} else {
					temp.isCorrect = 0;
				}
				sub_newArr.push(temp);
			});
			return {
				...state,
				subQuestion: { ...state.subQuestion, options: sub_newArr },
			};
		case "optionTitle":
			let optionvalue = state.question.options
				.filter((option, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								title: action.value,
							},
						],
					};
				});
			let raopion = state.question.options.splice(
				action.id,
				1,
				optionvalue[0]
			);
			// console.log(state.question.options);

			return {
				...state,
				question: {
					...state.question,
					options: state.question.options,
				},
			};
		case "sub_optionTitle":
			let sub_optionvalue = state.subQuestion.options
				.filter((option, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								title: action.value,
							},
						],
					};
				});
			let sub_raopion = state.subQuestion.options.splice(
				action.id,
				1,
				sub_optionvalue[0]
			);

			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: state.subQuestion.options,
				},
			};
		case "option_image_upload":
			let question_option_image_upload = state.question.options
				.filter((option, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								imageUrl: action.value,
							},
						],
					};
				});
			state.question.options.splice(
				action.id,
				1,
				question_option_image_upload[0]
			);
			return {
				...state,
				question: {
					...state.question,
					options: state.question.options,
				},
			};

		case "sub_option_image_upload":
			let sub_question_option_image_upload = state.subQuestion.options
				.filter((option, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								imageUrl: action.value,
							},
						],
					};
				});
			state.subQuestion.options.splice(
				action.id,
				1,
				sub_question_option_image_upload[0]
			);
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: state.subQuestion.options,
				},
			};
		case "subquestionForm":
			return {
				...state,
				mode: 1,
			};
		case "editToSubQues":
			return {
				...state,
				mode: 1,
				edit_id: null,
			};

		case "sub_question_type":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					question_type: action.value,
				},
			};

		case "edit_to_subquestion":
			return {
				...state,
				mode: 1,
				edit_id: null,
				edit_mode_validation: [],
			};
		case "mainquestionForm":
			return {
				...state,
				mode: 0,
			};
		case "fullSubQuestion":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					question_traslation: [
						...state.subQuestion.question_traslation,
						state.sub_question_traslation,
					],
				},
			};
		case "addTranslationMainQues":
			return {
				...state,
				question: {
					...state.question,
					question_traslation: [
						...state.question.question_traslation,
						state.question_traslation,
					],
				},
			};
		case "addSubInMainQuestion":
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.subQuestion && [
						...state.question.subQuestions,
						state.subQuestion,
					],
				},
			};
		case "onchangeMcq":
			return {
				...state,
				question: {
					...state.question,
					options: [
						state.oneOption,
						state.oneOption,
						state.oneOption,
					],
				},
				subQuestion: {
					...state.subQuestion,
					options: [
						state.oneOption,
						state.oneOption,
						state.oneOption,
					],
				},
			};
		case "onchangeSubMcq":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [
						state.oneOption,
						state.oneOption,
						state.oneOption,
					],
				},
			};
		case "onchangeTF":
			return {
				...state,
				question: {
					...state.question,
					options: [
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "True",
									imageUrl: "",
								},
							],
						},
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "False",
									imageUrl: "",
								},
							],
						},
					],
				},
				subQuestion: {
					...state.question,
					options: [
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "True",
									imageUrl: "",
								},
							],
						},
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "False",
									imageUrl: "",
								},
							],
						},
					],
				},
			};

		case "onchangeSubTF":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "True",
									imageUrl: "",
								},
							],
						},
						{
							isCorrect: 0,
							OptionTranslation: [
								{
									langCode: "en",
									title: "False",
									imageUrl: "",
								},
							],
						},
					],
				},
			};

		case "delOptionValues":
			return {
				...state,
				question: {
					...state.question,
					options: [],
				},
			};
		case "delSubOptionValues":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [],
				},
			};
		case "cleanOldData":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					question_traslation: [],
					files: [],
					word_limit: "",
					word_limit_checked: false,
					// options: [],
				},
				sub_question_traslation: {
					...state.sub_question_traslation,
					title: "",
					description: "",
					longHint: "",
					shortHint: "",
				},
			};
		case "cleanAllOldData":
			return {
				...state,
				question: createQuestion_initialstate.question,
				question_traslation:
					createQuestion_initialstate.question_traslation,
				subQuestion: createQuestion_initialstate.subQuestion,
				sub_question_traslation:
					createQuestion_initialstate.sub_question_traslation,
				mode: 0,
			};
		case "question_fileupload":
			return {
				...state,
				question: {
					...state.question,
					files: [...state.question.files, action.value],
				},
			};
		case "sub_question_fileupload":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					files: [...state.subQuestion.files, action.value],
				},
			};
		case "remove_question_file":
			let question_file = state.question.files;

			let temp_question_file = Object.assign([], question_file);
			temp_question_file.splice(action.id, 1);

			return {
				...state,
				question: {
					...state.question,
					files: temp_question_file,
				},
			};
		case "remove_subquestion_file":
			let sub_question_file = state.subQuestion.files;
			let temp_sub_question_file = Object.assign([], sub_question_file);
			temp_sub_question_file.splice(action.id, 1);
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					files: temp_sub_question_file,
				},
			};
		case "remove_subquestion_file_editmode":
			// let del_subquestion = state.question.subQuestions;
			// let one_subquestion = state.question.subQuestions[action.edit_id];
			// let del_subquestion_file =
			// 	state.question.subQuestions[action.edit_id].files;
			// let temp_del_subquestion = Object.assign([], del_subquestion);
			// let temp_one_subquestion = Object.assign({}, one_subquestion);
			// let temp_del_subquestion_file = Object.assign(
			// 	[],
			// 	del_subquestion_file
			// );
			// console.log(
			// 	temp_del_subquestion,
			// 	temp_one_subquestion,
			// 	temp_del_subquestion_file
			// );

			// temp_del_subquestion_file.splice(action.id, 1);
			// temp_one_subquestion.files = temp_del_subquestion_file;
			// temp_del_subquestion[action.id] = temp_one_subquestion;
			// return {
			// 	...state,
			// 	question: {
			// 		...state.question,
			// 		subQuestions: temp_del_subquestion,
			// 	},
			// };
			// let new_remove_file_arr: any = [];
			let one_subquestion = state.question.subQuestions[action.edit_id];
			let del_subquestion = state.question.subQuestions;

			let temp_remove_files: any = Object.assign(
				[],
				one_subquestion.files
			);

			let temp_remove_question = Object.assign({}, one_subquestion);
			let temp_del_subquestion = Object.assign([], del_subquestion);

			temp_remove_files.splice(action.id, 1);

			temp_remove_question.files = temp_remove_files;
			temp_del_subquestion[action.id] = temp_remove_question;
			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_del_subquestion,
				},
			};

		case "validateForm":
			return {
				...state,
				valid: action.value,
			};
		case "edit_subquestion":
			return {
				...state,
				valid: {},
				mode: 2,
				edit_id: action.id,
				edit_sub_question_data: state.question.subQuestions[action.id],
			};
		case "edit_sub_description":
			let description_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					// return elem;
					return {
						...elem,
						question_traslation: [
							{
								...elem.question_traslation[0],
								description: action.value,
							},
						],
					};
				});
			// console.log(description_value);
			state.question.subQuestions.splice(
				action.id,
				1,
				description_value[0]
			);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_question_level":
			let level_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						question_level: action.value,
					};
				});
			state.question.subQuestions.splice(action.id, 1, level_value[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_question_language":
			let language_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						question_traslation: [
							{
								...elem.question_traslation[0],
								langCode: action.value,
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, language_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_question_title":
			let title_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						question_traslation: [
							{
								...elem.question_traslation[0],
								title: action.value,
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, title_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_question_longhint":
			let longhint_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						question_traslation: [
							{
								...elem.question_traslation[0],
								longHint: action.value,
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, longhint_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_question_shorthint":
			let shorthint_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,

						question_traslation: [
							{
								...elem.question_traslation[0],
								shortHint: action.value,
							},
						],
					};
				});
			state.question.subQuestions.splice(
				action.id,
				1,
				shorthint_value[0]
			);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_questions_tags":
			let new_edit_tag_arr: any = [];
			let edit_subquesion_tag = state.question.subQuestions[action.id];
			let all_sub_question_tag = state.question.subQuestions;
			let temp_tags: any = Object.assign([], edit_subquesion_tag.tags);
			let temp_question_tag = Object.assign({}, edit_subquesion_tag);
			let temp_all_sub_question_tag = Object.assign(
				[],
				all_sub_question_tag
			);
			new_edit_tag_arr.unshift(...temp_tags, action.value);
			// console.log(new_edit_tag_arr);
			temp_question_tag.tags = new_edit_tag_arr;
			temp_all_sub_question_tag[action.id] = temp_question_tag;

			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_sub_question_tag,
				},
			};

		case "edit_sub_question_type":
			let type_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						question_type: action.value,
					};
				});
			state.question.subQuestions.splice(action.id, 1, type_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_mcq":
			let options = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						options: [
							state.oneOption,
							state.oneOption,
							state.oneOption,
						],
						question_traslation: [
							{
								...elem.question_traslation[0],
								description: "",
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, options[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_iq":
			let options_iq = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						options: [
							state.oneOption,
							state.oneOption,
							state.oneOption,
						],
						question_traslation: [
							{
								...elem.question_traslation[0],
								longHint: "",
								shortHint: "",
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, options_iq[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_truefalse":
			let option_tf = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						options: [
							{
								isCorrect: 0,
								OptionTranslation: [
									{
										langCode: "en",
										title: "True",
										imageUrl: "",
									},
								],
							},
							{
								isCorrect: 0,
								OptionTranslation: [
									{
										langCode: "en",
										title: "False",
										imageUrl: "",
									},
								],
							},
						],
						question_traslation: [
							{
								...elem.question_traslation[0],
								longHint: "",
								shortHint: "",
								description: "",
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, option_tf[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_short_answer":
			let short_value = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						options: [],
						question_traslation: [
							{
								...elem.question_traslation[0],
								description: "",
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, short_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_research":
			let research_value = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						options: [],
					};
				});
			state.question.subQuestions.splice(action.id, 1, research_value[0]);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_matching":
			let matching_value = state.question.subQuestions
				.filter((elem, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						option: [],
						question_traslation: [
							{
								...elem.question_traslation[0],
								description: "",
							},
						],
					};
				});
			state.question.subQuestions.splice(action.id, 1, matching_value[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_option_title":
			let edit_sub_question = state.question.subQuestions.filter(
				(elem, index) => {
					return index === action.id;
				}
			);
			let edited_option = edit_sub_question[0].options
				.filter((elem, index) => {
					return index === action.option_id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								title: action.value,
							},
						],
					};
				});
			edit_sub_question[0].options.splice(
				action.option_id,
				1,
				edited_option[0]
			);
			state.question.subQuestions.splice(
				action.id,
				1,
				edit_sub_question[0]
			);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_suboption_iscorrect":
			let new_option_arr: any = [];
			let editable_subquesion = state.question.subQuestions.filter(
				(elem, index) => {
					return index === action.id;
				}
			);
			editable_subquesion[0].options.map((elem, index) => {
				let temp: any = Object.assign({}, elem);
				if (index === action.value) {
					temp.isCorrect = 1;
				} else {
					temp.isCorrect = 0;
				}
				new_option_arr.push(temp);
			});
			editable_subquesion[0].options = new_option_arr;
			state.question.subQuestions.splice(
				action.id,
				1,
				editable_subquesion[0]
			);
			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		case "edit_sub_add_option":
			let new_edit_option_arr: any = [];
			let edit_subquesion = state.question.subQuestions[action.id];
			let all_sub_question = state.question.subQuestions;

			let temp_option: any = Object.assign([], edit_subquesion.options);
			let temp_question = Object.assign({}, edit_subquesion);
			let temp_all_sub_question = Object.assign([], all_sub_question);

			new_edit_option_arr.unshift(...temp_option, state.oneOption);

			temp_question.options = new_edit_option_arr;
			temp_all_sub_question[action.id] = temp_question;

			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_sub_question,
				},
			};
		case "edit_sub_question_fileupload":
			let new_edit_file_arr: any = [];
			let edit_subquesion_file = state.question.subQuestions[action.id];
			let all_sub__question = state.question.subQuestions;

			let temp_files: any = Object.assign([], edit_subquesion_file.files);
			let temp__question = Object.assign({}, edit_subquesion_file);
			let temp_all_sub__question = Object.assign([], all_sub__question);

			new_edit_file_arr.unshift(...temp_files, action.value);

			temp__question.files = new_edit_file_arr;
			temp_all_sub__question[action.id] = temp__question;

			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_sub__question,
				},
			};

		case "mainquestion_edit_mode":
			return {
				...state,
				mode: 0,
				edit_id: null,
				edit_main_flag: 1,
			};
		case "set_blank_counter":
			return {
				...state,
				blank_count: action.value,
			};
		case "main_blank_right_answer":
			let option_arr = [...state.question.options];
			if (state.blank_count?.length > state.question.options?.length) {
				for (
					let index = 0;
					index <
					state.blank_count?.length - state.question.options?.length;
					index++
				) {
					option_arr = [...option_arr, state.oneOption];
				}
			}

			if (state.blank_count?.length < state.question.options?.length) {
				for (
					let index = 0;
					index <
					state.question.options?.length - state.blank_count?.length;
					index++
				) {
					option_arr.pop();
				}
			}
			return {
				...state,
				question: {
					...state.question,
					options: option_arr,
				},
			};
		case "set_sub_blank_counter":
			return {
				...state,
				sub_blank_count: action.value,
			};
		case "sub_blank_right_answer":
			let sub_option_arr = [...state.subQuestion.options];
			if (
				state.sub_blank_count?.length >
				state.subQuestion.options?.length
			) {
				for (
					let index = 0;
					index <
					state.sub_blank_count?.length -
						state.subQuestion.options?.length;
					index++
				) {
					sub_option_arr = [...sub_option_arr, state.oneOption];
				}
			}
			if (
				state.sub_blank_count?.length <
				state.subQuestion.options?.length
			) {
				for (
					let index = 0;
					index <
					state.subQuestion.options?.length -
						state.sub_blank_count?.length;
					index++
				) {
					sub_option_arr.pop();
				}
			}

			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: sub_option_arr,
				},
			};

		case "edit_sub_blank_right_answer":
			let sub_fillblank_option_arr: any = [
				...state.question.subQuestions[action.id].options,
			];
			if (
				state.sub_blank_count?.length >
				state.question.subQuestions[action.id].options?.length
			) {
				for (
					let index = 0;
					index <
					state.sub_blank_count?.length -
						state.question.subQuestions[action.id].options?.length;
					index++
				) {
					sub_fillblank_option_arr = [
						...sub_fillblank_option_arr,
						state.oneOption,
					];
				}
			}
			if (
				state.sub_blank_count?.length <
				state.question.subQuestions[action.id].options?.length
			) {
				for (
					let index = 0;
					index <
					state.question.subQuestions[action.id].options?.length -
						state.sub_blank_count?.length;
					index++
				) {
					sub_fillblank_option_arr.pop();
				}
			}
			let edit_subquesion_ = state.question.subQuestions[action.id];
			let all_sub_question_ = state.question.subQuestions;
			let temp_question_ = Object.assign({}, edit_subquesion_);
			let temp_all_sub_question_ = Object.assign([], all_sub_question_);
			temp_question_.options = sub_fillblank_option_arr;
			temp_all_sub_question_[action.id] = temp_question_;

			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_sub_question_,
				},
			};
		case "edit_subques_tag":
			let subquestion_to_remove_tag =
				state.question.subQuestions[action.id];
			let all_sub_question_remove_tag = state.question.subQuestions;
			let temp_question_to_remove_tag = Object.assign(
				{},
				subquestion_to_remove_tag
			);
			let temp_all_sub_question_to_remove_tag = Object.assign(
				[],
				all_sub_question_remove_tag
			);
			temp_question_to_remove_tag.tags = action.value;
			temp_all_sub_question_to_remove_tag[action.id] =
				temp_question_to_remove_tag;

			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_sub_question_to_remove_tag,
				},
			};

		case "set_word_limit":
			return {
				...state,
				question: {
					...state.question,
					word_limit: action.value,
				},
			};
		case "set_sub_word_limit":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					word_limit: action.value,
				},
			};
		case "set_word_limit_checked":
			return {
				...state,
				question: {
					...state.question,
					word_limit_checked: action.value,
				},
			};
		case "set_sub_word_limit_checked":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					word_limit_checked: action.value,
				},
			};

		case "edit_sub_word_limit_checked":
			let checked_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						word_limit_checked: action.value,
					};
				});
			state.question.subQuestions.splice(action.id, 1, checked_value[0]);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_word_limit":
			let edit_limit_value = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						word_limit: action.value,
					};
				});
			state.question.subQuestions.splice(
				action.id,
				1,
				edit_limit_value[0]
			);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "blank_word_limit":
			return {
				...state,
				question: { ...state.question, word_limit: "" },
			};
		case "sub_blank_word_limit":
			return {
				...state,
				subQuestion: { ...state.subQuestion, word_limit: "" },
			};

		case "edit_sub_blank_word_limit":
			let blank_word_limit = state.question.subQuestions
				.filter((subQ, index) => {
					return index === action.id;
				})
				.map((elem) => {
					return {
						...elem,
						word_limit: "",
					};
				});
			state.question.subQuestions.splice(
				action.id,
				1,
				blank_word_limit[0]
			);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};

		case "edit_sub_option_image_upload":
			let edit_sub_question_option = state.question.subQuestions.filter(
				(elem, index) => {
					return index === action.id;
				}
			);
			let edited_option_url = edit_sub_question_option[0].options
				.filter((elem, index) => {
					return index === action.option_id;
				})
				.map((elem) => {
					return {
						...elem,
						OptionTranslation: [
							{
								...elem.OptionTranslation[0],
								imageUrl: action.value,
							},
						],
					};
				});
			edit_sub_question_option[0].options.splice(
				action.option_id,
				1,
				edited_option_url[0]
			);
			state.question.subQuestions.splice(
				action.id,
				1,
				edit_sub_question_option[0]
			);

			return {
				...state,
				question: {
					...state.question,
					subQuestions: state.question.subQuestions,
				},
			};
		// return {
		// 	...state,
		// 	question: {
		// 		...state.question,
		// 		subQuestions: temp_all_sub_question_to_add_file,
		// 	},
		// };
		case "main_del_option":
			let main_question = state.question.options;
			let temp_main_question = Object.assign([], main_question);
			temp_main_question.splice(action.option_id, 1);
			return {
				...state,
				question: {
					...state.question,
					options: temp_main_question,
				},
			};

		case "sub_del_option":
			let sub_question = state.subQuestion.options;
			let temp_sub_question = Object.assign([], sub_question);
			temp_sub_question.splice(action.option_id, 1);
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: temp_sub_question,
				},
			};
		case "edit_sub_del_option":
			let all_subquestion = state.question.subQuestions;
			let del_option_subquestion = state.question.subQuestions[action.id];
			let all_option = state.question.subQuestions[action.id].options;

			let temp_all_subquestion = Object.assign([], all_subquestion);
			let temp_del_option_subquestion = Object.assign(
				{},
				del_option_subquestion
			);
			let temp_all_option = Object.assign([], all_option);

			temp_all_option.splice(action.option_id, 1);

			temp_del_option_subquestion.options = temp_all_option;
			temp_all_subquestion[action.id] = temp_del_option_subquestion;
			return {
				...state,
				question: {
					...state.question,
					subQuestions: temp_all_subquestion,
				},
			};
		case "main_title_del":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					title: "",
				},
			};
		case "main_hint_del":
			return {
				...state,
				question_traslation: {
					...state.question_traslation,
					longHint: "",
				},
			};
		case "sub_title_del":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					title: "",
				},
			};
		case "sub_hint_del":
			return {
				...state,
				sub_question_traslation: {
					...state.sub_question_traslation,
					longHint: "",
				},
			};
		case "edit_validation":
			return {
				...state,
				edit_mode_validation: action.value,
			};

		case "open_create_playlist_modal":
			return {
				...state,
				is_create_playlist_modal_open_in_qu_create: action.value,
			};
		case "onchangeAnimation":
			return {
				...state,
				question: {
					...state.question,
					options: [state.oneOption, state.oneOption],
				},
				subQuestion: {
					...state.subQuestion,
					options: [state.oneOption, state.oneOption],
				},
			};
		case "onchangeSubAnimation":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [state.oneOption, state.oneOption],
				},
			};
			
		case "onchangeSubFillInTheBlank":
			return {
				...state,
				subQuestion: {
					...state.subQuestion,
					options: [],
				},
			};

		default:
			return state;
	}
};

const CreateQuestionState = ({ children }: theme) => {
	const [state, dispatch] = useReducer(reducer, createQuestion_initialstate);

	return (
		<CreateQuesContext.Provider value={{ dispatch, state }}>
			{children}
		</CreateQuesContext.Provider>
	);
};

export default CreateQuestionState;
