import React from "react";
import OptionTypeQuestion from "./PlaylistCheckLayouts/OptionTypeQuestion";
import CanvasTypeQuestion from "./PlaylistCheckLayouts/CanvasTypeQuestion";

const QNA = ({
	QuestionType,
	value,
	QuestionNumber,
	onchangeMarksValue,
	setMarksArray,
	marksArray,
	editState,
}) => {
	return (
		<>
			{(QuestionType === "SHORT ANSWER" ||
				QuestionType === "RESEARCH") && (
				<CanvasTypeQuestion
					value={value}
					QuestionNumber={QuestionNumber}
					onchangeMarksValue={onchangeMarksValue}
					setMarksArray={setMarksArray}
					marksArray={marksArray}
					editState={editState}
				/>
			)}
			{(QuestionType === "MCQ" ||
				QuestionType === "TRUE FALSE" ||
				QuestionType === "IQ" ||
				QuestionType === "FILL IN THE BLANK" ||
				QuestionType === "ANIMATION") && (
				<OptionTypeQuestion
					value={value}
					QuestionNumber={QuestionNumber}
					onchangeMarksValue={onchangeMarksValue}
					marksArray={marksArray}
					editState={editState}
				/>
			)}
		</>
	);
};

export default QNA;
