import React from "react";
import QuestionTitle from "./QuestionTitle";
import { Image } from "antd";
import { ParseHtmlToText } from "../../../utlis/parseHtmlTotext/ParseHtmlToText";
const gridStyle_img: React.CSSProperties = {
	height: "50px",
	width: "50px",
};

const OptionTypeQuestion = ({
	value,
	QuestionNumber,
	onchangeMarksValue,
	marksArray,
	editState,
}) => {
	let correctAnswer =
		value && value?.questions?.question_type === "FILL IN THE BLANK"
			? value?.option?.map((elm) => {
					return ParseHtmlToText(elm?.OptionTranslation[0]?.title);
			  })
			: "";
	return (
		<>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "start",
					gap: "1rem",
					padding: "1rem",
					marginBottom: "1.5rem",
					boxShadow: "2px 2px 4px 0px #ccc",
					borderRadius: "5px",
					cursor: "pointer",
				}}
				className="cardHoverEffect"
			>
				<div
					style={{
						width: "100%",
						// display: "flex",
						// justifyContent: "space-between",
						// alignItems: "center",
						// gap: "0.5rem",
						fontSize: "1.25rem",
					}}
				>
					<QuestionTitle
						QuestionNumber={QuestionNumber}
						data={value}
						onchangeMarksValue={onchangeMarksValue}
						marksArray={marksArray}
						editState={editState}
					/>
				</div>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "start",
						alignItems: "start",
						gap: "0.5rem",
						color: "#ffffff",
					}}
				>
					{/* {value?.file &&
						value?.file?.map((elm, index) => {
							return (
								<React.Fragment key={index}>
									<Image
										// src="https://static.inaturalist.org/photos/774608/large.jpg"
										src={elm?.url}
										width={200}
										style={{
											color: "#ffffff",
											height: "50px",
											width: "50px",
										}}
										// style={gridStyle_img}
									/>
								</React.Fragment>
							);
						})} */}
				</div>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "start",
						gap: "0.5rem",
						fontSize: "1rem",
						fontWeight: "500",
					}}
				>
					{value &&
						value?.questions?.question_type ===
							"FILL IN THE BLANK" && (
							<>
								<aside
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
										gap: "0.5rem",
									}}
								>
									<span>Correct Ans:</span>
									<span>{correctAnswer.join(" , ")}</span>
								</aside>
								<aside
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "end",
										alignItems: "center",
										gap: "0.5rem",
									}}
								>
									<span>Chosen Ans:</span>
									<span>
										{value?.answers[0]?.answer_data?.answer
											? JSON.parse(
													value?.answers[0]
														?.answer_data?.answer
											  ).join(" , ")
											: "Not attempted !!"}
										{/* Macaw Parrot */}
									</span>
								</aside>
							</>
						)}
					{value &&
						value?.questions?.question_type !==
							"FILL IN THE BLANK" && (
							<>
								{value?.option?.map((elm, index) => {
									if (elm?.isCorrect === 1) {
										return (
											<aside
												style={{
													width: "100%",
													display: "flex",
													justifyContent: "start",
													alignItems: "center",
													gap: "0.5rem",
												}}
												key={index}
											>
												<span>Correct Ans:</span>
												<span>
													{ParseHtmlToText(
														elm
															?.OptionTranslation[0]
															?.title
													)}

													{/* Macaw Parrot */}
												</span>
												{/* <Image
												src="https://static.inaturalist.org/photos/774608/large.jpg"
												width={200}
												style={{
													color: "#ffffff",
												}}
											/> */}
												{elm?.OptionTranslation[0]
													?.imageUrl && (
													<Image
														src={
															elm
																?.OptionTranslation[0]
																?.imageUrl
														}
														// width={100}
														style={{
															color: "#ffffff",
															height: "50px",
															width: "50px",
														}}
													/>
												)}
											</aside>
										);
									}
								})}
								<aside
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "end",
										alignItems: "center",
										gap: "0.5rem",
									}}
								>
									<span>Chosen Ans:</span>
									<span>
										{value?.answers[0]?.answer_data?.answer
											? ParseHtmlToText(
													value?.answers[0]
														?.answer_data?.answer
											  )
											: "Not Attempted !!"}
										{value?.answers[0]?.answer_data?.answer
											?.length === 0 &&
										value?.answers[0]?.answer_data?.url
											?.length === 0 &&
										value?.answers[0]?.answer_data
											?.option_id?.length === 0
											? "Not attempted !!"
											: ""}
										{/* "Not attempted !!" */}
										{/* Macaw Parrot */}
									</span>
									{value?.answers[0]?.answer_data?.url && (
										<Image
											src={
												value?.answers[0]?.answer_data
													?.url
											}
											// src={value?.answers[0]?.answer_data?.url}
											width={100}
											style={{
												color: "#ffffff",
											}}
										/>
									)}
									{value?.option?.map((opElm, index) => {
										if (
											opElm?._id ==
											value?.answers[0]?.answer_data
												?.option_id
										) {
											return (
												<>
													{opElm?.OptionTranslation[0]
														?.imageUrl && (
														<Image
															src={
																opElm
																	?.OptionTranslation[0]
																	?.imageUrl
															}
															style={{
																color: "#ffffff",
																height: "50px",
																width: "50px",
															}}
														/>
													)}
												</>
											);
										}
									})}
									{/* <Image
									src="https://static.inaturalist.org/photos/774608/large.jpg"
									// src={value?.answers[0]?.answer_data?.url}
									width={100}
									style={{
										color: "#ffffff",
									}}
								/> */}
								</aside>
							</>
						)}
				</div>
			</div>
		</>
	);
};

export default OptionTypeQuestion;
