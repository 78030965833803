import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import HeaderExam from "../components/PlaylistCheck/HeaderExam";
import QNA from "../components/PlaylistCheck/QNA";
import { ApiConnector } from "../axios/apiClient";
import {
	EDITREMARKS,
	QUESTIONANSWER,
	REMARKSSUBMIT,
} from "../axios/AllBaseUrl";
import MarksObtained from "../components/PlaylistCheck/MarksObtained";
import S3fileUpload from "../utlis/canvas/S3fileUpload";
import CustomToast from "../utlis/toast/CustomToast";
import { useLocation } from "react-router-dom";

const CheckPlayList = () => {
	// const navigate = useNavigate();
	let location = useLocation();
	// console.log("location check", location.state);

	let [questionData, setQuestionData] = useState<any>();
	const [open, setOpen] = useState(false);
	let [obtainedMark, setObtainedMark] = useState(0);
	let [marksArray, setMarksArray] = useState<any>([
		{
			questionNumber: "",
			marks: "",
			blob: "",
			answer_id: "",
			question_id: "",
		},
	]);
	// console.log("marksArray", marksArray);

	let onchangeMarksValue = (
		QuestionNumber,
		Marks,
		answer_id,
		question_id
	) => {
		setMarksArray((prevMarksArray) => {
			// Find the index of the object with the matching questionNumber
			const index = prevMarksArray.findIndex(
				(item) => item.questionNumber === QuestionNumber
			);

			if (index !== -1) {
				// If the questionNumber exists, update its marks
				const updatedArray = [...prevMarksArray];
				// updatedArray[index] = {
				updatedArray[index].questionNumber = QuestionNumber;
				updatedArray[index].marks = Marks;
				updatedArray[index].answer_id = answer_id;
				updatedArray[index].question_id = question_id;
				// };
				return updatedArray;
			} else {
				// If the questionNumber doesn't exist, add a new object
				return [
					...prevMarksArray,
					{
						questionNumber: QuestionNumber,
						marks: Marks,
						blob: {},
						answer_id: answer_id,
						question_id: question_id,
					},
				];
			}
		});
	};
	let totalObtained = () => {
		// Initialize a variable to store the total sum
		let total = 0;
		// Loop through the array and add each "a" value to the total (converting them to numbers)
		for (let i = 0; i < marksArray?.length; i++) {
			total += Number(marksArray[i]?.marks);
		}
		setObtainedMark(total);
	};

	const hide = () => {
		setOpen(false);
	};
	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};
	console.log("location?.state", location?.state);

	let fetchQuestion = async () => {
		let res = await ApiConnector("post", `${QUESTIONANSWER}`, {
			// playlistID: "63f85eb43ca24249bbdc12a6", //local
			// userId: "63f72ad1305687320915f95c", //local

			// playlistID: "650bebe783ee2a877d4d9d07", 	//local
			// playlistID: "650d39568b7eff5ab2ebc193",
			// userId: "635f89fff2e60d1667aca7e0", //local(student_id)
			playlistID: location?.state?.playlistData?.playlist_id,
			userId: location?.state?.user_id,
			exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
		});
		if (res?.data?.ok) {
			setQuestionData(res?.data?.data);
		}
	};
	// console.log("questionData", questionData);

	let handleSubmit = async () => {
		let answerArray = await Promise.all(
			marksArray.slice(1)?.map(async (value) => {
				let markeValue = {
					// user_id: "635f89fff2e60d1667aca7e0",
					// playlist_id: "650d39568b7eff5ab2ebc193",
					playlist_id: location?.state?.playlistData?.playlist_id,
					user_id: location?.state?.user_id,
					answer_id: value?.answer_id,
					remarksURL:
						value?.blob?.size !== undefined
							? await S3fileUpload(value)
							: "",
					marks: value?.marks,
					question_id: value?.question_id,
				};
				return markeValue;
			})
		);
		let res = await ApiConnector("post", REMARKSSUBMIT, answerArray);
		if (res.data.ok) {
			CustomToast("success", res.data.why);
			// navigate("/conductedExamsDashboard");
			window.history.go(-1);
		} else {
			CustomToast("error", res.data.why);
		}
		// console.log("answerArray", answerArray);
		// console.log("answerArray res", res);
		return res;
	};

	let handleEditSubmit = async () => {
		let answerArray = await Promise.all(
			marksArray.slice(1)?.map(async (value) => {
				let markeValue = {
					// user_id: "635f89fff2e60d1667aca7e0",
					// playlist_id: "650d39568b7eff5ab2ebc193",
					playlist_id: location?.state?.playlistData?.playlist_id,
					user_id: location?.state?.user_id,
					answer_id: value?.answer_id,
					remarksURL:
						value?.blob?.size !== undefined
							? await S3fileUpload(value)
							: "",
					marks: value?.marks,
					question_id: value?.question_id,
				};
				return markeValue;
			})
		);
		let res = await ApiConnector("put", EDITREMARKS, answerArray);
		if (res.data.ok) {
			CustomToast("success", res.data.why);
			// navigate("/conductedExamsDashboard");
			window.history.go(-1);
		} else {
			CustomToast("error", res.data.why);
		}
		// console.log("answerArray", answerArray);
		// console.log("answerArray res", res);
		return res;
	};

	useEffect(() => {
		fetchQuestion();
	}, []);
	useEffect(() => {
		if (marksArray) {
			totalObtained();
		}
	}, [marksArray]);

	if (location?.state?.mode === 1 && location?.state?.remarks === false) {
		return (
			<>
				<Row
					gutter={24}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						margin: 0,
					}}
				>
					<Col style={{ fontWeight: "bold", fontSize: "24px" }}>
						You need to check the answer first
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			{questionData && (
				<div
					style={{
						width: "100%",
						padding: "3rem",
						display: "relative",
					}}
				>
					<HeaderExam
						open={open}
						handleOpenChange={handleOpenChange}
						hide={hide}
						marksArray={marksArray}
						obtainedMark={obtainedMark}
						playlistData={location?.state?.playlistData}
						studentName={location?.state?.studentName}
						checkExamValue={location?.state}
					/>

					<Row
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "start",
							paddingTop: "3rem",
						}}
					>
						<Col
							span={16}
							style={{
								backgroundColor: "#ffffff",
								borderRadius: "0.5rem",
								padding: "1rem",
							}}
						>
							{questionData?.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<QNA
											QuestionType={
												value?.questions?.question_type
											}
											value={value}
											QuestionNumber={index + 1}
											onchangeMarksValue={
												onchangeMarksValue
											}
											setMarksArray={setMarksArray}
											marksArray={marksArray}
											editState={location.state}
										/>
									</React.Fragment>
								);
							})}
						</Col>
						<Col
							span={7}
							style={{
								backgroundColor: "#ffffff",
								borderRadius: "0.5rem",
								padding: "1rem",
								textAlign: "left",
								justifyContent: "end",
							}}
						>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<h3
									style={{
										fontSize: "1.25rem",
										color: "#a0a0a0",
									}}
								>
									Marks Obtained
								</h3>

								<h3
									style={{
										fontSize: "1.25rem",
										color: "#a8dadc",
										backgroundColor: "#084b83",
										padding: "2px 8px",
										borderRadius: "0.25rem",
										margin: "0",
									}}
								>
									{/* 11.5 */}
									{obtainedMark}
								</h3>
							</div>
							{marksArray.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<MarksObtained
											value={value}
											index={index}
										/>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
					<Row>
						<Col>
							{location?.state?.mode === 0 && (
								<Button onClick={handleSubmit}>Submit</Button>
							)}
							{location?.state?.mode === 1 && (
								<Button onClick={handleEditSubmit}>
									Edit Submit
								</Button>
							)}
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default CheckPlayList;
