import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Button, Card, Col, Row, Tooltip } from "antd";

import AddHint from "./AddHint";
import Editor from "./Editor";
import Uploads from "./Uploads";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import Limitwords from "./Limitwords";
import { Typography } from "antd";
import UploadCustom from "./UploadCustom";
import { OrderedListOutlined } from "@ant-design/icons";
import Option from "../svg/Option";
import {
	ANIMATION,
	FILL_IN_THE_BLANK,
	MATCHING,
	MCQ,
	RESEARCH,
	SHORT_ANSWER,
} from "../../utlis/constants/questionType";
const { Text } = Typography;

const AddPrimaryQuestion = (prop: any) => {
	const questiondata: any = useContext(createQuesContext);
	let { state, dispatch } = questiondata;
	const { label } = prop;

	let [ques, setQues] = useState<any>({});
	let [quesTrans, setQuesTrans] = useState("");
	let [error, setError] = useState<any>({});

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quData;
		let quTransData;
		quData =
			currentQState === 0
				? state.question
				: currentQState === 1
				? state.subQuestion
				: state.question.subQuestions[state.edit_id];
		quTransData =
			currentQState === 0
				? state.question_traslation.title
				: currentQState === 1
				? state.question_traslation.title
				: state.question.subQuestions[state.edit_id].question_traslation
						.title;
		setQues(quData);
		setQuesTrans(quTransData);
	};
	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	let get_error_in_edit_mode = () => {
		if (
			state.edit_id !== null &&
			state?.edit_mode_validation?.length !== 0
		) {
			if (
				Object.keys(state?.edit_mode_validation[state?.edit_id])
					?.length !== 0
			) {
				setError(state?.edit_mode_validation[state?.edit_id]);
			} else {
				setError({});
			}
		}
	};

	useEffect(() => {
		get_error_in_edit_mode();
	}, [state.edit_id, state.edit_mode_validation]);

	return (
		<div>
			<Row gutter={[24, 0]} justify="start" align="middle">
				<Col xs={24} xl={24}>
					<div className="question-mandatory-div">
						<Text
							type="danger"
							className="question-mandatory-label"
						>
							*
						</Text>
						<Text className="question-mandatory-label">
							{label}
						</Text>
					</div>

					<Editor
						class_name={
							state.valid?.title || error?.title
								? "editorError"
								: ""
						}
						value={
							(state.mode === 0 &&
								state.question_traslation.title) ||
							(state.mode === 1 &&
								state.sub_question_traslation.title) ||
							(state.mode === 2 &&
								state.question.subQuestions[state.edit_id]
									.question_traslation[0].title)
						}
						type="title"
					/>
					{ques.question_type === SHORT_ANSWER && <Limitwords />}

					<Row gutter={[24, 0]} justify="space-between" align="top">
						<Col xl={24}>
							{(ques.question_type === MCQ ||
								ques.question_type === SHORT_ANSWER ||
								ques.question_type === RESEARCH ||
								ques.question_type === MATCHING) && <AddHint />}
						</Col>
						{ques.question_type === ANIMATION ||
						ques.question_type === FILL_IN_THE_BLANK ? null : (
							<Col xl={24}>
								{/* <Uploads where="main" id={undefined} /> */}
								<UploadCustom where="main" id={undefined} />
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default AddPrimaryQuestion;
