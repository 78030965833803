import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import React from "react";
const BGStyle: React.CSSProperties = {
	border: "1px solid #70e000",
	backgroundColor: "#caffbf",
};

const ExamAttendViewCard = ({ value, navigateToCheckExam, editCheck }) => {
	// console.log(" ExamAttendViewCard value", value);

	return (
		<>
			<Card
				bordered={false}
				style={value?.remarks === true ? BGStyle : {}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "start",
						alignItems: "start",
						gap: "0.5rem",
						color: "#000000",
					}}
				>
					<aside
						style={{
							display: "flex",
							justifyContent: "start",
							alignItems: "center",
							gap: "0.5rem",
						}}
					>
						<span>Student</span>
						<span>:</span>
						<span style={{ fontWeight: "500" }}>
							{value?.user_name}
						</span>
					</aside>
					<aside
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "end",
							alignItems: "center",
						}}
						className="actionButtons"
					>
						<Space wrap>
							<Tooltip title="Checked">
								<Button
									type="primary"
									icon={<CheckOutlined />}
									onClick={() =>
										navigateToCheckExam(
											value?._id,
											value?.remarks,
											value?.user_name
										)
									}
									disabled={false}
								></Button>
							</Tooltip>
							<Tooltip title="Edit">
								<Button
									type="dashed"
									icon={<EditOutlined />}
									onClick={() =>
										editCheck(
											value?._id,
											value?.remarks,
											value?.user_name
										)
									}
								></Button>
							</Tooltip>
						</Space>
					</aside>
				</div>
			</Card>
		</>
	);
};

export default ExamAttendViewCard;
