import React from "react";
import { Column } from "@ant-design/charts";

const TrendingTags: React.FC = () => {
	const data = [
		{ tags: "#math", user: 150},
		{ tags: "#relation", user: 100},
		{ tags: "#function", user: 270},
	];

	const config = {
		data,
		isGroup: true,
		width: 500,
		height: 300,
		autoFit: false,
		xField: "tags",
		yField: "user",
		seriesField: "tags",
		point: {
			size: 5,
			shape: "diamond",
		},
		label: {
			style: {
				fill: "#aaa",
			},
		},
	};

	let chart;

	return (
		<div>
			<Column
				{...config}
				onReady={(chartInstance) => (chart = chartInstance)}
			/>
		</div>
	);
};
export default TrendingTags;
