import "react-quill/dist/quill.snow.css";
import { Form, Select } from "antd";

import React, { useContext, useEffect, useState } from "react";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { Typography } from "antd";
import InvalidMsg from "./InvalidMsg";
const { Text } = Typography;

const AddQuestionLang = () => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [quesTrans, setQuesTrans] = useState<any>({});

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quTransData;
		if (currentQState === 0) {
			quTransData = state.question_traslation;
		}
		if (currentQState === 1) {
			quTransData = state.sub_question_traslation;
		}
		if (currentQState === 2) {
			quTransData =
				state.question.subQuestions[state.edit_id]
					.question_traslation[0];
		}

		setQuesTrans(quTransData);
	};
	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);
	const { Option } = Select;
	const getLangValue = (value: any) => {
		if (state.mode === 0) {
			dispatch({ type: "langCode", value: value });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_langCode", value: value });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_sub_question_language",
				value: value,
				id: state.edit_id,
			});
		}
	};
	useEffect(() => {
		dispatch({
			type: "sub_langCode",
			value: state.question_traslation.langCode,
		});
	}, [state.mode]);

	return (
		<div>
			<div className="width-100">
				{/* <Form layout="vertical" className="row-col full-width"> */}
					<div className="question-mandatory-div">
						<Text
							type="danger"
							className="question-mandatory-label"
						>
							*
						</Text>
						<Text className="question-mandatory-label">
							Question Language
						</Text>
					</div>
					<Select
						status={state.valid?.langCode ? "error" : ""}
						className="question-select-box"
						value={quesTrans.langCode}
						// value={
						// 	state.mode === 0
						// 		? state.question_traslation.langCode
						// 		: state.sub_question_traslation.langCode
						// }
						onChange={getLangValue}
						placeholder="Choose a Specific Language"
					>
						<Option value="eng">ENGLISH</Option>
						<Option value="hind">HINDI</Option>
						<Option value="beng">BENGALI</Option>
					</Select>
					<div style={{ height: "16px" }}>
						{state.valid?.langCode && (
							<InvalidMsg msg={state.valid.langCode} />
						)}
					</div>
				{/* </Form> */}
			</div>
		</div>
	);
};

export default AddQuestionLang;
