import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Card, Col, Row } from "antd";

import React from "react";
import SuggestedQuestion from "../components/create/SuggestedQuestion";
import CreateQuestionComponent from "../components/create/CreateQuestionComponent";
import AddQuestionPreviewModal from "../components/modal/AddQuestionPreviewModal";
import AddSubQuestionModal from "../components/modal/AddSubQuestionModal";
// import Backtop from "./Backtop";
// import AddFlotingPlaylist from "../components/create/AddFlotingPlaylist";
// import { useSelector } from "react-redux";
// import { RootState } from "../redux/store";
import AddFloatingButton from "../components/MyPlaylist/AddFloatingButton";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import { PageDataInterface } from "../utlis/Interface/homeSearch";

const Create = () => {
	// const playlist: any = useSelector((state: RootState) => state.playlist);
	const [open, setOpen] = useState(false); //for subquestion modal
	const [subShow, SetSubShow] = useState(false); //to show added subquestions
	const [pageData, setPageData] = useState<PageDataInterface>({
		page: 1,
		pageSize: 10,
	}); //pagination page select and page size
	const handlePageChange = (page, pageSize) => {
		setPageData({ page: page, pageSize: pageSize });
	};

	//for question preview
	const [modal2Open, setModal2Open] = useState(false);

	const onChange = (key: string | string[]) => {
		// console.log(key);
	};
	let flag = FlagLocalhost();

	return (
		<>
			<AddSubQuestionModal
				setOpen={setOpen}
				open={open}
				heading="Create Sub Question"
				subShow={subShow}
				SetSubShow={SetSubShow}
			/>
			<AddQuestionPreviewModal
				modal2Open={modal2Open}
				setModal2Open={setModal2Open}
				title="Question Preview"
			/>
			<Row gutter={[24, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={14}>
					<CreateQuestionComponent
						heading="Create New Question"
						subShow={subShow}
						SetSubShow={SetSubShow}
						allButton={true}
						setModal2Open={setModal2Open}
						pageData={pageData}
					/>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={10}>
					<Card
						bordered={true}
						className="criclebox h-full width-100"
						style={{
							height: "1000px",
							overflow: "auto",
							scrollbarWidth: "none",
							msOverflowStyle: "none",
						}}
					>
						{" "}
						<style>
							{`
					  ::-webkit-scrollbar {
						display: none;
					  }
					  body {
						scrollbar-width: none;
						-ms-overflow-style: none;
						overflow: -moz-scrollbars-none;
					  }
					`}
						</style>
						<SuggestedQuestion
							onChange={onChange}
							setModal2Open={setModal2Open}
							handlePageChange={handlePageChange}
							pageData={pageData}
						/>
					</Card>
				</Col>
			</Row>
			<Row
				gutter={[24, 0]}
				style={{ position: "fixed", bottom: "4vh", right: "4vw" }}
			>
				{flag?.isFromEvidya === 1 && <AddFloatingButton />}
			</Row>
			{/* <Backtop /> */}
		</>
	);
};

export default Create;
