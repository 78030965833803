import { Button, Modal } from "antd";
import React from "react";

const DynamicDeleteModal = ({
	deleteModal,
	deleteConfirm,
	deleteCancel,
	deleteMSg,
}) => {
	return (
		<>
			<Modal
				open={deleteModal}
				onOk={deleteConfirm}
				onCancel={deleteCancel}
				footer={[
					<Button key="no" onClick={deleteCancel}>
						Cancel
					</Button>,
					<Button key="yes" type="primary" onClick={deleteConfirm}>
						Confirm
					</Button>,
				]}
			>
				<strong>{deleteMSg} </strong>
			</Modal>
		</>
	);
};

export default DynamicDeleteModal;
