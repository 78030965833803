import {
	ExclamationCircleOutlined,
	EyeOutlined,
	LikeFilled,
	LikeOutlined,
	ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Card, Collapse, Modal, Space } from "antd";
import React, { useState } from "react";
import { Checkbox, Divider } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

const CheckboxGroup = Checkbox.Group;

const plainOptions = [
	"1. Which of the following can be used to open a file called myText.txt in read-only mode?",
	"2. Which of these followings can be used to open a file called myText.txt in read-only mode?",
	"3. Which of the following can be used to open a file called myText.txt in read-only mode?",
	"4. Which of these followings can be used to open a file called myText.txt in read-only mode?",
];

const defaultCheckedList = [
	"Which of the following can be used to open a file called myText.txt in read-only mode?",
];

const AddQuestionPreviewModal = (prop: any) => {
	const { modal2Open, setModal2Open, title } = prop;
	const [checkedList, setCheckedList] =
		useState<CheckboxValueType[]>(defaultCheckedList);
	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);

	const onChange = (list: CheckboxValueType[]) => {
		setCheckedList(list);
		setIndeterminate(!!list.length && list.length < plainOptions.length);
		setCheckAll(list.length === plainOptions.length);
	};

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		setCheckedList(e.target.checked ? plainOptions : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};
	return (
		<div>
			<Modal
				title={title}
				centered
				open={modal2Open}
				onOk={() => setModal2Open(false)}
				onCancel={() => setModal2Open(false)}
			>
				<img
					src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
					style={{ height: "100%", width: "100%" }}
				></img>

				<Checkbox
					indeterminate={indeterminate}
					onChange={onCheckAllChange}
					checked={checkAll}
				>
					Check all
				</Checkbox>
				<Divider />
				<CheckboxGroup
					options={plainOptions}
					value={checkedList}
					onChange={onChange}
				/>
				{/* <p>A. infile = open("myText.txt", “w”)</p>
				<p>B. infile = open("myText.txt", r)</p>
				<p>C. infile = open("myText.txt", “read”)</p>
				<p>D. infile = open("myText.txt")</p>

				<h2></h2>
				<p>A. infile = open("myText.txt", “w”)</p>
				<p>B. infile = open("myText.txt", r)</p>
				<p>C. infile = open("myText.txt", “read”)</p>
				<p>D. infile = open("myText.txt")</p> */}
			</Modal>
		</div>
	);
};

export default AddQuestionPreviewModal;
