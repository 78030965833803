import { Space, Button, Card } from "antd";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

const { Text } = Typography;

const QuestionPreview = (prop: any) => {
	const [correctAnswer, setCorrectAnswer] = useState<any>({});

	const { isShown, cardValue, showanswer, setAnswer } = prop;
	// console.log("i am cardvalue ", cardValue);
	const viewanswer = () => {
		setAnswer(true);
	};

	useEffect(() => {
		cardValue?.option?.map((elm, i) => {
			if (elm?.isCorrect === 1) {
				setCorrectAnswer({
					key: i + 1,
					answer: elm?.OptionTranslation[0]?.title,
				});
			}
		});
	}, [cardValue]);
	return (
		<>
			<Space
				direction="vertical"
				size="middle"
				style={{ display: "flex" }}
			>
				{/* <div className="site-card-border-less-wrapper"> */}
				{!isShown && (
					<div className="btn-hover">
						<Button
							type="dashed"
							className="hover-me-to-preview"
							ghost
							disabled
						>
							Hover On a Question To Preview
						</Button>
					</div>
				)}

				{isShown && (
					<Card
						bordered={false}
						className="btn-hover-question-preview"
						// onMouseEnter={ShowAnswer(true)}
					>
						<Space direction="vertical">
							<Text type="danger" style={{ display: "flex" }}>
								Question : {parse(cardValue.title)}
							</Text>
							{cardValue?.option?.map((elm, i) => {
								return (
									<Text key={i}>
										{i + 1} .{" "}
										{elm.OptionTranslation[0].title}
									</Text>
								);
							})}
							<Button onClick={viewanswer}>View Answer</Button>
							{showanswer === true && (
								<div className="view-btn-answer">
									{correctAnswer.key}.
									{correctAnswer.answer}
								</div>
							)}
						</Space>
					</Card>
				)}
				{/* </div>  */}
			</Space>
		</>
	);
};

export default QuestionPreview;
