import { createSlice } from "@reduxjs/toolkit";
import { getquestionThunk } from "../thunk/RootThunk";

export interface QuestionState {
  question: object;
  loading: boolean;
  error: any;
  tokenExpiredError: boolean;
}

const initialState: QuestionState = {
  question: [],
  loading: false,
  error: null,
  tokenExpiredError: false
};

export const getquestion: any = getquestionThunk;

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: {
    [getquestion.pending]: (state: any, action) => {
      state.loading = true;
    },
    [getquestion.fulfilled]: (state: any, action) => {
      state.loading = false;
      state.question = [action.payload];
      if (action.payload.data.ok === false && action.payload.data.http$.status === 401) {
        state.tokenExpiredError = true
      }
    },
    [getquestion.rejected]: (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default questionSlice.reducer;
