import React from "react";

const ColorIconSVG = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
			>
				<path d="M17.71,11.291l-9-9a1,1,0,0,0-1.42,1.42l1.32,1.31L2.84,11.211a2.872,2.872,0,0,0,0,4.059L6.73,19.16A2.851,2.851,0,0,0,8.76,20a2.8,2.8,0,0,0,2-.82l6.92-6.449a0.963,0.963,0,0,0,.32-0.71A1.036,1.036,0,0,0,17.71,11.291ZM5.77,11l4.26-4.559L14.59,11H5.77Z" />
				<path d="M22,18.991a3,3,0,1,1-6,0c0-1.284,1.52-3.631,2.18-4.584a1.031,1.031,0,0,1,1.64,0C20.48,15.359,22,17.707,22,18.991Z" />
			</svg>
		</>
	);
};

export default ColorIconSVG;
