import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Row, Tooltip, Image } from "antd";
import React from "react";
import CheckExamSvg from "../svg/CheckExamSvg";
import EditIconSvg from "../svg/EditIconSvg";

const BGStyle: React.CSSProperties = {
	border: "1px solid #70e000",
	backgroundColor: "#caffbf",
};
const AttendBGStyle: React.CSSProperties = {
	border: "1px solid ",
	backgroundColor: ` #d62828 `,
	// background: `linear-gradient(90deg, rgba(139,205,214,1) 12%, rgba(199,159,242,1) 71%)`,
};

const ExamStudentViewCard = ({ value, editCheck, navigateToCheckExam }) => {
	// console.log("value", value);

	return (
		<>
			<Card
				size="small"
				style={
					(value?.attend && value?.remarks) === true
						? BGStyle
						: value?.attend
						? {}
						: AttendBGStyle
				}
			>
				<Row
					gutter={24}
					style={{
						display: "flex",
						flexFlow: "row wrap",
						alignItems: "center",
						columnGap: "0px",
					}}
				>
					<Col xxl={4} xl={4} md={4} sm={4}>
						<Avatar
							src={
								value?.StudentPic ? (
									<Image
										src={value?.StudentPic}
										style={{ width: 32 }}
									/>
								) : (
									<UserOutlined />
								)
							}
							style={
								value?.StudentPic
									? {}
									: { backgroundColor: "#cccccc" }
							}
						/>
					</Col>
					<Col xxl={14} xl={14} md={14} sm={14}>
						<p
							style={
								value?.attend
									? { margin: "0px", textAlign: "left" }
									: {
											margin: "0px",
											textAlign: "left",
											color: "#ffffff",
									  }
							}
						>
							{value?.user_name}
						</p>
						<p
							style={
								value?.attend
									? { margin: "0px", textAlign: "left" }
									: {
											margin: "0px",
											textAlign: "left",
											color: "#ffffff",
									  }
							}
						>
							{value?.Roll_No && <>Roll No : {value?.Roll_No}</>}
						</p>
					</Col>
					<Col xxl={4} xl={4} md={4} sm={4}>
						<div
							style={{
								display: "flex",
							}}
						>
							<Tooltip placement="topLeft" title="Check ">
								<Button
									type="link"
									style={
										value?.attend
											? { padding: "0" }
											: {
													padding: "0",
													color: "#ffffff",
											  }
									}
									onClick={() =>
										navigateToCheckExam(
											value?._id,
											value?.remarks,
											value?.user_name,
											value
										)
									}
									disabled={
										value?.attend === true
											? false || value?.remarks === true
												? true
												: false
											: true
									}
								>
									<CheckExamSvg />
								</Button>
							</Tooltip>
							<Tooltip placement="topLeft" title="Edit ">
								<Button
									type="link"
									style={
										value?.attend
											? { padding: "0" }
											: {
													padding: "0",
													color: "#ffffff",
											  }
									}
									onClick={() =>
										// editCheck(
										// 	value?._id,
										// 	value?.remarks,
										// 	value?.user_name,
										// 	value
										// )
										navigateToCheckExam(
											value?._id,
											value?.remarks,
											value?.user_name,
											value
										)
									}
									disabled={
										value?.attend === true
											? false || value?.remarks === true
												? false
												: true
											: true
									}
								>
									<EditIconSvg />
								</Button>
							</Tooltip>
						</div>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default ExamStudentViewCard;
