import React from "react";

const RemarksPenSVG = () => {
	return (
		<>
			<svg
				viewBox="0 0 1023.449 1066.697"
				xmlns="http://www.w3.org/2000/svg"
				height={20}
				width={20}
				fill="#fff"
			>
				<path d="M828.402 21.333h.572c53.783 0 102.447 21.92 137.545 57.312l.012.013c35.172 35.31 56.917 84.014 56.917 137.8 0 54.02-21.937 102.915-57.387 138.267l-.004.004-647.906 648.898L0 1045.285 41.657 727.13l648.9-647.952c35.17-35.566 83.91-57.65 137.808-57.846h.037zM285.018 936.315l630.96-631.905c22.513-22.352 36.46-53.307 36.496-87.52v-.007-.12c0-34.314-13.83-65.4-36.222-87.984l.01.008c-22.315-22.53-53.258-36.48-87.458-36.48-34.317 0-65.354 14.046-87.676 36.702l-.252.252L108.97 760.268 82.416 962.872zm542.485-493.207L602.178 217.783l50.178-50.178L877.68 392.93zM0 1045.333l45.35-346.32L84.4 709.38c62.2 16.848 115.554 48.672 157.89 91.427l.027.028c43.813 42.59 76.393 96.513 93.014 157.04l.57 2.44 10.463 39.052zm104.852-253.917l-22.438 171.36 171.36-22.768c-15.142-34.444-35.73-63.798-61-88.363l-.064-.063-.568-.568c-24.39-24.668-53.35-44.757-85.515-58.902l-1.775-.696zm139.456 59.74l-50.178-50.18 520.71-520.71 50.178 50.18z" />
			</svg>
		</>
	);
};

export default RemarksPenSVG;
