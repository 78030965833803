import "react-quill/dist/quill.snow.css";

import React, { useContext, useEffect, useState } from "react";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import AddQuestionLang from "./AddQuestionLang";
import AddPrimaryQuestion from "./AddPrimaryQuestion";
import AddDescription from "./AddDescription";
import AddOption from "./AddOption";
import AddField from "./AddField";
import { Button, Col, Tooltip } from "antd";
import Option from "../svg/Option";
import {
	IQ,
	MATCHING,
	MCQ,
	RESEARCH,
	ANIMATION,
} from "../../utlis/constants/questionType";
// import AddField from "./AddField";

const Main_question = () => {
	const questiondata: any = useContext(createQuesContext);
	let { state, dispatch } = questiondata;
	let [ques, setQues] = useState<any>({});

	let addOption = () => {
		if (state.mode === 0) {
			dispatch({ type: "setAddOption" });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_setAddOption" });
		}
		if (state.mode === 2) {
			dispatch({ type: "edit_sub_add_option", id: state.edit_id });
		}
	};

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quData;
		if (currentQState === 0) {
			quData = state.question;
		}
		if (currentQState === 1) {
			quData = state.subQuestion;
		}
		if (currentQState === 2) {
			quData = state.question.subQuestions[state.edit_id];
		}
		// quData =
		// 	currentQState === 0
		// 		? state.question
		// 		: currentQState === 1
		// 		? state.subQuestion
		// 		: state.question.subQuestions[state.edit_id];

		setQues(quData);
	};

	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	return (
		<>
			<AddQuestionLang />
			<AddPrimaryQuestion
				label="Add Question"
				placeholder="Write a Question"
			/>

			{(ques.question_type === RESEARCH || ques.question_type === IQ) && (
				<AddDescription
					placeholder="Add Your Description"
					level="Add Description"
				/>
			)}

			{ques.question_type === MATCHING && <AddField />}

			{/* <AddField /> */}
			<AddOption />

			{(ques.question_type === MCQ ||
				ques.question_type === IQ ||
				ques.question_type === ANIMATION) && (
				<Col xl={24} style={{ paddingBottom: "10px" }}>
					<Tooltip placement="right" title="Add Option">
						<Button
							type="link"
							icon={<Option />}
							onClick={addOption}
						></Button>
					</Tooltip>
				</Col>
			)}
		</>
	);
};

export default Main_question;
