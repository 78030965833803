import React, { useContext, useEffect, useState } from "react";
import { Button, PaginationProps } from "antd";
import { Pagination } from "antd";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import Validate from "../../utlis/validateQuestionform/Validate";
import CustomToast from "../../utlis/toast/CustomToast";
// import Leftarrow from "../svg/Leftarrow";

const Paginations = () => {
	const questiondata: any = useContext(createQuesContext);
	let { state, dispatch } = questiondata;

	const [current, setCurrent] = useState(0);

	const onChange: PaginationProps["onChange"] = (page) => {
		let res: object[] = state.question?.subQuestions?.map((subq, index) => {
			let validateRes = Validate(subq, subq.question_traslation[0]);
			return validateRes;
		});
		dispatch({ type: "edit_validation", value: res });
		let error_res: Number[] = res?.map((error, index) => {
			if (Object.keys(error)?.length !== 0) {
				return 1;
			} else {
				return 0;
			}
		});
		if (!error_res.includes(1)) {
			setCurrent(page);
			dispatch({ type: "edit_subquestion", id: page - 1 });
		} else {
			for (const key in res[state.edit_id]) {
				CustomToast("error", res[state.edit_id][key]);
			}
		}

		// dispatch({ type: "validateForm", value: validateRes });
		// if (Object.keys(validateRes)?.length === 0) {
		// 	setOpen(true);
		// }
	};
	const itemRender = (_, type, originalElement) => {
		if (type === "prev") {
			return (
				<Button type="link">
					{" "}
					<b> {`<<`}</b>
				</Button>
			);
		}
		if (type === "next") {
			return (
				<Button type="link">
					<b>{`>>`} </b>
				</Button>
			);
		}
		return originalElement;
	};

	useEffect(() => {
		if (state.mode !== 2) {
			setCurrent(0);
		}
	}, [state.question.subQuestions.length, current, state.mode]);
	return (
		<>
			<Pagination
				current={current === 0 ? undefined : current}
				onChange={onChange}
				total={state.question.subQuestions.length * 10}
				itemRender={itemRender}
				showSizeChanger={false}
			/>
		</>
	);
};

export default Paginations;
