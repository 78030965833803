import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { postSearch } from "../../redux/reducers/SearchQuestionSlice";
import { getquestion } from "../../redux/reducers/questionSlice";
import {
	playListSearch,
	searchSetApi,
} from "../../redux/reducers/SearchPlaylistSlice";
import { useNavigate } from "react-router-dom";
import { PlaylistFetch } from "../../redux/reducers/PlaylistSlice";

const HomeSearch = ({
	searchMode,
	setShowQuestion,
	searchTags,
	setSearchTags,
	pageData,
	playlistPageData,
}) => {
	let navigate = useNavigate();
	const [searchValue, setSearchValue] = useState<any>("");
	const [restrictDespatch, setRestrictDespatch] = useState<boolean>(false);

	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);
	const playlistSearch: any = useSelector(
		(state: RootState) => state.playlistSearch
	);
	const QuestionSearch: any = useSelector((state: RootState) => state.search);
	if (playlistSearch.tokenExpiredError || QuestionSearch.tokenExpiredError) {
		localStorage.removeItem("token");
		navigate("/");
	}

	const handleChange = () => {
		if (searchValue.length !== 0) {
			setSearchTags([...searchTags, searchValue]);
		}
		setSearchValue("");
	};

	function handleSearchChange(event) {
		let search_Value = event.target.value.toLowerCase();
		setSearchValue(search_Value);
	}

	function handleKeyPress(event) {
		if (event.key === "Enter" && searchValue.length !== 0) {
			// Replace underscores and slashes with spaces
			let cleanedString = searchValue.replace(/[_\/]/g, " ");
			// Split the string into an array of words
			let wordsArray = cleanedString.split(" ");
			// Convert to lowercase if needed (optional)
			let newArray = wordsArray?.map((word) => word.toLowerCase());
			setSearchTags([...searchTags, ...newArray]);
			// setSearchTags([...searchTags, searchValue]);
			setSearchValue("");
		}
	}

	function handleRemoveTag(index) {
		const updatedTags = [...searchTags];
		updatedTags.splice(index, 1);
		setSearchTags(updatedTags);
		setShowQuestion(false);
	}

	useEffect(() => {
		if (searchTags.length !== 0 && (searchMode === 1 || searchMode === 0)) {
			setRestrictDespatch(true);
			// searchMode ===1 : all Search api || searchMode ===0 : tag seacrh
			if (searchMode === 1) {
				dispatch(
					postSearch({
						tags: searchTags,
						allSearch: 1,
						page: pageData?.page,
						showItems: pageData?.pageSize,
					})
				);
			}
			if (searchMode === 0) {
				dispatch(
					postSearch({
						tags: searchTags,
						allSearch: 0,
						page: pageData?.page,
						showItems: pageData?.pageSize,
					})
				);
			}
			dispatch(getquestion(searchTags));
		}
		if (restrictDespatch && (searchMode === 1 || searchMode === 0)) {
			if (searchTags.length === 0) {
				setRestrictDespatch(false);
				dispatch(
					postSearch({
						tags: searchTags,
						allSearch: 0,
						page: pageData?.page,
						showItems: pageData?.pageSize,
					})
				);
				dispatch(
					postSearch({
						tags: searchTags,
						allSearch: 1,
						page: pageData?.page,
						showItems: pageData?.pageSize,
					})
				);
				setShowQuestion(false);
			}
			// if (playlistSearch?.playListSearch?.length === 0) {
			// 	dispatch(searchSetApi(false));
			// }
		}
	}, [searchTags, pageData]);
	useEffect(() => {
		if (searchTags.length !== 0 && searchMode === 2) {
			// console.log("searchTags", searchTags);

			setRestrictDespatch(true);
			// searchMode ===1 : all Search api || searchMode ===0 : tag seacrh

			if (searchMode === 2 && searchTags) {
				// search mode ===2  : playlistSearch
				dispatch(
					playListSearch({
						tags: searchTags,
						page: playlistPageData.page,
						showItems: playlistPageData.pageSize,
					})
				);
			}

			dispatch(getquestion(searchTags));
		}
		if (restrictDespatch && searchMode === 2) {
			if (searchTags.length === 0) {
				setRestrictDespatch(false);
				dispatch(
					playListSearch({
						tags: searchTags,
						page: playlistPageData.page,
						showItems: playlistPageData.pageSize,
					})
				);

				setShowQuestion(false);
			}
			// if (playlistSearch?.playListSearch?.length === 0) {
			// 	dispatch(searchSetApi(false));
			// }
		}

		if (searchMode === 4) {
			setRestrictDespatch(true);
			// searchMode ===1 : all Search api || searchMode ===0 : tag search || searchMode=4 = my playlist search
			dispatch(
				PlaylistFetch({
					tag: searchTags?.length > 0 ? searchTags : [],
					page: playlistPageData?.page,
					showItems: playlistPageData?.pageSize,
				})
			);
		}
	}, [searchTags, playlistPageData]);
	useEffect(() => {
		setSearchTags(playlist.tag);
		dispatch(
			postSearch({
				tags: searchTags,
				allSearch: 1,
				page: pageData?.page,
				showItems: pageData?.pageSize,
			})
		);
		dispatch(
			playListSearch({
				tags: searchTags,
				page: playlistPageData.page,
				showItems: playlistPageData.pageSize,
			})
		);
	}, []);
	useEffect(() => {
		setSearchTags([]);
		setSearchValue("");
	}, []);
	// useEffect(() => {

	// }, [restrictDespatch]);

	return (
		<div className="multiple-search-div">
			<div className="multiple-search-tag-div">
				{searchTags.map((tag, index) => (
					<div className="multiple-search-tag-map" key={index}>
						{tag}
						<span
							onClick={() => handleRemoveTag(index)}
							style={{ color: "darkred", padding: " 2px" }}
						>
							&times;
						</span>
					</div>
				))}
				<Input
					className="multiple-search-input"
					type="text"
					placeholder="Enter search tag ....."
					value={searchValue}
					onChange={handleSearchChange}
					onKeyPress={handleKeyPress}
				/>
				<Button onClick={handleChange} type="link">
					<SearchOutlined
						className="Searchcolor"
						style={{
							fontSize: 20,
							color: "#1890ff",
						}}
					/>
				</Button>
			</div>
		</div>
	);
};

export default HomeSearch;
