import React, { useEffect, useState } from "react";
import { Layout, Button, Breadcrumb, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import CustomToast from "../utlis/toast/CustomToast";
import { ApiConnector } from "../axios/apiClient";
import { ATTENDEXAMSTUDENT, QUESTIONANSWER } from "../axios/AllBaseUrl";
import { useLocation } from "react-router-dom";
import ExamStudentViewCard from "../components/PlaylistCheck/ExamStudentViewCard";
import CheckExamPaper from "../components/PlaylistCheck/CheckExamPaper";

import { Divider, notification, Space } from "antd";
import checkStudentLoader from "../assets/images/checkStudentLoader.gif";
import { NotificationPlacement } from "antd/lib/notification";
import FlagLocalhost from "../Localhost/FlagLocalhost";
//   import type { NotificationPlacement } from 'antd/es/notification/interface';

const { Header, Sider, Content } = Layout;
interface UserData {
	combined_unique_id: string;
	created_time: string;
	isFromOtherSite: number;
	is_active: boolean;
	remarks: boolean;
	user_email: string;
	user_name: string;
	user_pass: string;
	user_phone: any;
	__v: number;
	_id: string;
}
interface markData {
	questionNumber: number;
	marks: number;
	blob: any;
	answer_id: any;
	question_id: any;
}

// {
// 	questionNumber: "",
// 	marks: "",
// 	blob: "",
// 	answer_id: "",
// 	question_id: "",
// },

const AttendStudentList = () => {
	let flag = FlagLocalhost();
	const [searchTerm, setSearchTerm] = useState<string>("");

	const [initialStudent, setInitialStudent] = useState<any>([]);

	let [questionData, setQuestionData] = useState<any>([]);
	// console.log("questionData", questionData);

	const [collapsed, setCollapsed] = useState(false);
	let [examPaperView, setExamPaperView] = useState(false);
	// let [marksArray, setMarksArray] = useState<markData[]>([]);
	let [answerID, setAnswerId] = useState("");
	let location = useLocation();
	// let [studentData, setStudentData] = useState<UserData[]>([]);
	let [studentData, setStudentData] = useState<any[]>([]);
	// console.log("studentData", studentData);

	let [checkExamValue, setCheckExamValue] = useState<any>({
		playlistData: {},
		user_id: "",
		mode: "",
		remarks: "",
		studentName: "",
		userData: {},
	});
	const [api, contextHolder] = notification.useNotification();

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};
	// console.log("location?.state", location?.state?.otherSidePlaylistData?.id);

	const navigateToCheckExam = async (id, remarks, name, userData) => {
		// console.log("id", id);

		if (answerID !== id) {
			// setMarksArray([]);
			setQuestionData([]);
		}

		setExamPaperView(false);
		// mode 0 for normal check and mode 1 for edit check playlist
		if (remarks === false) {
			let res = await ApiConnector("post", `${QUESTIONANSWER}`, {
				// playlistID: "63f85eb43ca24249bbdc12a6", //local
				// userId: "63f72ad1305687320915f95c", //local
				// playlistID: "650bebe783ee2a877d4d9d07", 	//local
				// playlistID: "650d39568b7eff5ab2ebc193",
				playlistID: location?.state?.playlist_id,
				userId: id,
				exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
			});

			if (res?.data?.ok) {
				setQuestionData(res?.data?.data);
			}
			setExamPaperView(true);
			setCheckExamValue({
				playlistData: location?.state,
				user_id: id,
				mode: 0,
				remarks: remarks,
				studentName: name,
				userData: userData,
			});
		}
		if (remarks === true) {
			let res = await ApiConnector("post", `${QUESTIONANSWER}`, {
				playlistID: location?.state?.playlist_id,
				userId: id,
				exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
			});
			// console.log(res);

			if (res?.data?.ok) {
				setQuestionData(res?.data?.data);
			}
			setExamPaperView(true);
			setCheckExamValue({
				playlistData: location?.state,
				user_id: id,
				mode: 1,
				remarks: remarks,
				studentName: name,
				userData: userData,
			});
		}
	};

	const editCheck = async (id, remarks, name, userData) => {
		if (answerID !== id) {
			// setMarksArray([]);
			setQuestionData([]);
			setExamPaperView(true);
		}
		// mode 0 for normal check and mode 1 for edit check playlist
		if (remarks === true) {
			let res = await ApiConnector("post", `${QUESTIONANSWER}`, {
				playlistID: location?.state?.playlist_id,
				userId: id,
				exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
			});
			// console.log(res);

			if (res?.data?.ok) {
				setQuestionData(res?.data?.data);
			}
			setExamPaperView(true);
			setCheckExamValue({
				playlistData: location?.state,
				user_id: id,
				mode: 1,
				remarks: remarks,
				studentName: name,
				userData: userData,
			});
		}
		if (remarks === false) {
			setExamPaperView(false);
			const placement = "top";
			api.info({
				message: `You need to check  ${name} answer first`,
				placement,
			});
		}
	};
	let ExamAttend = async () => {
		let res = await ApiConnector("post", `${ATTENDEXAMSTUDENT}`, {
			// playlistId: "63f85eb43ca24249bbdc12a6", //local
			// playlistId: "650d39568b7eff5ab2ebc193", //local
			// isFromOtherSite: "1", //local
			// schoolId: "43", //local
			// classId: "73", //local

			isFromOtherSite: flag?.isFromEvidya,
			playlistId: location?.state?.playlist_id,
			schoolId:
				location?.state?.otherSidePlaylistData?.school_id.toString(),
			classId:
				location?.state?.otherSidePlaylistData?.class_id.toString(),
			sectionId:
				location?.state?.otherSidePlaylistData?.section_id.toString(),
			academicYearId:
				location?.state?.otherSidePlaylistData?.academic_year_id.toString(),
			exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
		});
		// console.log("res in exam attend", res);
		if (res?.data?.ok) {
			setStudentData(res?.data?.data);
			setInitialStudent(res?.data?.data);
		}
	};

	const handleSearch = (name: string) => {
		if (name?.trim() === "") {
			setStudentData(initialStudent);
			return;
		}
		const filteredStudents = studentData?.filter((student) =>
			student?.user_name?.toLowerCase()?.includes(name?.toLowerCase())
		);
		setStudentData(filteredStudents);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		handleSearch(event.target.value);
	};
	useEffect(() => {
		ExamAttend();
	}, []);
	// console.log("first studentData", studentData);
	// console.log(
	// 	"location?.state?.otherSidePlaylistData",
	// 	location?.state?.otherSidePlaylistData
	// );

	return (
		<>
			{contextHolder}
			<Layout style={{ height: "100vh" }}>
				<Sider
					width={300}
					collapsible
					collapsed={collapsed}
					onCollapse={toggleCollapsed}
				>
					<div style={{ padding: "10px" }}>
						<Input
							placeholder="Search"
							prefix={
								<SearchOutlined
									style={{ color: "rgba(0,0,0,.25)" }}
								/>
							}
							value={searchTerm}
							onChange={(e) => handleChange(e)}
						/>
					</div>

					{collapsed ? null : (
						<Scrollbars
							style={{
								width: "300px",
								height: "85vh",
							}}
						>
							<div style={{ padding: "10px" }}>
								{studentData?.length === 0 && (
									<>
										{flag?.isFromEvidya !== 1 && (
											<strong> No one attend </strong>
										)}
										<img src={checkStudentLoader} />
									</>
								)}
								{studentData?.map((value, index) => {
									return (
										<React.Fragment key={index}>
											<ExamStudentViewCard
												value={value}
												editCheck={editCheck}
												navigateToCheckExam={
													navigateToCheckExam
												}
											/>
										</React.Fragment>
									);
								})}
							</div>
						</Scrollbars>
					)}
				</Sider>
				<Layout>
					{/* <Header style={{ background: "#fff", padding: 0 }}>
						<Button
							type="primary"
							onClick={toggleCollapsed}
							style={{ margin: "10px" }}
						>
							{collapsed
								? "Display Student"
								: "Hide Student view"}
						</Button>
					</Header> */}
					{examPaperView === true && (
						<Content>
							<CheckExamPaper
								checkExamValue={checkExamValue}
								// marksArray={marksArray}
								// setMarksArray={setMarksArray}
								setAnswerId={setAnswerId}
								setExamPaperView={setExamPaperView}
								questionData={questionData}
								setQuestionData={setQuestionData}
								setStudentData={setStudentData}
								answerID={answerID}
								setInitialStudent={setInitialStudent}
							/>
						</Content>
					)}
					{examPaperView === false && (
						<Content
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								margin: "16px",
								padding: "24px",
								background: "#fff",
							}}
						>
							<Breadcrumb style={{ margin: "16px 0" }}>
								<Breadcrumb.Item>Student</Breadcrumb.Item>
								<Breadcrumb.Item>Answer</Breadcrumb.Item>
							</Breadcrumb>
							<div
								style={{
									background: "#fff",
									padding: 24,
									minHeight: 360,
									textAlign: "center",
								}}
							>
								<strong>
									Select a student to view answer page
								</strong>
							</div>
						</Content>
					)}
				</Layout>
			</Layout>
		</>
	);
};

export default AttendStudentList;
