import React from "react";

const ExcelSvg = () => {
	return (
		<>
			<svg
				viewBox="0 0 512 512"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="32"
				height="32"
			>
				<text x="-109.95" y="148.92">
					<tspan x="-109.95" y="148.92" />
				</text>
				<text transform="scale(4.0128 .2492)" x="169.68" y="248.43" />
				<text x="471.32" y="68.919">
					<tspan x="471.32" y="68.919" />
				</text>
				<text transform="scale(4.0128 .2492)" x="314.53" y="-72.6" />
				<text x="-600.26" y="-112.34">
					<tspan x="-600.26" y="-112.34" />
				</text>
				<text transform="scale(4.0128 .2492)" x="47.49" y="-799.96" />
				<text x="497.79" y="-328.81">
					<tspan x="497.79" y="-328.81" />
				</text>
				<text transform="scale(4.0128 .2492)" x="321.13" y="-1668.62" />
				<text x="1079.06" y="-408.82">
					<tspan x="1079.06" y="-408.82" />
				</text>
				<text transform="scale(4.0128 .2492)" x="465.98" y="-1989.66" />
				<text x="689.94" y="-881.22">
					<tspan x="689.94" y="-881.22" />
				</text>
				<text transform="scale(4.0128 .2492)" x="369.01" y="-3885.31" />
				<text x="1271.2" y="-961.22">
					<tspan x="1271.2" y="-961.22" />
				</text>
				<text transform="scale(4.0128 .2492)" x="513.86" y="-4206.34" />
				<text x="-561.6" y="453.5">
					<tspan x="-561.6" y="453.5" />
				</text>
				<path
					d="m36.06 0c-5.63 0-10.156 4.527-10.156 10.156v491.69c0 5.63 4.527 10.156 10.156 10.156h439.88c5.63 0 10.156-4.527 10.156-10.156v-334.09l-167.75-167.75h-282.28"
					opacity=".1"
				/>
				<path
					d="m39.06 3.5c-5.553 0-10.03 4.479-10.03 10.03v484.94c0 5.553 4.479 10.03 10.03 10.03h433.88c5.553 0 10.03-4.479 10.03-10.03v-327.84l-167.12-167.12h-276.78"
					opacity=".1"
				/>
				<path
					d="m42.06 7c-5.476 0-9.875 4.399-9.875 9.875v478.25c0 5.476 4.399 9.875 9.875 9.875h427.88c5.476 0 9.875-4.399 9.875-9.875v-321.66l-166.47-166.47h-271.28"
					opacity=".1"
				/>
				<path
					d="m45.06 10.5c-5.399 0-9.719 4.351-9.719 9.75v471.5c0 5.399 4.32 9.75 9.719 9.75h421.88c5.399 0 9.719-4.351 9.719-9.75v-315.44l-165.81-165.81h-265.78"
					fill="#49a026"
				/>
				<path
					d="m316.59 175.47c10.496 10.551 21.254 21.353 31.938 32.09h-104.87c6.085 6.085 2.171 20.575-.031 23.531-5.063-5.063-16.484-16.734-23.19-23.438-6.605 0-18.896.573-29.5-.094-9.213 12.85-18.08 26.363-27.16 39.469-12.704-13.577-26.15-25.923-38.906-39.469-10.25.007-20.509-.024-30.75 0l47.938 70-47.938 69.47c7.806 7.243.002.139 18.25 16.625-.189.637-24.782.413-28.09.156 0 7.828-.188 2.308-.188 13.906.281 29.643.225 59.29 0 88.94 14.844 14.761 17.75 17.628 34.938 34.844h347.91c5.399 0 9.719-4.351 9.719-9.75v-315.44h-156.09c-1.422 0-2.756-.3-3.969-.844"
					opacity=".25"
				/>
				<path
					id="a"
					d="m476.66 176.31-165.81-165.81v156.06c0 5.399 4.32 9.75 9.719 9.75h156.09"
					fill="#fff"
					fillOpacity=".196"
				/>
				<use xlinkHref="#a" />
				<text x="-990.51" y="-43.575">
					<tspan x="-990.51" y="-43.575" />
				</text>
				<text transform="scale(4.0128 .2492)" x="70.891" y="-737.75" />
				<text x="-1638.27" y="-811.53">
					<tspan x="-1638.27" y="-811.53" />
				</text>
				<text transform="scale(4.0128 .2492)" x="-90.53" y="-3819.41" />
				<text x="-931.93" y="-967.16">
					<tspan x="-931.93" y="-967.16" />
				</text>
				<text transform="scale(4.0128 .2492)" x="85.49" y="-4443.94" />
				<text x="-34.02" y="244.38">
					<tspan x="-34.02" y="244.38" />
				</text>
				<text transform="scale(4.0128 .2492)" x="188.6" y="631.48" />
				<text x="547.25" y="164.38">
					<tspan x="547.25" y="164.38" />
				</text>
				<text transform="scale(4.0128 .2492)" x="333.45" y="310.45" />
				<text x="-524.33" y="-16.884">
					<tspan x="-524.33" y="-16.884" />
				</text>
				<text transform="scale(4.0128 .2492)" x="66.41" y="-416.9" />
				<text x="573.72" y="-233.35">
					<tspan x="573.72" y="-233.35" />
				</text>
				<text transform="scale(4.0128 .2492)" x="340.05" y="-1285.57" />
				<text x="1154.99" y="-313.36">
					<tspan x="1154.99" y="-313.36" />
				</text>
				<text transform="scale(4.0128 .2492)" x="484.9" y="-1606.61" />
				<text x="765.87" y="-785.76">
					<tspan x="765.87" y="-785.76" />
				</text>
				<text transform="scale(4.0128 .2492)" x="387.93" y="-3502.26" />
				<text x="1347.13" y="-865.76">
					<tspan x="1347.13" y="-865.76" />
				</text>
				<text transform="scale(4.0128 .2492)" x="532.78" y="-3823.29" />
				<text x="-485.67" y="548.96">
					<tspan x="-485.67" y="548.96" />
				</text>
				<text x="-394.98" y="-513.27">
					<tspan x="-394.98" y="-513.27" />
				</text>
				<text transform="scale(4.0128 .2492)" x="219.3" y="-2622.56" />
				<text x="-1042.75" y="-1281.22">
					<tspan x="-1042.75" y="-1281.22" />
				</text>
				<text transform="scale(4.0128 .2492)" x="57.872" y="-5704.22" />
				<text x="-336.41" y="-1436.85">
					<tspan x="-336.41" y="-1436.85" />
				</text>
				<text transform="scale(4.0128 .2492)" x="233.89" y="-6328.75" />
				<path
					d="m9.639 9v14.1c.216.319.431.517.756.629h12.11l-3.428-3.447h-6l-.004-7.863h7.797v6.152l3.492 3.492v-12.11c-.116-.653-.502-.865-.959-.961h-13.764m18.355 5.04c-.457.097-.845.307-.961.961l-.006 6.875 3.369-3.369v-1.109h3l-.004 2.986h-1.111l-3.654 3.301h7.359c.34-.066.562-.196.756-.582v-9.06h-8.75m-15.518 12.221c-.34.066-.563.196-.758.582v12.16h11.684c.457-.096.843-.307.959-.961l.008-9.918-3.369 3.367v4.086h-5.984l.004-5.955h4.221l3.428-3.361h-10.191m16.15 0 3.428 3.361h2.932l.004 4.658h-4.596v-2.789l-3.369-3.367.006 8.553c.116.653.504.865.961.961h10.367v-10.795c-.194-.386-.418-.516-.758-.582z"
					fill="#fff"
					transform="matrix(3.4309 0 0 3.4309 52.15 -.198)"
				/>
				<text x="1009.05" y="-305.88">
					<tspan x="1009.05" y="-305.88" />
				</text>
				<text transform="scale(4.0128 .2492)" x="448.54" y="-1576.61" />
				<text x="1590.32" y="-385.88">
					<tspan x="1590.32" y="-385.88" />
				</text>
				<text transform="scale(4.0128 .2492)" x="593.39" y="-1897.64" />
				<text x="518.74" y="-567.14">
					<tspan x="518.74" y="-567.14" />
				</text>
				<text transform="scale(4.0128 .2492)" x="326.35" y="-2625" />
				<text x="1616.79" y="-783.61">
					<tspan x="1616.79" y="-783.61" />
				</text>
				<text transform="scale(4.0128 .2492)" x="599.99" y="-3493.66" />
				<text x="2198.06" y="-863.62">
					<tspan x="2198.06" y="-863.62" />
				</text>
				<text transform="scale(4.0128 .2492)" x="744.84" y="-3814.7" />
				<text x="1808.94" y="-1336.02">
					<tspan x="1808.94" y="-1336.02" />
				</text>
				<text transform="scale(4.0128 .2492)" x="647.87" y="-5710.35" />
				<text x="2390.2" y="-1416.02">
					<tspan x="2390.2" y="-1416.02" />
				</text>
				<text transform="scale(4.0128 .2492)" x="792.72" y="-6031.38" />
				<text x="557.4" y="-1.303">
					<tspan x="557.4" y="-1.303" />
				</text>
				<text x="128.5" y="-498.38">
					<tspan x="128.5" y="-498.38" />
				</text>
				<text transform="scale(4.0128 .2492)" x="349.75" y="-2562.79" />
				<text x="-519.27" y="-1266.33">
					<tspan x="-519.27" y="-1266.33" />
				</text>
				<text transform="scale(4.0128 .2492)" x="188.32" y="-5644.46" />
				<text x="187.07" y="-1421.96">
					<tspan x="187.07" y="-1421.96" />
				</text>
				<text transform="scale(4.0128 .2492)" x="364.34" y="-6268.98" />
				<text x="659.77" y="-136.66">
					<tspan x="659.77" y="-136.66" />
				</text>
				<text transform="scale(4.0128 .2492)" x="361.5" y="-897.56" />
				<text x="1241.04" y="-216.66">
					<tspan x="1241.04" y="-216.66" />
				</text>
				<text transform="scale(4.0128 .2492)" x="506.34" y="-1218.59" />
				<text x="169.46" y="-397.92">
					<tspan x="169.46" y="-397.92" />
				</text>
				<text transform="scale(4.0128 .2492)" x="239.31" y="-1945.95" />
				<text x="1267.51" y="-614.39">
					<tspan x="1267.51" y="-614.39" />
				</text>
				<text transform="scale(4.0128 .2492)" x="512.94" y="-2814.61" />
				<text x="1848.78" y="-694.4">
					<tspan x="1848.78" y="-694.4" />
				</text>
				<text transform="scale(4.0128 .2492)" x="657.79" y="-3135.65" />
				<text x="1459.66" y="-1166.8">
					<tspan x="1459.66" y="-1166.8" />
				</text>
				<text transform="scale(4.0128 .2492)" x="560.82" y="-5031.3" />
				<text x="2040.92" y="-1246.8">
					<tspan x="2040.92" y="-1246.8" />
				</text>
				<text transform="scale(4.0128 .2492)" x="705.67" y="-5352.33" />
				<text x="208.12" y="167.92">
					<tspan x="208.12" y="167.92" />
				</text>
				<path
					d="m94.13 207.56 47.937 70-47.937 69.47h30.75l32.469-48.12 33.406 48.13h30l-48.31-69.47 48.31-70h-30l-33.406 48.688-32.469-48.688h-30.75m149.53 0v156.25h-159.56v24.688c.541 27.22-.004 55.01 0 78.13v.031h343.81v-259.09h-184.24m24.906 24.719h54.781v53.41h-54.781zm79.69 0h54.781v53.41h-54.781zm-79.69 78.13h54.781v53.41h-54.781v-53.407m79.69 0h54.781v53.41h-54.781v-53.407m-239.06 78.13h54.781v53.41h-54.781v-53.407m79.69 0h54.781v53.41h-54.781v-53.403m79.69 0h54.781v53.41h-54.781zm79.69 0h54.781v53.41h-54.781z"
					fill="#fff"
				/>
			</svg>
		</>
	);
};

export default ExcelSvg;
