import React from "react";

const CheckExamSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				viewBox="0 0 52 52"
				x="0px"
				y="0px"
                width={24}
                height={24}
                fill="currentColor"
			>
				<g>
					<g>
						<g>
							<path d="M39.987793,50.0002441H12.012207c-1.8896484,0-3.4267578-1.5380859-3.4267578-3.4277344V9.3103027     c0-1.9902344,1.6240234-3.609375,3.6191406-3.609375h6.6191406c0.5527344,0,1,0.4477539,1,1s-0.4472656,1-1,1h-6.6191406     c-0.8925781,0-1.6191406,0.722168-1.6191406,1.609375v37.262207c0,0.7871094,0.6396484,1.4277344,1.4267578,1.4277344H39.987793     c0.7871094,0,1.4267578-0.640625,1.4267578-1.4277344V9.3103027c0-0.887207-0.7265625-1.609375-1.6191406-1.609375h-6.6191406     c-0.5527344,0-1-0.4477539-1-1s0.4472656-1,1-1h6.6191406c1.9951172,0,3.6191406,1.6191406,3.6191406,3.609375v37.262207     C43.4145508,48.4621582,41.8774414,50.0002441,39.987793,50.0002441z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M39.987793,50.0002441H12.012207c-1.8896484,0-3.4267578-1.5380859-3.4267578-3.4277344V9.3103027     c0-1.9902344,1.6240234-3.609375,3.6191406-3.609375h6.6191406c0.5527344,0,1,0.4477539,1,1s-0.4472656,1-1,1h-6.6191406     c-0.8925781,0-1.6191406,0.722168-1.6191406,1.609375v37.262207c0,0.7871094,0.6396484,1.4277344,1.4267578,1.4277344H39.987793     c0.7871094,0,1.4267578-0.640625,1.4267578-1.4277344V9.3103027c0-0.887207-0.7265625-1.609375-1.6191406-1.609375h-6.6191406     c-0.5527344,0-1-0.4477539-1-1s0.4472656-1,1-1h6.6191406c1.9951172,0,3.6191406,1.6191406,3.6191406,3.609375v37.262207     C43.4145508,48.4621582,41.8774414,50.0002441,39.987793,50.0002441z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M35.730957,11.3952637H16.269043c-0.3720703,0-0.7128906-0.206543-0.8857422-0.5356445     c-0.1728516-0.3295898-0.1484375-0.7275391,0.0634766-1.0332031l5.1162109-7.3955078     c0.1865234-0.2700195,0.4941406-0.4311523,0.8222656-0.4311523h9.2285156c0.328125,0,0.6357422,0.1611328,0.8222656,0.4311523     l5.1171875,7.3955078c0.2119141,0.3056641,0.2363281,0.7036133,0.0634766,1.0332031     C36.4438477,11.1887207,36.1030273,11.3952637,35.730957,11.3952637z M18.1772461,9.3952637h15.6455078l-3.7333984-5.3955078     H21.909668L18.1772461,9.3952637z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M38.2817383,31.2033691H24.1088867c-0.5527344,0-1-0.4472656-1-1c0-0.5522461,0.4472656-1,1-1h14.1728516     c0.5527344,0,1,0.4477539,1,1C39.2817383,30.7561035,38.8344727,31.2033691,38.2817383,31.2033691z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M38.2817383,19.5183105H24.1088867c-0.5527344,0-1-0.4477539-1-1s0.4472656-1,1-1h14.1728516     c0.5527344,0,1,0.4477539,1,1S38.8344727,19.5183105,38.2817383,19.5183105z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M38.2817383,43.1594238H24.1079102c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h14.1738281     c0.5527344,0,1,0.4472656,1,1S38.8344727,43.1594238,38.2817383,43.1594238z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M17.4819336,22.0969238c-1.9726563,0-3.578125-1.6054688-3.578125-3.5786133s1.6054688-3.578125,3.578125-3.578125     s3.578125,1.6049805,3.578125,3.578125S19.4545898,22.0969238,17.4819336,22.0969238z M17.4819336,16.9401855     c-0.8701172,0-1.578125,0.7080078-1.578125,1.578125c0,0.8706055,0.7080078,1.5786133,1.578125,1.5786133     s1.578125-0.7080078,1.578125-1.5786133C19.0600586,17.6481934,18.3520508,16.9401855,17.4819336,16.9401855z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M17.4819336,33.7814941c-1.9726563,0-3.578125-1.6054688-3.578125-3.578125     c0-1.9731445,1.6054688-3.578125,3.578125-3.578125s3.578125,1.6049805,3.578125,3.578125     C21.0600586,32.1760254,19.4545898,33.7814941,17.4819336,33.7814941z M17.4819336,28.6252441     c-0.8701172,0-1.578125,0.7080078-1.578125,1.578125s0.7080078,1.578125,1.578125,1.578125s1.578125-0.7080078,1.578125-1.578125     S18.3520508,28.6252441,17.4819336,28.6252441z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M16.3745117,45.6262207c-0.2558594,0-0.5117188-0.0976563-0.7070313-0.2929688l-2.65625-2.65625     c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625s1.0234375-0.390625,1.4140625,0l1.9492188,1.9492188l4.2285156-4.2275391     c0.390625-0.390625,1.0234375-0.390625,1.4140625,0s0.390625,1.0234375,0,1.4140625L17.081543,45.333252     C16.8862305,45.5285645,16.6303711,45.6262207,16.3745117,45.6262207z" />
						</g>
					</g>
					<g>
						<g>
							<path d="M26.0004883,7.7019043c-0.1298828,0-0.2597656-0.0302734-0.3798828-0.0800781     c-0.1298828-0.050293-0.2402344-0.1201172-0.3300781-0.2202148c-0.0898438-0.0898438-0.1601563-0.199707-0.2197266-0.3198242     c-0.0507813-0.1201172-0.0703125-0.25-0.0703125-0.3798828c0-0.1401367,0.0195313-0.2602539,0.0703125-0.3901367     c0.0595703-0.1201172,0.1298828-0.2299805,0.2197266-0.3198242c0.3798828-0.3701172,1.0400391-0.3701172,1.4199219,0     c0.1796875,0.1899414,0.2900391,0.4399414,0.2900391,0.7099609c0,0.2597656-0.1103516,0.5200195-0.2900391,0.699707     c-0.1005859,0.1000977-0.2099609,0.1699219-0.3300781,0.2202148C26.2602539,7.6716309,26.1303711,7.7019043,26.0004883,7.7019043     z" />
						</g>
					</g>
				</g>
			</svg>
		</>
	);
};

export default CheckExamSvg;
