import React, { useContext, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Form, Button, Space, Modal, Tooltip } from "antd";
import SvgDelete from "../svg/SvgDelete";
import {
	DownCircleOutlined,
	ExclamationCircleOutlined,
	UpCircleOutlined,
} from "@ant-design/icons";
import createQuesContext from "../../context/createQuestion/createQuesContext";

import "../../assets/styles/main.css";
import InvalidMsg from "./InvalidMsg";
import {
	ANIMATION,
	FILL_IN_THE_BLANK,
	TRUE_FALSE,
} from "../../utlis/constants/questionType";
import TextEditor from "../../pages/TextEditor";
import MathSymbol from "../layout/MathSymbol";
import CustomToast from "../../utlis/toast/CustomToast";
import DownArrow from "../svg/DownArrow";
import UpArrow from "../svg/UpArrow";

const { confirm } = Modal;

const Editor = (prop: any) => {
	let [error, setError] = useState<any>({});
	const { class_name, value, level, type, id, ques_type } = prop;
	// console.log("value", value);
	const [editorContent, setEditorContent] = useState("");
	const [showSymbols, setShowSymbols] = useState<boolean>(false);
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	const showPromiseConfirm = () => {
		// confirm({
		// 	title: "Do you want to delete this item?",
		// 	icon: <ExclamationCircleOutlined />,
		// 	content:
		// 		"When clicked the OK button, the editor value will be remove !",
		// 	onOk() {
		if (state.mode === 0 && type === "title") {
			dispatch({ type: "main_title_del" });
		}
		if (state.mode === 0 && type === "hint") {
			dispatch({ type: "main_hint_del" });
		}
		if (state.mode === 1 && type === "title") {
			dispatch({ type: "sub_title_del" });
		}
		if (state.mode === 1 && type === "hint") {
			dispatch({ type: "sub_hint_del" });
		}
		if (state.mode === 2 && type === "title") {
			dispatch({
				type: "edit_sub_question_title",
				value: "",
				id: state.edit_id,
			});
		}
		if (state.mode === 2 && type === "hint") {
			dispatch({
				type: "edit_sub_question_longhint",
				value: "",
				id: state.edit_id,
			});
		}
		// },
		// 	onCancel() {},
		// });
	};
	const animation_option_length = (data: any) => {
		if (ques_type === ANIMATION) {
			if (data?.length === 26) {
				CustomToast("error", "Cannot Add More Than 20 Characters");
			}
		}
	};
	const calTitleRender = () => {
		const currentQState = state.mode; // 0 = main , 1 = subQ, 2 = edit
		let title;
		if (currentQState === 0) {
			title = state.question_traslation.title;
		}
		if (currentQState === 1) {
			title = state.sub_question_traslation.title;
		}
		if (currentQState === 2) {
			title =
				state.question.subQuestions[state.edit_id].question_traslation
					.title;
		}
	};
	const count_blank = (data: any) => {
		let count = data?.match(/_{3,}/g) || [];
		// console.log(count);
		return count;
	};
	const getDescriptionValue = (editorValue: any) => {
		// console.log("ques_type", ques_type, editorValue, type);

		if (type === "hint" && state.mode === 0) {
			dispatch({
				type: "longHint",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
			});
		}
		if (type === "hint" && state.mode === 1) {
			dispatch({
				type: "sub_longHint",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
			});
		}

		if (
			type === "title" &&
			state.mode === 0 &&
			editorValue != "" &&
			editorValue != "<p><br></p>"
		) {
			// console.log("log e", editorValue, state.mode);
			if (state.question.question_type === FILL_IN_THE_BLANK) {
				dispatch({
					type: "set_blank_counter",
					value: count_blank(
						editorValue === "<p><br></p>" ? "" : editorValue
					),
				});
				dispatch({ type: "main_blank_right_answer" });
			}
			dispatch({
				type: "title",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
			});
		}
		if (
			type === "title" &&
			state.mode === 1 &&
			editorValue != "" &&
			editorValue != "<p><br></p>"
		) {
			if (state.subQuestion.question_type === FILL_IN_THE_BLANK) {
				dispatch({
					type: "set_sub_blank_counter",
					value: count_blank(editorValue),
				});
				dispatch({ type: "sub_blank_right_answer" });
			}

			dispatch({
				type: "sub_title",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
			});
		}
		if (
			type === "title" &&
			state.mode === 2 &&
			editorValue != "" &&
			editorValue != "<p><br></p>"
		) {
			if (
				state.question.subQuestions[state.edit_id].question_type ===
				FILL_IN_THE_BLANK
			) {
				dispatch({
					type: "set_sub_blank_counter",
					value: count_blank(editorValue),
				});
				dispatch({
					type: "edit_sub_blank_right_answer",
					id: state.edit_id,
				});
			}

			dispatch({
				type: "edit_sub_question_title",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
				id: state.edit_id,
			});
		}
		if (state.mode === 2 && type === "hint") {
			dispatch({
				type: "edit_sub_question_longhint",
				value: editorValue === "<p><br></p>" ? "" : editorValue,
				id: state.edit_id,
			});
		}
		if (
			ques_type !== ANIMATION &&
			type === "option" &&
			ques_type !== undefined
		) {
			if (state.mode === 0) {
				dispatch({
					type: "optionTitle",
					value: editorValue === "<p><br></p>" ? "" : editorValue,
					id: id,
				});
				animation_option_length(editorValue);
			}
			if (state.mode === 1) {
				dispatch({
					type: "sub_optionTitle",
					value: editorValue === "<p><br></p>" ? "" : editorValue,
					id: id,
				});
				animation_option_length(editorValue);
			}
			if (state.mode === 2) {
				dispatch({
					type: "edit_sub_option_title",
					value: editorValue === "<p><br></p>" ? "" : editorValue,
					id: state.edit_id,
					option_id: id,
				});
				animation_option_length(editorValue);
			}
		}
		if (
			ques_type === ANIMATION &&
			type === "option" &&
			ques_type !== undefined
		) {
			const maxLength = 26;

			if (editorValue?.length <= maxLength) {
				setEditorContent(editorValue);
				// console.log("editorContent", editorValue);
				if (state.mode === 0) {
					dispatch({
						type: "optionTitle",
						value: editorValue === "<p><br></p>" ? "" : editorValue,
						id: id,
					});
					animation_option_length(editorValue);
				}
				if (state.mode === 1) {
					dispatch({
						type: "sub_optionTitle",
						value: editorValue === "<p><br></p>" ? "" : editorValue,
						id: id,
					});
					animation_option_length(editorValue);
				}
				if (state.mode === 2) {
					dispatch({
						type: "edit_sub_option_title",
						value: editorValue === "<p><br></p>" ? "" : editorValue,
						id: state.edit_id,
						option_id: id,
					});
					animation_option_length(editorValue);
				}
			}
		}
	};
	let get_error_in_edit_mode = () => {
		if (
			state.edit_id !== null &&
			state?.edit_mode_validation?.length !== 0
		) {
			if (
				Object.keys(state?.edit_mode_validation[state?.edit_id])
					?.length !== 0
			) {
				setError(state?.edit_mode_validation[state?.edit_id]);
			} else {
				setError({});
			}
		}
	};
	const modules: any = {
		toolbar: {
			container: [
				["bold", "italic", "underline", "strike"],
				[{ script: "sub" }, { script: "super" }],
				["link"],
				[{ header: [1, 2, 3, false] }],
			],
		},
	};
	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		// "image",
		"color",
		"background",
		"script", // Add 'script' to enable subscript and superscript
	];
	function changeEditorHeight() {
		const idElements = document.querySelectorAll(".quill");
		idElements.forEach((one_element) => {
			if (one_element.id === "option") {
				let childDiv = one_element.getElementsByTagName("div")[1];
				let targetDiv = childDiv.getElementsByTagName("div")[0];
				if (targetDiv instanceof HTMLElement) {
					targetDiv.style.maxHeight = "50px";
				}
			}
		});
	}
	useEffect(() => {
		calTitleRender();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);
	useEffect(() => {
		get_error_in_edit_mode();
	}, [state.edit_id, state.edit_mode_validation]);
	useEffect(() => {
		changeEditorHeight();
	}, [type]);
	return (
		<>
			<Space
				direction="vertical"
				size="middle"
				style={{
					display: "flex",
				}}
			>
				<Form.Item label={level}>
					{showSymbols && (
						<MathSymbol onChange={getDescriptionValue} />
					)}
					<Tooltip
						placement="left"
						title={showSymbols ? "Hide Symbols" : "Show Symbols"}
					>
						<Button
							type="link"
							onClick={() => setShowSymbols(!showSymbols)}
							style={{
								float: "left",
								fontWeight: "bold",
								color: "#333333",
							}}
						>
							{showSymbols ? <DownArrow /> : <UpArrow />}
						</Button>
					</Tooltip>

					{type !== "option" && (
						<Button
							type="link"
							className="editor-delete"
							style={{
								float: "right",
								fontWeight: "bold",
							}}
							onClick={showPromiseConfirm}
						>
							<SvgDelete />
						</Button>
					)}

					<ReactQuill
						className={`${class_name}`}
						theme="snow"
						value={value}
						onChange={(editorValue) =>
							getDescriptionValue(editorValue)
						}
						modules={modules}
						formats={formats}
						readOnly={editorContent?.length >= 26}
						id={type}
					/>

					<div style={{ height: "16px" }}>
						{(state.valid?.title || error?.title) &&
							type !== "hint" &&
							type !== "option" && (
								<InvalidMsg
									msg={state.valid.title || error?.title}
								/>
							)}
					</div>
				</Form.Item>
			</Space>
		</>
	);
};

export default Editor;
