// import { useState } from "react";

import {
	Card,
	Col,
	Row,
	Typography,
	Form,
	Select,
	Button,
	AutoComplete,
	Input,
	Tooltip,
} from "antd";

import {
	FloatingMenu,
	MainButton,
	ChildButton,
	Directions,
} from "react-floating-button-menu";

import { PlusOutlined, CloseOutlined, HeartOutlined } from "@ant-design/icons";
import { useState } from "react";

const optionsLanguage = [
	{ value: "English" },
	{ value: "Hindi" },
	{ value: "Bengali" },
];

// const state = {
// 	isOpen: false,
// };

const { TextArea } = Input;

function AddQuestion() {
	const [isopen, setIsOpen] = useState(false);

	const { Title } = Typography;
	const { Option } = Select;

	return (
		<>
			{/* {isopen && <AddQuestion />} */}
			<Row gutter={[24, 0]} justify="center" align="middle">
				<Col xs={24} sm={24} md={14} lg={10} xl={8} className="mb-24">
					<Card
						bordered={false}
						className="criclebox h-full width-100"
					>
						<div className="width-100">
							<Title level={5}>Create Questions</Title>

							<Form
								layout="vertical"
								className="row-col full-width"
							>
								<Form.Item
									label="Add Primary Question"
									name="primary_question"
									rules={[
										{
											required: true,
											message:
												"Please enter primary question",
										},
									]}
									className="width-100"
								>
									<Input placeholder="Enter question here" />
								</Form.Item>

								<Form.Item
									name="level"
									label="Description"
									rules={[{ required: false }]}
								>
									<TextArea
										rows={4}
										placeholder="Enter description here"
									/>
								</Form.Item>

								<Form.Item>
									{/* <div justify="center" align="end"> */}
									<div style={{ textAlign: "end" }}>
										<Button htmlType="submit">
											Proceed
										</Button>
									</div>
								</Form.Item>
							</Form>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={14} lg={10} xl={4} className="mb-24">
					<FloatingMenu
						slideSpeed={500}
						direction={Directions.Down}
						spacing={8}
						isOpen={isopen}
					>
						<MainButton
							iconResting={
								<PlusOutlined style={{ fontSize: 24 }} />
							}
							iconActive={
								<CloseOutlined style={{ fontSize: 24 }} />
							}
							background="white"
							onClick={() => setIsOpen(!isopen)}
							size={48}
						/>
						<ChildButton
							icon={
								<Tooltip placement="right" title="Icon 1">
									<HeartOutlined style={{ fontSize: 18 }} />
								</Tooltip>
							}
							background="white"
							size={36}
							// onClick={() => console.log("First button clicked")}
						/>
						<ChildButton
							icon={
								<Tooltip placement="right" title="Icon 2">
									<HeartOutlined style={{ fontSize: 18 }} />
								</Tooltip>
							}
							background="white"
							size={36}
						/>
						<ChildButton
							icon={
								<Tooltip placement="right" title="Icon 1">
									<HeartOutlined style={{ fontSize: 18 }} />
								</Tooltip>
							}
							background="white"
							size={36}
						/>
					</FloatingMenu>
				</Col>
			</Row>
		</>
	);
}

export default AddQuestion;
