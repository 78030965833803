import React, { useState, useRef, useEffect } from "react";

const TextEditor = ({ word, onChange, deleteData, className, editorRef }) => {
	// console.log("value", word);
	// console.log("className", className);

	const [content, setContent] = useState<string>("");
	const [fontSize, setFontSize] = useState<string>("2");
	// const editorRef = useRef<HTMLDivElement | null>(null);

	const handleFormat = (command: string, value?: string) => {
		if (!editorRef.current) return;
		// console.log("command", command);

		document.execCommand("styleWithCSS", false, "true");

		switch (command) {
			case "fontSize":
				if (value) {
					setFontSize(value);
					document.execCommand(command, false, value);
				}
				break;
			case "bold":
			case "italic":
			case "underline":
			case "superscript":
			case "subscript":
				document.execCommand(
					command,
					false,
					undefined as string | undefined
				);
				break;
			case "formatBlock":
				if (value) {
					const selection = window.getSelection();
					const range = selection?.getRangeAt(0);

					if (range) {
						const span = document.createElement("span");
						span.style.fontSize = `${fontSize}px`;
						span.innerHTML = range.toString();
						range.deleteContents();
						range.insertNode(span);

						document.execCommand(command, false, value);
					}
				}
				break;
			default:
				console.error(`Unsupported command: ${command}`);
		}
	};

	const handleChange = () => {
		if (editorRef.current) {
			setContent(editorRef.current.innerHTML);
			onChange(editorRef.current.innerHTML);
		}
	};

	const handleInsertSymbol = (symbol: string) => {
		if (!editorRef.current) return;
		if (editorRef.current) {
			document.execCommand("styleWithCSS", false, "true");

			const fontSizeStyle = `font-size: "${fontSize}px"`;
			const html = `<span style={{${fontSizeStyle}}}>${symbol}</span>`;
			// console.log("handleInsertSymbol", html);
			// const selection = window.getSelection();
			// const range = selection?.getRangeAt(0);
			// if (range) {
			// 	const span = document.createElement("span");
			// 	span.style.fontSize = `${fontSize}px`;
			// 	span.innerHTML = range.toString();
			// 	range.deleteContents();
			// 	range.insertNode(span);

			// 	document.execCommand("insertHTML", false, symbol);
			// 	console.log(document.execCommand("insertHTML", false, symbol));
			// }

			document.execCommand("insertHTML", false, html);
		}
	};
	const handleClear = () => {
		if (editorRef.current) {
			editorRef.current.innerHTML = "";
			setContent("");
			deleteData();
		}
	};

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.innerHTML = word ? word : "";
			// setContent(editorRef.current.innerHTML);
		}
	}, []);

	return (
		<div className="text-editor-container">
			<div className="button-container">
				<button onClick={() => handleFormat("bold")}>Bold</button>
				<button onClick={() => handleFormat("italic")}>Italic</button>
				<button onClick={() => handleFormat("underline")}>
					Underline
				</button>
				<button onClick={() => handleFormat("superscript")}>
					Superscript
				</button>
				<button onClick={() => handleFormat("subscript")}>
					Subscript
				</button>
				<button onClick={() => handleInsertSymbol("&radic;")}>√</button>
				<button onClick={() => handleInsertSymbol("&times;")}>×</button>
				<button onClick={() => handleInsertSymbol("&divide;")}>
					÷
				</button>
				<button onClick={() => handleInsertSymbol("&plusmn;")}>
					±
				</button>
				<button onClick={() => handleInsertSymbol("&pi;")}>π</button>
				<button onClick={() => handleInsertSymbol("&Sigma;")}>Σ</button>
				<select
					value={fontSize}
					onChange={(e) => handleFormat("fontSize", e.target.value)}
				>
					{[...Array(9).keys()].map((size) => (
						<option key={size} value={size.toString()}>
							{size}
						</option>
					))}
				</select>
				<select
					onChange={(e) => handleFormat("fontSize", e.target.value)}
				>
					<option value="Select">Select Header</option>
					<option value="24px">H1</option>
					<option value="5px">H2</option>
				</select>
				<button onClick={handleClear}>Clear</button>
			</div>
			<div
				ref={editorRef}
				style={{ fontSize: "24px" }}
				contentEditable
				// className="editor"
				className={className}
				onInput={handleChange}
				// onInput={onChange}
			/>
			<div>
				{content}
				<h2>Preview: </h2>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</div>
	);
};

export default TextEditor;
