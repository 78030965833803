import { Badge, message, Row, Space } from "antd";
import Col from "antd/es/grid/col";
import React, { useContext, useEffect, useState } from "react";
import { FILE_UPLOAD, S3BUCKET_URL } from "../../axios/AllBaseUrl";
import { ApiConnector } from "../../axios/apiClient";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import SvgBin from "../svg/SvgBin";
import SvgDelete from "../svg/SvgDelete";

import SvgPencil from "../svg/SvgPencil";
import SvgUpload from "../svg/SvgUpload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	edit_playlist_picture,
	pictureadded,
	remove_playlist_file,
	remove_playlist_file_edit,
} from "../../redux/reducers/PlaylistSlice";
import { useNavigate } from "react-router-dom";

const UploadCustom = ({ where, id }) => {
	const dispatched = useDispatch();
	const questiondata: any = useContext(createQuesContext);
	let { state, dispatch } = questiondata;
	let [ques, setQues] = useState<any>({});
	let [optionData, setOptionData] = useState<any>({});
	let [playlistData, setPlaylistData] = useState<any>({});
	const [edithover, setEdithover] = useState(false);
	const playlist = useSelector((state: RootState) => state.playlist);

	let navigate = useNavigate();

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quData;
		let optionData_;
		let playlist_data;
		if (where === "main") {
			quData =
				currentQState === 0
					? state.question
					: currentQState === 1
					? state.subQuestion
					: state.question.subQuestions[state.edit_id];
			setQues(quData);
		}
		if (where === "option") {
			optionData_ =
				currentQState === 0
					? state.question.options[id]?.OptionTranslation[0]
					: currentQState === 1
					? state.subQuestion?.options[id]?.OptionTranslation[0]
					: state.question?.subQuestions[state.edit_id]?.options[id]
							?.OptionTranslation[0];
			setOptionData(optionData_);
		}
		if (where === "playlist") {
			if (playlist.mode === 0) {
				playlist_data = playlist.file;
			}
			if (playlist.mode === 1) {
				playlist_data = playlist.playlist_to_edit.file;
			}
			setPlaylistData(playlist_data);
		}
	};
	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.question.options,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
		playlist.file,
		playlist.playlist_to_edit,
	]);

	let s3ImgUpload = async (event) => {
		try {
			let random_str = Math.random().toString(36).slice(2, 12);
			let yourDate = new Date();
			let format_date = yourDate.toISOString().split("T")[0];
			let current_month = yourDate.toLocaleString("default", {
				month: "short",
			});
			let current_year = new Date().getFullYear();
			const files = event.target.files[0];
			let S3BucketData = {
				fileName: random_str.concat(files.name),
				fileType: files.type,
				product: `QuestionBank`,
				uploadDate: `${current_year}/${current_month}/${format_date}`,
			};
			const S3Bucket = await ApiConnector(
				"post",
				S3BUCKET_URL,
				S3BucketData
			);
			let config = {
				maxBodyLength: Infinity,
			};
			let S3BucketUpload = await ApiConnector(
				"put",
				`${S3Bucket?.data?.uploadUrl}`,
				files,
				{
					"Content-Type": files.type,
				},
				config,
				false
			);
			if (S3Bucket) {
				return {
					ok: true,
					data: {
						file_type: files.type,
						file_name: files.name,
						url: S3Bucket.data.fileUrl,
					},
				};
			}
		} catch (error) {
			console.log("Something goes wrong");
			return {
				ok: false,
				data: {},
			};
		}
	};

	const imgUpload = async (event) => {
		const res = await s3ImgUpload(event);
		// let formData = new FormData();
		// formData.append("url", files[0]);
		// let res = await ApiConnector("post", `${FILE_UPLOAD}`, formData);
		// console.log("res", res);

		if (res?.ok === true) {
			message.success(`File uploaded successfully`);
			if (where === "main" && state.mode === 0) {
				dispatch({
					type: "question_fileupload",
					value: res.data,
				});
			}

			if (where === "main" && state.mode === 1) {
				dispatch({
					type: "sub_question_fileupload",
					value: res.data,
				});
			}

			if (where === "main" && state.mode === 2) {
				dispatch({
					type: "edit_sub_question_fileupload",
					value: res.data,
					id: state.edit_id,
				});
			}
			if (where === "option" && state.mode === 0) {
				dispatch({
					type: "option_image_upload",
					value: res.data.url,
					id: id,
				});
			}
			if (where === "option" && state.mode === 1) {
				dispatch({
					type: "sub_option_image_upload",
					value: res.data.url,
					id: id,
				});
			}
			if (where === "option" && state.mode === 2) {
				// console.log("edit");
				dispatch({
					type: "edit_sub_option_image_upload",
					value: res.data.url,
					id: state.edit_id,
					option_id: id,
				});
			}
			if (where === "playlist" && playlist.mode === 0) {
				dispatched(pictureadded(res.data));
			}
			if (where === "playlist" && playlist.mode === 1) {
				dispatched(edit_playlist_picture(res.data));
			}
		} else {
			message.error(`File upload failed.`);
		}
	};

	const remove_question_file = (index) => {
		if (where === "main" && state.mode === 0) {
			dispatch({
				type: "remove_question_file",
				id: index,
			});
		}
		if (where === "main" && state.mode === 1) {
			dispatch({ type: "remove_subquestion_file", id: index });
		}
		if (where === "main" && state.mode === 2) {
			dispatch({
				type: "remove_subquestion_file_editmode",
				id: index,
				edit_id: state.edit_id,
			});
		}
		if (where === "option" && state.mode === 0) {
			// console.log("asfasdf");

			dispatch({
				type: "option_image_upload",
				value: "",
				id: id,
			});
		}
		if (where === "option" && state.mode === 1) {
			dispatch({
				type: "sub_option_image_upload",
				value: "",
				id: id,
			});
		}
		if (where === "option" && state.mode === 2) {
			// console.log("edit");
			dispatch({
				type: "edit_sub_option_image_upload",
				value: "",
				id: state.edit_id,
				option_id: id,
			});
		}
		if (where === "playlist" && playlist.mode === 0) {
			dispatched(remove_playlist_file());
		}
		if (where === "playlist" && playlist.mode === 1) {
			dispatched(remove_playlist_file_edit());
		}
	};

	const bin = <SvgBin />;

	return (
		<>
			<Row>
				{where === "main" && (
					<Col xl={22}>
						{ques?.files?.map((elem, index) => {
							return (
								<div key={index}>
									{/* <img key={index} src={elem.url} className="image-upload"></img><DeleteOutlined key={index} /> */}

									<Space
										size="middle"
										className="image-upload"
										key={index}
									>
										<label title="remove">
											<div className="show-and-edit-div">
												<img
													key={index}
													src={elem.url}
													// src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
													className="image-upload"
													style={{ opacity: "0.6" }}
													onMouseEnter={() =>
														setEdithover(true)
													}
												></img>
												{edithover && (
													<span
														className="edit-icon"
														onClick={() => {
															remove_question_file(
																index
															);
														}}
													>
														{bin}
													</span>
												)}
											</div>
										</label>
									</Space>
								</div>
							);
						})}
					</Col>
				)}
				{where === "option" && optionData.imageUrl !== "" && (
					<Space size="middle" className="image-upload">
						<label title="remove">
							<div className="show-and-edit-div">
								<img
									src={optionData.imageUrl}
									// src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
									className="image-upload"
									style={{ opacity: "0.6" }}
									onMouseEnter={() => setEdithover(true)}
								></img>
								{edithover && (
									<span
										className="edit-icon"
										onClick={() => {
											remove_question_file(null);
										}}
									>
										{bin}
									</span>
								)}
							</div>
						</label>
					</Space>
				)}
				<Col xl={2}>
					<div className="upload-btn-wrapper">
						<button className="btn" title="upload">
							<SvgUpload />
						</button>
						<input
							type="file"
							accept="image/jpeg, image/png, image/gif, image/jpg"
							name="url"
							onChange={imgUpload}
							// onChange={s3ImgUpload}
						/>
					</div>
				</Col>
				{where === "playlist" && playlistData.url !== "" && (
					<Space size="middle" className="image-upload">
						<label title="remove">
							<div className="show-and-edit-div">
								<img
									src={playlistData.url}
									// src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
									className="image-upload"
									style={{ opacity: "0.6" }}
									onMouseEnter={() => setEdithover(true)}
								></img>
								{edithover && (
									<span
										className="edit-icon"
										onClick={() => {
											remove_question_file(null);
										}}
									>
										{bin}
									</span>
								)}
							</div>
						</label>
					</Space>
				)}
			</Row>
		</>
	);
};

export default UploadCustom;
