import { ApiConnector } from '../../axios/apiClient';
import { S3BUCKET_URL } from '../../axios/AllBaseUrl';

const S3fileUpload = async (value) => {
    // console.log("value S3fileUpload", value);
    // console.log("s3ImgUpload blob", value.blob);
    try {
        let random_str = Math.random().toString(36).slice(2, 12);
        let yourDate = new Date();
        let format_date = yourDate.toISOString().split("T")[0];
        let current_month = yourDate.toLocaleString("default", {
            month: "short",
        });
        let current_year = new Date().getFullYear();
        const files = value;
        let S3BucketData = {
            fileName: random_str,
            fileType: files.type,
            product: `QuestionBank/answer/studentAnswer`,
            uploadDate: `${current_year}/${current_month}/${format_date}`,
        };
        const S3Bucket = await ApiConnector(
            "post",
            S3BUCKET_URL,
            S3BucketData
        );
        let config = {
            maxBodyLength: Infinity,
        };
        let S3BucketUpload = await ApiConnector(
            "put",
            `${S3Bucket?.data?.uploadUrl}`,
            files,
            {
                "Content-Type": files.type,
            },
            config,
            false
        );
        if (S3Bucket) {
            return S3Bucket.data.fileUrl;
            // {
            // ok: true,
            // data: {
            // 	file_type: files.type,
            // 	file_name: files.name,
            // url: S3Bucket.data.fileUrl,
            // },
        }
    } catch (error) {
        console.log("Something goes wrong");
        return {
            ok: false,
            data: {},
        };
    }
}

export default S3fileUpload