import React, { useState } from "react";
import {
	Layout,
	Button,
	Typography,
	Card,
	Form,
	Input,
	Checkbox,
	Row,
	Col,
	Select,
} from "antd";
import logo3 from "../assets/images/google.png";

import { Link, useNavigate } from "react-router-dom";

import { ApiConnector } from "../axios/apiClient";
import CustomToast from "../utlis/toast/CustomToast";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { USER_REGISTER } from "../axios/AllBaseUrl";

const { Option } = Select;

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

interface Formdata {
	name: string;
	email: string;
	phone: string;
	password: string;
	confirm: string;
	prefix: string;
	remember: boolean;
}
const SignUp: React.FC = () => {
	let navigate = useNavigate();
	const [data, setData] = useState<Formdata>({
		name: "",
		email: "",
		phone: "",
		password: "",
		confirm: "",
		prefix: "+91",
		remember: true,
	});

	const handle = (e) => {
		//debugger;
		setData({ ...data, [e.target.name]: e.target.value });
	};
	const handleChange = (value: string) => {
		setData({ ...data, prefix: value });
	};

	const prefixSelector = (
		<Form.Item noStyle>
			<Select
				defaultValue={data.prefix}
				style={{ width: 100 }}
				onChange={handleChange}
			>
				<Option value="+91">+91</Option>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);
	const termCheck = (e: CheckboxChangeEvent) => {
		setData({ ...data, remember: e.target.checked });
	};
	const Register = async () => {
		let isFormOk = true;
		if (!data.remember) {
			isFormOk = false;
			CustomToast("error", "Please Accept terms & conditions");
		}
		if (
			data.name.length === 0 ||
			data.email.length === 0 ||
			data.password.length === 0 ||
			data.phone.length === 0 ||
			data.confirm.length === 0
		) {
			isFormOk = false;
			CustomToast("error", "Please fill out all required fields");
		}

		if (isFormOk) {
			let res = await ApiConnector("post", `${USER_REGISTER}`, {
				user_name: data.name,
				user_email: data.email,
				user_phone: data.phone,
				user_pass: data.password,
				user_conpass: data.confirm,
				isFromOtherSite: 0,
			});
			if (res.data.ok === false) {
				CustomToast("error", res.data.why);
				// Object.keys(res.data.error).forEach((e) => {
				// 	CustomToast("error", res.data.error[e]);
				// });
			}
			if (res.data.ok === true) {
				localStorage.setItem("token", JSON.stringify(res?.data?.data));
				CustomToast("success", res.data.why);
				navigate("/home");
			}
		}
	};
	return (
		<>
			<Layout
				className="layout-default ant-layout layout-sign-up"
				style={{
					height: "100vh",
				}}
			>
				<Header>
					<div className="header-col header-brand">
						{/* <h5>Muse Dashboard</h5> */}
					</div>
				</Header>
				<Content className="p-0">
					<Row justify="center" align="middle">
						<Col
							style={{ width: "500px", padding: "0 10px 0 10px" }}
						>
							<Card
								className="card-signup header-solid  ant-card pt-0"
								title={<h5>Register </h5>}
								bordered={false}
							>
								{/* <div className="sign-up-gateways">
									<Button>
										<img src={logo3} alt="logo 3" />
									</Button>
								</div>
								<p className="text-center my-25 font-semibold text-muted">
									Or
								</p> */}
								<Form
									name="basic"
									initialValues={{ remember: true }}
									className="row-col"
								>
									<Form.Item
										name="Name"
										rules={[
											{
												required: true,
												message:
													"Please input your Name!",
											},
										]}
									>
										<Input
											onChange={handle}
											name="name"
											placeholder="Name"
										/>
									</Form.Item>
									<Form.Item
										name="email"
										rules={[
											{
												required: true,
												type: "email",
												message:
													"Please enter a valid email address",
											},
										]}
									>
										<Input
											onChange={handle}
											name="email"
											placeholder="email"
										/>
									</Form.Item>
									<Form.Item
										name="phone"
										rules={[
											{
												min: 10,
												max: 10,
												required: true,
												message:
													"Please input your contact number",
											},
										]}
									>
										<Input
											onChange={handle}
											name="phone"
											addonBefore={prefixSelector}
											placeholder="Phone Number"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
									<p className="password-msg">** Password must be 8 character **</p>
									<Form.Item
										name="password"
										rules={[
											{
												required: true,
												message:
													"Please input your password!",
											},

											{
												min: 8,
												message:
													"Password must have a minimum length of 8",
											},
										]}
									>
										
										<Input.Password
											onChange={handle}
											name="password"
											type="password"
											placeholder="Password"
										/>
									</Form.Item>

									<Form.Item
										name="confirm"
										dependencies={["password"]}
										hasFeedback
										rules={[
											{
												required: true,
												message:
													"Please confirm your password!",
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (
														!value ||
														getFieldValue(
															"password"
														) === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														"The two passwords that you entered do not match!"
													);
												},
											}),
										]}
									>
										<Input.Password
											onChange={handle}
											name="confirm"
											placeholder="ConfirmPassword"
										/>
									</Form.Item>

									<Form.Item
										name="remember"
										valuePropName="checked"
									>
										<Checkbox
											// name="remember"
											onChange={termCheck}
										>
											I agree the{" "}
											<a
												href="#pablo"
												className="font-bold text-dark"
											>
												Terms and Conditions
											</a>
										</Checkbox>
									</Form.Item>

									<Form.Item>
										<Button
											style={{ width: "100%" }}
											type="primary"
											htmlType="submit"
											onClick={Register}
										>
											SIGN UP
										</Button>
									</Form.Item>
								</Form>
								<p className="font-semibold text-muted text-center">
									Already have an account?{" "}
									<Link
										to="/"
										className="font-bold text-dark"
									>
										Sign In
									</Link>
								</p>
							</Card>
						</Col>
					</Row>
				</Content>
				<Footer>
					<p className="copyright">
						{" "}
						Copyright &copy; 2022{" "}
						<a href="#!">Adhayan Digital Pvt. Ltd.</a>{" "}
					</p>
				</Footer>
			</Layout>
		</>
	);
};

export default SignUp;
