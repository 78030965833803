import { Modal } from "antd";
import React from "react";
import AddSubQuesCard from "../create/AddSubQuesCard";
import CreateQuestionComponent from "../create/CreateQuestionComponent";

const AddSubQuestionModal = (prop: any) => {
	const { open, setOpen, heading, subShow, SetSubShow, preview } = prop;
	return (
		<>
			<Modal
				centered
				open={open}
				onOk={function (event) {
					SetSubShow(true);
					setOpen(false);
				}}
				onCancel={() => setOpen(false)}
				width={1000}
			>
				<CreateQuestionComponent
					heading={heading}
					subShow={subShow}
					allButton={false}
					ques_type="sub"
				/>
			</Modal>
		</>
	);
};

export default AddSubQuestionModal;
