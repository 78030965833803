import React from "react";

const EditIconSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 100 100"
				x="0px"
				y="0px"
                width={24}
                height={24}
                fill="currentColor"
			>
				<path d="M73.73,20.35,41.55,52.53A2,2,0,0,0,41,53.66l-2.28,16a2,2,0,0,0,2,2.28l.25,0,16.25-2a2,2,0,0,0,1.17-.57L90.53,37.15a11.88,11.88,0,1,0-16.8-16.8ZM56,66,43,67.62l1.82-12.74L72.63,27.1,83.78,38.25ZM87.7,34.32l-1.1,1.1L75.46,24.28l1.1-1.1A7.88,7.88,0,0,1,87.7,34.32Z" />
				<path d="M6,10.5a2,2,0,0,0,2,2H72a2,2,0,1,0,0-4H8A2,2,0,0,0,6,10.5Z" />
				<path d="M8,32.25H52a2,2,0,0,0,0-4H8a2,2,0,1,0,0,4Z" />
				<path d="M8,52H32a2,2,0,0,0,0-4H8a2,2,0,0,0,0,4Z" />
				<path d="M8,71.75H32a2,2,0,0,0,0-4H8a2,2,0,1,0,0,4Z" />
				<path d="M8,91.5H72a2,2,0,0,0,0-4H8a2,2,0,1,0,0,4Z" />
			</svg>
		</>
	);
};

export default EditIconSvg;
