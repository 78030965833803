import React from "react";
import ExcelSvg from "../svg/ExcelSvg";
import * as XLSX from "xlsx";
import CustomToast from "../../utlis/toast/CustomToast";

const ExcelUpload = ({ data, setData, setLoadingData }) => {
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setLoadingData({
				loading: true,
				uploaded: false,
			});
			const file = event.target.files?.[0];
			const reader = new FileReader();
			if (!file) return;

			reader.onload = (e) => {
				try {
					const binaryStr = e.target?.result as string;
					const workbook = XLSX.read(binaryStr, { type: "binary" });
					// Assuming the first sheet is the one we want to read
					const worksheet = workbook.Sheets[workbook.SheetNames[0]];
					const jsonData = XLSX.utils.sheet_to_json<string[]>(
						worksheet,
						{
							header: 1,
						}
					);
					// Convert array of arrays to array of objects
					const keys: string[] = jsonData[0]?.map((key: string) =>
						key?.trim()
					);

					// Validate keys

					if (
						!keys.includes("tags") ||
						!keys.includes("questionId")
					) {
						CustomToast("error", "Invalid excel");
						throw new Error(
							"Invalid Excel file: Missing required headers."
						);
					}
					// Assuming first row contains the keys
					const formattedData: any[] = jsonData
						.slice(1)
						?.map((row: string[]) => {
							const rowData: any = {};
							keys.forEach((key, index) => {
								const value = row[index];
								// Remove extra quotes from the value if it's a string with quotes
								rowData[key] =
									typeof value === "string" &&
									value.startsWith('"') &&
									value.endsWith('"')
										? value.slice(1, -1) // Remove leading and trailing quotes
										: value;
								if (
									typeof value === "string" &&
									value?.includes(",") &&
									key == "tags"
								) {
									rowData[key] = value
										.split(",")
										?.map((tag: string) => tag?.trim());
								}
							});
							return rowData;
						});
					const hasInvalidRows = formattedData.some(
						(row) => !row.tags || !row.questionId
					);
					if (hasInvalidRows) {
						CustomToast("error", "Invalid excel");
						throw new Error(
							"Invalid Excel file: 'tags' or 'questionId' cannot be blank."
						);
					}
					setLoadingData({
						loading: false,
						uploaded: true,
					});
					setData(formattedData);
				} catch (error) {
					setData([]);
					setLoadingData({
						loading: false,
						uploaded: true,
					});
					console.log("i am error ", error);
				}
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			console.log("error", error);
		}
	};
	// console.log("excel data", data);

	return (
		<>
			<div style={{ margin: "20px" }}>
				<input
					type="file"
					accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={(event) => handleFileChange(event)}
					style={{ display: "none" }}
					id="upload-button"
					// onClick={() =>
					// 	setLoadingData({
					// 		loading: true,
					// 		uploaded: false,
					// 	})
					// }
				/>
				<label htmlFor="upload-button" style={{ cursor: "pointer" }}>
					<div
						style={{
							display: "inline-block",
							position: "relative",
						}}
					>
						<ExcelSvg />
					</div>

					<div
						style={{
							color: "#217346",
							fontWeight: "bold",
						}}
					>
						Upload Excel File
					</div>
				</label>
			</div>
		</>
	);
};

export default ExcelUpload;
