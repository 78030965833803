import axios from "axios";
import { BASE_URL_FORGOTPASSWORD } from "./AllBaseUrl";

export const UserForgotPassword = async (formdata) => {
	return await axios.post(`${BASE_URL_FORGOTPASSWORD}`, {
		user_email: formdata.email,
	});
};

export const ApiConnector = async (
	method: string,
	url: string,
	data: any = {},
	headers = {},
	config = {},
	auth = true
) => {
	let token: any;
	let gettoken: any = localStorage.getItem("token");

	if (gettoken !== undefined && gettoken !== "undefined") {
		token = JSON.parse(gettoken);
	}
	let res = await axios({
		...config,
		method: `${method}`,
		url: `${url}`,
		data: data,
		headers: auth
			? { ...headers, Authorization: `Bearer ${token}` }
			: {
					...headers,
			  },
	});

	//   console.log("i am api response",res.data)
	return res;
};
