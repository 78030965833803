import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./reducers/counterSlice";
import SuggestionQuestionSlice, {
  initialState,
} from "./reducers/SuggestionQuestionSlice";
// import sagaMiddleware from "redux-saga";
import SearchQuestionSlice from "./reducers/SearchQuestionSlice";
import questionSlice from "./reducers/questionSlice";
import PlaylistSlice from "./reducers/PlaylistSlice";
import SearchPlaylistSlice from "./reducers/SearchPlaylistSlice";

// import RootSaga from "./saga/RootSaga";

// const saga = sagaMiddleware();


export const store = configureStore({
  reducer: {
    suggestion: SuggestionQuestionSlice,
    question: questionSlice,
    search: SearchQuestionSlice,
    playlist: PlaylistSlice,
    playlistSearch:SearchPlaylistSlice,
  },
  // middleware: [saga],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['question/getquestion/fulfilled', 'search/postSearch/fulfilled', 'playlistData/PlaylistFetch/fulfilled', 'playlists/ViewPlaylist/fulfilled'],
        // , 'playlist/PlaylistFetch/fulfilled'
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['question.question.0', 'search.search.0', 'playlist.playlistData.0', 'playlist.playlists.0'],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ReturnType<typeof store.dispatch>;
