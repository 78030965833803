import React, { Component, useEffect, useState } from "react";
import {
	Layout,
	Menu,
	Button,
	Typography,
	Card,
	Form,
	Input,
	Checkbox,
	Row,
	Col,
	Select,
	message,
} from "antd";

import logo1 from "../assets/images/logos-facebook.svg";
import logo2 from "../assets/images/logo-apple.svg";
import logo3 from "../assets/images/google.png";
import {
	DribbbleOutlined,
	TwitterOutlined,
	InstagramOutlined,
	GithubOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { url } from "inspector";
import Password from "antd/lib/input/Password";
import { Link, useNavigate } from "react-router-dom";
// import { ResetPassword } from "../axios/apiClient";

const { Option } = Select;

const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const template = [
	<svg
		data-v-4ebdc598=""
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			data-v-4ebdc598=""
			d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
			fill="#111827"
			className="fill-muted"
		></path>
		<path
			data-v-4ebdc598=""
			d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
			fill="#111827"
			className="fill-muted"
		></path>
		<path
			data-v-4ebdc598=""
			d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
			fill="#111827"
			className="fill-muted"
		></path>
	</svg>,
];
const Reset: React.FC = () => {
	let navigate = useNavigate();

	const [data, setData] = useState({
		oldpassword: "",
		newpassword: "",
		confirm: "",
	});
	const handle = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
		// console.log(data);
	};

	const Click = async () => {
		// let res = await ResetPassword(data);
		// console.log("res");
	};

	return (
		<>
			<Layout
				className="layout-default ant-layout layout-sign-up"
				style={{
					height: "100vh",
				}}
			>
				<Content className="p-0">
					<Row>
						<Col
							style={{ width: "500px", padding: "0 10px 0 10px" }}
						>
							<Card
								className="card-signup header-solid  ant-card pt-0"
								title={<h5>Reset Password</h5>}
								bordered={false}
							>
								<Form
									name="basic"
									initialValues={{ remember: true }}
									className="row-col"
								>
									<Form.Item
										name="oldpassword"
										rules={[
											{
												required: true,
												message:
													"Please input your password!",
											},

											{
												min: 8,
												message:
													"Password must have a minimum length of 8",
											},
											// {
											// 	pattern: new RegExp(
											// 		"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
											// 	),
											// 	message:
											// 		"Password must contain at least one lowercase letter, uppercase letter, number, and special character",
											// },
										]}
									>
										<Input.Password
											name="oldpassword"
											type="password"
											placeholder="Old Password"
											onChange={handle}
										/>
									</Form.Item>

									<Form.Item
										name="newpassword"
										rules={[
											{
												required: true,
												message:
													"Please input your password!",
											},

											{
												min: 8,
												message:
													"Password must have a minimum length of 8",
											},
											// {
											// 	pattern: new RegExp(
											// 		"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
											// 	),
											// 	message:
											// 		"Password must contain at least one lowercase letter, uppercase letter, number, and special character",
											// },
										]}
									>
										<Input.Password
											name="newpassword"
											type="password"
											placeholder="New Password"
											onChange={handle}
										/>
									</Form.Item>

									<Form.Item
										name="confirm"
										dependencies={["newpassword"]}
										hasFeedback
										rules={[
											{
												required: true,
												message:
													"Please confirm your password!",
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (
														!value ||
														getFieldValue(
															"newpassword"
														) === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														"The two passwords that you entered do not match!"
													);
												},
											}),
										]}
									>
										<Input.Password
											name="confirm"
											placeholder="ConfirmPassword"
											onChange={handle}
										/>
									</Form.Item>

									<Form.Item>
										<Button
											style={{ width: "100%" }}
											type="primary"
											htmlType="submit"
											onClick={Click}
										>
											Reset
										</Button>
									</Form.Item>
								</Form>
							</Card>
						</Col>
					</Row>
				</Content>
				<Footer>
					<p className="copyright">
						{" "}
						Copyright &copy; 2022{" "}
						<a href="#!">Adhayan Digital Pvt. Ltd.</a>{" "}
					</p>
				</Footer>
			</Layout>
		</>
	);
};
export default Reset;
