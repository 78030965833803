import React from "react";

const PenSizeIconSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 182 182"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M107 108C107 131.748 87.7482 151 64 151C40.2518 151 21 131.748 21 108C21 84.2518 40.2518 65 64 65C87.7482 65 107 84.2518 107 108ZM97 108C97 126.225 82.2254 141 64 141C45.7746 141 31 126.225 31 108C31 89.7746 45.7746 75 64 75C82.2254 75 97 89.7746 97 108Z"
				/>
				<path d="M136 151C149.255 151 160 140.255 160 127C160 113.745 149.255 103 136 103C122.745 103 112 113.745 112 127C112 140.255 122.745 151 136 151Z" />
			</svg>
		</>
	);
};

export default PenSizeIconSvg;
