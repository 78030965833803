import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import SuggestedQuestion from "../create/SuggestedQuestion";
import DonePlaylist from "./DonePlaylist";
import Playlistnamesettings from "./Playlistnamesettings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PlaylistQuestion from "./PlaylistQuestion";
import HomeSearch from "../Search/HomeSearch";
import AddFloatingButton from "./AddFloatingButton";
import FlagLocalhost from "../../Localhost/FlagLocalhost";
import { PageDataInterface } from "../../utlis/Interface/homeSearch";

const CreatePlaylist = () => {
	const [refresh, setRefresh] = useState<boolean>(true);
	const [pageData, setPageData] = useState<PageDataInterface>({
		page: 1,
		pageSize: 10,
	});
	const [isBlurred, setIsBlurred] = useState("");

	const [searchTags, setSearchTags] = useState<any>([]);
	const dispatch = useDispatch();

	const playlist: any = useSelector((state: RootState) => state.playlist);

	if (playlist.mode === 1) {
		// dispatch(playlisteditmode(playlist.playlist_to_edit))
	}
	let flag = FlagLocalhost();

	const handleBeforeUnload = (event) => {
		if (refresh) {
			const message =
				"Are you sure you want to refresh? Your changes will be lost.";
			event.returnValue = message;
			setRefresh(false);
			return message;
		}
	};
	const handlePageChange = (page, pageSize) => {
		setPageData({ page: page, pageSize: pageSize });
	};

	useEffect(() => {
		if (refresh && playlist.mode === 1) {
			window.addEventListener("beforeunload", handleBeforeUnload);
			setRefresh(true);
			return () => {
				window.removeEventListener("beforeunload", handleBeforeUnload);
			};
		}
	}, [refresh]);
	useEffect(() => {
		setIsBlurred("");
	}, []);

	return (
		<div style={{ filter: isBlurred }}>
			<Row gutter={[24, 6]}>
				<Col xl={12} xs={24} md={12}>
					<div className="playlist-div">
						<Playlistnamesettings />
						{playlist.mode === 0 &&
						playlist.question_ids.length !== 0 ? (
							<PlaylistQuestion isBlurred={isBlurred} setIsBlurred={setIsBlurred}/>
						) : playlist.mode === 1 &&
						  playlist.playlist_to_edit.question_ids.length !==
								0 ? (
							<PlaylistQuestion isBlurred={isBlurred} setIsBlurred={setIsBlurred} />
						) : (
							""
						)}
					</div>
				</Col>

				<Col xl={12} xs={24} md={12}>
					<div>
						<Card
							bordered={true}
							className="playlist-settings-saggestedcard"
						>
							<Row gutter={[24, 6]}>
								{/* <Input
									allowClear
									style={{ borderRadius: "20px" }}
									placeholder=" Type something..."
									size="middle"
									suffix={suffix}
									onChange={handleChange}
								/> */}
								<Col xl={24} md={24} xs={24}>
									<HomeSearch
										searchMode={0}
										setShowQuestion={() => {}}
										searchTags={searchTags}
										setSearchTags={setSearchTags}
										pageData={pageData}
										playlistPageData={pageData}
									/>
								</Col>
							</Row>
							<Row
								gutter={[24, 6]}
								className="playlist-search-card"
							>
								<Col xl={24} xs={24} md={24}>
									<SuggestedQuestion
										handlePageChange={handlePageChange}
										pageData={pageData}
									/>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
			<Row gutter={[24, 6]} className="playlist-ok">
				{playlist.question_ids.length !== 0 && (
					<DonePlaylist
						isBlurred={isBlurred}
						setIsBlurred={setIsBlurred}
					/>
				)}
				{playlist.mode === 1 && playlist.question_ids.length === 0 && (
					<DonePlaylist
						isBlurred={isBlurred}
						setIsBlurred={setIsBlurred}
					/>
				)}
			</Row>
			<Row
				gutter={[24, 6]}
				style={{ position: "fixed", bottom: "4vh", right: "4vw" }}
			>
				{flag?.isFromEvidya === 1 && <AddFloatingButton />}
			</Row>
		</div>
	);
};

export default CreatePlaylist;
