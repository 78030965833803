import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Image, Input } from "antd";
import { ParseHtmlToText } from "../../../utlis/parseHtmlTotext/ParseHtmlToText";
const gridStyle_img: React.CSSProperties = {
	height: "50px",
	width: "50px",
};

const QuestionTitle = ({
	QuestionNumber,
	data,
	onchangeMarksValue,
	marksArray,
	editState,
}) => {
	// console.log("data", data);

	function compareArrays(arr1, arr2) {
		let results: any = [];
		let questionMark = Number(data?.marks);

		// Iterate through each element of the arrays
		for (let i = 0; i < arr1.length; i++) {
			let lowerCaseArr1 = arr1[i]?.toLowerCase();
			let lowerCaseArr2 = arr2[i]?.toLowerCase();
			// Check if the element of arr1 is the beginning substring of the element of arr2
			if (lowerCaseArr2 === lowerCaseArr1) {
				// results.push(true);
				let number: any = questionMark / arr1?.length;
				// let v = (Math.round(number * 100) / 100).toFixed(2);
				results.push(number);
			} else {
				// results.push(false);
				results.push(0);
			}
		}

		return results;
	}

	// console.log("editState", editState?.mode);

	let [rightAnswer, setRightNumber] = useState<any>("");
	let [marks, setMarks] = useState(false);
	let [blankNumber, setBlankNumber] = useState<number>(0);
	useEffect(() => {
		if (data && data?.questions?.question_type !== "FILL IN THE BLANK") {
			// console.log("data", data?.questions?.question_type);
			setMarks(true);
			// let newMarksArray = [...marksArray];
			data?.option?.map((elm) => {
				if (
					elm?.isCorrect === 1 &&
					// elm?.OptionTranslation[0]?.title ===
					// 	data?.answers[0]?.answer_data?.answer
					elm?._id == data?.answers[0]?.answer_data?.option_id
				) {
					// setRightNumber(elm?.OptionTranslation[0]?.title);
					setRightNumber(elm?._id);
				}
			});
		}
		if (data && data?.questions?.question_type === "FILL IN THE BLANK") {
			let answerData = JSON.parse(data?.answers[0]?.answer_data?.answer);
			let correctAnswer = data?.option?.map((elm) => {
				return ParseHtmlToText(elm?.OptionTranslation[0]?.title);
			});
			let comparisonResults = compareArrays(correctAnswer, answerData);
			// console.log("Comparison results:", comparisonResults);
			let oneQuestionMark = comparisonResults.reduce(
				(accumulator, currentValue) => accumulator + currentValue,
				0
			);
			let roundMark = (Math.round(oneQuestionMark * 100) / 100).toFixed(
				2
			);
			// console.log("oneQuestionMark", oneQuestionMark);
			setBlankNumber(Number(roundMark));
			setMarks(true);
		}
	}, [data]);
	useEffect(() => {
		if (marks) {
			editState?.mode === 0
				? data && data?.questions?.question_type === "FILL IN THE BLANK"
					? onchangeMarksValue(
							QuestionNumber,
							blankNumber,
							data?.answers[0]?._id,
							data?.q_id,
							data?.editData[0]?.remarksURL
								? data?.editData[0]?.remarksURL
								: data?.answers[0]?.answer_data?.url
					  )
					: onchangeMarksValue(
							QuestionNumber,
							rightAnswer &&
								rightAnswer ==
									data?.answers[0]?.answer_data?.option_id
								? Number(data?.marks)
								: 0,
							data?.answers[0]?._id,
							data?.q_id,
							data?.editData[0]?.remarksURL
								? data?.editData[0]?.remarksURL
								: data?.answers[0]?.answer_data?.url
					  )
				: onchangeMarksValue(
						QuestionNumber,
						data?.editData[0]?.marks
							? Number(data?.editData[0]?.marks)
							: 0,
						data?.answers[0]?._id,
						data?.q_id,
						data?.editData[0]?.remarksURL
							? data?.editData[0]?.remarksURL
							: data?.answers[0]?.answer_data?.url
				  );
		}
	}, [data, marks]);

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "end",
					alignItems: "center",
					gap: "0.5rem",
					fontSize: "0.85rem",
					color: "#a0a0a0",
					fontWeight: "500",
				}}
			>
				{editState?.mode === 0 && (
					<span>
						{data?.questions?.question_type === "SHORT ANSWER" ||
						data?.questions?.question_type === "RESEARCH" ||
						data?.questions?.question_type ===
							"FILL IN THE BLANK" ||
						data?.questions?.question_type === "IQ" ? (
							<span style={{ color: "red" }}>Marks Input</span>
						) : (
							<span>Marks</span>
						)}
						:
					</span>
				)}
				{editState?.mode === 1 && (
					<span>
						<span style={{ color: "red" }}>Edit Marks</span>:
					</span>
				)}
				{editState?.mode === 2 && (
					<span>
						<span> Marks</span> :
					</span>
				)}
				<aside
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "center",
						gap: "0.25rem",
						border: "2px dashed #e8e8e8",
						borderRadius: "0.35rem",
						padding: "0.25rem 0.5rem",
					}}
				>
					<span>
						{editState?.mode === 0 &&
							(data?.questions?.question_type === "MCQ" ||
								data?.questions?.question_type ===
									"TRUE FALSE" ||
								data?.questions?.question_type ===
									"ANIMATION") && (
								<Input
									bordered={false}
									type="text"
									max={data?.marks}
									placeholder="0"
									style={{
										width: "20px",
										height: "20px",
										textAlign: "center",
										padding: "0",
									}}
									// option_id
									value={
										rightAnswer &&
										rightAnswer ==
											data?.answers[0]?.answer_data
												?.option_id
											? Number(data?.marks)
											: 0
									}
									readOnly
								/>
							)}
						{editState?.mode === 0 &&
							data?.questions?.question_type ===
								"FILL IN THE BLANK" && (
								<Input
									bordered={false}
									type="text"
									max={data?.marks}
									placeholder="0"
									style={{
										width: "20px",
										height: "20px",
										textAlign: "center",
										padding: "0",
									}}
									// option_id
									value={
										marksArray?.find(
											(elm) =>
												QuestionNumber ===
												elm.questionNumber
										)?.marks || ""
									}
									// value={blankNumber}
									// readOnly

									onChange={(e) => {
										const newValue = e.target.value;
										const numericValue =
											parseFloat(newValue);

										if (
											!isNaN(numericValue) &&
											numericValue <= data?.marks
										) {
											// Update the state with the new valid value
											onchangeMarksValue(
												QuestionNumber,
												newValue,
												data?.answers[0]?._id,
												data?.q_id
											);
										} else if (newValue === "") {
											// If the input is empty, use the initial value
											onchangeMarksValue(
												QuestionNumber,
												0,
												data?.answers[0]?._id,
												data?.q_id
											);
										}
										// You can optionally add a validation message or handle other cases here.
									}}
								/>
							)}
						{editState?.mode === 0 &&
							(data?.questions?.question_type ===
								"SHORT ANSWER" ||
								data?.questions?.question_type === "RESEARCH" ||
								data?.questions?.question_type === "IQ") && (
								<Input
									bordered={false}
									type="text"
									max={data?.marks}
									placeholder="0"
									style={{
										width: "20px",
										height: "20px",
										textAlign: "center",
										padding: "0",
									}}
									value={
										marksArray?.find(
											(elm) =>
												QuestionNumber ===
												elm.questionNumber
										)?.marks || ""
									}
									onChange={(e) => {
										const newValue = e.target.value;
										const numericValue =
											parseFloat(newValue);

										if (
											!isNaN(numericValue) &&
											numericValue <= data?.marks
										) {
											// Update the state with the new valid value
											onchangeMarksValue(
												QuestionNumber,
												newValue,
												data?.answers[0]?._id,
												data?.q_id
											);
										} else if (newValue === "") {
											// If the input is empty, use the initial value
											onchangeMarksValue(
												QuestionNumber,
												0,
												data?.answers[0]?._id,
												data?.q_id
											);
										}
										// You can optionally add a validation message or handle other cases here.
									}}
								/>
							)}

						{editState?.mode === 1 && (
							<Input
								bordered={false}
								type="text"
								max={data?.marks}
								placeholder="0"
								style={{
									width: "20px",
									height: "20px",
									textAlign: "center",
									padding: "0",
								}}
								value={
									marksArray?.find(
										(elm) =>
											QuestionNumber ===
											elm.questionNumber
									)?.marks || ""
								}
								onChange={(e) => {
									const newValue = e.target.value;
									const numericValue = parseFloat(newValue);

									if (
										!isNaN(numericValue) &&
										numericValue <= data?.marks
									) {
										// Update the state with the new valid value
										onchangeMarksValue(
											QuestionNumber,
											newValue,
											data?.answers[0]?._id,
											data?.q_id
										);
									} else if (newValue === "") {
										// If the input is empty, use the initial value
										onchangeMarksValue(
											QuestionNumber,
											0,
											data?.answers[0]?._id,
											data?.q_id
										);
									}
									// You can optionally add a validation message or handle other cases here.
								}}
							/>
						)}

						{editState?.mode === 2 && (
							<Input
								bordered={false}
								type="text"
								max={data?.marks}
								placeholder="0"
								style={{
									width: "20px",
									height: "20px",
									textAlign: "center",
									padding: "0",
								}}
								value={
									marksArray?.find(
										(elm) =>
											QuestionNumber ===
											elm.questionNumber
									)?.marks || ""
								}
								disabled
							/>
						)}
					</span>
					<span>/</span>
					<span>{data?.marks}</span>
				</aside>
			</div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "start",
					alignItems: "start",
					gap: "0.5rem",
					fontSize: "1.25rem",
				}}
				className="displayQuestion"
			>
				<div
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "start",
						gap: "0.5rem",
						fontSize: "1.25rem",
						flex: "1 1 0%",
					}}
					className="answerQuestionTitle"
				>
					<span style={{ fontSize: "1.15rem", fontWeight: "600" }}>
						{`Q ${QuestionNumber} :`}
					</span>
					<aside
						style={{
							// display: "flex",
							textAlign: "left",
							columnGap: "2px",
							flex: "1 1 0%",
						}}
					>
						{/* Identify the bird the picture. */}
						{parse(data?.questions?.question_traslation[0]?.title)}
						{data?.file &&
							data?.file.map((imgValue, i) => {
								return (
									<React.Fragment key={i}>
										<Image
											src={imgValue?.url}
											style={gridStyle_img}
										/>
									</React.Fragment>
								);
							})}
					</aside>
				</div>
			</div>
		</>
	);
};

export default QuestionTitle;
