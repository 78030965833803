import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Form, Space, Input } from "antd";
import createQuesContext from "../../context/createQuestion/createQuesContext";

const { TextArea } = Input;

const AddDescription = (prop: any) => {
	const { placeholder, level } = prop;
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [quesTrans, setQuesTrans] = useState<any>({});

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quData;
		quData =
			currentQState === 0 
				? state.question_traslation.description
				: currentQState === 1
				? state.sub_question_traslation.description
				: state.question.subQuestions[state.edit_id]
						.question_traslation[0].description;

		setQuesTrans(quData);
	};
	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	const getDescriptionValue = (e: any) => {
		if (state.mode === 0) {
			dispatch({ type: "description", value: e.target.value });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_description", value: e.target.value });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_sub_description",
				value: e.target.value,
				id: state.edit_id,
			});
		}
	};
	return (
		<>
			<Form
				layout="vertical"
				className="row-col full-width"
				// name="primary_question"
			>
				<Space
					direction="vertical"
					size="middle"
					style={{
						display: "flex",
						paddingBottom: "40px",
					}}
				>
					<Form.Item label={level}>
						<TextArea
							value={quesTrans}
							// value={
							// 	(state.mode === 0 &&
							// 		state.question_traslation.description) ||
							// 	(state.mode === 1 &&
							// 		state.sub_question_traslation
							// 			.description) ||
							// 	(state.mode === 2 &&
							// 		state.question.subQuestions[state.edit_id]
							// 			.question_traslation[0].description)
							// }
							showCount
							placeholder={placeholder}
							maxLength={100}
							style={{ height: 100 }}
							onChange={getDescriptionValue}
						/>
					</Form.Item>
				</Space>
			</Form>
		</>
	);
};

export default AddDescription;
