import React from "react";

const DownArrow = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				viewBox="0 0 100 100"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="4"
			>
				<path d="M67.9,45L51,61.9c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6L32.1,45.8c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0  l14.7,14.7l15.5-15.5c0.8-0.8,2-0.8,2.8,0C68.7,42.9,68.7,44.2,67.9,45z M88,50c0,21-17,38-38,38S12,71,12,50s17-38,38-38  S88,29,88,50z M84,50c0-18.8-15.2-34-34-34S16,31.2,16,50s15.2,34,34,34S84,68.8,84,50z" />
			</svg>
		</>
	);
};

export default DownArrow;
