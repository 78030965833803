import { Col, Row, Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import MainQuestionPreview from "./MainQuestionPreview";

import SubQuestionPreview from "./SubQuestionPreview";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import { ApiConnector } from "../../axios/apiClient";
import { ADD_QUESTION } from "../../axios/AllBaseUrl";
import CustomToast from "../../utlis/toast/CustomToast";
import Validate from "../../utlis/validateQuestionform/Validate";
import CreatePlaylistAlertModal from "../modal/CreatePlaylistAlertModal";
import { useNavigate } from "react-router-dom";
import { MCQ, IQ, ANIMATION } from "../../utlis/constants/questionType";

const Preview = () => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [ques, setQues] = useState<any>({});
	let [quesTrans, setQuesTrans] = useState<any>({});
	let [createQuestionRes, setCreateQuesionRes] = useState<
		{ id: String; title: String }[]
	>([]);
	// let [valid, setValid] = useState<any>({});
	const [open, setOpen] = useState(false);
	let navigate = useNavigate();

	let questionData = () => {
		let currentQState = state.mode;
		let quData = currentQState === 0 && state.question;
		let quTransData = currentQState === 0 && state.question_traslation;
		setQues(quData);
		setQuesTrans(quTransData);
	};

	useEffect(() => {
		questionData();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	const validateform = (question, ques_trans) => {
		// console.log(question.question_type);
		let validateRes = Validate(question, ques_trans);
		dispatch({ type: "validateForm", value: validateRes });
		return validateRes;
	};
	const CreateQuestion = async () => {
		let res = await ApiConnector("post", `${ADD_QUESTION}`, [
			state.question,
		]);

		if (res.data.http$.status === 401 && res.data.ok === false) {
			localStorage.removeItem("token");
			navigate("/");
		}
		if (res.data.ok) {
			setCreateQuesionRes(res.data.data);
			dispatch({ type: "open_create_playlist_modal", value: true });
			dispatch({ type: "cleanAllOldData" });
		} else {
			CustomToast("error", "Some error occured");
		}
	};
	const createNewQuestion = () => {
		if (state.mode === 0) {
			dispatch({ type: "addTranslationMainQues" });
		}
		if (state.mode === 1) {
			dispatch({ type: "addTranslationMainQues" });
		}
		if (state.mode === 2) {
			dispatch({ type: "addTranslationMainQues" });
		}
	};
	let validate_on_finish = () => {
		if (state.mode === 0) {
			let res = validateform(ques, quesTrans);

			if (Object.keys(res)?.length === 0) {
				setOpen(true);
			} else {
				for (let val in res) {
					CustomToast("error", res[val]);
				}
			}
		}
		if (state.mode === 1) {
			if (
				state.sub_question_traslation.title !== "<p><br></p>" &&
				state.sub_question_traslation.title !== ""
			) {
				let validateRes = Validate(
					state.subQuestion,
					state.sub_question_traslation
				);
				dispatch({ type: "validateForm", value: validateRes });
				if (Object.keys(validateRes)?.length === 0) {
					setOpen(true);
					dispatch({ type: "fullSubQuestion" });
					dispatch({ type: "addSubInMainQuestion" });
					dispatch({ type: "cleanOldData" });
					if (
						state.subQuestion.question_type === MCQ ||
						state.subQuestion.question_type === IQ
					) {
						dispatch({ type: "onchangeSubMcq" });
						// dispatch({ type: "clearSetOptionvalue" });
					}
					if (state.subQuestion.question_type === ANIMATION) {
						dispatch({ type: "onchangeSubAnimation" });
					}
				} else {
					for (let val in validateRes) {
						CustomToast("error", validateRes[val]);
					}
				}
			} else {
				setOpen(true);
			}
		}
		if (state.mode === 2) {
			let res: object[] = state.question?.subQuestions?.map(
				(subq, index) => {
					let validateRes = Validate(
						subq,
						subq.question_traslation[0]
					);
					return validateRes;
				}
			);
			dispatch({ type: "edit_validation", value: res });
			let error_res: Number[] = res?.map((error, index) => {
				if (Object.keys(error)?.length !== 0) {
					return 1;
				} else {
					return 0;
				}
			});
			if (!error_res.includes(1)) {
				dispatch({ type: "editToSubQues" });
				setOpen(true);
			} else {
				CustomToast("error", "Mandetory field cant be blank !");
			}

			// dispatch({ type: "validateForm", value: validateRes });
			// if (Object.keys(validateRes)?.length === 0) {
			// 	setOpen(true);
			// }
		}
	};

	useEffect(() => {
		if (state.question.question_traslation.length > 0) {
			CreateQuestion();
		}
	}, [state.question.question_traslation]);

	return (
		<>
			<CreatePlaylistAlertModal data={createQuestionRes} />
			<Button
				type="default"
				className="preview-btn"
				onClick={validate_on_finish}
			>
				Finish
			</Button>

			<Modal
				title="Question Preview"
				centered
				open={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				width={1200}
				footer={[
					<Button
						// className="preview-edit-button"
						key="back"
						onClick={() => setOpen(false)}
					>
						Edit
					</Button>,
					<Button
						className="preview-edit-button"
						key="back"
						onClick={() => {
							setOpen(false);
							createNewQuestion();
						}}
					>
						Done
					</Button>,
					// <SendButton />,
				]}
			>
				<Row className="preview-row">
					{/* <Paginations/> */}
					<Col xl={2} md={1} xs={24}></Col>
					<Col xl={20} md={22} xs={24}>
						{/* Options  */}
						<MainQuestionPreview />
						{state.question?.subQuestions?.map((elem, index) => {
							return (
								<SubQuestionPreview
									sub_ques={elem}
									key={index}
									q_no={index + 1}
								/>
							);
						})}

						{/* subQuestion */}

						{/* T/F */}
						{/* <Divider /> */}
						{/* <TrueFalse /> */}

						{/* MCQ */}
						{/* <Divider /> */}

						{/*  table  matching  */}
						{/* <Divider/>
                        <MatchingIndex/> */}
						{/* <Divider /> */}
						{/* <MatchCheck/> */}
						{/* <TableMatching /> */}

						{/* Match 2 */}
						{/* <Divider/>
						<RowMatching/> */}

						{/* fill in the blanks  */}
						{/* <Divider /> */}
						{/* <BlanksFill /> */}

						{/* Short answer  */}
						{/* <Divider /> */}
						{/* <ShortAnswerPreview /> */}

						{/* <Divider/>
						<Subpreview/> */}
					</Col>
					<Col xl={2} md={1} xs={24}></Col>
				</Row>
			</Modal>
		</>
	);
};

export default Preview;
