import React, { useEffect, useState } from "react";
import QuestionTitle from "./QuestionTitle";
import MyCanva from "../../../pages/MyCanva";
import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import S3fileUpload from "../../../utlis/canvas/S3fileUpload";
import RemarksPenSVG from "../../svg/RemarksPenSVG";
import { ParseHtmlToText } from "../../../utlis/parseHtmlTotext/ParseHtmlToText";
import { ApiConnector } from "../../../axios/apiClient";
import { CONVERTIMGTOBASE64 } from "../../../axios/AllBaseUrl";

const CanvasTypeQuestion = ({
	value,
	QuestionNumber,
	onchangeMarksValue,
	setMarksArray,
	marksArray,
	editState,
}) => {
	// console.log("value in CanvasTypeQuestion ", value?.editData);

	const [modal2Open, setModal2Open] = useState<any>({
		modal: false,
		canvasData: "",
	});
	let [answerText, setAnswerText] = useState<string>("");
	const [base64Image, setBase64Image] = useState<any>();
	let canvaStyle = {
		border: "1px solid black",
	};
	let [editObj, setEditObj] = useState<{ Qno: number; edit: boolean }>({
		Qno: 0,
		edit: false,
	});
	const [isFullScreen, setIsFullScreen] = useState(false);

	const [canvasBlob, setCanvasBlob] = useState<any>("");
	const viewFullScreen = () => {
		document.documentElement.requestFullscreen().then(() => {
			setIsFullScreen(true);
			setModal2Open({
				modal: true,
				canvasData: answerText,
			});
		});
	};
	const ExitFullscreen = () => {
		document.exitFullscreen().then(() => {
			setModal2Open({
				modal: false,
				canvasData: "",
			});
			setCanvasBlob("");
			setIsFullScreen(false);
		});
	};

	const confirmModal = async () => {
		try {
			await document.exitFullscreen();

			setModal2Open({
				modal: false,
				canvasData: "",
			});

			setIsFullScreen(false);

			const updatedArray = await Promise.all(
				marksArray?.map(async (item) => {
					if (item.questionNumber === QuestionNumber) {
						const updatedItem = {
							...item,
							blob: await S3fileUpload(canvasBlob),
						};
						return updatedItem;
					}
					return item;
				})
			);

			setMarksArray(updatedArray);
		} catch (error) {
			// Handle any errors here
			console.error("Error during confirmModal:", error);
		}
	};

	const ImgLink =
		value?.editData[0]?.remarksURL && value?.editData[0]?.remarksURL
			? value?.editData[0]?.remarksURL
			: value?.answers[0]?.answer_data?.url;
	// console.log("ImgLink", ImgLink);

	const convertImgToBase64 = async () => {
		try {
			let res = await ApiConnector("post", `${CONVERTIMGTOBASE64}`, {
				url: ImgLink,
			});
			if (res?.data?.ok) {
				setBase64Image(res?.data?.data?.data);
			}
		} catch (error) {
			console.log("img convert error ", error);
		}
	};

	useEffect(() => {
		// parseData();
		let answerData = ParseHtmlToText(
			value?.answers[0]?.answer_data?.answer
		);
		const startIndex = answerData?.indexOf(">") + 1;
		const endIndex = answerData?.lastIndexOf("<");

		// Extract the text content using substr
		const textContent = answerData?.substr(
			startIndex,
			endIndex - startIndex
		);
		if (value?.answers[0]?.answer_data?.answer.includes("<span style=")) {
			setAnswerText(ParseHtmlToText(`copied:  ${answerData}`));
		}
		if (
			value?.answers[0]?.answer_data?.answer &&
			!value?.answers[0]?.answer_data?.answer.includes("<span style=")
			// ||
			// (value?.answers[0]?.answer_data?.url?.length > 0 &&
			// 	value?.answers[0]?.answer_data?.url != undefined)
		) {
			// setAnswerText(textContent);
			setAnswerText(answerData);
		}
		if (!answerData || answerData?.length == 0) {
			setAnswerText("Not attempted");
		}
		setEditObj({ Qno: QuestionNumber, edit: false });
		if (
			(value?.answers[0]?.answer_data?.url &&
				value?.answers[0]?.answer_data?.url?.length > 0 &&
				value?.answers[0]?.answer_data?.url != undefined) ||
			(value?.editData[0]?.remarksURL &&
				value?.editData[0]?.remarksURL?.length > 0)
		) {
			convertImgToBase64();
		}
	}, [value]);

	useEffect(() => {
		// Add event listener for the fullscreen change event
		document.addEventListener("fullscreenchange", () => {
			setIsFullScreen(!!document.fullscreenElement);
		});

		// Remove the event listener when the component unmounts
		return () => {
			document.removeEventListener("fullscreenchange", () => {});
		};
	}, []);
	return (
		<>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "start",
					gap: "1rem",
					padding: "1.5rem",
					marginBottom: "1.5rem",
					boxShadow: "2px 2px 4px 0px #ccc",
					borderRadius: "5px",
					position: "relative",
				}}
				className="cardHoverEffect"
			>
				<div
					style={{
						width: "100%",
						// display: "flex",
						// justifyContent: "space-between",
						// alignItems: "center",
						// gap: "0.5rem",
						fontSize: "1.25rem",
					}}
				>
					<QuestionTitle
						QuestionNumber={QuestionNumber}
						data={value}
						onchangeMarksValue={onchangeMarksValue}
						marksArray={marksArray}
						editState={editState}
					/>
				</div>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "start",
						alignItems: "start",
						fontSize: "1.25rem",
					}}
				>
					<span>Ans:</span>
					{/*editState?.mode === 0  check mode    1. edit mode 2.   */}
					{(editState?.mode === 0 ||
						editState?.mode === 1 ||
						editState?.mode === 2) && (
						<>
							{(editState?.mode === 0 ||
								editState?.mode === 1) && (
								<Button type="primary" onClick={viewFullScreen}>
									{editState?.mode === 0
										? "Remarks"
										: "Edit Remarks"}

									<span
										style={{
											marginLeft: "10px",
										}}
									>
										<RemarksPenSVG />
									</span>
								</Button>
							)}
							<aside
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "start",
									alignItems: "start",
								}}
							>
								{marksArray?.map((elm, index) => {
									if (
										elm?.questionNumber ===
											QuestionNumber &&
										// elm?.blob === undefined
										!elm?.blob
									) {
										return (
											<React.Fragment key={index}>
												<TextArea
													rows={4}
													disabled
													value={answerText}
													style={{
														// fontWeight: "bold",
														color: " black",
													}}
												/>
											</React.Fragment>
										);
									}
								})}
								{marksArray?.map((elm, index) => {
									return elm?.questionNumber ===
										QuestionNumber ? (
										<img src={elm?.blob} key={index} />
									) : (
										""
									);
								})}
								{isFullScreen && modal2Open?.modal === true && (
									<MyCanva
										initialX={100}
										initialY={100}
										answerText={modal2Open?.canvasData}
										setMarksArray={setMarksArray}
										marksArray={marksArray}
										QuestionNumber={QuestionNumber}
										canvaStyle={canvaStyle}
										editState={editState}
										editObj={editObj}
										setEditObj={setEditObj}
										value={value}
										modal2Open={modal2Open}
										setModal2Open={setModal2Open}
										exitFullscreen={ExitFullscreen}
										setCanvasBlob={setCanvasBlob}
										confirmModal={confirmModal}
										base64Image={base64Image}
									/>
								)}
							</aside>
						</>
					)}
					{/* {editState?.mode === 2 && (
						<img src={value?.editData[0]?.remarksURL} />
					)} */}
				</div>
			</div>
		</>
	);
};

export default CanvasTypeQuestion;
