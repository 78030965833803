import { Button, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ExcelUpload from "../create/ExcelUpload";
import { ApiConnector } from "../../axios/apiClient";
import { EDIT_BULK_QUESTION } from "../../axios/AllBaseUrl";
import CustomToast from "../../utlis/toast/CustomToast";
interface excelData {
	questionId: any;
	tags: any;
	QuestionTitle: any;
}
interface loadingInterface {
	loading: boolean;
	uploaded: boolean;
}
const BulkQuestionEditModal = ({
	isBulkEditData,
	setBulkEditModalOpen,
	isBlurred,
	setIsBlurred,
}) => {
	const [data, setData] = useState<excelData[]>([]);
	const [loadingData, setLoadingData] = useState<loadingInterface>({
		loading: false,
		uploaded: false,
	});
	const handleOk = async () => {
		try {
			setIsBlurred("blur(2px)");
			if (data?.length > 0) {
				let res = await ApiConnector("post", `${EDIT_BULK_QUESTION}`, {
					data,
				});
				if (res.data.ok) {
					CustomToast("success", res.data.why);
					setLoadingData({
						loading: false,
						uploaded: false,
					});
					setIsBlurred("");
					setData([]);
					setBulkEditModalOpen(false);
				} else {
					setIsBlurred("");
					CustomToast("error", res.data.why);
				}
			} else {
				setIsBlurred("");
				CustomToast("error", "Blank Excel");
			}
		} catch (error) {
			setIsBlurred("");
			console.log(error);
		}
	};
	const handleCancel = () => {
		setLoadingData({
			loading: false,
			uploaded: false,
		});
		setData([]);
		setBulkEditModalOpen(false);
	};

	return (
		<>
			<Modal
				title="Edit Bulk Question Tag"
				open={isBulkEditData}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="no" onClick={handleCancel}>
						Cancel
					</Button>,
					<Button key="yes" type="primary" onClick={handleOk}>
						Confirm
					</Button>,
				]}
			>
				<ExcelUpload
					data={data}
					setData={setData}
					setLoadingData={setLoadingData}
				/>

				{!loadingData.loading && !loadingData.uploaded ? (
					"upload your excel file !"
				) : loadingData.loading ? (
					<p>Loading.....</p>
				) : !loadingData.loading &&
				  loadingData.uploaded &&
				  !data?.length ? (
					<p>No data Found in your Excel </p>
				) : (
					<p
						style={{
							color: "#103353",
							fontWeight: "bold",
						}}
					>
						Excel Uploaded Successfully Click on Confirm for
						Question tag edit
					</p>
				)}
			</Modal>
		</>
	);
};

export default BulkQuestionEditModal;
