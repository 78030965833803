import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import AddedPlaylist from "../AddedPlaylist";
import PlaylistFetchcard from "./PlaylistFetchcard";
import PlaylistPreview from "./PlaylistPreview";
import AddFloatingButton from "../AddFloatingButton";
import FlagLocalhost from "../../../Localhost/FlagLocalhost";
import HomeSearch from "../../Search/HomeSearch";
import { PageDataInterface } from "../../../utlis/Interface/homeSearch";

const Playlist = () => {
	const [isShown, setIsShown] = useState(false);
	const [playlistPageData, setPlaylistPageData] = useState<PageDataInterface>(
		{
			page: 1,
			pageSize: 10,
		}
	);
	const [searchTags, setSearchTags] = useState<any>([]);
	let flag = FlagLocalhost();

	return (
		<>
			<Row gutter={[24, 6]}>
				<Col xl={10} xs={24} md={12}>
					<Card className="testplaylist-card">
						<Row gutter={[24, 6]}>
							<Col xl={12} xs={12} md={12}>
								<AddedPlaylist />
							</Col>
						</Row>
						<Row style={{ margin: "10px" }}>
							<Col xl={24} xs={24} md={24}>
								<HomeSearch
									searchMode={4}
									setShowQuestion={() => {}}
									searchTags={searchTags}
									setSearchTags={setSearchTags}
									pageData={playlistPageData}
									playlistPageData={playlistPageData}
								/>
							</Col>
						</Row>
						<Row>
							<Col xl={24} xs={24} md={24}>
								<PlaylistFetchcard
									isShown={isShown}
									setIsShown={setIsShown}
									playlistPageData={playlistPageData}
									setPlaylistPageData={setPlaylistPageData}
									searchTags={searchTags}
								/>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col xl={14} xs={24} md={12}>
					<PlaylistPreview
						isShown={isShown}
						setIsShown={setIsShown}
					/>
				</Col>
			</Row>
			<Row
				gutter={[24, 0]}
				style={{ position: "fixed", bottom: "4vh", right: "4vw" }}
			>
				{flag?.isFromEvidya === 1 && <AddFloatingButton />}
			</Row>
		</>
	);
};

export default Playlist;
