import React from "react";

const Publish = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="18"
				width="20"
				style={{ marginTop: "5px" }}
				fill="#747c9f"
				viewBox="0 0 20 20"
			>
				<path d="M9.967 8.193L5 13h3v6h4v-6h3L9.967 8.193zM18 1H2C.9 1 0 1.9 0 3v12c0 1.1.9 2 2 2h4v-2H2V6h16v9h-4v2h4c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM2.5 4.25a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm2 0a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zM18 4H6V3h12.019L18 4z" />
			</svg>
		</>
	);
};

export default Publish;
