import { Col, Modal, Row, Tooltip } from "antd";
import React, { useState } from "react";
import {
	EmailShareButton,
	FacebookShareButton,
	WhatsappShareButton,
} from "react-share";
import { EmailIcon, FacebookIcon, WhatsappIcon } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ClipSvg from "../svg/ClipSvg";

const ShareIcon = ({
	isModalOpen,
	handleCancel,
	url,
	handleOk,
	copied,
	setCopied,
}) => {
	// const [copied, setCopied] = useState(false);
	const handleCopyLinkClick = () => {
		// Logic to show a notification or alert that the link is copied
		// You can use a library like react-toastify or custom CSS to show a notification
		setCopied(true);
		alert("Link copied to clipboard!");
	};
	const handleWhatsAppClick = () => {
		// Replace 'your_message_here' with the message you want to send
		const message = "Check out this link: " + url;
		const encodedMessage = encodeURIComponent(message);
		window.open(`https://api.whatsapp.com/send?text=${encodedMessage}`);
	};

	const handleFacebookClick = () => {
		window.open(
			`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
				url
			)}`
		);
	};
	const handleEmailClick = () => {
		// Replace 'recipient_email' with the actual recipient email address
		const recipientEmail = "recipient_email";
		// Replace 'email_subject' with the subject of the email
		const emailSubject = "Check out this link!";
		// Replace 'email_body' with the body of the email
		const emailBody = `Hey,\n\nClick to view Questions:\n${url}`;

		const mailToLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
			emailSubject
		)}&body=${encodeURIComponent(emailBody)}`;

		window.location.href = mailToLink;
	};
	return (
		<Modal open={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
			{/* <Row>
				<Col xxl={24} xl={24} md={24} xs={24}>
					<p> Link here </p>
				</Col>
			</Row> */}
			{/* <ShareButtons> */}
			<Row>
				<Col xxl={6} xl={6} md={6} xs={6}>
					<Tooltip
						placement="top"
						title="Share on Whatsapp"
						className="rating-tooltip"
					>
						<WhatsappShareButton url={url}>
							<WhatsappIcon
								size={32}
								round={true}
								onClick={handleWhatsAppClick}
							/>
						</WhatsappShareButton>
					</Tooltip>
				</Col>
				{/* <Col xxl={6} xl={6} md={6} xs={6}>
					<FacebookShareButton url={url}>
						<FacebookIcon
							size={32}
							round={true}
							onClick={handleFacebookClick}
						/>
					</FacebookShareButton>
				</Col> */}
				<Col xxl={6} xl={6} md={6} xs={6}>
					<Tooltip
						placement="top"
						title="Mail"
						className="rating-tooltip"
					>
						<EmailShareButton url={url}>
							<EmailIcon
								size={32}
								round={true}
								onClick={handleEmailClick}
							/>
						</EmailShareButton>
					</Tooltip>
				</Col>
				<Col xxl={6} xl={6} md={6} xs={6}>
					<Tooltip
						placement="top"
						title="Copy link to clipboard"
						className="rating-tooltip"
					>
						<CopyToClipboard
							text={url}
							onCopy={handleCopyLinkClick}
						>
							<span>
								<ClipSvg />
							</span>
						</CopyToClipboard>
					</Tooltip>
				</Col>
			</Row>
			{copied && <span style={{ marginLeft: "5px" }}>Link copied!</span>}

			{/* </ShareButtons> */}
			{/* <FacebookShareCount url={url}>
				{(shareCount) => (
					<span className="myShareCountWrapper">{shareCount}</span>
				)}
			</FacebookShareCount> */}
		</Modal>
	);
};

export default ShareIcon;
