import { Card, Col, Collapse, Row, Image, Button, Tag, Empty } from "antd";
import React, { useState } from "react";
import {
	BorderOutlined,
	CheckSquareOutlined,
	// HeartFilled,
	// HeartTwoTone,
	LikeFilled,
	LikeOutlined,
	QuestionCircleFilled,
	SettingFilled,
	ShareAltOutlined,
} from "@ant-design/icons";
import parse from "html-react-parser";
import ShareIcon from "./Share/ShareIcon";
import { ParseHtmlToText } from "../utlis/parseHtmlTotext/ParseHtmlToText";

const { Panel } = Collapse;

// const gridStyle: React.CSSProperties = {
// 	width: "20%",
// };
const gridStyle_two: React.CSSProperties = {
	width: "100%",
	// display: "flex",
	paddingTop: "0",
	paddingRight: "0",
};
const gridStyle_img: React.CSSProperties = {
	height: "50px",
	width: "50px",
};

const ColapsableCard = ({
	onChange,
	image,
	index,
	value,
	Questionadded,
	toggleIcon,
	question_setting,
	Option,
}) => {
	let [settingsIcon, setSettingsIcon] = useState(false);
	const [copied, setCopied] = useState(false);
	let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const handleCancel = () => {
		setCopied(false);
		setIsModalOpen(false);
	};
	const handleOk = () => {
		setCopied(false);
		setIsModalOpen(false);
	};
	let playlistShare = () => {
		setIsModalOpen(true);
	};
	const playListShareUrl = `https://adhyandigital.com/`;
	// const playListShareUrl = `https://adhyandigital.com/viewQBPL/#/receive/${shareId}`

	let toggleSettingsIcon = (id) => {
		if (id && settingsIcon === true) {
			setSettingsIcon(false);
		}
		if (id && settingsIcon === false) {
			setSettingsIcon(true);
		}
	};
	return (
		<>
			{value?.length === 0 && (
				<div>
					<Empty />
				</div>
			)}
			<Collapse defaultActiveKey={["1"]} onChange={onChange} key={index}>
				<Panel
					header={
						value?.question_traslation[0]?.title &&
						ParseHtmlToText(value?.question_traslation[0]?.title)
					}
					key={index}
				>
					<Card.Grid
						style={gridStyle_two}
						onClick={() =>
							Questionadded(
								value?._id,
								value?.question_traslation[0]?.title
							)
						}
					>
						<Row>
							<Col
								style={{
									float: "left",
									display: "flex",
									columnGap: "2px",
								}}
								xl={10}
								xs={10}
								md={10}
								sm={10}
								xxl={10}
							>
								{(image && image !== undefined) ||
								(image && image?.length !== 0)
									? image?.map((imgValue, i) => {
											return (
												<React.Fragment key={i}>
													<Image
														src={imgValue?.url}
														style={gridStyle_img}
													/>
												</React.Fragment>
											);
									  })
									: ""}
							</Col>
							<Col
								style={{ float: "right" }}
								xl={14}
								xs={14}
								md={14}
								sm={14}
								xxl={14}
							>
								<Row>
									<Col
										xxl={12}
										xl={12}
										xs={0}
										md={0}
										sm={0}
									></Col>
									<Col xl={6} xs={10} md={10} sm={10} xxl={6}>
										<Button
											type="link"
											// href="url://"
											onClick={() =>
												toggleIcon(
													value?._id,
													value?.liked,
													value?.totalLike
												)
											}
											style={{
												float: "right",
												display: "flex",
											}}
										>
											{value?.liked === true ? (
												// 	 ||
												// (buttonIcon === true &&
												// 	likedId ===
												// 		value?._id)
												<LikeFilled
													style={{
														color: "#00305a",
													}}
												/>
											) : (
												<LikeOutlined />
											)}
											{value?.totalLike > 0 && (
												<p>{value?.totalLike}</p>
											)}
										</Button>
									</Col>
									<Col xl={3} xs={5} md={6} sm={6} xxl={3}>
										<Button
											type="link"
											// href="url://"
											style={{
												float: "right",
											}}
											onClick={playlistShare}
										>
											<ShareAltOutlined />
										</Button>
									</Col>
									<Col xl={3} xs={6} md={8} sm={8} xxl={3}>
										<Button
											type="link"
											// href="url://"
											style={{
												float: "right",
											}}
											onClick={() =>
												toggleSettingsIcon(value?._id)
											}
										>
											{value?._id &&
												settingsIcon === true && (
													<QuestionCircleFilled />
												)}
											{value?._id &&
												settingsIcon === false && (
													<SettingFilled />
												)}
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xxl={23} xl={23} md={23} xs={23}>
								<div style={{ display: "inline-block" }}>
									{value?.tags?.map((item, i) => {
										return (
											<React.Fragment key={i}>
												<Tag color="#2db7f5" key={i}>
													{item}
												</Tag>
											</React.Fragment>
										);
									})}
								</div>
							</Col>
						</Row>
						<Row>
							{settingsIcon === false && (
								<div>
									<Row>
										<Col xxl={0} xl={0} xs={1} md={0}></Col>
										<Col xxl={24} xl={24} md={24} xs={22}>
											<strong>
												{value?.question_traslation[0]
													?.title &&
													ParseHtmlToText(
														value
															?.question_traslation[0]
															?.title
													)}
											</strong>
										</Col>
										<Col xxl={0} xl={0} xs={1} md={0}></Col>
									</Row>

									{/* <div>
											{Option?.map(
												(elm, index) => {

													return (
														<div key={index}>
															<p>
																{
																	elm
																		?.OptionTranslation[0]
																		?.title
																}
															</p>
														</div>
													);
												}
											)}
										</div> */}
									<Row gutter={[24, 6]}>
										{Option?.map((elem, index) => {
											// console.log("Option", elem);

											return (
												<Col
													xl={12}
													xs={24}
													md={12}
													key={index}
												>
													<Row>
														<Col
															xxl={0}
															xl={0}
															xs={1}
															md={0}
														></Col>
														<Col
															xxl={1}
															xl={1}
															xs={1}
															md={2}
															style={{
																columnGap:
																	"2px",
															}}
														>
															{elem.isCorrect ===
																0 && (
																<BorderOutlined />
															)}
															{elem.isCorrect ===
																1 && (
																<CheckSquareOutlined />
															)}
														</Col>
														<Col
															xxl={3}
															xl={3}
															xs={0}
															md={4}
														></Col>
														<Col
															xxl={19}
															xl={19}
															xs={18}
															md={18}
															style={
																elem
																	.OptionTranslation
																	?.length > 1
																	? {}
																	: {
																			paddingLeft:
																				"15px",
																	  }
															}
														>
															<p>
																{ParseHtmlToText(
																	elem
																		?.OptionTranslation[0]
																		?.title
																)}
															</p>
															{elem
																?.OptionTranslation[0]
																?.imageUrl &&
																elem
																	?.OptionTranslation[0]
																	?.imageUrl !==
																	undefined && (
																	<Image
																		src={
																			elem
																				?.OptionTranslation[0]
																				?.imageUrl
																		}
																		style={
																			gridStyle_img
																		}
																	/>
																)}
														</Col>
													</Row>
													{/* {elem
																.OptionTranslation[0]
																.imageUrl && (
																<Row>
																	<Col
																		xl={8}
																		xs={24}
																		md={24}
																	>
																		<Image
																			className="question-fetch-image"
																			width={
																				150
																			}
																			src={
																				elem
																					.OptionTranslation[0]
																					.imageUrl
																			}
																		/>
																	</Col>
																</Row>
															)} */}
												</Col>
											);
										})}
									</Row>
								</div>
							)}
							{settingsIcon === true && (
								<div>
									<h5>
										Question Type : {value?.question_type}
									</h5>
									<div>
										Question Level : {value?.question_level}
									</div>
									<div>
										Question Language:{" "}
										{
											value?.question_traslation[0]
												?.langCode
										}{" "}
									</div>
									{/* <div>
									Description :
									{value?.question_traslation[0]?.description}
								</div> */}
									{question_setting?.marks !== undefined && (
										<div>
											Marks :{question_setting?.marks}
										</div>
									)}
									{question_setting?.negative !== undefined &&
										question_setting?.negative > 0 && (
											<div>
												Negative Marks :
												{question_setting?.negative}
											</div>
										)}
									{question_setting?.set_time !==
										undefined && (
										<div>
											Time :{question_setting?.set_time}
										</div>
									)}
								</div>
							)}
						</Row>
					</Card.Grid>
				</Panel>
			</Collapse>
			<ShareIcon
				isModalOpen={isModalOpen}
				handleCancel={handleCancel}
				url={playListShareUrl}
				handleOk={handleOk}
				copied={copied}
				setCopied={setCopied}
			/>
		</>
	);
};

export default ColapsableCard;
