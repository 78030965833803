import { EditOutlined } from "@ant-design/icons";
import { Card, Col, Input, Row, Typography } from "antd";
import React from "react";
import UploadCustom from "../create/UploadCustom";
import { useDispatch, useSelector } from "react-redux";
import { playlistname } from "../../redux/reducers/PlaylistSlice";
import { RootState } from "../../redux/store";
import PlaylistTags from "./PlaylistTags";
import InvalidMsg from "../create/InvalidMsg";
import PlaylistFullmarks from "./PlaylistFullmarks";
import PlaylistTimesettings from "./PlaylistTimesettings";
const { Text } = Typography;

const Playlistnamesettings = () => {
	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);
	const PlaylistName = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(playlistname(e.target.value));
	};

    const prefix = (
        <EditOutlined
            style={{
                fontSize: "20px",
                paddingRight: "5px",
                opacity: "0.5",
            }}
        />
    );

	return (
		<>
			<Card className="playlist-settings-card">
				<Row gutter={[24, 6]}>
					<div className="width-100" style={{ padding: "5px" }}>
						<div className="question-mandatory-div">
							<Text
								type="danger"
								className="question-mandatory-label"
							>
								*
							</Text>
							<Text className="question-mandatory-label">
								Playlist Name
							</Text>
						</div>
					</div>
					<Input
						name="playlist_name"
						value={
							playlist.mode === 0
								? playlist.playlistName
								: playlist.playlist_to_edit.playlist_name
						}
						prefix={prefix}
						placeholder="Please Input your Playlist Name"
						onChange={PlaylistName}
						// maxLength={50}
						status={playlist.valid?.playlistName ? "error" : ""}
					/>
					<div style={{ height: "16px" }}>
						{"playlistName" in playlist.valid && (
							<InvalidMsg msg={playlist.valid?.playlistName} />
						)}
					</div>
				</Row>
				<Row gutter={[24, 6]}>
					<Col xl={11} md={8} xs={8}>
						<PlaylistFullmarks />
					</Col>
					<Col xl={2} md={2} xs={2}></Col>
					<Col xl={11} md={14} xs={14}>
						<PlaylistTimesettings />
					</Col>
				</Row>
				<Row gutter={[24, 6]}>
					<PlaylistTags />
				</Row>
				<Row gutter={[24, 6]} className="playlist-image">
					<UploadCustom where="playlist" id={undefined} />
				</Row>
			</Card>
		</>
	);
};

export default Playlistnamesettings;
