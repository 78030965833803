import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PlaylistAdd from "../svg/PlaylistAdd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	playlist_edit_mode,
	resetplaylistStoredata,
} from "../../redux/reducers/PlaylistSlice";

const AddedPlaylist = () => {
	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);

	const AddNewPlalist = () => {
		// dispatch(resetplaylistStoredata());
		dispatch(playlist_edit_mode(0));
	};
	return (
		<>
			<Row gutter={[24, 6]}>
				<Col xl={24} xs={24} md={24}>
					<Row gutter={[24, 6]}>
						<Col xl={2} md={3} xs={3}>
							<Link to="/createPlaylist">
								<Button
									type="link"
									icon={<PlaylistAdd />}
									onClick={AddNewPlalist}
								>
									Add New Playlist
								</Button>
							</Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default AddedPlaylist;
