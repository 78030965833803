import React from "react";

const PlaylistAdd = () => {
	return (
		<>
			<svg
				width="40px"
				height="40px"
				viewBox="0 0 512 512"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					width="208"
					height="32"
					x="256"
					y="152"
					fill="var(--ci-primary-color, currentColor)"
					className="ci-primary"
				/>
				<rect
					width="288"
					height="32"
					x="176"
					y="256"
					fill="var(--ci-primary-color, currentColor)"
					className="ci-primary"
				/>
				<rect
					width="288"
					height="32"
					x="176"
					y="360"
					fill="var(--ci-primary-color, currentColor)"
					className="ci-primary"
				/>
				<polygon
					fill="var(--ci-primary-color, currentColor)"
					points="192 152 128 152 128 88 96 88 96 152 32 152 32 184 96 184 96 248 128 248 128 184 192 184 192 152"
					className="ci-primary"
				/>
			</svg>
		</>
	);
};

export default PlaylistAdd;
