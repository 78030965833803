import React, { useState, useRef, useEffect } from "react";

interface TextProps {
	initialX: number;
	initialY: number;
}

type Tool = "pen" | "eraser" | "text";

const TestCanva = ({ initialX, initialY, answerText }) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const ctx = canvasRef.current?.getContext("2d");
	const [textPosition, setTextPosition] = useState<{ x: number; y: number }>({
		x: initialX,
		y: initialY,
	});
	const [drawingContext, setDrawingContext] =
		useState<CanvasRenderingContext2D | null>(null);
	const [currentTool, setCurrentTool] = useState<Tool>("pen");
	const [isDrawing, setIsDrawing] = useState(false);
	const [currentText, setCurrentText] = useState<string>("");

	const [undoStack, setUndoStack] = useState<string[]>([]);
	const [redoStack, setRedoStack] = useState<string[]>([]);
	const [penSize, setPenSize] = useState<number>(2); // Initial pen size
	const [eraserSize, setEraserSize] = useState<number>(10); // Initial eraser size
	const [penColor, setPenColor] = useState<string>("#000000"); // Initial pen color

	if (ctx) {
		if (undoStack.length === 0) {
			undoStack.push(canvasRef.current?.toDataURL() || "");
		}
	}

	const handleMouseDown = (e: any) => {
		if (!canvasRef.current) return;

		const ctx = canvasRef.current.getContext("2d");
		if (!ctx) return;

		const mouseX =
			e.clientX - canvasRef.current.getBoundingClientRect().left;
		const mouseY =
			e.clientY - canvasRef.current.getBoundingClientRect().top;

		if (currentTool === "pen" && !isDrawing) {
			setIsDrawing(true);
			ctx.beginPath();
			ctx.moveTo(mouseX, mouseY);
		} else if (currentTool === "eraser") {
			ctx.lineWidth = eraserSize; // Set eraser size
			ctx.clearRect(mouseX, mouseY, eraserSize, eraserSize); // Clear with eraser size
		} else if (currentTool === "text") {
			const newTextX = mouseX;
			const newTextY = mouseY;
			setTextPosition({ x: newTextX, y: newTextY });
		}

		saveCanvasState();
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		if (isDrawing && currentTool === "pen" && drawingContext) {
			const mouseX =
				e.clientX - canvasRef.current!.getBoundingClientRect().left;
			const mouseY =
				e.clientY - canvasRef.current!.getBoundingClientRect().top;

			drawingContext.lineTo(mouseX, mouseY);
			drawingContext.stroke();
		}
	};

	const handleMouseUp = () => {
		setIsDrawing(false);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.closePath();
		}
	};

	const saveCanvasState = () => {
		if (!canvasRef.current || !drawingContext) return;
		const canvasState = canvasRef.current?.toDataURL() || "";
		setUndoStack((prevStack) => [...prevStack, canvasState]);
		setRedoStack([]);
	};

	const undo = () => {
		if (undoStack.length > 1) {
			const newUndoStack = undoStack.slice(0, undoStack.length - 1);
			setRedoStack((prevStack) => [
				...prevStack,
				undoStack[undoStack.length - 1],
			]);
			setUndoStack(newUndoStack);
			drawImageFromUrl(newUndoStack[newUndoStack.length - 1]);
		}
	};

	const redo = () => {
		if (redoStack.length > 0) {
			const newRedoStack = redoStack.slice(0, redoStack.length - 1);
			setUndoStack((prevStack) => [
				...prevStack,
				redoStack[redoStack.length - 1],
			]);
			setRedoStack(newRedoStack);
			drawImageFromUrl(redoStack[redoStack.length - 1]);
		}
	};

	const drawImageFromUrl = (url: string) => {
		if (!ctx) return;
		const img = new Image();
		img.src = url;
		img.onload = () => {
			ctx.clearRect(
				0,
				0,
				canvasRef.current!.width,
				canvasRef.current!.height
			);
			ctx.drawImage(img, 0, 0);
		};
	};
	const handlePenSizeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSize = parseInt(event.target.value, 10);
		setPenSize(newSize);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.lineWidth = newSize;
		}
	};
	const handlePenColorChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newColor = event.target.value;
		setPenColor(newColor);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.strokeStyle = newColor;
		}
	};
	const handleDownload = () => {
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		// console.log("canvas", canvas);

		const downloadLink = document.createElement("a");

		downloadLink.href = canvas.toDataURL("image/png");
		downloadLink.download = "canvas_image.png";
		// console.log("downloadLink fgdg", downloadLink.href);
		downloadLink.click();
	};

	const handleTouchStart = (e: React.TouchEvent) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseDown(e.touches[0]);
	};

	const handleTouchMove = (e: any) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseMove(e.touches[0]);
	};

	const handleTouchEnd = (e: React.TouchEvent) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseUp();
		saveCanvasState();
	};

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				const imageData = reader.result as string;
				setCanvasImageData(imageData);
			};
			reader.readAsDataURL(file);
		}
	};
	const [canvasImageData, setCanvasImageData] = useState<string | null>(null); // New state to store canvas image data

	const renderCanvasImageData = () => {
		if (canvasImageData && ctx) {
			const img = new Image();
			img.src = canvasImageData;
			img.onload = () => {
				ctx.clearRect(
					0,
					0,
					canvasRef.current!.width,
					canvasRef.current!.height
				);
				ctx.drawImage(img, 0, 0);
			};
		}
	};

	const handleUploadURL = () => {
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		const dataUrl = canvas.toDataURL("image/png");
		// console.log("dataUrl", dataUrl);

		// dataUrl.href = canvas.toDataURL("image/png");
		// Extract the mime type and base64 data from the image string
		const parts = dataUrl?.split(";base64,");
		const mimeType = parts[0].split(":")[1];
		const base64Data = parts[1];

		// Convert the base64 data to a Uint8Array
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		// Create a Blob object from the Uint8Array and mime type
		const blob = new Blob([byteArray], { type: mimeType });
		// console.log(blob);
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		setDrawingContext(ctx);

		if (ctx) {
			let text = answerText;
			// Set the maximum width for text wrapping
			let maxWidth = 800;

			// Set the initial position for drawing text
			let x = 20;
			let y = 30;
			ctx.font = "20px Arial";

			// Split the text into words
			let words = text?.split(" ");
			let line = "";

			// Loop through each word and build lines for wrapping
			for (let i = 0; i < words.length; i++) {
				let testLine = line + words[i] + " ";
				let metrics = ctx.measureText(testLine);
				let testWidth = metrics.width;

				if (testWidth > maxWidth) {
					// Draw the line and move to the next line
					ctx.fillText(line, x, y);
					line = words[i] + " ";
					y += 20; // Move to the next line
				} else {
					line = testLine;
				}
			}

			// Draw the last line
			ctx.fillText(line, x, y);
		}
	}, [answerText]);
	// [canvasRef, textPosition]
	return (
		<>
			<div>
				<div>
					<button onClick={() => setCurrentTool("pen")}>Pen</button>
					<label>
						Color:
						<input
							type="color"
							value={penColor}
							onChange={handlePenColorChange}
						/>
					</label>
					<label>
						Size:
						<input
							type="range"
							min="1"
							max="10"
							value={penSize}
							onChange={handlePenSizeChange}
						/>
						{penSize}
					</label>

					<button onClick={() => setCurrentTool("eraser")}>
						Eraser
					</button>
					<button onClick={undo}>Undo</button>
					<button onClick={redo}>Redo</button>
					<button onClick={handleDownload}>Download</button>
					<button onClick={handleUploadURL}>Get URL</button>
					<input
						type="file"
						accept="image/*"
						onChange={handleFileInputChange}
					/>
					<button onClick={renderCanvasImageData}>
						Render Uploaded Image
					</button>
				</div>
				<canvas
					ref={canvasRef}
					width={800}
					height={600}
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseOut={saveCanvasState}
					style={{ border: "1px solid black" }}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
				/>
			</div>
		</>
	);
};

export default TestCanva;
