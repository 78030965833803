import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiConnector } from "../axios/apiClient";
import { USER_REGISTER } from "../axios/AllBaseUrl";
import { useDispatch } from "react-redux";
import {
	FromEvidya,
	other_site_tag,
	playlistname,
} from "../redux/reducers/PlaylistSlice";
import { evidya_data_interface } from "../Interface/evidya";
import { Base64 } from "js-base64";
import VerifyMsgLayout from "./VerifyLayout/VerifyMsgLayout";

const VerifyPlaylist = () => {
	let [loopString, setLoopString] = useState<string>("");
	let [decodedata, setDecodedData] = useState<evidya_data_interface>();
	let [validDecodeData, setValidDecodeData] = useState<boolean>(false);
	let [validtoken, setValidtoken] = useState<boolean>(true);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { data } = useParams();

	localStorage.setItem("evidya_data", JSON.stringify(""));
	if (data && loopString === "") {
		try {
			let decodedata = Base64.atob(data);

			if (decodedata) {
				setValidDecodeData(true);
				setLoopString(decodedata);
				setDecodedData(JSON.parse(decodedata));
			}
		} catch (error) {
			setValidDecodeData(false);
			setLoopString("invalid");
			setValidtoken(false);
		}
	}
	const flag_Registration = async () => {
		if (validDecodeData) {
			try {
				const registration = await ApiConnector(
					"post",
					`${USER_REGISTER}`,
					{
						user_name: decodedata?.user_name,
						user_email: decodedata?.user_email,
						user_phone: decodedata?.user_phone,
						user_pass: "",
						user_conpass: "",
						combined_unique_id: decodedata?.user_id,
						isFromOtherSite: decodedata?.isFromEvidya,
					}
				);

				if (registration.data.ok) {
					setValidtoken(true);
					localStorage.setItem(
						"token",
						JSON.stringify(registration.data.data)
					);
					dispatch(playlistname(decodedata?.assTitle));
					let tag = decodedata
						? [
								decodedata?.shareCName,
								decodedata?.shareChapName,
								decodedata?.shareSName,
								decodedata?.shareTopicName,
						  ]
						: [];
					const tagLower = tag?.map((item) => item?.toLowerCase());
					const trimmedTag = tagLower?.map((item) => item?.trim());
					const filteredTag = trimmedTag?.filter(
						(item) => item !== "" && item !== undefined
					);

					dispatch(other_site_tag(filteredTag));

					localStorage.setItem(
						"evidya_data",
						JSON.stringify(decodedata)
					);
					setValidDecodeData(false);

					const incomingString = localStorage.getItem("evidya_data")!;

					const incomingdata = JSON.parse(incomingString);

					if (incomingdata?.isFromEvidya === 1) {
						incomingdata?.where === 0 &&
							navigate("/createPlaylist");

						let assData = {
							isFromEvidya: incomingdata?.isFromEvidya,
							classId: incomingdata?.assClassId,
							chapId: incomingdata?.assChId,
							topicId: incomingdata?.assTId,
							subjectId: incomingdata?.assSubId,
						};
						dispatch(FromEvidya(assData));
						incomingdata?.where === 1 && navigate("/playlist");
						// console.log("incomingdata", incomingdata);

						if (
							incomingdata?.isFromEvidya === 1 &&
							incomingdata?.where === 2 // teacher share playlist view
						) {
							navigate("/conductedExamsDashboard", {
								state: {
									user_name: incomingdata?.user_name,
									user_id: incomingdata?.user_id,
									school_id: incomingdata?.school_id,
									academic_year_id:
										incomingdata?.academic_year_id,
									type_id: incomingdata?.user_type_id,
								},
							});
						}
						if (
							incomingdata?.isFromEvidya === 1 &&
							incomingdata?.where === 3 // student playlist view
						) {
							navigate("/fetchStudentPlaylist");
						}
					}
				} else {
					setValidDecodeData(false);
					setValidtoken(false);
				}
			} catch (error) {
				setValidtoken(false);
				setValidDecodeData(false);
				setLoopString("invalid");
			}
		}
	};

	useEffect(() => {
		flag_Registration();
	});

	return (
		<>
			<VerifyMsgLayout validtoken={validtoken} />
		</>
	);
};

export default VerifyPlaylist;
