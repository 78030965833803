import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ExamDashboardSearch from "../components/PlaylistCheck/ExamDashboardSearch";
import ExamAttendViewCard from "../components/PlaylistCheck/ExamAttendViewCard";
import { ApiConnector } from "../axios/apiClient";
import { ATTENDEXAMSTUDENT } from "../axios/AllBaseUrl";
import CustomToast from "../utlis/toast/CustomToast";

const headerFontStyle: React.CSSProperties = {
	fontSize: "1.25rem",
	fontWeight: "500",
	color: "#6c757d",
};
const headerBGStyle: React.CSSProperties = {
	backgroundColor: "#ffffff",
	borderBottom: "1px solid #e8e8e8",
	padding: "0.5rem 1rem",
};
const onSearch = (value: string) => console.log(value);

const ExamAttendedStudentList = () => {
	let location = useLocation();
	let [studentData, setStudentData] = useState<any>([]);
	const navigate = useNavigate();
	const navigateToCheckExam = (id, remarks, name) => {
		// mode 0 for normal check and mode 1 for edit check playlist
		if (remarks === false) {
			navigate("/checkPlayList", {
				state: {
					playlistData: location?.state,
					user_id: id,
					mode: 0,
					remarks: remarks,
					studentName: name,
				},
			});
		}
		if (remarks === true) {
			CustomToast("warning", ` You already checked ${name}`);
		}
	};

	const navigateToEditCheckExam = (id, remarks, name) => {
		// mode 0 for normal check and mode 1 for edit check playlist
		if (remarks === true) {
			navigate("/checkPlayList", {
				state: {
					playlistData: location?.state,
					user_id: id,
					mode: 1,
					remarks: remarks,
					studentName: name,
				},
			});
		}
		if (remarks === false) {
			CustomToast("warning", ` You need to check  ${name} answer first`);
		}
	};
	let ExamAttend = async () => {
		let res = await ApiConnector("post", `${ATTENDEXAMSTUDENT}`, {
			// playlistId: "63f85eb43ca24249bbdc12a6",//local
			// playlistId: "650d39568b7eff5ab2ebc193", //local
			playlistId: location?.state?.playlist_id,
		});
		// console.log("res in exam attend", res);
		if (res?.data?.ok) {
			setStudentData(res?.data?.data);
		}
	};
	useEffect(() => {
		ExamAttend();
	}, []);

	// console.log("studentData", studentData);
	// console.log("location?.state", location?.state);

	return (
		<>
			<Layout className="layout">
				<div style={headerBGStyle}>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col className="gutter-row" span={8}>
							<div style={headerFontStyle}>
								{location?.state?.playlist_name}
							</div>
						</Col>
						<Col className="gutter-row" span={8}>
							<div style={headerFontStyle}>
								Exam Date:{location?.state?.start_date}
								{/* 29/08/2023 */}
							</div>
						</Col>
						<Col className="gutter-row" span={8}>
							{location?.state?.playlist_fullMarks.length !==
								0 && (
								<div style={headerFontStyle}>
									Full Marks:{" "}
									{location?.state?.playlist_fullMarks}
								</div>
							)}
						</Col>
					</Row>
				</div>

				<div style={{ width: "100%", padding: "2rem" }}>
					<Row gutter={8}>
						<Col span={8} style={{ margin: "0 auto 2rem auto" }}>
							<ExamDashboardSearch
								onSearch={onSearch}
								placeholder="Search Student..."
								searchTerm={undefined}
								handleChange={undefined}
							/>
						</Col>
					</Row>
					<Row
						gutter={{ md: 24 }}
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "start",
							flexFlow: "wrap",
						}}
					>
						{studentData?.map((value, index) => {
							return (
								<Col
									className="gutter-row"
									span={4}
									style={{ margin: "0.5rem 0" }}
									key={index}
								>
									<ExamAttendViewCard
										value={value}
										navigateToCheckExam={
											navigateToCheckExam
										}
										editCheck={navigateToEditCheckExam}
									/>
								</Col>
							);
						})}
					</Row>
				</div>
			</Layout>
		</>
	);
};

export default ExamAttendedStudentList;
