import { Modal, message, Empty, Row, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
	PlaylistFetch,
	ViewPlaylist,
	edit_playlist_old_data,
	playlistLike,
	playlist_edit_mode,
} from "../../../redux/reducers/PlaylistSlice";
import {
	ANIMATION_CHOOSE_URL,
	BASE_URL,
	CLASS_SECTION,
	DELETE_PLAYLIST_DATA,
	DOWNLOAD_PLAYLIST,
	PLAYLISTLIKE,
	PLAYLISTPUBLISHED,
	Rating_Collection,
} from "../../../axios/AllBaseUrl";
import { ApiConnector } from "../../../axios/apiClient";
import CustomToast from "../../../utlis/toast/CustomToast";
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import PlaylistViewCard from "./PlaylistViewCard";
import FlagLocalhost from "../../../Localhost/FlagLocalhost";
import PlaylistPublishedModal from "../../modal/PlaylistPublishedModal";
import Loader from "../../../utlis/Loader/Loader";
import { PageDataInterface } from "../../../utlis/Interface/homeSearch";
import axios from "axios";
import HomeSearch from "../../Search/HomeSearch";

const PlaylistFetchcard = (prop: any) => {
	const { setIsShown, playlistPageData, setPlaylistPageData, searchTags } =
		prop;
	let flag = FlagLocalhost();
	let navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [modalValue, setModalValue] = useState({
		open: false,
		playlist_name: "",
	});
	const [publishmodal, setPublishmodal] = useState(false);
	const [deleteid, setDeleteid] = useState();
	const [playlistID, setPlaylistID] = useState();
	let [selectClass, setSelectClass] = useState<any>([{}]);
	let [classId, setClassId] = useState<string>("");
	let [selectSection, setSelectSection] = useState<any>([{}]);
	let [sectionId, setSectionId] = useState<any>("");

	let [startTime, setStartTime] = useState<any>("");
	// console.log("startTime", startTime);
	let [endTime, setEndTime] = useState<any>("");
	// console.log("endTime", endTime);
	let [startDate, setStartDate] = useState<any>("");
	// console.log("startDate", startDate);
	let [endDate, setEndDate] = useState<any>("");
	let [questionTimeCheckedStatus, setQuestionTimeCheckedStatus] =
		useState<boolean>(true);
	let [playlistTime, setPlaylistTime] = useState<number>(0);
	// console.log("endDate", endDate);
	// const [playlistPageData, setPlaylistPageData] = useState<PageDataInterface>(
	// 	{
	// 		page: 1,
	// 		pageSize: 10,
	// 	}
	// );
	// const [searchTags, setSearchTags] = useState<any>([]);

	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);

	// console.log(playlist?.playlistData[0]?.data?.data);
	// console.log(playlist?.playlistData[0]?.data?.data[0].questions);

	const deleteme = (id, name) => {
		setModalValue({ open: true, playlist_name: name });
		setDeleteid(id);
	};
	const deleteitem = async () => {
		let id = deleteid;
		let res = await ApiConnector("delete", `${DELETE_PLAYLIST_DATA}/${id}`);
		if (res.data.http$.status === 401 && res.data.ok === false) {
			localStorage.removeItem("token");
			navigate("/");
		}
		if (res.data.ok === false) {
			CustomToast("error", res.data.why);
		}
		if (res.data.ok === true) {
			CustomToast("success", res.data.why);
			dispatch(
				PlaylistFetch({
					page: 1,
					showItems: 10,
				})
			);
		}
		setModalValue({ ...modalValue, open: false });
	};
	const handleCancel = () => {
		setModalValue({ ...modalValue, open: false });
	};

	const editme = (elm) => {
		dispatch(
			edit_playlist_old_data({
				elm,
			})
		);
		dispatch(playlist_edit_mode(1));
		navigate("/createPlaylist");
	};
	const playlistgetId = (id: any) => {
		dispatch(ViewPlaylist(id));
	};
	// console.log(playlist?.playlistData[0]?.data?.data);

	const starCollect = async (value: number) => {
		let id = playlistID;
		let string = value.toString();
		let res = await ApiConnector("post", `${Rating_Collection}/${id}`, {
			rating: string,
		});
		if (res.data.http$.status === 401 && res.data.ok === false) {
			localStorage.removeItem("token");
			navigate("/");
		}
		if (res.data.ok === false) {
			CustomToast("error", res.data.msg);
		}
		if (res.data.ok === true) {
			CustomToast("success", res.data.msg);
		}
	};

	const Published = (id, timeData) => {
		setStartTime("");
		setEndTime("");
		setEndDate("");
		setStartDate("");
		setPlaylistTime(timeData);
		setPlaylistID(id);
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");

		// setPublishmodal(true);
		if (flag?.isFromEvidya === 1 && id) {
			setPublishmodal(true);
		} else {
			messageApi.open({
				type: "info",
				content: "Published For our Teacher's Only ",
			});
		}
	};
	const publishCancel = () => {
		setStartTime("");
		setEndTime("");
		setEndDate("");
		setStartDate("");
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId([]);
		setPublishmodal(false);
	};
	const Playlistpublish = async () => {
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");
		setPublishmodal(false);

		// console.log("playlistID", playlistID);
		// console.log("flag.user_id", flag.user_id);
		// console.log("classId", classId);

		let res = await ApiConnector("post", `${PLAYLISTPUBLISHED}`, {
			playlist_id: playlistID,
			class_id: classId,
			section_id: sectionId,
			// subject_id: "3",
			school_id: flag.school_id,
			academic_year_id: flag.academic_year_id,
			teacher_id: flag.user_id,
			start_date: startDate,
			end_date: endDate,
			start_time: startTime,
			end_time: endTime,
			time_check: questionTimeCheckedStatus ? 1 : 0,
		});
		// console.log(" playlist publish res", res);

		if (res?.data?.ok) {
			messageApi.open({
				type: "success",
				content: "Published",
			});
		} else {
			messageApi.open({
				type: "error",
				content: "Published failed",
			});
		}
	};
	const [likeIcon, setlikeIcon] = useState<any>({});
	let toggleIcon = async (id, name, liked, totalLike, flag) => {
		setlikeIcon((prevState) => {
			if (id && prevState[id] === 1) {
				const newState = { ...prevState };
				newState[id] = 0;
				return newState;
			} else {
				const newState = { ...prevState };
				newState[id] = 1;
				return newState;
			}
		});
		if (flag === 1) {
			CustomToast("success", `Thank you for liked  ${name}`);
		}
		let res = await ApiConnector("post", `${PLAYLISTLIKE}`, {
			playlist_id: id,
		});
		if (res.data.ok) {
			dispatch(
				playlistLike({
					id: id,
					liked: liked,
					totalLike: totalLike,
				})
			);
		}
	};
	//to navigate to choose animation page
	const redirect_to_edit = async (playlist_id: String) => {
		let get_token = localStorage.getItem("token");
		// console.log(playlist_id);
		try {
			let res = await ApiConnector(
				"get",
				`${BASE_URL}/animation_playlist/${playlist_id}`
			);
			let animation_arr = res.data.data.data[0].mapped_ids?.map(
				(value, index) => {
					let dataset = {
						id: value._id,
						img: value.animation_img,
						name: value.animation_name,
						animation_id: value.animation_id,
					};
					return dataset;
				}
			);
			let questionset_arr = res.data.data.data[0].mapped_ids?.map(
				({ question_id, question_title }) => {
					return { question_id, question_title };
				}
			);
			// console.log(questionset_arr);

			let data_set = {
				mode: 1,
				flag: "1",
				playlist_id: playlist_id,
				data: questionset_arr,
				animation: animation_arr,
				tags: res.data.data.data[0].animation_tags,
				token: get_token,
			};
			let str_val = JSON.stringify(data_set);
			let encode = Base64.encode(str_val);
			if (res.data.ok === true) {
				window.open(`${ANIMATION_CHOOSE_URL}/${encode}`, "_blank");
			}
		} catch (error) {}
	};

	let fetchClass = async () => {
		try {
			setSelectClass([{}]);
			setSelectSection([{}]);
			setSectionId("");
			let res = await ApiConnector("post", `${CLASS_SECTION}`, {
				schoolId: flag?.school_id,
				userId: flag?.user_id,
				userTypeId: flag?.user_type_id,
				// schoolId: "81",
				// userId: "43",
				// userTypeId: "4",
			});
			if (res?.data?.ok) {
				setSelectClass(res?.data?.data?.data);
			}
		} catch (error) {
			console.error(error);
		}
	};
	let classIdSet = (e) => {
		setSelectSection([{}]);
		setSectionId("");
		setClassId(e.target.value);
	};
	let sectionIdSet = (e) => {
		setSectionId(e.target.value);
	};

	const handleEndTime = (e) => {
		setEndTime(e);
	};
	const handleStartTime = (e) => {
		setStartTime(e);
	};
	const handlePlaylistPageChange = (page, pageSize) => {
		setPlaylistPageData({ page: page, pageSize: pageSize });
	};

	if (playlist.tokenExpiredError === true) {
		localStorage.removeItem("token");
		navigate("/");
	}

	const excelDownloadPlayList = async (downLoadId, playlist_name) => {
		let playlistId = downLoadId.toString();
		try {
			axios({
				url: `${DOWNLOAD_PLAYLIST}/${playlistId}`,
				method: "get",
				responseType: "blob",
			})
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", `${playlist_name}.xlsx`);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					CustomToast("error", "Something goes wrong !");
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		setQuestionTimeCheckedStatus(true);
		setSelectClass([{}]);
		setSelectSection([{}]);
		setSectionId("");
		setClassId("");
		if (flag?.isFromEvidya === 1 && publishmodal) {
			fetchClass();
		}
	}, [publishmodal]);
	useEffect(() => {
		if (playlistTime === 0) {
			setQuestionTimeCheckedStatus(false);
		}
		setSelectSection([{}]);
		setSectionId("");
		if (flag?.isFromEvidya === 1) {
			if (classId) {
				setSelectSection([{}]);
				setSectionId("");
				selectClass?.map((elm, index) => {
					if (classId == elm.id) {
						setSelectSection(elm.mappedSections);
					}
				});
			}
		}
	}, [publishmodal, classId]);
	useEffect(() => {
		dispatch(
			PlaylistFetch({
				tag: searchTags,
				page: playlistPageData?.page,
				showItems: playlistPageData?.pageSize,
			})
		);
		setIsShown(false);
		// dispatch(ViewPlaylist(""));
	}, [playlistPageData]);

	// console.log("playlist?.playlistData", playlist);

	return (
		<>
			{playlist?.playlist_loading === false &&
				playlist.playlistData?.length === 0 && (
					<div className="empty-playlist">
						<Empty />
					</div>
				)}
			{playlist?.playlist_loading && (
				<div className="empty-playlist">
					<Loader />
				</div>
			)}
			{/* {playlist.playlistData !== undefined &&
				playlist.playlistData?.length !== 0 && (
					<Row style={{ marginBottom: "6px" }}>
						<HomeSearch
							searchMode={4}
							setShowQuestion={() => {}}
							searchTags={searchTags}
							setSearchTags={setSearchTags}
							pageData={playlistPageData}
							playlistPageData={playlistPageData}
						/>
					</Row>
				)} */}

			{playlist.playlistData !== undefined &&
				playlist.playlistData?.length !== 0 && (
					<Row style={{ marginBottom: "6px" }}>
						<Pagination
							current={playlist?.currentPage}
							total={playlist?.totalItems}
							showSizeChanger
							pageSize={
								playlistPageData
									? playlistPageData?.pageSize
									: 10
							}
							pageSizeOptions={[10, 20, 50, 100]}
							showTotal={
								(total, range) =>
									`Showing ${range[0]}-${range[1]} of ${total} playlist`
								// items in ${playlist?.totalPages} pages
							}
							onChange={(page, pageSize) =>
								handlePlaylistPageChange(page, pageSize)
							}
						/>
					</Row>
				)}

			{playlist.playlistData !== undefined &&
				playlist.playlistData?.length !== 0 &&
				playlist?.playlistData[0]?.map((elm, index) => {
					// console.log("elmelmelm", elm);
					// console.log("elm?.file?.url", elm?.file);

					// let get_token = localStorage.getItem("token");

					// let data_set = {
					// 	data: elm,
					// 	token: get_token,
					// };
					// let str_val = JSON.stringify(data_set);
					// let encoded_data = Base64.encode(str_val);
					return (
						<React.Fragment key={index}>
							<PlaylistViewCard
								index={index}
								Published={Published}
								toggleIcon={toggleIcon}
								likeIcon={likeIcon}
								elm={elm}
								editme={editme}
								deleteme={deleteme}
								redirect_to_edit={redirect_to_edit}
								setPlaylistID={setPlaylistID}
								playlistgetId={playlistgetId}
								setIsShown={setIsShown}
								starCollect={starCollect}
								excelDownloadPlayList={excelDownloadPlayList}
							/>
						</React.Fragment>
					);
				})}

			<Modal
				open={modalValue.open}
				onOk={deleteitem}
				onCancel={handleCancel}
			>
				<p>
					Do you want to delete <b>{modalValue.playlist_name}</b>
					playlist
				</p>
			</Modal>
			{contextHolder}
			<PlaylistPublishedModal
				publishmodal={publishmodal}
				publishCancel={publishCancel}
				Playlistpublish={Playlistpublish}
				classIdSet={classIdSet}
				classId={classId}
				selectClass={selectClass}
				sectionIdSet={sectionIdSet}
				selectSection={selectSection}
				sectionId={sectionId}
				startDate={startDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startTime={startTime}
				setStartTime={setStartTime}
				setEndTime={setEndTime}
				handleEndTime={handleEndTime}
				handleStartTime={handleStartTime}
				endTime={endTime}
				endDate={endDate}
				questionTimeCheckedStatus={questionTimeCheckedStatus}
				setQuestionTimeCheckedStatus={setQuestionTimeCheckedStatus}
				playlistTime={playlistTime}
			/>
		</>
	);
};

export default PlaylistFetchcard;
