import React from "react";
import Loader from "../../utlis/Loader/Loader";

const VerifyMsgLayout = ({ validtoken }) => {
	return (
		<>
			{validtoken === true && (
				<div>
					<div style={{ marginTop: "300px" }}>
						<p>
							Please wait! While we verify your user details and
							you will be redirected to the landing page.
							Meanwhile please don't close the window{" "}
						</p>
					</div>
					{/* return a loder  */}
					<div style={{ marginTop: "30px" }}>
						<Loader />
					</div>
				</div>
			)}
			{validtoken === false && (
				<div>
					<div style={{ marginTop: "300px" }}>
						<h3 style={{ color: "red" }}>
							Something going wrong!!
						</h3>
					</div>
				</div>
			)}
		</>
	);
};

export default VerifyMsgLayout;
