import { Input, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { playlist_fullmarks } from "../../redux/reducers/PlaylistSlice";
import InvalidMsg from "../create/InvalidMsg";
const { Text } = Typography;

const PlaylistFullmarks = () => {
	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);
	// const a =( ...playlist.question_ids)
	// console.log(...playlist.question_ids);

	const marks = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(playlist_fullmarks(e.target.value));
	};
	useEffect(() => {
		if (playlist.mode === 0) {
			const totalmarks = playlist?.question_ids
				.map((obj) => Number(obj.marks))
				.reduce((acc, curr) => acc + curr, 0);
			dispatch(playlist_fullmarks(totalmarks));
		}
	}, [dispatch, playlist.question_ids]);
	useEffect(() => {
		if (playlist.mode === 1) {
			const totalmarks = playlist?.playlist_to_edit?.question_ids
				.map((obj) => Number(obj.marks))
				.reduce((acc, curr) => acc + curr, 0);
			dispatch(playlist_fullmarks(totalmarks));
		}
	}, [dispatch, playlist.playlist_to_edit.question_ids]);

	return (
		<>
			<Row gutter={[24, 6]}>
				<div className="width-100" style={{ padding: "5px" }}>
					<div className="question-mandatory-div">
						<Text
							type="danger"
							className="question-mandatory-label"
						>
							*
						</Text>
						<Text className="question-mandatory-label">
							Full Marks
						</Text>
					</div>
				</div>
				<Input
					value={
						playlist.mode === 0
							? playlist.full_marks
							: playlist.playlist_to_edit.full_marks
					}
					placeholder="Set your full marks"
					readOnly={true}
					// onChange={marks}
					status={playlist.valid?.full_marks ? "error" : ""}
				/>
				<div style={{ height: "16px" }}>
					{"full_marks" in playlist.valid && (
						<InvalidMsg msg={playlist.valid?.full_marks} />
					)}
				</div>
			</Row>
		</>
	);
};

export default PlaylistFullmarks;
