import React, { useState } from "react";
import { Col, Row, Space } from "antd";
// import { Divider, List, Typography } from "antd";
// import { Link } from "react-router-dom";
import QuestionCards from "../components/questionbank/QuestionCards";
import QuestionPreview from "../components/questionbank/QuestionPreview";
import Backtop from "./Backtop";

export interface viewQuestion {
	child_question_ids: string[];
	created_by: string;
	options: [{}];
	question_level: string;
	question_traslation: [{}];
	question_type: string;
	tags: [];
	word_limit: string;
}

const MyQuestionBank = () => {
	const [isShown, setIsShown] = useState(false);
	const [cardValue, setCardValue] = useState<viewQuestion>();
	const [answer, setAnswer] = useState(false);

	return (
		<>
			<div className="layout-content">
				<Space
					direction="vertical"
					size="middle"
					style={{ display: "flex" }}
				>
					<Row gutter={[24, 0]}>
						<Col xs={24} sm={24} md={12} lg={12} xl={16}>
							{/* <p className="search-result-number">100 results found</p> */}
							<QuestionCards
								isShown={isShown}
								setIsShown={setIsShown}
								setCardValue={setCardValue}
								setAnswer={setAnswer}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8}>
							<QuestionPreview
								isShown={isShown}
								cardValue={cardValue}
								showanswer={answer}
								setAnswer={setAnswer}
							/>
						</Col>
					</Row>
				</Space>
			</div>
			<Backtop />
		</>
	);
};

export default MyQuestionBank;
