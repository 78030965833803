import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	// MoreOutlined,
} from "@ant-design/icons";
import { Card, Col, Image, Row, Tag, Typography } from "antd";
import React, { useContext } from "react";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import fallback from "../../assets/images/fallback.gif";
import { ParseHtmlToText } from "../../utlis/parseHtmlTotext/ParseHtmlToText";
const { Text } = Typography;
const parse = require("html-react-parser");

const MainQuestionPreview = () => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;

	return (
		<>
			<Card className="preview-card" hoverable={true}>
				{/* <Row className="preview-more-option">
					<Col xl={24} xs={24} md={24}>
						<MoreOutlined />
					</Col>
				</Row> */}
				<Row>
					<Col xl={24} xs={24} md={24}>
						{state.question.tags.map((elem, index) => {
							return (
								<Tag color="#2db7f5" key={index}>
									{elem}
								</Tag>
							);
						})}
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={24} xs={24} md={24}>
						Question Type : {state.question.question_type}
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={24} xs={24} md={24}>
						<Text>
							{" "}
							Question Language :{" "}
							{state.question_traslation.langCode}
						</Text>
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={24} xs={24} md={24}>
						<Text>
							Question Level : {state.question.question_level}
						</Text>
					</Col>
				</Row>
				<Row>
					<Col xl={18} md={24} xs={24}>
						<Row>
							<Col xl={24} md={24} xs={24}>
								<b className="preview-title">
									Q1.{parse(state.question_traslation.title)}
								</b>
								{/* <h3>
								Q1.
								<div
									dangerouslySetInnerHTML={{
										__html: state.question_traslation.title,
									}}
								/>
							</h3> */}
							</Col>
						</Row>

						{state.question_traslation.description && (
							<Row className="preview-des">
								<p> {state.question_traslation.description}</p>
							</Row>
						)}
						{state.question_traslation.shortHint && (
							<Row className="preview-des">
								<p>
									Short Hint :{" "}
									{state.question_traslation.shortHint}
								</p>
							</Row>
						)}
						{state.question_traslation.longHint && (
							<Row className="preview-des">
								<p>
									Long Hint :{" "}
									{state.question_traslation.longHint}
								</p>
							</Row>
						)}
					</Col>
					<Col xl={4} md={10} xs={24}>
						{state.question.files?.map((elem, index) => {
							return (
								<Image
									className="question-fetch-image"
									width={100}
									fallback={fallback}
									src={elem?.url}
									key={index}
								/>
							);
						})}
					</Col>
					<Col xl={2} md={8} xs={24}></Col>
				</Row>

				<Row>
					{state.question.options.map((elem, index) => {
						let optionTitle = ParseHtmlToText(
							elem.OptionTranslation[0].title
						);
						return (
							<Col xl={12} xs={24} md={12} key={index}>
								<Row>
									<Col xl={1} xs={2} md={2}>
										{elem.isCorrect === 0 && (
											<CloseCircleOutlined />
										)}
										{elem.isCorrect === 1 && (
											<CheckCircleOutlined />
										)}
									</Col>
									<Col xl={9} xs={20} md={11}>
										<Text>{optionTitle}</Text>
									</Col>
								</Row>
								{elem.OptionTranslation[0].imageUrl && (
									<Row>
										<Col xl={8} xs={24} md={24}>
											<Image
												className="question-fetch-image"
												width={70}
												src={
													elem.OptionTranslation[0]
														.imageUrl
												}
											/>
										</Col>
									</Row>
								)}
							</Col>
						);
					})}
				</Row>
			</Card>
		</>
	);
};

export default MainQuestionPreview;
