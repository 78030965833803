import React from "react";

const SvgMinus = () => {
    return (
        <div>
            <svg
                height="15"
                viewBox="0 0 15 15"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m4 7.5h7m-3.5 7c-3.86599 0-7-3.134-7-7 0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7 0 3.866-3.134 7-7 7z"
                    stroke="#fff" style={{fill:"crimson"}}
                />
            </svg>
        </div>
    );
};

export default SvgMinus;
