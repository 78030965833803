import React from "react";

const RedoSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
			>
				<path
					d="M2.22212 12.3715C2.52833 11.9639 2.85721 11.5754 3.22216 11.2105C5.28506 9.14756 8.2826 8 11.2 8C14.1174 8 16.9153 9.15892 18.9782 11.2218C19.3484 11.592 19.6895 11.9859 20 12.3999V9C20 8.44772 20.4477 8 21 8C21.5523 8 22 8.44772 22 9V15C22 15.5523 21.5523 16 21 16H15C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14H18.6834C18.3582 13.5133 17.984 13.056 17.564 12.636C15.8761 10.9482 13.5869 10 11.2 10C8.81303 10 6.3242 10.9368 4.63637 12.6247C4.35769 12.9033 4.09918 13.1984 3.86176 13.5076C3.6878 13.8023 3.36699 14 3 14C2.44772 14 2 13.5523 2 13C2 12.7619 2.08321 12.5433 2.22212 12.3715Z"
				/>
			</svg>
		</>
	);
};

export default RedoSvg;
