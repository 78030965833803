import { Button, Card, Col, Row, Tooltip, Image, Tag, Empty } from "antd";
import React, { useEffect, useState } from "react";
import ColapsableList from "../components/MyPlaylist/Newplaylistui/ColapsableList";
import {
	EllipsisOutlined,
	EyeOutlined,
	LikeFilled,
	LikeOutlined,
	ShareAltOutlined,
} from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import Meta from "antd/lib/card/Meta";
import { ApiConnector } from "../axios/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ViewPlaylist } from "../redux/reducers/PlaylistSlice";
import {
	LIKE,
	PLAYLISTLIKE,
	USERLIKEPLAYLIST,
	USERLIKEQUESTION,
} from "../axios/AllBaseUrl";
import ColapsableCard from "../components/ColapsableCard";
import SearchPlaylistViewCard from "../components/SearchPlaylistViewCard";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import AddFloatingButton from "../components/MyPlaylist/AddFloatingButton";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 8,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1200 },
		items: 5,
	},
	laptop: {
		breakpoint: { max: 1199, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1020, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const LikedPage = () => {
	let flag = FlagLocalhost();
	const dispatch = useDispatch();
	const playlist = useSelector((state: RootState) => state.playlist);
	let [eyePlaylist, setEyePlaylist] = useState<String>("");
	let [likedQuestion, setLikedQuestion] = useState<any>([]);
	let [playListLiked, setPlayListLiked] = useState<any>([]);
	let [showQuestion, setShowQuestion] = useState<boolean>(false);
	const [searchType, setSearchType] = useState(2);
	let [buttonColor, setButtonColor] = useState(0);
	const [playlistID, setPlaylistID] = useState();
	let clickButton = (id) => {
		setShowQuestion(false);
		// 2=== playlis , 1=== Question
		setButtonColor(id);
		setSearchType(id);
	};

	let clickPlaylist = (id) => {
		dispatch(ViewPlaylist(id));
		setShowQuestion(true);
	};
	let likePlaylist = async (id, liked, totalLike, flag, name) => {
		let res = await ApiConnector("post", `${PLAYLISTLIKE}`, {
			playlist_id: id,
		});
		if (res.data.ok) {
			let newArray = playListLiked.filter((e) => e.playlist_id !== id);
			setPlayListLiked([...newArray]);
		}
	};

	let toggleIcon = async (id, liked, totalLike) => {
		let res: any = await ApiConnector("post", `${LIKE}`, {
			question_id: id,
		});
		if (res.data.ok) {
			let newArray = likedQuestion.filter((e) => e?.q_data?._id !== id);
			setLikedQuestion([...newArray]);
		}
	};
	let LikedPlaylist = async () => {
		let res = await ApiConnector("post", `${USERLIKEPLAYLIST}`);
		setPlayListLiked(res.data.data);
		// console.log("res", res.data.data.length);
		return res.data.data;
	};
	let LikedQuestion = async () => {
		let res = await ApiConnector("post", `${USERLIKEQUESTION}`);
		// console.log("res", res);
		setLikedQuestion(res.data.data);
		return res.data.data;
	};

	useEffect(() => {
		LikedPlaylist();
	}, []);
	useEffect(() => {
		if (searchType === 1) {
			LikedQuestion();
		}
	}, [searchType]);

	return (
		<>
			<Row gutter={[24, 0]} className="front_button" justify="center">
				<Col xs={12} xl={4} md={8}>
					<Tooltip placement="left" title=" Liked Question">
						<Button
							type="primary"
							size="large"
							className="Myquestionbutton"
							style={
								buttonColor === 1
									? {
											background: "rgba(0, 203, 244, 1)",
									  }
									: {}
							}
							onClick={() => clickButton(1)}
						>
							Liked Question
						</Button>
					</Tooltip>
				</Col>
				<Col xs={12} xl={4} md={8}>
					<Tooltip placement="right" title="Liked Playlist">
						<Button
							type="primary"
							size="large"
							className="Myquestionbutton"
							style={
								buttonColor === 2
									? {
											background: "rgba(0, 203, 244, 1)",
									  }
									: {}
							}
							onClick={() => clickButton(2)}
						>
							Liked Playlist
						</Button>
					</Tooltip>
				</Col>
			</Row>
			<Row gutter={[24, 0]} className="front">
				<Col xxl={2} xl={2} md={2} xs={2}></Col>
				<Col xxl={20} xl={20} md={20} xs={20}>
					<Card
						style={{
							width: "100%",
							height: "700px",
							backgroundColor: "transparent",
							boxShadow: "0 0px 0px rgb(0 0 0 / 0%)",
						}}
					>
						{searchType === 1 && likedQuestion?.length === 0 && (
							<div>
								<Empty />
							</div>
						)}
						{searchType === 1 && (
							<>
								<Row gutter={[24, 0]}>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
									>
										{likedQuestion?.length > 0 &&
											likedQuestion?.map(
												(value, index) => {
													return (
														// <FetchCards
														// 	value={value}
														// 	index={index}
														// 	isShown={isShown}
														// 	setIsShown={setIsShown}
														// 	setCardValue={
														// 		setCardValue
														// 	}
														// 	key={index}
														// 	setAnswer={setAnswer}
														// />
														<ColapsableCard
															onChange={() => {}}
															index={index}
															value={
																value?.q_data
															}
															Option={
																value?.q_options
															}
															Questionadded={() => {}}
															toggleIcon={
																toggleIcon
															}
															image={
																value?.q_files
															}
															question_setting={
																value?.question_setting
															}
														/>
													);
												}
											)}
									</Col>
									{/* <Col xs={24} sm={24} md={12} lg={12} xl={8}>
									<QuestionPreview
										isShown={isShown}
										cardValue={cardValue}
										showanswer={answer}
										setAnswer={setAnswer}
									/>
								</Col> */}
								</Row>
							</>
						)}
						{searchType === 2 && (
							<>
								<Row gutter={[24, 0]}>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
									>
										<Carousel
											responsive={responsive}
											showDots={true}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlaySpeed={1000}
											keyBoardControl={true}
											customTransition="all .5"
											transitionDuration={500}
											containerClass="carousel-container"
											dotListClass="custom-dot-list-style"
										>
											{searchType === 2 &&
												playListLiked?.length === 0 && (
													<div>
														<Empty />
													</div>
												)}

											{searchType === 2 &&
												playListLiked?.map(
													(elm, index) => {
														return (
															<>
																<SearchPlaylistViewCard
																	elm={elm}
																	playlist_id={
																		elm?.playlist_id
																	}
																	index={
																		index
																	}
																	setShowQuestion={
																		setShowQuestion
																	}
																	likePlaylist={
																		likePlaylist
																	}
																	setEyePlaylist={
																		setEyePlaylist
																	}
																	eyePlaylist={
																		eyePlaylist
																	}
																	playlistID={
																		playlistID
																	}
																	setPlaylistID={
																		setPlaylistID
																	}
																/>
															</>
														);
													}
												)}
										</Carousel>
									</Col>
								</Row>
							</>
						)}
						{showQuestion === true && (
							<Row style={{ marginTop: "15px" }}>
								<Col xxl={24} xl={24} md={24} xs={20}>
									{!playlist.loading &&
										playlist.playlistQuestions?.q_array
											?.length === 0 && (
											<h1>
												<b>
													No questions in this
													playlist !
												</b>
											</h1>
										)}
									{!playlist.loading &&
										playlist.playlistQuestions?.q_array?.map(
											(value, index) => (
												<ColapsableList
													key={index}
													value={value}
												/>
											)
										)}
								</Col>
							</Row>
						)}
					</Card>

					<Col xxl={2} xl={2} md={2} xs={2}></Col>
				</Col>
			</Row>
			<Row
				gutter={[24, 0]}
				style={{ position: "fixed", bottom: "4vh", right: "4vw" }}
			>
				{flag?.isFromEvidya === 1 && <AddFloatingButton />}
			</Row>
		</>
	);
};

export default LikedPage;
