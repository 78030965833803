import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Input } from "antd";
import createQuesContext from "../../context/createQuestion/createQuesContext";

const Limitwords = () => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	const [limit, setLimit] = useState(false);

	let [ques, setQues] = useState<any>({});

	const get_word_value = () => {
		let currentQState = state.mode;
		let quData: string = "";
		if (currentQState === 0) {
			quData = state.question;
		}
		if (currentQState === 1) {
			quData = state.subQuestion;
		}
		if (currentQState === 2) {
			quData = state.question.subQuestions[state.edit_id];
		}

		setQues(quData);
	};
	useEffect(() => {
		get_word_value();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	const toggle = () => {
		if (state.mode === 0) {
			if (state.question.word_limit_checked === false) {
				dispatch({ type: "set_word_limit_checked", value: true });
			}
			if (state.question.word_limit_checked === true) {
				dispatch({ type: "set_word_limit_checked", value: false });
				dispatch({ type: "blank_word_limit" });
			}
		}
		if (state.mode === 1) {
			if (state.subQuestion.word_limit_checked === false) {
				dispatch({ type: "set_sub_word_limit_checked", value: true });
			}
			if (state.subQuestion.word_limit_checked === true) {
				dispatch({ type: "set_sub_word_limit_checked", value: false });
				dispatch({ type: "sub_blank_word_limit" });
			}
		}
		if (state.mode === 2) {
			if (
				state.question.subQuestions[state.edit_id]
					.word_limit_checked === false
			) {
				dispatch({
					type: "edit_sub_word_limit_checked",
					value: true,
					id: state.edit_id,
				});
			}
			if (
				state.question.subQuestions[state.edit_id]
					.word_limit_checked === true
			) {
				dispatch({
					type: "edit_sub_word_limit_checked",
					value: false,
					id: state.edit_id,
				});
				dispatch({
					type: "edit_sub_blank_word_limit",
					id: state.edit_id,
				});
			}
		}
	};

	const limit_value = (e: any) => {
		if (state.mode === 0) {
			dispatch({ type: "set_word_limit", value: e.target.value });
		}
		if (state.mode === 1) {
			dispatch({ type: "set_sub_word_limit", value: e.target.value });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_sub_word_limit",
				value: e.target.value,
				id: state.edit_id,
			});
		}
	};

	return (
		<>
			<Checkbox
				onClick={() => {
					toggle();
				}}
				checked={ques.word_limit_checked}
			>
				Limit your answer in words
			</Checkbox>
			{ques.word_limit_checked && (
				<Input
					value={ques.word_limit}
					className="limit-word-input"
					placeholder="Eg. 200, 400"
					onChange={limit_value}
				/>
			)}
		</>
	);
};

export default Limitwords;
