import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ApiConnector } from "../axios/apiClient";
import { QUESTIONANSWER } from "../axios/AllBaseUrl";
import { useLocation } from "react-router-dom";
import QNA from "../components/PlaylistCheck/QNA";
import HeaderExam from "../components/PlaylistCheck/HeaderExam";

const StudentResult = () => {
	// const navigate = useNavigate();
	let location = useLocation();
	// console.log("location", location.state);

	let [questionData, setQuestionData] = useState<any>();
	const [open, setOpen] = useState(false);
	let [obtainedMark, setObtainedMark] = useState(0);
	let [marksArray, setMarksArray] = useState<any>([
		{
			questionNumber: "",
			marks: "",
			blob: "",
			answer_id: "",
			question_id: "",
		},
	]);
	// console.log("marksArray", marksArray);

	let onchangeMarksValue = (
		QuestionNumber,
		Marks,
		answer_id,
		question_id,
		blob
	) => {
		setMarksArray((prevMarksArray) => {
			// Find the index of the object with the matching questionNumber
			const index = prevMarksArray.findIndex(
				(item) => item.questionNumber === QuestionNumber
			);

			if (index !== -1) {
				// If the questionNumber exists, update its marks
				const updatedArray = [...prevMarksArray];
				// updatedArray[index] = {
				updatedArray[index].questionNumber = QuestionNumber;
				updatedArray[index].marks = Marks;
				updatedArray[index].answer_id = answer_id;
				updatedArray[index].question_id = question_id;
				// };
				return updatedArray;
			} else {
				// If the questionNumber doesn't exist, add a new object
				return [
					...prevMarksArray,
					{
						questionNumber: QuestionNumber,
						marks: Marks,
						blob: blob,
						answer_id: answer_id,
						question_id: question_id,
					},
				];
			}
		});
	};
	let totalObtained = () => {
		// Initialize a variable to store the total sum
		let total = 0;
		// Loop through the array and add each "a" value to the total (converting them to numbers)
		for (let i = 0; i < marksArray?.length; i++) {
			total += Number(marksArray[i]?.marks);
		}
		setObtainedMark(total);
	};

	const hide = () => {
		setOpen(false);
	};
	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};
	console.log("location?.state", location?.state);

	let fetchQuestion = async () => {
		let res = await ApiConnector("post", `${QUESTIONANSWER}`, {
			// playlistID: "63f85eb43ca24249bbdc12a6", //local
			// userId: "63f72ad1305687320915f95c", //local

			//  playlistID: "650bebe783ee2a877d4d9d07", 	//local
			// playlistID: "650d39568b7eff5ab2ebc193",
			// userId: "635f89fff2e60d1667aca7e0", //local(student_id)
			playlistID: location?.state?.playlistData?.playlist_id,
			userId: location?.state?.user_id,
			exam_playlist_id: location?.state?.otherSidePlaylistData?.id,
		});
		if (res?.data?.ok) {
			setQuestionData(res?.data?.data);
		}
	};
	// console.log("questionData", questionData);

	useEffect(() => {
		fetchQuestion();
	}, []);
	useEffect(() => {
		if (marksArray) {
			totalObtained();
		}
	}, [marksArray]);

	if (location?.state?.mode === 1 && location?.state?.remarks === false) {
		return (
			<>
				<Row
					gutter={24}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						margin: 0,
					}}
				>
					<Col style={{ fontWeight: "bold", fontSize: "24px" }}>
						You need to check the answer first
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			{questionData && (
				<>
					<Row gutter={[24, 6]} style={{ marginBottom: "20px" }}>
						<Col xxl={24} xl={24} xs={24} md={24}>
							<HeaderExam
								open={open}
								handleOpenChange={handleOpenChange}
								hide={hide}
								marksArray={marksArray}
								obtainedMark={obtainedMark}
								playlistData={location?.state?.playlistData}
								studentName={location?.state?.studentName}
								checkExamValue={location?.state}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 6]}>
						<Col xxl={2} xl={2} xs={2} md={2}></Col>
						<Col xxl={20} xl={20} xs={20} md={20}>
							{questionData?.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<QNA
											QuestionType={
												value?.questions?.question_type
											}
											value={value}
											QuestionNumber={index + 1}
											onchangeMarksValue={
												onchangeMarksValue
											}
											setMarksArray={setMarksArray}
											marksArray={marksArray}
											// editState={location.state}
											editState={{ mode: 2 }} //local
										/>
									</React.Fragment>
								);
							})}
						</Col>
						<Col xxl={2} xl={2} xs={2} md={2}></Col>
					</Row>
				</>
			)}
		</>
	);
};

export default StudentResult;
