const PlaylistValidate = (playlist: any) => {
	let error: any = {};
	if (
		!playlist.playlist_name ||
		playlist.playlist_name?.trim().length === 0
	) {
		error.playlistName = "Playlist name can't be blank ";
	}
	if (!playlist.tag || playlist.tag.length === 0) {
		error.tag = "Playlist tag can't be blank";
	}
	// if (
	// 	!playlist.playlist_time ||
	// 	playlist.playlist_time?.length === 0
	// ) {
	// 	error.playlist_time = "Playlist set time can't be blank";
	// }
	// if (!playlist.full_marks || playlist.full_marks?.trim().length === 0) {
	// 	error.full_marks = "Playlist Full marks can't be blank";
	// }
	return error;
};

export default PlaylistValidate;
