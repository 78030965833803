import React from "react";

const SvgUpload = () => {
	return (
		<div>
			<svg
				enableBackground="new 0 0 52 52"
				height="30"
				viewBox="0 0 52 52"
				width="30"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="m50 10c0-2.2-1.8-4-4-4h-40c-2.2 0-4 1.8-4 4v32c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4zm-10.4 28h-29c-1.2 0-1.9-1.3-1.3-2.3l8.8-15.3c.4-.7 1.3-.7 1.7 0l5.3 9.1c.4.6 1.3.7 1.7.1l4.3-6.2c.4-.6 1.3-.6 1.7 0l7.9 12.6c.6.9 0 2-1.1 2zm-2.6-18c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"
					style={{ fill: "rgb(0,48,90)" }}
				/>
			</svg>
		</div>
	);
};

export default SvgUpload;
