import {
	BarChartOutlined,
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	EllipsisOutlined,
	LikeFilled,
	LikeOutlined,
	ShareAltOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import {
	Button,
	Card,
	Col,
	Popover,
	Rate,
	Row,
	Tag,
	Tooltip,
	Image,
} from "antd";
import React, { useState } from "react";
import Publish from "../../svg/Publish";
import animation_icon from "../../../assets/images/121628-smiley.gif";
import fallback from "../../../assets/images/fallback.gif";
import TagAnalysis from "../../questionbank/TagAnalysis";
import ShareIcon from "../../Share/ShareIcon";
const content = <TagAnalysis />;

const PlaylistViewCard = ({
	index,
	Published,
	toggleIcon,
	likeIcon,
	elm,
	editme,
	deleteme,
	redirect_to_edit,
	setPlaylistID,
	playlistgetId,
	setIsShown,
	starCollect,
	excelDownloadPlayList,
}) => {
	// console.log("vale of playlist ", elm);

	const [copied, setCopied] = useState(false);
	let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	let [shareId, setShareId] = useState("");

	const shareme = (id) => {
		setShareId(id);
		setIsModalOpen(true);
	};
	const handleCancel = () => {
		setCopied(false);
		setIsModalOpen(false);
	};
	const handleOk = () => {
		setCopied(false);
		setIsModalOpen(false);
	};
	// local -http://192.168.0.123:8086/#/receive/${shareId}
	const playListShareUrl = `https://adhyandigital.com/viewQBPL/#/receive/${shareId}`;
	// console.log("elm", elm);

	return (
		<>
			<Card
				hoverable={true}
				// onClick={() => {
				//     setPlaylistID(elm._id);
				//     playlistgetId(elm._id);
				//     setIsShown(true);
				// }}
				key={index}
				className={
					index % 2 === 0
						? "question-fetch-card-odd"
						: "question-fetch-card-even"
				}
				actions={[
					<Tooltip placement="top" title="Published Playlist">
						<div
							//  className="publish-button"
							onClick={() =>
								Published(elm._id, elm?.playlist_time)
							}
						>
							<Publish />
						</div>
					</Tooltip>,
					<>
						<span>
							{elm.liked === true ? (
								<LikeFilled
									onClick={() =>
										toggleIcon(
											elm._id,
											elm.playlist_name,
											elm.liked,
											elm.totalLike,
											2
										)
									}
									style={{ color: "#00305a" }}
								/>
							) : (
								<LikeOutlined
									onClick={() =>
										toggleIcon(
											elm?._id,
											elm?.playlist_name,
											elm.liked,
											elm.totalLike,
											1
										)
									}
								/>
							)}
							{elm.totalLike > 0 ? elm.totalLike : ""}
						</span>
					</>,
					// <Tooltip
					//     placement="top"
					//     title="Total number of Questions"
					// >
					//     <p>{elm?.questions?.length}</p>
					// </Tooltip>,
					<ShareAltOutlined
						key="share"
						onClick={() => shareme(elm._id)}
					/>,
					// <Tooltip placement="top" title="20 partcipants">
					// 	<UsergroupAddOutlined />
					// 	20
					// </Tooltip>,
					<Tooltip
						placement="top"
						title="Edit"
						className="rating-tooltip"
					>
						<EditOutlined key="edit" onClick={() => editme(elm)} />
					</Tooltip>,
					<Tooltip
						placement="top"
						title="Delete"
						className="rating-tooltip"
					>
						<DeleteOutlined
							key="delete"
							onClick={() =>
								deleteme(elm?._id, elm?.playlist_name)
							}
						/>
					</Tooltip>,
					<Tooltip placement="top" title="Download Playlist">
						<DownloadOutlined
							onClick={() =>
								excelDownloadPlayList(
									elm._id,
									elm?.playlist_name
								)
							}
						/>
					</Tooltip>,

					<>
						{elm?.playlist_type === 1 ? (
							<Tooltip
								placement="top"
								title="Edit Animations"
								className="rating-tooltip"
							>
								<Image
									width={30}
									src={animation_icon}
									preview={false}
									onClick={() => {
										redirect_to_edit(elm?._id);
									}}
								/>
							</Tooltip>
						) : (
							<Tooltip
								placement="top"
								title="Total number of Questions"
							>
								<p>{elm?.questions?.length}</p>
							</Tooltip>
						)}
					</>,

					<Tooltip
						placement="top"
						title={
							<>
								{/* <p>Tag: </p>
								{elm?.tag?.map((value, i) => {
									return (
										<Tag color="processing" key={i}>
											{value?.substring(0, 2)}
										</Tag>
									);
								})} */}
								<p>
									Total Questions:
									{elm?.questions?.length}
								</p>
								<p>
									Full Marks:
									{elm?.full_marks}
								</p>
								<p>
									Time limit:
									{elm?.playlist_time}
								</p>
							</>
						}
					>
						<EllipsisOutlined key="ellipsis" />
					</Tooltip>,
				]}
			>
				<Row
					gutter={[24, 6]}
					className="image-col"
					onClick={() => {
						setPlaylistID(elm?._id);
						playlistgetId(elm?._id);
						setIsShown(true);
					}}
				>
					{/* <Col xl={3} xs={5} md={5}>
									{elm?.file?.is_active === true &&
										elm?.file?.url &&
										elm?.file?.url?.trim().length !== 0 && (
											<Image
												width={100}
												src={elm.file.url}
												fallback={fallback}
											/>
										)}
								</Col> */}
					{/* &&
						elm?.file?.url?.trim().length !== 0 elm?.file?.is_active === true && */}
					<Col xl={3} xs={3} md={5}>
						{elm?.file?.url?.trim().length !== 0 &&
						elm?.file?.url ? (
							<Image
								width={100}
								src={elm?.file.url}
								style={{ height: "90px", width: "90px" }}
							/>
						) : (
							<Image width={120} src={fallback} />
						)}
					</Col>

					<Col xl={21} xs={19} md={19}>
						<Row>
							{/* <Col xl={3} md={5} xs={7}></Col>
							<Col
								xl={3}
								xs={6}
								md={6}
								className={
									index % 2 === 0
										? "question-fetch-avatar-col-rating-number-odd"
										: "question-fetch-avatar-col-rating-number-even"
								}
							>
								4.2
							</Col>
							<Col xl={10} xs={8} md={8}>
								<Tooltip
									placement="top"
									title="4.2 out of 5"
									className="rating-tooltip"
								>
									<Rate
										className="playlist-star-size"
										allowHalf
										onChange={starCollect}
										defaultValue={4.2}
									/>
								</Tooltip>
							</Col>
							<Col xl={4}></Col> */}
							{/* <Col xl={2} xs={1} md={4}>
								<Popover
									content={content}
									title="Why is it Trending?"
									placement="leftBottom"
									className="tag-analysis-report"
								>
									<Button type="link">
										<BarChartOutlined />
									</Button>
								</Popover>
							</Col> */}
						</Row>

						<Row gutter={[24, 6]}>
							<Col xl={4} md={5} xs={8}></Col>
							<Col xl={20} md={17} xs={16}>
								{/* <Tooltip
									placement="top"
									title={elm?.playlist_name}
								> */}
								<div style={{ display: "inline-block" }}>
									<h1
									// style={{
									// 	whiteSpace: "nowrap",
									// 	width: "200px",
									// 	overflow: "hidden",
									// 	textOverflow: "ellipsis",
									// }}
									>
										<strong>{elm?.playlist_name}</strong>
									</h1>
								</div>
								{/* </Tooltip> */}
							</Col>
						</Row>
						<Row gutter={[24, 6]}>
							<Col xl={4} md={7} xs={7}></Col>
							<Col xl={20} md={17} xs={17}>
								{elm?.tag?.map((value, i) => (
									<Tag color="blue" key={i}>
										{value}
									</Tag>
								))}
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
			<ShareIcon
				isModalOpen={isModalOpen}
				handleCancel={handleCancel}
				url={playListShareUrl}
				handleOk={handleOk}
				copied={copied}
				setCopied={setCopied}
			/>
		</>
	);
};

export default PlaylistViewCard;
