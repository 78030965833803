import React from "react";

const Option = () => {
	return (
		<>
			<svg
				fill="#00305a"
				height="40"
				viewBox="0 0 20 20"
				width="40"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g fill="#00305a">
					<path
						d="m3.25 5.5c.69036 0 1.25-.55964 1.25-1.25s-.55964-1.25-1.25-1.25-1.25.55964-1.25 1.25.55964 1.25 1.25 1.25z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m7 4.25c0-.41421.33579-.75.75-.75h9.5c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-9.5c-.41421 0-.75-.33579-.75-.75z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m7 8.75c0-.41421.33579-.75.75-.75h9.5c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-9.5c-.41421 0-.75-.33579-.75-.75z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m7.75 12.5c-.41421 0-.75.3358-.75.75s.33579.75.75.75h1.27242c.04729-.5247.16827-1.0282.35252-1.5z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m3.25 10c.69036 0 1.25-.55964 1.25-1.25s-.55964-1.25-1.25-1.25-1.25.55964-1.25 1.25.55964 1.25 1.25 1.25z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m3.25 14.5c.69036 0 1.25-.5596 1.25-1.25s-.55964-1.25-1.25-1.25-1.25.5596-1.25 1.25.55964 1.25 1.25 1.25z"
						style={{ fill: "#00305a" }}
					/>
					<path
						d="m19 14.5c0 2.4853-2.0147 4.5-4.5 4.5s-4.5-2.0147-4.5-4.5 2.0147-4.5 4.5-4.5 4.5 2.0147 4.5 4.5zm-4-2c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5v1.5h-1.5c-.2761 0-.5.2239-.5.5s.2239.5.5.5h1.5v1.5c0 .2761.2239.5.5.5s.5-.2239.5-.5v-1.5h1.5c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5h-1.5z"
						style={{ fill: "#00305a" }}
					/>
				</g>
			</svg>
		</>
	);
};

export default Option;
