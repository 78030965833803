import { Card, Input } from "antd";
import React from "react";
const { Search } = Input;

const ExamDashboardSearch = ({
	onSearch,
	placeholder,
	searchTerm,
	handleChange,
}) => {
	return (
		<>
			<Card bordered={false}>
				<Search
					placeholder={placeholder}
					onChange={(e) => handleChange(e)}
					enterButton
				/>
			</Card>
		</>
	);
};

export default ExamDashboardSearch;
