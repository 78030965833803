import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { DrawerProps } from "antd/es/drawer";
import DrawerLayout from "./DrawerLayout";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
	const [visible, setVisible] = useState(false);
	// const [placement, setPlacement] = useState("right");
	const [sidenavColor, setSidenavColor] = useState("#1890ff");
	const [sidenavType, setSidenavType] = useState("transparent");
	const [fixed, setFixed] = useState(false);

	const openDrawer = () => setVisible(!visible);
	const handleSidenavType = (type) => setSidenavType(type);
	const handleSidenavColor = (color) => setSidenavColor(color);
	const handleFixedNavbar = (type) => setFixed(type);

	//code for drawer starts
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] =
		useState<DrawerProps["placement"]>("left");

	const showDrawer = () => {
		setOpen(true);
		setPlacement("left");
	};

	const onClose = () => {
		setOpen(false);
		setPlacement("left");
	};
	//code for drawer ends

	let { pathname } = useLocation();
	pathname = pathname.replace("/", "");

	useEffect(() => {
		if (pathname === "rtl") {
			setPlacement("left");
		} else {
			setPlacement("left");
		}
	}, [pathname]);
	const incomingString = localStorage.getItem("evidya_data")!;
	const incomingdata = JSON.parse(incomingString);
	if (incomingdata?.isFromEvidya === 1) {
		return (
			<Layout
				className={`layout-dashboard ${
					pathname === "profile" ? "layout-profile" : ""
				} ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
			>
				<Content>{children}</Content>
				<Footer />
			</Layout>
		);
	}

	return (
		<Layout
			className={`layout-dashboard ${
				pathname === "profile" ? "layout-profile" : ""
			} ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
		>
			<Drawer
				className="sidenav-drawer"
				title={false}
				placement={"left"}
				closable={true}
				onClose={() => setVisible(false)}
				open={visible}
				key={"left"}
				width={250}
			>
				<DrawerLayout onClose={onClose} />
			</Drawer>
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				onCollapse={(collapsed, type) => {
					// console.log(collapsed, type);
				}}
				trigger={null}
				width={80}
				theme="light"
				className={`sider-primary ant-layout-sider-primary ${
					sidenavType === "#fff" ? "active-route" : ""
				}`}
				style={{ background: sidenavType }}
			>
				<Sidenav
					color={sidenavColor}
					showDrawer={showDrawer}
					placement={placement}
					onClose={onClose}
					open={open}
					key={1}
				/>
			</Sider>

			<Layout>
				<div className="header-main-footer-page">
					<div className="header-fix-page">
						{fixed ? (
							<Affix>
								<AntHeader
									className={`${
										fixed ? "ant-header-fixed" : ""
									}`}
								>
									<Header
										onPress={openDrawer}
										name={pathname}
										subName={pathname}
										handleSidenavColor={handleSidenavColor}
										handleSidenavType={handleSidenavType}
										handleFixedNavbar={handleFixedNavbar}
										placement={undefined}
									/>
								</AntHeader>
							</Affix>
						) : (
							<AntHeader
								className={`${fixed ? "ant-header-fixed" : ""}`}
							>
								<Header
									onPress={openDrawer}
									name={pathname}
									subName={pathname}
									handleSidenavColor={handleSidenavColor}
									handleSidenavType={handleSidenavType}
									handleFixedNavbar={handleFixedNavbar}
									placement={undefined}
								/>
							</AntHeader>
						)}
					</div>
					<Content className="content-ant">{children}</Content>
					<Footer />
				</div>
			</Layout>
		</Layout>
	);
}

export default Main;
