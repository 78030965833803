import React from "react";

const MarksObtained = ({ value, index }) => {
	return (
		<>
			<div
				style={{
					width: "100%",
					marginTop: "10px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					fontSize: "1rem",
					padding: "5px 0.5rem",
					color: "#666666",
					borderBottom: "1px dashed #c8c8c8",
				}}
				key={index}
			>
				{value?.questionNumber > 0 && (
					<span>
						Question {value?.questionNumber}
						{/* Question 1 */}
					</span>
				)}
				<span>
					{/* 4 */}
					{value?.marks}
				</span>
			</div>
		</>
	);
};

export default MarksObtained;
