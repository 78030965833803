import React, { useState, useRef, useEffect } from "react";
import PencilSvg from "../components/svg/PencilSvg";
import { Button, Modal, Tooltip } from "antd";
import UndoSvg from "../components/svg/UndoSvg";
import RedoSvg from "../components/svg/RedoSvg";
import PenSizeIconSvg from "../components/svg/PenSizeIconSvg";
import ColorIconSVG from "../components/svg/ColorIconSVG";
import { ApiConnector } from "../axios/apiClient";
import { CONVERTIMGTOBASE64 } from "../axios/AllBaseUrl";

interface TextProps {
	initialX: number;
	initialY: number;
}

type Tool = "pen" | "eraser" | "text";

const MyCanva = ({
	initialX,
	initialY,
	answerText,
	setMarksArray,
	marksArray,
	QuestionNumber,
	canvaStyle,
	editState,
	editObj,
	setEditObj,
	value,
	modal2Open,
	setModal2Open,
	exitFullscreen,
	setCanvasBlob,
	confirmModal,
	base64Image,
}) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const ctx = canvasRef.current?.getContext("2d");
	const [isDragging, setIsDragging] = useState(false);
	const [offset, setOffset] = useState<{ x: number; y: number }>({
		x: 0,
		y: 0,
	});

	const [textPosition, setTextPosition] = useState<{ x: number; y: number }>({
		x: initialX,
		y: initialY,
	});
	const [drawingContext, setDrawingContext] =
		useState<CanvasRenderingContext2D | null>(null);
	const [currentTool, setCurrentTool] = useState<Tool>("pen");
	const [isDrawing, setIsDrawing] = useState(false);

	const [canvasHistory, setCanvasHistory] = useState<any[]>([]);
	const [currentCanvasIndex, setCurrentCanvasIndex] = useState<number>(0);
	const [currentText, setCurrentText] = useState<string>("");

	const [undoStack, setUndoStack] = useState<string[]>([]);
	const [redoStack, setRedoStack] = useState<string[]>([]);
	const [penSize, setPenSize] = useState<number>(2); // Initial pen size
	const [eraserSize, setEraserSize] = useState<number>(10); // Initial eraser size
	const [penColor, setPenColor] = useState<string>("#000000"); // Initial pen color
	const [canvasWidth, setCanvasWidth] = useState<number>(200);
	const [canvasHeight, setCanvasHeight] = useState<number>(200);
	const ImgLink =
		value?.editData[0]?.remarksURL && value?.editData[0]?.remarksURL
			? value?.editData[0]?.remarksURL
			: value?.answers[0]?.answer_data?.url;
	// console.log("ImgLink", ImgLink);

	// const [base64Image, setBase64Image] = useState<any>();

	// const convertImgToBase64 = async () => {
	// 	try {
	// 		let res = await ApiConnector("post", `${CONVERTIMGTOBASE64}`, {
	// 			url: ImgLink,
	// 		});
	// 		if (res?.data?.ok) {
	// 			setBase64Image(res?.data?.data?.data);
	// 		}
	// 	} catch (error) {
	// 		console.log("img convert error ", error);
	// 	}
	// };

	if (ctx) {
		if (undoStack.length === 0) {
			undoStack.push(canvasRef.current?.toDataURL() || "");
		}
	}

	const handleMouseDown = (e: any) => {
		if (!canvasRef.current) return;

		const ctx = canvasRef.current.getContext("2d");
		if (!ctx) return;

		const mouseX =
			e.clientX - canvasRef.current.getBoundingClientRect().left;
		const mouseY =
			e.clientY - canvasRef.current.getBoundingClientRect().top;

		if (currentTool === "pen" && !isDrawing) {
			setIsDrawing(true);
			ctx.beginPath();
			ctx.moveTo(mouseX, mouseY);
		} else if (currentTool === "eraser") {
			ctx.lineWidth = eraserSize; // Set eraser size
			ctx.clearRect(mouseX, mouseY, eraserSize, eraserSize); // Clear with eraser size
		} else if (currentTool === "text") {
			const newTextX = mouseX;
			const newTextY = mouseY;
			setTextPosition({ x: newTextX, y: newTextY });
		}

		saveCanvasState();
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		if (isDrawing && currentTool === "pen" && drawingContext) {
			const mouseX =
				e.clientX - canvasRef.current!.getBoundingClientRect().left;
			const mouseY =
				e.clientY - canvasRef.current!.getBoundingClientRect().top;

			drawingContext.lineTo(mouseX, mouseY);
			drawingContext.stroke();
			handleUploadURL();
		}
	};

	const handleMouseUp = () => {
		setIsDrawing(false);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.closePath();
		}
	};

	const saveCanvasState = () => {
		if (!canvasRef.current || !drawingContext) return;

		// const newCanvasState = canvasRef.current.toDataURL();
		// const historyCopy = canvasHistory.slice(0, currentCanvasIndex + 1);
		const canvasState = canvasRef.current?.toDataURL() || "";
		setUndoStack((prevStack) => [...prevStack, canvasState]);
		setRedoStack([]);

		// setCanvasHistory([...historyCopy, newCanvasState]);
		// setCurrentCanvasIndex(currentCanvasIndex + 1);
	};

	const undo = () => {
		if (undoStack.length > 1) {
			const newUndoStack = undoStack.slice(0, undoStack.length - 1);
			setRedoStack((prevStack) => [
				...prevStack,
				undoStack[undoStack.length - 1],
			]);
			setUndoStack(newUndoStack);
			drawImageFromUrl(newUndoStack[newUndoStack.length - 1]);
		}
	};

	const redo = () => {
		if (redoStack.length > 0) {
			const newRedoStack = redoStack.slice(0, redoStack.length - 1);
			setUndoStack((prevStack) => [
				...prevStack,
				redoStack[redoStack.length - 1],
			]);
			setRedoStack(newRedoStack);
			drawImageFromUrl(redoStack[redoStack.length - 1]);
		}
	};

	const drawImageFromUrl = (url: string) => {
		if (!ctx) return;
		const img = new Image();
		img.src = url;
		img.onload = () => {
			ctx.clearRect(
				0,
				0,
				canvasRef.current!.width,
				canvasRef.current!.height
			);
			ctx.drawImage(img, 0, 0);
		};
	};
	const textAdd = () => {
		if (ctx) {
			ctx.fillStyle = "#000000";
			ctx.font = "20px Arial";
			ctx.fillText(currentText, textPosition.x, textPosition.y); // Position the text
		}
	};
	const handlePenSizeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSize = parseInt(event.target.value, 10);
		setPenSize(newSize);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.lineWidth = newSize;
		}
	};

	const handleEraserSizeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSize = parseInt(event.target.value, 10);
		setEraserSize(newSize);
		if (currentTool === "eraser" && drawingContext) {
			drawingContext.lineWidth = newSize;
		}
	};
	const handlePenColorChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newColor = event.target.value;
		setPenColor(newColor);
		if (currentTool === "pen" && drawingContext) {
			drawingContext.strokeStyle = newColor;
		}
	};
	const handleDownload = () => {
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		const downloadLink = document.createElement("a");

		downloadLink.href = canvas.toDataURL("image/png");
		// console.log("downloadLink", downloadLink.href);
		downloadLink.download = "canvas_image.png";
		downloadLink.click();
	};
	const handleUploadURL = () => {
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		const dataUrl = canvas.toDataURL("image/png");

		// Extract the mime type and base64 data from the image string
		const parts = dataUrl?.split(";base64,");
		const mimeType = parts[0].split(":")[1];
		const base64Data = parts[1];

		// Convert the base64 data to a Uint8Array
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		// Create a Blob object from the Uint8Array and mime type
		const blob = new Blob([byteArray], { type: mimeType });
		// console.log(blob);
		setCanvasBlob(blob);

		// setMarksArray((prevMarksArray) => {
		// 	// Find the index of the object with the matching questionNumber
		// 	const index = prevMarksArray?.findIndex(
		// 		(item) => item.questionNumber === QuestionNumber
		// 	);

		// 	if (index !== -1) {
		// 		// If the questionNumber exists, update its marks
		// 		const updatedArray = [...prevMarksArray];
		// 		updatedArray[index].blob = blob; // Update the blob value directly
		// 		return updatedArray;
		// 	}
		// });
	};

	const handleTouchStart = (e: React.TouchEvent) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseDown(e.touches[0]);
	};

	const handleTouchMove = (e: any) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseMove(e.touches[0]);
		handleUploadURL();
	};

	const handleTouchEnd = (e: React.TouchEvent) => {
		e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
		handleMouseUp();
		saveCanvasState();
	};
	const checkedEdit = (Qno, edit) => {
		setEditObj({ Qno: Qno, edit: edit });
	};

	const renderCanvasImageData = () => {
		if (ctx) {
			const img = new Image();
			img.crossOrigin = "anonymous";
			// img.crossOrigin = "no-cors";
			img.src = base64Image;
			img.onload = () => {
				ctx.clearRect(
					0,
					0,
					canvasRef.current!.width,
					canvasRef.current!.height
				);
				ctx.drawImage(img, 0, 0);
			};
		}
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		setDrawingContext(ctx);

		if (ctx && editObj?.Qno === QuestionNumber && editObj.edit === false) {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			let text = answerText;
			// "This is a long piece of text that needs to be wrapped within the canvas. gfsdgrsdtbwea  art rwat waett  rre atwear4t wea wea twea twatbw";

			// Set the maximum width for text wrapping
			let maxWidth = 800;

			// Set the initial position for drawing text
			let x = 20;
			let y = 30;
			ctx.font = "20px Arial";

			// Split the text into words
			let words = text?.split(" ");
			let line = "";

			// Loop through each word and build lines for wrapping
			for (let i = 0; i < words.length; i++) {
				let testLine = line + words[i] + " ";
				let metrics = ctx.measureText(testLine);
				let testWidth = metrics.width;

				if (testWidth > maxWidth) {
					// Draw the line and move to the next line
					ctx.fillText(line, x, y);
					line = words[i] + " ";
					y += 20; // Move to the next line
				} else {
					line = testLine;
				}
			}

			// Draw the last line
			ctx.fillText(line, x, y);
			handleUploadURL();
		}

		// const drawCanvas = () => {
		// 	ctx.font = `${40}px Arial`;
		// 	// ctx.clearRect(0, 0, canvas.width, canvas.height);
		// 	// ctx.clearRect(0, 0, textPosition.x, textPosition.y);
		// 	ctx.fillText("Drag me!", textPosition.x, textPosition.y);
		// };

		// drawCanvas();
		// console.log("my canvas editObj", base64Image);
		if (
			ctx &&
			editObj?.Qno == QuestionNumber &&
			editObj.edit === true &&
			editState?.mode === 1
		) {
			// console.log("my canvas", value?.answers[0]?.answer_data?.url);
			renderCanvasImageData();
			handleUploadURL();
		}
		if (
			ctx &&
			value?.answers[0]?.answer_data?.url &&
			value?.answers[0]?.answer_data?.url != undefined &&
			value?.answers[0]?.answer_data?.url?.length > 0
		) {
			// console.log("my canvas", value?.answers[0]?.answer_data?.url);
			renderCanvasImageData();
			handleUploadURL();
		}
	}, [editObj.edit, canvasRef.current]);
	// [canvasRef, textPosition]\

	useEffect(() => {
		if (value?.answers[0]?.answer_data?.url) {
			setCanvasWidth(900);
			setCanvasHeight(500);
		}
		if (window.outerWidth < 1023 && !value?.answers[0]?.answer_data?.url) {
			setCanvasWidth(500);
		}
		if (window.outerWidth > 1023 && !value?.answers[0]?.answer_data?.url) {
			setCanvasWidth(800);
		}
		// setCanvasWidth(800);
	}, [window.outerWidth]);
	// useEffect(() => {
	// 	convertImgToBase64();
	// }, [ImgLink]);

	return (
		<>
			<Modal
				title=""
				centered
				maskClosable={false}
				open={modal2Open.modal}
				onOk={confirmModal}
				onCancel={exitFullscreen}
				width={1000}
			>
				<div style={{ padding: "2.5rem 0" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<aside
							style={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<span style={{ color: "#999999" }}>
								{/* {colorIcon} */}
								<ColorIconSVG />
							</span>
							<input
								type="color"
								value={penColor}
								onChange={handlePenColorChange}
							/>
						</aside>
						{/* <input
						type="text"
						placeholder="Type your text"
						value={currentText}
						onChange={(event) => setCurrentText(event.target.value)}
					/>
					<button onClick={textAdd}>Text</button> */}
						<aside
							style={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<span style={{ color: "#999999" }}>
								<PenSizeIconSvg />
							</span>
							<input
								type="range"
								min="1"
								max="10"
								value={penSize}
								onChange={handlePenSizeChange}
							/>
							<span>{penSize}</span>
						</aside>
						{/* <button onClick={() => setCurrentTool("eraser")}>
						Eraser
					</button> */}
						{/* <button onClick={() => setCurrentTool("text")}>Text</button> */}
						<Tooltip placement="top" title="Pen">
							<Button
								type="link"
								onClick={() => setCurrentTool("pen")}
								style={{ padding: "0", margin: "0" }}
							>
								<PencilSvg />
							</Button>
						</Tooltip>
						<Tooltip placement="top" title="Undo">
							<Button
								type="link"
								onClick={undo}
								style={{ padding: "0", margin: "0" }}
							>
								<UndoSvg />
							</Button>
						</Tooltip>
						<Tooltip placement="top" title="Redo">
							<Button
								type="link"
								onClick={redo}
								style={{ padding: "0", margin: "0" }}
							>
								<RedoSvg />
							</Button>
						</Tooltip>
						{editState.mode === 1 && (
							<Tooltip placement="top" title="Checked Data">
								<Button
									// type="link"
									onClick={() =>
										checkedEdit(
											QuestionNumber,
											!editObj.edit
										)
									}
									style={{ padding: "0", margin: "0" }}
								>
									Checked Data
								</Button>
							</Tooltip>
						)}
						{/* {editState<Button >Checked Data</Button>} */}

						{/* <button onClick={handleDownload}>Download</button> */}
						{/* <button onClick={handleUploadURL}>Done</button> */}
					</div>
					<canvas
						ref={canvasRef}
						width={canvasWidth}
						height={canvasHeight}
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						onMouseOut={saveCanvasState}
						style={{
							border: "1px solid #e3e4e5",
							borderRadius: "10px",
						}}
						onTouchStart={handleTouchStart}
						onTouchMove={handleTouchMove}
						onTouchEnd={handleTouchEnd}
					/>
				</div>
			</Modal>
		</>
	);
};

export default MyCanva;
