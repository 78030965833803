import { Input, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { playlist_timer } from "../../redux/reducers/PlaylistSlice";
import InvalidMsg from "../create/InvalidMsg";
const { Text } = Typography;

const PlaylistTimesettings = () => {
	const dispatch = useDispatch();
	const playlist: any = useSelector((state: RootState) => state.playlist);
	const timer = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(playlist_timer(e.target.value));
	};

	useEffect(() => {
		if (playlist.mode === 0) {
			const totalTime = playlist?.question_ids
				.map((obj) => Number(obj.set_time))
				.reduce((acc, curr) => acc + curr, 0);
			let minutes = totalTime / 60;
			dispatch(playlist_timer(minutes));
		}
	}, [dispatch, playlist.question_ids]);
	useEffect(() => {
		if (playlist.mode === 1) {
			const totalTime = playlist?.playlist_to_edit?.question_ids
				.map((obj) => Number(obj.set_time))
				.reduce((acc, curr) => acc + curr, 0);
			let minutes = totalTime / 60;
			dispatch(playlist_timer(minutes));
		}
	}, [dispatch, playlist.playlist_to_edit.question_ids]);
	return (
		<>
			<Row gutter={[24, 6]}>
				<div className="width-100" style={{ padding: "5px" }}>
					<div className="question-mandatory-div">
						<Text
							type="danger"
							className="question-mandatory-label"
						>
							*
						</Text>
						<Text className="question-mandatory-label">
							Set playlist Time (min)
						</Text>
					</div>
				</div>
				<Input
					value={
						playlist.mode === 0
							? playlist.playlist_time
							: playlist.playlist_to_edit.playlist_time
					}
					placeholder="Set your playlist time"
					readOnly={true}
					maxLength={10}
					status={playlist.valid?.playlist_time ? "error" : ""}
				/>
				<div style={{ height: "16px" }}>
					{"playlist_time" in playlist.valid && (
						<InvalidMsg msg={playlist.valid?.playlist_time} />
					)}
				</div>
			</Row>
		</>
	);
};

export default PlaylistTimesettings;
