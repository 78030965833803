import { Button, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "../..";
import icon from "../../assets/images/icon.png";
import React from "react";
// import SvgDashboard from "../svg/SvgDashboard";
import SvgCreate from "../svg/SvgCreate";
import SvgQuesttionBank from "../svg/SvgQuesttionBank";
// import SvgAssign from "../svg/SvgAssign";
// import SvgShare from "../svg/SvgShare";
// import SvgLiveSession from "../svg/SvgLiveSession";
import SvgPlaylist from "../svg/SvgPlaylist";
import { LogoutOutlined } from "@ant-design/icons";
import create_animation_icon from "../../assets/images/create_animation.gif";
import { ANIMATION_CHOOSE_URL } from "../../axios/AllBaseUrl";
import { Base64 } from "js-base64";
import SvgHome from "../svg/SvgHome";
import LikedSvg from "../svg/LikedSvg";

interface Props {
	color: string;
}

const DrawerLayout = (prop: any): JSX.Element => {
	const { onClose } = prop;
	let navigate = useNavigate();
	let userLogOut = () => {
		localStorage.removeItem("token");
		navigate("/");
	};
	const redirect = () => {
		let get_token = localStorage.getItem("token");
		// console.log(get_token);
		let data_set = {
			mode: 0,
			token: get_token,
		};
		let str_val = JSON.stringify(data_set);
		let encode = Base64.encode(str_val);
		window.open(`${ANIMATION_CHOOSE_URL}/${encode}`, "_blank");
	};

	return (
		<>
			<div className="brand">
				<div className="drawer-top">
					<span>
						<img src={icon} alt="" className="drawer-logo-img" />
					</span>
					<span className="drawer-top-heading">Question Bank</span>
				</div>
				<hr />
				<Menu mode="inline" style={{ height: "100%" }}>
					{/* Dashbboard */}
					{/* <Menu.Item key="1">
						<Link
							to="/dashboard"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon">
								<SvgDashboard />
							</span>
							<span className="drawer-headings">Dashboard</span>
						</Link>
					</Menu.Item> */}
					<Menu.Item key="8">
						<Link
							to="/home"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon-create">
								<SvgHome />
							</span>
							<span className="drawer-headings">Home Page</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="9">
						<Link
							to="/create"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon-create">
								<SvgCreate />
							</span>
							<span className="drawer-headings">Create</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="10">
						<Link
							to="/my_question_bank"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon">
								<SvgQuesttionBank />
							</span>
							<span
								className="/my_question_bank"
								style={{ paddingLeft: "20px" }}
							>
								Question Bank
							</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="11">
						<Link
							to="/playlist"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon">
								<SvgPlaylist />
							</span>
							<span
								className="drawer-headings"
								style={{ paddingLeft: "20px" }}
							>
								My Playlist
							</span>
						</Link>
					</Menu.Item>
					<Menu.Item
						key="12"
						onClick={() => {
							redirect();
						}}
					>
						<Link to="" className="drawer-icons" onClick={onClose}>
							<img
								src={create_animation_icon}
								alt="create_animation"
								className="create_animation_icon"
							/>
							<span
								className="drawer-headings"
								style={{ paddingLeft: "0px" }}
							>
								Create Animation
							</span>
						</Link>
					</Menu.Item>
					{/* <Menu.Item key="12">
						<Link to="/assigned" className="drawer-icons">
							<span className="drawer-top-icon">
								<SvgAssign />
							</span>
							<span className="drawer-headings">Assign</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="13">
						<Link to="/share" className="drawer-icons">
							<span className="drawer-top-icon">
								<SvgShare />
							</span>
							<span className="drawer-headings">Share</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="14">
						<Link to="/live_session" className="drawer-icons">
							<span className="drawer-top-icon">
								<SvgLiveSession />
							</span>
							<span className="drawer-headings">
								Live Session
							</span>
						</Link>
					</Menu.Item> */}
					<Menu.Item key="8">
						<Link
							to="/liked_Page"
							className="drawer-icons"
							onClick={onClose}
						>
							<span className="drawer-top-icon-create">
								<LikedSvg />
							</span>
							<span className="drawer-headings">Liked Page</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="2" onClick={userLogOut}>
						<Button type="link" className="drawer-icons">
							<span
								className="drawer-top-icon"
								style={{ color: "white" }}
							>
								{<LogoutOutlined />}
							</span>
							<span className="drawer-headings">Logout</span>
						</Button>
					</Menu.Item>
				</Menu>
			</div>
		</>
	);
};

export default DrawerLayout;
