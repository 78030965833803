import { Button, Card, Switch, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const CheckPlaylistViewCard = ({
	navigateToStudentList,
	value,
	StatusCheck,
	playlistStatus,
	Published,
	deleteData,
	QuestionTimeCheck,
	questionTime,
}) => {
	let [checkedStatus, setCheckedStatus] = useState<any>("");
	let [questionTimeCheckedStatus, setQuestionTimeCheckedStatus] =
		useState<any>("");

	let statusValue = () => {
		playlistStatus?.map((elm) => {
			if (
				elm?.exam_playlist_id === value.examData[0]?.id &&
				elm.status === 1
			) {
				setCheckedStatus(true);
			}
			if (
				elm?.exam_playlist_id === value.examData[0]?.id &&
				elm.status === 2
			) {
				setCheckedStatus(false);
			}
		});
	};

	let timeStatusValue = () => {
		questionTime?.map((elm) => {
			if (
				elm?.exam_playlist_id === value.examData[0]?.id &&
				elm.time_check === 1
			) {
				setQuestionTimeCheckedStatus(true);
			}
			if (
				elm?.exam_playlist_id === value.examData[0]?.id &&
				elm.time_check === 0
			) {
				setQuestionTimeCheckedStatus(false);
			}
		});
	};
	useEffect(() => {
		if (playlistStatus?.length > 1) {
			statusValue();
		}
	}, [playlistStatus, value]);
	useEffect(() => {
		StatusCheck(
			value?.examData[0]?.playlist_id,
			value?.examData[0]?.status,
			1,
			value.examData[0]?.id
		);
		QuestionTimeCheck(
			value?.examData[0]?.playlist_id,
			value?.examData[0]?.time_check,
			1,
			value.examData[0]?.id
		);
	}, [value]);
	useEffect(() => {
		timeStatusValue();
	}, [questionTime, value]);

	return (
		<>
			<Card
				bordered={false}
				hoverable={true}
				style={{
					textAlign: "left",
					marginBottom: "1rem",
					width: "100%",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "start",
						flexDirection: "column",
						gap: "0.5rem",
						color: "#6c757d",
						width: "100%",
					}}
				>
					<aside
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "end",
							alignItems: "center",
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<Tooltip
							placement="top"
							title="Delete Published Playlist"
						>
							<Button
								type="link"
								onClick={() => {
									deleteData(
										value?._id,
										value.examData[0]?.id,
										value?.playlist_name
									);
								}}
							>
								<DeleteOutlined />
							</Button>
						</Tooltip>
						<Tooltip
							placement="top"
							title="Edit Published Playlist"
						>
							<Button
								type="link"
								onClick={() => {
									// setEditModalData({
									// 	isOpen: true,
									// 	playlistId: value?._id,
									// 	examData: value?.examData[0],
									// });
									Published(value?._id, value?.examData[0]);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
					</aside>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "0.5rem",
							marginTop: "10px",
						}}
					>
						<aside>
							<h3
								style={{
									margin: "0",
									wordWrap: "break-word",
								}}
							>
								{/* Exam Name 1 */}
								{value?.playlist_name}
							</h3>
						</aside>
					</div>

					{/* <aside>
						Tag:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.tag?.map((value, i) => (
								<Tag color="blue" key={i}>
									{value}
								</Tag>
							))}
						</span>
					</aside> */}
					<aside>
						Total Participant Attempted:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{/* 40 */}
							{value?.attendStudent}
						</span>
					</aside>
					<aside>
						Total Number of Questions:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{/* 10 */}
							{value?.questions?.length}
						</span>
					</aside>
					<aside>
						Full Marks:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{/* 20 */}
							{value?.full_marks}
						</span>
					</aside>
					<aside>
						Duration:{" "}
						{value?.playlist_time ? (
							<span
								style={{
									fontWeight: "500",
								}}
							>
								{/* 20 mins */}
								{`${value?.playlist_time} minute`}
							</span>
						) : (
							""
						)}
					</aside>
					<aside>
						Class Name:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.class_name}
						</span>
					</aside>
					<aside>
						Section Name:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.section_name}
						</span>
					</aside>
					<aside>
						Start Date:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.start_date}
						</span>
					</aside>
					<aside>
						Start Time:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.start_time}
						</span>
					</aside>
					<aside>
						End Date:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.end_date}
						</span>
					</aside>

					<aside>
						End Time:{" "}
						<span
							style={{
								fontWeight: "500",
							}}
						>
							{value?.examData[0]?.end_time}
						</span>
					</aside>
					<aside
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "0.5rem",
						}}
					>
						<aside
							style={{
								display: "flex",
								gap: "0.5rem",
							}}
						>
							<span>Status:</span>
							<span>
								<Switch
									checked={checkedStatus}
									onChange={(e) => {
										e === true
											? StatusCheck(
													value?.examData[0]
														?.playlist_id,
													1,
													0,
													value.examData[0]?.id
											  )
											: StatusCheck(
													value?.examData[0]
														?.playlist_id,
													2,
													0,
													value.examData[0]?.id
											  );
									}}
								/>
							</span>
						</aside>
						{checkedStatus === true ? (
							<span
								style={{
									color: "#52C41A",
									fontWeight: "500",
								}}
							>
								Active
							</span>
						) : (
							<span
								style={{ color: "#BABABA", fontWeight: "500" }}
							>
								Inactive
							</span>
						)}
					</aside>

					<aside
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "0.5rem",
						}}
					>
						<aside
							style={{
								display: "flex",
								gap: "0.5rem",
							}}
						>
							<span>Timer:</span>
							<span>
								<Switch
									checked={questionTimeCheckedStatus}
									onChange={(e) => {
										e === true
											? QuestionTimeCheck(
													value?.examData[0]
														?.playlist_id,
													1,
													0,
													value.examData[0]?.id
											  )
											: QuestionTimeCheck(
													value?.examData[0]
														?.playlist_id,
													0,
													0,
													value.examData[0]?.id
											  );
									}}
								/>
							</span>
						</aside>
						{questionTimeCheckedStatus === true ? (
							<span
								style={{
									color: "#52C41A",
									fontWeight: "500",
								}}
							>
								True
							</span>
						) : (
							<span
								style={{ color: "#BABABA", fontWeight: "500" }}
							>
								False
							</span>
						)}
					</aside>

					<aside style={{ width: "100%" }}>
						<Button
							block
							onClick={() =>
								navigateToStudentList(
									value?._id,
									value?.playlist_name,
									value?.full_marks,
									value?.examData[0]?.start_date,
									value?.examData[0]
								)
							}
						>
							Review Play List
						</Button>
					</aside>
				</div>
			</Card>
		</>
	);
};

export default CheckPlaylistViewCard;
