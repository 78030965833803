import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Col } from "antd";
import "../../assets/styles/main.css";
import { Row, Radio, Space } from "antd";
import RadioOption from "./RadioOption";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import {
	FILL_IN_THE_BLANK,
	IQ,
	MCQ,
	TRUE_FALSE,
	ANIMATION,
} from "../../utlis/constants/questionType";

const AddOption = (prop: any) => {
	const questiondata: any = useContext(createQuesContext);
	const [optionHandler, setOptionHandler] = useState([]);
	let [error, setError] = useState<any>({});
	let [ques, setQues] = useState<any>({});

	let { state, dispatch } = questiondata;
	let [optionCorrect, setOptionCorrect] = useState<any>();

	// const remove = () => {
	// 	let arr = [...addOption];
	// 	arr.splice(id, 1);
	// 	setAddOption(arr);
	// };

	const correctAnswer = (e: any) => {
		if (state.mode === 0) {
			dispatch({ type: "optionIscorrect", value: e.target.value });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_optionIscorrect", value: e.target.value });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_suboption_iscorrect",
				value: e.target.value,
				id: state.edit_id,
			});
		}
	};
	const optionRender = () => {
		const currentQState = state.mode; // 0 = main , 1 = subQ , 2 = edit
		const quData =
			currentQState === 0
				? state.question
				: currentQState === 1
				? state.subQuestion
				: state.question.subQuestions[state.edit_id];
		setOptionHandler(quData.options);

		setQues(quData);
		if (currentQState === 0) {
			setOptionCorrect(null);
			state.question.options.map((elem, index) => {
				if (elem.isCorrect === 1) {
					setOptionCorrect(index);
				}
			});
		}
		if (currentQState === 1) {
			setOptionCorrect(null);
			state.subQuestion.options.map((elem, index) => {
				if (elem.isCorrect === 1) {
					setOptionCorrect(index);
				}
			});
		}
		if (currentQState === 2) {
			setOptionCorrect(null);
			state.question.subQuestions[state.edit_id].options.map(
				(elem, index) => {
					if (elem.isCorrect === 1) {
						setOptionCorrect(index);
					}
				}
			);
		}
	};

	useEffect(() => {
		optionRender();
	}, [
		optionHandler,
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);

	let get_error_in_edit_mode = () => {
		if (
			state.edit_id !== null &&
			state?.edit_mode_validation?.length !== 0
		) {
			if (
				Object.keys(state?.edit_mode_validation[state?.edit_id])
					?.length !== 0
			) {
				setError(state?.edit_mode_validation[state?.edit_id]);
			} else {
				setError({});
			}
		}
	};

	useEffect(() => {
		get_error_in_edit_mode();
	}, [state.edit_id, state.edit_mode_validation]);

	return (
		<>
			<Space direction="vertical" size="middle" className="pt-40 pb-10">
				{/* <Radio.Group
                    value={optionCorrect}
                    onChange={correctAnswer}
                    className={
                        state.valid.correct_answer || error.correct_answer
                            ? "optionChoice"
                            : ""
                    }
                > */}
				<Row>
					<Col xl={24} xxl={24} xs={24} md={24}>
						<Row
							gutter={[24, 0]}
							justify="start"
							align="middle"
							style={{ width: "100%" }}
						>
							<Col xl={24} xxl={24} xs={24} md={24}>
								{(ques.question_type === MCQ ||
									ques.question_type === TRUE_FALSE ||
									ques.question_type === IQ ||
									ques.question_type === FILL_IN_THE_BLANK ||
									ques.question_type === ANIMATION) &&
									optionHandler.map(
										(elem: any, index: any) => {
											return (
												<RadioOption
													key={index}
													index={index}
													id={index}
													elem={elem}
												/>
											);
										}
									)}
							</Col>
						</Row>
					</Col>
				</Row>
				{/* </Radio.Group> */}
			</Space>
		</>
	);
};

export default AddOption;
