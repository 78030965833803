import React from "react";

const UndoSvg = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
			>
				<path
					d="M21.7779 12.3715C21.4717 11.9639 21.1428 11.5754 20.7778 11.2105C18.7149 9.14756 15.7174 8 12.8 8C9.88264 8 7.08472 9.15892 5.02182 11.2218C4.65161 11.592 4.31051 11.9859 4 12.3999V9C4 8.44772 3.55228 8 3 8C2.44772 8 2 8.44772 2 9V15C2 15.5523 2.44772 16 3 16H9C9.55229 16 10 15.5523 10 15C10 14.4477 9.55229 14 9 14H5.31663C5.64182 13.5133 6.01603 13.056 6.43603 12.636C8.12386 10.9482 10.4131 10 12.8 10C15.187 10 17.6758 10.9368 19.3636 12.6247C19.6423 12.9033 19.9008 13.1984 20.1382 13.5076C20.3122 13.8023 20.633 14 21 14C21.5523 14 22 13.5523 22 13C22 12.7619 21.9168 12.5433 21.7779 12.3715Z"
				/>
			</svg>
		</>
	);
};

export default UndoSvg;
