import { createSlice } from "@reduxjs/toolkit";
import { SearchQuestionThunk } from "../thunk/RootThunk";
import { PostSearchState } from "../../utlis/Interface/postSearchInterface";

export interface SearchState {
	search: PostSearchState[];
	loading: boolean;
	error: any;
	apiCall: boolean;
	currentPage: any;
	totalPages: any;
	totalItems: any;
	tokenExpiredError: boolean;
}

export const initialState: SearchState = {
	search: [],
	loading: false,
	error: null,
	apiCall: false,
	currentPage: 0,
	totalPages: 0,
	totalItems: 0,
	tokenExpiredError: false
};


export const postSearch: any = SearchQuestionThunk;

const SearchQuestionSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		// QuestionArray: (state, action) => {
		// 	let all_Q: any = [...state.search];
		// 	all_Q?.map((value, index) => {
		// 		if (action.payload.id === value?._id) {
		// 			if (action.payload?.liked === true) {
		// 				all_Q[index].liked = false
		// 			}
		// 			if (action.payload?.liked === false) {
		// 				all_Q[index].liked = true
		// 				all_Q[index].totalLike = action.payload?.totalLike + 1
		// 			}
		// 			if (action.payload?.totalLike > 0 && action.payload?.liked === true) {
		// 				all_Q[index].totalLike = action.payload?.totalLike - 1
		// 			}
		// 		}

		// 	})

		// },

		QuestionArray: (state, action) => {
			const allQuestions: any = [...state.search];
			const questionIndex = allQuestions.findIndex(
				(value) => action.payload.id === value?._id
			);

			if (questionIndex !== -1) {
				const questionItem = allQuestions[questionIndex];

				if (action.payload.liked === true) {
					questionItem.liked = false;
				} else if (action.payload.liked === false) {
					questionItem.liked = true;
					questionItem.totalLike = action.payload.totalLike + 1;
				}

				if (action.payload.totalLike > 0 && action.payload.liked === true) {
					questionItem.totalLike = action.payload.totalLike - 1;
				}
			}
		},
	},
	extraReducers: {
		[postSearch.pending]: (state: any, action) => {
			state.loading = true;
			state.apiCall = false;
			state.search = [];
			state.currentPage = 0;
			state.totalPages = 0;
			state.totalItems = 0;
		},
		[postSearch.fulfilled]: (state: any, action) => {
			state.loading = false;
			state.apiCall = true;
			state.currentPage = action.payload?.currentPage;
			state.totalPages = action.payload?.totalPages;
			state.totalItems = action.payload?.totalItems;
			state.search = [...action.payload?.questionData];


			if (action.payload.ok === false && action.payload.http$.status === 401) {
				state.tokenExpiredError = true
			}
		},
		[postSearch.rejected]: (state: any, action) => {
			state.loading = false;
			state.error = action.payload;
		},

	},
});

export const { QuestionArray } = SearchQuestionSlice.actions
export default SearchQuestionSlice.reducer;
