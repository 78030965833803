import { Alert, Button, Modal } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import "react-quill/dist/quill.snow.css";
import Editor from "../create/Editor";
import createQuesContext from "../../context/createQuestion/createQuesContext";
import SvgBulb from "../svg/SvgBulb";
import SvgBulbOn from "../svg/SvgBulbOn";
import TextArea from "antd/lib/input/TextArea";
import { Typography } from "antd";
const { Text } = Typography;

const AddHint = () => {
	const questiondata: any = useContext(createQuesContext);
	let { dispatch, state } = questiondata;
	let [quesTrans, setQuesTrans] = useState<any>({});

	const WhichTypeQuestion = () => {
		let currentQState = state.mode;
		let quData;
		quData =
			currentQState === 0
				? state.question_traslation
				: currentQState === 1
				? state.sub_question_traslation
				: state.question.subQuestions[state.edit_id]
						.question_traslation[0];
		setQuesTrans(quData);
	};
	useEffect(() => {
		WhichTypeQuestion();
	}, [
		state.mode,
		state.question,
		state.subQuestion,
		state.question_traslation,
		state.sub_question_traslation,
		state.edit_id,
	]);
	const getFormValue = (e) => {
		if (state.mode === 0) {
			dispatch({ type: "shortHint", value: e.target.value });
		}
		if (state.mode === 1) {
			dispatch({ type: "sub_shortHint", value: e.target.value });
		}
		if (state.mode === 2) {
			dispatch({
				type: "edit_sub_question_shorthint",
				value: e.target.value,
				id: state.edit_id,
			});
		}
	};
	const [open, setOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [bounds, setBounds] = useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
	});
	const draggleRef = useRef<HTMLDivElement>(null);

	const icon = <SvgBulb />;
	const [bulbOn, setBulbOn] = useState(icon);
	const [alert, showAlert] = useState(false);

	const showModal = () => {
		setOpen(true);
	};

	const handleOk = (e: React.MouseEvent<HTMLElement>) => {
		// console.log(e);
		setOpen(false);
		setBulbOn(<SvgBulbOn />);
		showAlert(true);
	};

	const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
		setOpen(false);
	};

	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	};

	return (
		<div>
			<Button size="middle" onClick={showModal} className="addhint-btn">
				{bulbOn} Add Hints
			</Button>
			{alert ? (
				<Alert
					message="Hint Succesfully Added!!!"
					type="success"
					closeText="Close Now"
				/>
			) : null}
			<Modal
				title={
					<div
						style={{
							width: "100%",
							cursor: "move",
						}}
						onMouseOver={() => {
							if (disabled) {
								setDisabled(false);
							}
						}}
						onMouseOut={() => {
							setDisabled(true);
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						Add Hints
					</div>
				}
				open={open}
				onOk={handleOk}
				onCancel={handleCancel}
				modalRender={(modal) => (
					<Draggable
						disabled={disabled}
						bounds={bounds}
						onStart={(event, uiData) => onStart(event, uiData)}
					>
						<div ref={draggleRef}>{modal}</div>
					</Draggable>
				)}
			>
				{/* <Form.Item
					label="Add Short Hint"
					name="question"
					rules={[
						{
							required: false,
						},
					]}
				> */}
				<Text>Add Short Hint :</Text>
				<TextArea
					value={quesTrans.shortHint}
					name="shortHint"
					style={{ height: 40, resize: "none" }}
					onChange={getFormValue}
					placeholder="Enter short hint"
				/>
				{/* </Form.Item> */}
				<Editor
					// value={quesTrans.longHint}
					class_name=""
					value={
						(state.mode === 0 &&
							state.question_traslation.longHint) ||
						(state.mode === 1 &&
							state.sub_question_traslation.longHint) ||
						(state.mode === 2 &&
							state.question.subQuestions[state.edit_id]
								.question_traslation[0].longHint)
					}
					delButton={false}
					level="Add long hint :"
					type="hint"
				/>
			</Modal>
		</div>
	);
};

export default AddHint;
