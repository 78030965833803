import React, { useEffect, useState } from "react";
import ViewOtherSitePlaylist from "../components/FetchOtherSitePlayList/ViewOtherSitePlaylist";
import { ApiConnector } from "../axios/apiClient";
import {
	ASSIGMENTPLAYLIST,
	FETCHPUBLISHEDPLAYLIST,
	OTHERSITEFETCHPLAYLIST,
} from "../axios/AllBaseUrl";
import { Empty, Row } from "antd";
import base64 from "base-64";
import FlagLocalhost from "../Localhost/FlagLocalhost";
import { useNavigate } from "react-router-dom";

// import { ReactSketchCanvas } from "react-sketch-canvas";

// const styles = {
// 	border: "0.0625rem solid #9c9c9c",
// 	borderRadius: "0.25rem",
// };

const FetchOtherSitePlaylist = () => {
	const navigate = useNavigate();
	let flag = FlagLocalhost();
	let [playlistArray, setPlaylistArray] = useState<any>([]);
	// console.log("playlistArray", playlistArray);

	// console.log("playlistArray", playlistArray);

	// let fetchPlaylist = async () => {
	// 	try {
	// 		let res = await ApiConnector("post", `${OTHERSITEFETCHPLAYLIST}`, {
	// 			playlist_data: [
	// 				// local
	// 				"64b655779a712f939484c788",
	// 				"64afefea027b9a83aa4f6c50",
	// 				"64afdcb4027b9a83aa4f6086",
	// 				"64dc86ad961652474d0d816e",
	// 			],
	// 			// otherSitePlaylistArray
	// 		});
	// 		console.log("res", res);

	// 		if (res?.data?.ok) {
	// 			setPlaylistArray(res?.data?.data);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	// let OtherSiteData = async () => {
	// 	try {
	// 		let res = await ApiConnector("post", `${ASSIGMENTPLAYLIST}`, {
	// 			user_id: "81",
	// 			school_id: "43",
	// 			academic_year_id: "2",
	// 			//user_id: flag.user_id,
	// 			//school_id:flag.school_id ,
	// 			//academic_year_id:flag.academic_year_id,
	// 		});
	// 		if (res.data.ok) {
	// 			let newarray = res.data.data.map((value) => {
	// 				return value?.dataValues?.playlist_id;
	// 			});
	// 			setOtherSitePlaylistArray(newarray);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	let FetchData = async () => {
		try {
			let res = await ApiConnector("post", `${FETCHPUBLISHEDPLAYLIST}`, {
				// user_id: "131",
				// school_id: "43",
				// academic_year_id: "2",
				// type_id: "5",

				// evidya user_id

				user_id: flag?.user_id,
				school_id: flag?.school_id,
				academic_year_id: flag?.academic_year_id,
				type_id: flag?.user_type_id,
			});
			if (res.data.ok) {
				setPlaylistArray(res?.data?.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	let attendPlaylist = (id, examTimeData) => {
		try {
			let data = {
				// user_email: "mr@gmail.com",
				// user_name: "Rohan",
				// is_from_other_Site: 1,
				// playlist_id: id,
				// combined_unique_id: "81",
				// user_typeId: "5",

				user_email: flag.user_email,
				user_name: flag.user_name,
				is_from_other_Site: flag.isFromEvidya,
				combined_unique_id: flag.user_id,
				playlist_id: id,
				user_typeId: flag.user_type_id,
				examTimeData: examTimeData,
			};
			const m = JSON.stringify(data);
			const examData = base64.encode(m);
			// https://adhyandigital.com/viewQBPL/
			window.location.replace(
				// `http://192.168.1.156:8086/#/attendExam/${examData}` //local
				`https://adhyandigital.com/viewQBPL/#/attendExam/${examData}`
			);
		} catch (error) {
			console.error(error);
		}
	};
	let viewResult = (id, name, marks, user_id, otherSidePlaylistData) => {
		navigate("/studentResult", {
			state: {
				playlistData: {
					playlist_id: id,
					playlist_name: name,
					playlist_fullMarks: marks,
				},
				otherSidePlaylistData: otherSidePlaylistData,
				user_id: user_id, //Question Bank user_id
				// user_id: "635f89fff2e60d1667aca7e0", //local
				mode: 2,
				remarks: "",
				studentName: flag?.user_name,
			},
		});
	};

	useEffect(() => {
		FetchData();
		// fetchPlaylist();
	}, []);
	// useEffect(() => {
	// 	OtherSiteData();
	// }, []);
	return (
		<>
			<Row
				className="fetchOtherSitePlaylist"
				style={{
					display: "flex",
					flexWrap: "wrap",
					columnGap: "10px",
					marginLeft: "10px",
					rowGap: "10px",
				}}
			>
				{playlistArray?.length > 0 ? (
					playlistArray?.map((value, index) => {
						return (
							<div key={index}>
								<ViewOtherSitePlaylist
									index={index}
									value={value}
									attendPlaylist={attendPlaylist}
									viewResult={viewResult}
								/>
							</div>
						);
					})
				) : (
					<Empty />
				)}
			</Row>
		</>
	);
};

export default FetchOtherSitePlaylist;
