import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	// MoreOutlined,
} from "@ant-design/icons";
import { Card, Col, Image, Row, Tag, Typography } from "antd";
import React from "react";
import { ParseHtmlToText } from "../../utlis/parseHtmlTotext/ParseHtmlToText";
const { Text } = Typography;
const parse = require("html-react-parser");

const SubQuestionPreview = ({ sub_ques, q_no }) => {
	return (
		<>
			<Card className="preview-card" hoverable={true}>
				{/* <Row className="preview-more-option">
					<Col xl={24} xs={24} md={24}>
						<MoreOutlined />
					</Col>
				</Row> */}
				<Row>
					<Col xl={15} xs={24} md={24}>
						{sub_ques.tags.map((elem, index) => {
							return (
								<Tag color="#2db7f5" key={index}>
									{elem}
								</Tag>
							);
						})}
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={15} xs={24} md={24}>
						Question Type : {sub_ques.question_type}
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={15} xs={24} md={24}>
						<Text>
							{" "}
							Question Language :{" "}
							{sub_ques.question_traslation[0].langCode}
						</Text>
					</Col>
				</Row>
				<Row className="type-preview">
					<Col xl={15} xs={24} md={24}>
						<Text>Question Level : {sub_ques.question_level}</Text>
					</Col>
				</Row>
				<Row>
					<Col xl={18} md={13} xs={24}>
						<Row>
							<Col xl={24} md={24} xs={24}>
								<b className="preview-title">
									{" "}
									{`Q${q_no + 1}.`}
									{ParseHtmlToText(
										sub_ques.question_traslation[0].title
									)}
								</b>
							</Col>
						</Row>

						{sub_ques.question_traslation[0].description && (
							<Row className="preview-des">
								<p>
									{" "}
									{
										sub_ques.question_traslation[0]
											.description
									}
								</p>
							</Row>
						)}
						{sub_ques.question_traslation[0].shortHint && (
							<Row className="preview-des">
								<p>
									Short Hint :{" "}
									{sub_ques.question_traslation[0].shortHint}
								</p>
							</Row>
						)}
						{sub_ques.question_traslation[0].longHint && (
							<Row className="preview-des">
								<p>
									Long Hint :{" "}
									{sub_ques.question_traslation[0].longHint}
								</p>
							</Row>
						)}
					</Col>
					<Col xl={4} md={10} xs={24}>
						{sub_ques.files?.map((elem, index) => {
							return (
								<Image
									className="question-fetch-image"
									width={100}
									src={elem.url}
									key={index}
								/>
							);
						})}
					</Col>
					<Col xl={2} md={8} xs={24}></Col>
				</Row>

				<Row>
					{sub_ques.options?.length > 0 &&
						sub_ques.options?.map((elem, index) => {
							return (
								<Col xl={12} xs={24} md={12} key={index}>
									<Row>
										<Col xl={1} xs={2} md={2}>
											{elem.isCorrect === 0 && (
												<CloseCircleOutlined />
											)}
											{elem.isCorrect === 1 && (
												<CheckCircleOutlined />
											)}
										</Col>
										<Col xl={9} xs={20} md={11}>
											<Text>
												{ParseHtmlToText(
													elem.OptionTranslation[0]
														.title
												)}
											</Text>
										</Col>
									</Row>
									{elem.OptionTranslation[0].imageUrl && (
										<Row>
											<Col xl={8} xs={24} md={24}>
												<Image
													className="question-fetch-image"
													width={70}
													src={
														elem
															.OptionTranslation[0]
															.imageUrl
													}
												/>
											</Col>
										</Row>
									)}
								</Col>
							);
						})}
				</Row>
			</Card>
		</>
	);
};

export default SubQuestionPreview;
