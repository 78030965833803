import { Button, message } from "antd";

const CustomToast = (type, content) => {
	if (type === "error") {
		message.error({
			content: content,
			className: "custom-class",
			style: {
				marginTop: "20vh",
			},
		});
	}

	if (type === "success") {
		message.success({
			content: content,
			className: "custom-class",
			style: {
				marginTop: "20vh",
			},
		});
	}

	if (type === "warning") {
		message.warning({
			content: content,
			className: "custom-class",
			style: {
				marginTop: "20vh",
			},
		});
	}
};

export default CustomToast;
