import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	PlaylistFetch,
	push_questioninto_mainquestion,
} from "../../redux/reducers/PlaylistSlice";
import CustomToast from "../../utlis/toast/CustomToast";
import { ApiConnector } from "../../axios/apiClient";
import { LIKE } from "../../axios/AllBaseUrl";
import { QuestionArray } from "../../redux/reducers/SearchQuestionSlice";
import ColapsableCard from "../ColapsableCard";
import { Empty, Pagination, Row } from "antd";
import Loader from "../../utlis/Loader/Loader";

const SuggestedQuestion = (prop: any) => {
	const { onChange, setModal2Open, handlePageChange, pageData } = prop;

	const dispatch = useDispatch();
	const search = useSelector((state: RootState) => state.search);

	// console.log(search[0]?.data?.data);
	const playlist = useSelector((state: RootState) => state.playlist);

	let toggleIcon = async (id, liked, totalLike) => {
		let res: any = await ApiConnector("post", `${LIKE}`, {
			question_id: id,
		});
		if (res.data.ok) {
			dispatch(
				QuestionArray({ id: id, liked: liked, totalLike: totalLike })
			);
		}
	};

	//   saga
	// useEffect(() => {
	// dispatch(SuggestionFetch(suggestions));
	// }, [dispatch]);

	//    thunk

	// useEffect(() => {
	// 	dispatch(PlaylistFetch());
	// }, []);

	const Questionadded = (id, title) => {
		if (window.location.href.endsWith("/createPlaylist")) {
			if (playlist.mode === 0 && playlist.question_ids?.length === 0) {
				dispatch(push_questioninto_mainquestion({ id, title }));
			}
			if (
				playlist.mode === 1 &&
				playlist.playlist_to_edit.question_ids?.length === 0
			) {
				dispatch(push_questioninto_mainquestion({ id, title }));
			}
			if (playlist.mode === 0 && playlist.question_ids.length !== 0) {
				let res = playlist?.question_ids?.map((value: any, index) => {
					if (value?.q_id === id) {
						return -1;
					}
					// if (value?.question_traslation[0]?.title === title) {
					// 	return -1;
					// }
				});

				if (res.includes(-1)) {
					CustomToast("error", "Already added in your playlist");
				}
				if (!res.includes(-1)) {
					dispatch(push_questioninto_mainquestion({ id, title }));
				}
			}
			if (
				playlist.mode === 1 &&
				playlist.playlist_to_edit.question_ids.length !== 0
			) {
				let res = playlist?.playlist_to_edit?.question_ids?.map(
					(value: any, index) => {
						if (value?.q_id === id) {
							return -1;
						}
					}
				);

				if (res.includes(-1)) {
					CustomToast("error", "Already added in your playlist");
				}
				if (!res.includes(-1)) {
					dispatch(push_questioninto_mainquestion({ id, title }));
				}
			}
		}
	};

	return (
		<>
			{search?.loading && <Loader />}
			{search?.apiCall && search?.search?.length === 0 && <Empty />}
			{search?.search?.length !== 0 && (
				<Row style={{ marginBottom: "6px" }}>
					<Pagination
						current={search?.currentPage}
						total={search?.totalItems}
						showSizeChanger
						pageSize={pageData?.pageSize ? pageData?.pageSize : 10}
						pageSizeOptions={[10, 20, 50, 100]}
						showTotal={(total, range) =>
							`Showing ${range[0]}-${range[1]} of ${total} questions`
						}
						// items in ${search?.totalPages} pages
						onChange={(page, pageSize) =>
							handlePageChange(page, pageSize)
						}
					/>
				</Row>
			)}
			<div>
				{search?.search?.length > 0 &&
					search?.search?.map((value, index) => {
						return (
							<React.Fragment key={index}>
								<ColapsableCard
									onChange={onChange}
									index={index}
									value={value}
									image={value?.image}
									Option={value?.option}
									Questionadded={Questionadded}
									toggleIcon={toggleIcon}
									question_setting={undefined}
								/>
							</React.Fragment>
						);
					})}
			</div>
		</>
	);
};

export default SuggestedQuestion;
