import React, { useState } from "react";
import { Layout, Button, Card, Form, Input, Row, Col } from "antd";

import { UserForgotPassword } from "../axios/apiClient";
import CustomToast from "../utlis/toast/CustomToast";

const { Header, Footer, Content } = Layout;

const ForgotPassword_mail: React.FC = () => {
	const [data, setData] = useState({
		email: "",
	});

	const handle = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};
	const forgot = async () => {
		if (data.email.length === 0) {
			CustomToast("error", "Email  cant be blank");
		} else {
			let res = await UserForgotPassword(data);
			if (res.data.ok === true) {
				CustomToast("success", res.data.why);
			}
			if (res.data.ok === false) {
				CustomToast("error", res.data.why);
			}
		}
	};
	return (
		<>
			<Row justify="center" align="middle">
				<Layout
					className="layout-default ant-layout layout-sign-up"
					style={{
						height: "100vh",
					}}
				>
					<Content className="p-0">
						<Row justify="center" align="middle">
							<Col
								style={{
									width: "500px",
									padding: "0 10px 0 10px",
								}}
							>
								<Card
									className="card-signup header-solid  ant-card pt-0"
									title={<h5>Forgot Password</h5>}
									bordered={false}
									style={{
										width: "500px",
										padding: "0 10px 0 10px",
										marginTop: "200px",
									}}
								>
									<Form
										name="basic"
										initialValues={{ remember: true }}
										className="row-col"
									>
										<Form.Item
											name="email"
											rules={[
												{
													required: true,
													type: "email",
													message:
														"Please enter a valid email address",
												},
											]}
										>
											<Input
												name="email"
												placeholder="email"
												onChange={handle}
											/>
										</Form.Item>

										<Form.Item>
											<Button
												style={{ width: "100%" }}
												type="primary"
												htmlType="submit"
												onClick={forgot}
											>
												Submit
											</Button>
										</Form.Item>
									</Form>
								</Card>
							</Col>
						</Row>
					</Content>

					<Footer>
						<p className="copyright">
							{" "}
							Copyright &copy; 2022{" "}
							<a href="#!">Adhayan Digital Pvt. Ltd.</a>{" "}
						</p>
					</Footer>
				</Layout>
			</Row>
		</>
	);
};
export default ForgotPassword_mail;
