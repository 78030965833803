import React from "react";
import { Line, Pie } from "@ant-design/charts";

const SingleTagYearly = () => {
    const data =[
        {
          "tag": "math",
          "month": "jan",
          "user": 100
        },
        {
          "tag": "math",
          "month": "feb",
          "user": 200
        },
        {
          "tag": "math",
          "month": "mar",
          "user": 300
        },
        {
          "tag": "math",
          "month": "apr",
          "user": 400
        },
        {
          "tag": "math",
          "month": "may",
          "user": 300
        },
        {
          "tag": "math",
          "month": "jun",
          "user": 200
        },
        {
            "tag": "math",
            "month": "jul",
            "user": 400
          },
          {
            "tag": "math",
            "month": "aug",
            "user": 500
          },
          {
            "tag": "math",
            "month": "sep",
            "user": 505
          },
          {
            "tag": "math",
            "month": "oct",
            "user": 550
          },
          {
            "tag": "math",
            "month": "nov",
            "user": 600
          },
          {
            "tag": "math",
            "month": "dec",
            "user": 650
          },
          {
            "tag": "relation",
            "month": "jan",
            "user": 50
          },
          {
            "tag": "relation",
            "month": "feb",
            "user": 100
          },
          {
            "tag": "relation",
            "month": "mar",
            "user": 150
          },
          {
            "tag": "relation",
            "month": "apr",
            "user": 200
          },
          {
            "tag": "relation",
            "month": "may",
            "user": 250
          },
          {
            "tag": "relation",
            "month": "jun",
            "user": 300
          },
          {
              "tag": "relation",
              "month": "jul",
              "user": 350
            },
            {
              "tag": "relation",
              "month": "aug",
              "user": 400
            },
            {
              "tag": "relation",
              "month": "sep",
              "user": 450
            },
            {
              "tag": "relation",
              "month": "oct",
              "user": 500
            },
            {
              "tag": "relation",
              "month": "nov",
              "user": 550
            },
            {
              "tag": "relation",
              "month": "dec",
              "user": 552
            },
            {
                "tag": "function",
                "month": "jan",
                "user": 120
              },
              {
                "tag": "function",
                "month": "feb",
                "user": 180
              },
              {
                "tag": "function",
                "month": "mar",
                "user": 220
              },
              {
                "tag": "function",
                "month": "apr",
                "user": 280
              },
              {
                "tag": "function",
                "month": "may",
                "user": 300
              },
              {
                "tag": "function",
                "month": "jun",
                "user": 320
              },
              {
                  "tag": "function",
                  "month": "jul",
                  "user": 380
                },
                {
                  "tag": "function",
                  "month": "aug",
                  "user": 400
                },
                {
                  "tag": "function",
                  "month": "sep",
                  "user": 410
                },
                {
                  "tag": "function",
                  "month": "oct",
                  "user": 415
                },
                {
                  "tag": "function",
                  "month": "nov",
                  "user": 430
                },
                {
                  "tag": "function",
                  "month": "dec",
                  "user": 460
                },
    ];

  const config:any = {
    data,
    xField: 'month',
    yField: 'user',
    seriesField: 'tag',
    yAxis: {
      label: {
        formatter: (v) =>  `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    legend: {
      position: 'top',
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 10000,
      },
    },
  };

  return <Line {...config}/>;
};
export default SingleTagYearly;














// import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import { Line } from '@ant-design/plots';

// const SingleTagYearly = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     asyncFetch();
//   }, []);

//   const asyncFetch = () => {
//     fetch('https://gw.alipayobjects.com/os/bmw-prod/e00d52f4-2fa6-47ee-a0d7-105dd95bde20.json')
//       .then((response) => response.json())
//       .then((json) => setData(json))
//       .catch((error) => {
//         console.log('fetch data failed', error);
//       });
//   };
//   const config = {
//     data,
//     xField: 'year',
//     yField: 'user',
//     seriesField: 'tag',
//     yAxis: {
//       label: {
//         formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
//       },
//     },
//     legend: {
//       position: 'top',
//     },
//     smooth: true,
//     // @TODO 后续会换一种动画方式
//     animation: {
//       appear: {
//         animation: 'path-in',
//         duration: 5000,
//       },
//     },
//   };

//   return <Line {...config} />;
// };

// ReactDOM.render(<SingleTagYearly />, document.getElementById('container'));
