// export const BASE_URL = "https://adhyandigital.com/qbbeApi";
export const BASE_URL = `${process.env.REACT_APP_All_BASE_URL}`;
// export const BASE_URL = "http://192.168.0.123:3001/qbbeApi";
// export const ASSIGMENT_URL = "https://adhyandigital.com/assignment";
export const ASSIGMENT_URL = `${process.env.REACT_APP_ASSIGMENT_URL}`;
// export const BASE_URL = "http://192.168.0.115:3001/qbbeApi";
export const EVIDYA_BASE_URL = `${process.env.REACT_APP_EVIDYA_BASE_URL}`;
// export const EVIDYA_BASE_URL = "https://adhyandigital.com/users";
// export const UNISTUPORAPI_BASE_URL = "https://adhyandigital.com/UniStuPorApi";
export const UNISTUPORAPI_BASE_URL = `${process.env.REACT_APP_UNISTUPORAPI_BASE_URL}`; //local

export const BASE_URL_USER = `${BASE_URL}/user`;
export const USER_REGISTER = `${BASE_URL}/user/register`;
export const USER_LOGIN = `${BASE_URL}/user/login`;
export const BASE_URL_FORGOTPASSWORD = `${BASE_URL}/user/forgotpassword`;
export const ADD_QUESTION = `${BASE_URL}/question/add`;
export const QUESTION_TYPE = `${BASE_URL}/question_type/`;
export const FILE_UPLOAD = `${BASE_URL}/question_file/addfile`;
export const SUGGESTION_QUESTION = `${BASE_URL}/search_by_tag`;
export const FETCH_QUESTION = `${BASE_URL}/question/`;
export const ALLSEARCH = `${BASE_URL}/search_by_tag/all_search/`;
export const CREATEPLAYLIST = `${BASE_URL}/playlist/add`;
export const FETCHPLAYLIST = `${BASE_URL}/playlist/`;
export const GET_PLAYLIST_DATA = `${BASE_URL}/playlist/getplaylistdata`;
export const DELETE_PLAYLIST_DATA = `${BASE_URL}/playlist`;
export const PLAYLIST_DATA_OPERATION = `${BASE_URL}/playlist`;
export const Rating_Collection = `${BASE_URL}/playlist/rating`;
export const LIKE = `${BASE_URL}/questionlike`;
export const PLAYLISTSEARCH = `${BASE_URL}/playlist/search`;
export const PLAYLISTLIKE = `${BASE_URL}/playlist_like`;
export const USERLIKEQUESTION = `${BASE_URL}/question/user_liked_question`;
export const USERLIKEPLAYLIST = `${BASE_URL}/playlist/user_liked_playlist`;
export const CLASS_SECTION = `${EVIDYA_BASE_URL}/users/fetchList`;
export const OTHERSITEFETCHPLAYLIST = `${BASE_URL}/playlist/fetchOtherSitePlaylist`;
// export const PLAYLISTPUBLISHED =`http://192.168.0.150:7003/assignment/Playlist/publish`
export const PLAYLISTPUBLISHED = `${ASSIGMENT_URL}/Playlist/publish`;
export const EDITPLAYLISTPUBLISHED = `${ASSIGMENT_URL}/Playlist/edit`;
export const DELETEPLAYLISTPUBLISHED = `${ASSIGMENT_URL}/Playlist/delete`;
export const ASSIGMENTPLAYLIST = `${ASSIGMENT_URL}/Playlist/fetch`;
// export const ANIMATION_CHOOSE_URL = `http://192.168.0.115:8087/#/verify`;
// export const ANIMATION_CHOOSE_URL = `http://localhost/gaming_quiz/#/verify`;
// export const ANIMATION_CHOOSE_URL = `https://d1evhjs91xfesa.cloudfront.net/#/verify`;
export const ANIMATION_CHOOSE_URL = `https://adhyandigital.com/gammingApp/#/verify`;

// export const EVIDYA_URL = `http://192.168.0.116/evidya/handler.php?encryptedData=`;
export const EVIDYA_URL = `https://evidyadigital.com/handler.php?encryptedData=`;
export const S3BUCKET_URL = `https://0sntie3p2k.execute-api.ap-south-1.amazonaws.com/dev/upload-url`;
export const TEACHERPUBLISHEDPLAYLIST = `${BASE_URL}/playlist/userPublishedPlaylist`;
export const ATTENDEXAMSTUDENT = `${UNISTUPORAPI_BASE_URL}/fetchStudent`; //local
export const QUESTIONANSWER = `${UNISTUPORAPI_BASE_URL}/answerFetch`; //local
export const FETCHPUBLISHEDPLAYLIST = `${BASE_URL}/playlist/studentPublishedPlaylist`;
export const REMARKSSUBMIT = `${BASE_URL}/remarks/add`;
export const EDITREMARKS = `${BASE_URL}/remarks/editRemarks`;
export const PLAYLISTSTATUS = `${ASSIGMENT_URL}/Playlist/update`;
export const PLAYLIST_QUESTION_TIME_CHECK = `${ASSIGMENT_URL}/Playlist/timeCheckUpdate`;
export const CONVERTIMGTOBASE64 = `${BASE_URL}/animation_playlist/url`;
export const GET_QUESTION_BY_ID = `${BASE_URL}/question`;
export const EDIT_QUESTION_BY_ID = `${BASE_URL}/question/edit_question`;
export const DOWNLOAD_PLAYLIST = `${BASE_URL}/download/downloadPlayList`;
export const EDIT_BULK_QUESTION = `${BASE_URL}/playlist/edit_question_tag`;
