import { createSlice } from "@reduxjs/toolkit";
import { PlaylistSearchThunk } from "../thunk/RootThunk";

export interface SearchState {
	playListSearch: any;
	loading: boolean;
	error: any;
	apiCall: boolean,
	currentPage: Number;
	totalPages: Number;
	totalItems: Number;
	tokenExpiredError: boolean;
}

export const initialState: SearchState = {
	playListSearch: [],
	loading: false,
	error: null,
	apiCall: false,
	currentPage: 0,
	totalPages: 0,
	totalItems: 0,
	tokenExpiredError: false
};


export const playListSearch: any = PlaylistSearchThunk;

const SearchPlaylistSlice = createSlice({
	name: "playListSearch",
	initialState,
	reducers: {
		// searchPlaylistLike: (state, action) => {
		// 	let all_playlist: any = [...state.playListSearch];
		// 	all_playlist?.map((value, index) => {
		// 		if (action.payload.id === value?._id) {
		// 			if (action.payload?.liked === true) {
		// 				all_playlist[index].liked = false
		// 			}
		// 			if (action.payload?.liked === false) {
		// 				all_playlist[index].liked = true
		// 				all_playlist[index].totalLike = action.payload?.totalLike + 1
		// 			}
		// 			if (action.payload?.totalLike > 0 && action.payload?.liked === true) {
		// 				all_playlist[index].totalLike = action.payload?.totalLike - 1
		// 			}
		// 		}
		// 	})
		// },

		searchPlaylistLike: (state, action) => {
			let all_playlist: any = [...state.playListSearch];
			const playlistIndex = all_playlist?.findIndex(
				(value) => action.payload.id === value?._id
			);
			if (playlistIndex !== -1) {
				const playlistItem = all_playlist[playlistIndex];
				if (action.payload.liked === true) {
					playlistItem.liked = false;
				} else if (action.payload.liked === false) {
					playlistItem.liked = true;
					playlistItem.totalLike = action.payload.totalLike + 1;
				}

				if (action.payload.totalLike > 0 && action.payload.liked === true) {
					playlistItem.totalLike = action.payload.totalLike - 1;
				}
			}
		},
		searchSetApi: (state, action) => {
			// console.log(action.payload);

			state.loading = action.payload;
			state.apiCall = action.payload;
		}

	},
	extraReducers: {
		[playListSearch.pending]: (state: any, action) => {
			state.loading = true;
			state.apiCall = false;
			state.playListSearch = []
		},
		[playListSearch.fulfilled]: (state: any, action) => {
			state.loading = false;
			state.apiCall = true;
			// console.log("action.payload", action.payload);
			// console.log("action.payload.ok", action.payload.ok);
			if (action.payload.ok === true) {
				state.playListSearch = [...action.payload.data.playlistData];
				state.currentPage = action.payload.data.currentPage;
				state.totalPages = action.payload.data.totalPages;
				state.totalItems = action.payload.data.totalItems;
			}
			if (action.payload.ok === false && action.payload.http$.status === 401) {
				state.tokenExpiredError = true
			}

		},
	},
});
export const { searchPlaylistLike, searchSetApi } = SearchPlaylistSlice.actions
export default SearchPlaylistSlice.reducer;
