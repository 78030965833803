import React from "react";

const UpArrow = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				viewBox="0 0 100 100"
				x="0px"
				y="0px"
				width={32}
				height={32}
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="4"
			>
				<path d="M67.9,57.1c-0.8,0.8-2,0.8-2.8,0L50.4,42.4L34.9,57.9c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8  L49,38.1c0.8-0.8,2.1-0.8,2.8,0l16.1,16.1C68.7,55,68.7,56.3,67.9,57.1z M88,50c0,21-17,38-38,38S12,71,12,50s17-38,38-38  S88,29,88,50z M84,50c0-18.8-15.2-34-34-34S16,31.2,16,50s15.2,34,34,34S84,68.8,84,50z" />
			</svg>
		</>
	);
};

export default UpArrow;
