import { Input, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	oneQuestionCalculate,
	oneQuestionMarks,
	oneQuestionNegativemarks,
	oneQuestionPercentage,
	oneQuestionisCalculation,
	oneQuestionisNegativemarks,
	oneQuestionis_settime,
	oneQuestionsettime,
	// PlaylistState,
	// edit_playlist_full_marks,
} from "../../redux/reducers/PlaylistSlice";

const Playlistquestionsettings = () => {
	let [questionId, setQuestionId] = useState<any>("");
	let [questionsArray, setQuestionsArray] = useState<any>("");
	const dispatch = useDispatch();
	const playlist = useSelector((state: RootState) => state.playlist);
	// const [demo, setDemo] = useState([questionsArray]);
	const getMarks = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			oneQuestionMarks({
				value: e.target.value.replace(/[^0-9]/g, ""),
				id: questionId,
			})
		);
	};
	// console.log("etaaaa", demo[0].marks);

	const isnegativeValue = (value) => {
		dispatch(
			oneQuestionisNegativemarks({
				value: value,
				id: questionId,
			})
		);
	};
	const negativeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			oneQuestionNegativemarks({
				value: e.target.value.replace(/[^0-9]/g, ""),
				id: questionId,
			})
		);
	};
	const isCalvalue = (value) => {
		dispatch(
			oneQuestionisCalculation({
				value: value,
				id: questionId,
			})
		);
	};
	const calculationvalue = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			oneQuestionCalculate({
				value: e.target.value,
				id: questionId,
			})
		);
	};
	const percentagevalue = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			oneQuestionPercentage({
				value: e.target.value,
				id: questionId,
			})
		);
	};
	const isTime = (value) => {
		dispatch(
			oneQuestionis_settime({
				value: value,
				id: questionId,
			})
		);
	};
	const Timelimit = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			oneQuestionsettime({
				value: e.target.value.replace(/[^0-9]/g, ""),
				id: questionId,
			})
		);
	};
	useEffect(() => {
		if (playlist.mode === 0) {
			setQuestionsArray(playlist.question_ids);
		}
	}, [playlist.question_ids]);
	useEffect(() => {
		if (playlist.mode === 1) {
			setQuestionsArray(playlist.playlist_to_edit.question_ids);
		}
	}, [playlist.playlist_to_edit.question_ids]);
	useEffect(() => {
		if (playlist.mode === 0) {
			setQuestionId(playlist.questionIndex);
		}
	}, [playlist.questionIndex]);
	useEffect(() => {
		if (playlist.mode === 1) {
			setQuestionId(playlist.playlist_to_edit.questionIndex);
		}
	}, [playlist.playlist_to_edit.questionIndex]);

	return (
		<>
			<table className="playlist-settings-table">
				<tr className="playlist-settings-table-row">
					<td className="first-table-data">
						Please enter individual question marks :
					</td>
					<td>
						<Input
							onChange={getMarks}
							value={questionsArray[questionId]?.marks}
							maxLength={4}
						/>
					</td>
				</tr>
				<tr className="playlist-settings-table-row">
					<td className="first-table-data">
						Do you want to add negative marking:
					</td>
					<td>
						<Radio
							value={true}
							checked={
								questionsArray[questionId]
									?.has_negetive_marks === true
							}
							onChange={() => {
								isnegativeValue(true);
							}}
						>
							Yes
						</Radio>
					</td>
					<td className="playlist-table-data">
						<Radio
							value={false}
							checked={
								questionsArray[questionId]
									?.has_negetive_marks === false
							}
							onChange={() => {
								isnegativeValue(false);
							}}
						>
							No
						</Radio>
					</td>
				</tr>
				{questionsArray[questionId]?.has_negetive_marks === true && (
					<tr className="playlist-settings-table-row">
						<td className="first-table-data">
							Please enter your negative marks :
						</td>
						<td>
							<Input
								onChange={negativeValue}
								value={questionsArray[questionId]?.negative}
								maxLength={4}
							/>
						</td>
					</tr>
				)}
				{/* <tr className="playlist-settings-table-row">
					<td className="first-table-data">
						What type of calculation do you want :
					</td>
					<td className="sec-table-data">
						<Radio
							value={false}
							checked={
								questionsArray[questionId]?.is_cal_level ===
								false
							}
							onChange={() => {
								isCalvalue(false);
							}}
							// onClick={isCalvalue}
						>
							Number
						</Radio>
					</td>
					<td className="playlist-table-data">
						<Radio
							value={true}
							checked={
								questionsArray[questionId]?.is_cal_level ===
								true
							}
							onChange={() => {
								isCalvalue(true);
							}}
							// onClick={isCalvalue}
						>
							Percentage
						</Radio>
					</td>
				</tr> */}
				{/* {questionsArray[questionId]?.is_cal_level === false && (
					<tr className="playlist-settings-table-row">
						<td className="first-table-data">
							Please enter your Calculate Number :
						</td>
						<td className="sec-table-data">
							<Input
								onChange={calculationvalue}
								value={questionsArray[questionId]?.cal_level}
								maxLength={4}
							/>
						</td>
					</tr>
				)}
				{questionsArray[questionId]?.is_cal_level === true && (
					<tr className="playlist-settings-table-row">
						<td className="first-table-data">
							Please enter your Calculate Percentage :
						</td>
						<td className="sec-table-data">
							<Input
								onChange={percentagevalue}
								value={questionsArray[questionId]?.percentage}
								maxLength={4}
							/>
						</td>
					</tr>
				)} */}
				<tr className="playlist-settings-table-row">
					<td className="first-table-data">
						Do you want to set timing :
					</td>
					<td className="sec-table-data">
						<Radio
							value={true}
							checked={
								questionsArray[questionId]?.is_set_time === true
							}
							onChange={() => {
								isTime(true);
							}}
							// onClick={isTime}
						>
							Yes
						</Radio>
					</td>
					<td className="playlist-table-data">
						<Radio
							value={false}
							checked={
								questionsArray[questionId]?.is_set_time ===
								false
							}
							onChange={() => {
								isTime(false);
							}}
							// onClick={isTime}
						>
							No
						</Radio>
					</td>
				</tr>
				{questionsArray[questionId]?.is_set_time === true && (
					<tr className="playlist-settings-table-row">
						<td className="first-table-data">
							Set time limit in seconds :
						</td>
						<td className="sec-table-data">
							<Input
								onChange={Timelimit}
								value={questionsArray[questionId]?.set_time}
								maxLength={10}
							/>
						</td>
					</tr>
				)}
			</table>
		</>
	);
};

export default Playlistquestionsettings;
