import { Button, Col, Modal, Row, Switch } from "antd";
import React from "react";
import CustomDatePicker from "../CustomDatePicker";
import CustomTimePicker from "../CustomTimePicker";

const PlaylistPublishedModal = ({
	publishmodal,
	publishCancel,
	Playlistpublish,
	classIdSet,
	classId,
	selectClass,
	sectionIdSet,
	selectSection,
	sectionId,
	startDate,
	setStartDate,
	setEndDate,
	startTime,
	setStartTime,
	setEndTime,
	endTime,
	endDate,
	handleEndTime,
	handleStartTime,
	questionTimeCheckedStatus,
	setQuestionTimeCheckedStatus,
	playlistTime,
}) => {
	return (
		<>
			<Modal
				// title=" Publish "
				open={publishmodal}
				onCancel={publishCancel}
				footer={[
					<Button
						className="published-button-item"
						key="back"
						onClick={Playlistpublish}
					>
						Publish Playlist
					</Button>,
				]}
			>
				Select Class
				<select
					className="published-select-modal"
					value={classId}
					// allowClear
					onChange={(e) => {
						classIdSet(e);
					}}
					style={{ height: "38px", width: " -webkit-fill-available" }}
				>
					<option key="1" value="">
						{" "}
						Select Class
					</option>
					{selectClass &&
						selectClass?.map((elm, index) => {
							return (
								<option key={index} value={elm?.id}>
									{elm?.name}
								</option>
							);
						})}
				</select>
				<p>Select Sections</p>
				<select
					// mode="multiple"
					className="published-select-modal"
					value={sectionId}
					onChange={(e) => {
						sectionIdSet(e);
					}}
					style={{ height: "38px", width: " -webkit-fill-available" }}
					// allowClear
				>
					<option key="1" value="">
						{" "}
						Select Section
					</option>
					{selectSection &&
						selectSection?.map((elm, index) => {
							return (
								<option key={index} value={elm?.id}>
									{elm?.secName}
								</option>
							);
						})}
				</select>
				<div style={{ marginTop: "10px" }}>
					<Row>
						<Col xl={12} xxl={12} md={12} xs={24}>
							Start Date :{" "}
							<CustomDatePicker
								startDate={startDate}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								flag={0}
							/>
						</Col>
						<Col xl={12} xxl={12} md={12} xs={24}>
							End Date :{" "}
							<CustomDatePicker
								startDate={endDate}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								flag={1}
							/>
						</Col>
					</Row>
					<Row style={{ marginTop: "2px" }}>
						<Col
							xl={12}
							xxl={12}
							md={12}
							xs={24}
							style={{ display: "flex" }}
						>
							<span>Start time :</span>
							<span>
								<CustomTimePicker
									changeTime={handleStartTime}
									startTime={startTime}
								/>
							</span>
						</Col>
						<Col
							xl={12}
							xxl={12}
							md={12}
							xs={24}
							style={{ display: "flex" }}
						>
							<span>End Time :</span>
							<span>
								<CustomTimePicker
									changeTime={handleEndTime}
									startTime={endTime}
								/>
							</span>
						</Col>
					</Row>
					{playlistTime !== 0 && (
						<Row style={{ marginTop: "4px" }}>
							<aside
								style={{
									// width: "100%",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "0.5rem",
								}}
							>
								<aside
									style={{
										display: "flex",
										gap: "0.5rem",
									}}
								>
									<span>Timer :</span>
									<span>
										<Switch
											checked={questionTimeCheckedStatus}
											onChange={(e) => {
												setQuestionTimeCheckedStatus(e);
											}}
										/>
									</span>
								</aside>
								{questionTimeCheckedStatus === true ? (
									<span
										style={{
											color: "#52C41A",
											fontWeight: "500",
										}}
									>
										True
									</span>
								) : (
									<span
										style={{
											color: "#BABABA",
											fontWeight: "500",
										}}
									>
										False
									</span>
								)}
							</aside>
						</Row>
					)}
				</div>
			</Modal>
		</>
	);
};

export default PlaylistPublishedModal;
