import { Menu, Drawer, Button } from "antd";
import Tooltip from "antd/es/tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../src";
import icon from "../../assets/images/icon.png";
import React, { useEffect, useState } from "react";
import DrawerLayout from "./DrawerLayout";
import LogoutSvg from "../svg/LogoutSvg";
import create_animation_icon from "../../assets/images/create_animation.gif";
import { Base64 } from "js-base64";
import { ANIMATION_CHOOSE_URL } from "../../axios/AllBaseUrl";
import { HomeOutlined, LikeOutlined } from "@ant-design/icons";
import SvgHome from "../svg/SvgHome";
import LikedSvg from "../svg/LikedSvg";

interface Props {
	color: string;
}

const Sidenav = (prop: any): JSX.Element => {
	const { color, showDrawer, placement, onClose, open } = prop;
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");

	const redirect = () => {
		let get_token = localStorage.getItem("token");
		// console.log(get_token);
		let data_set = {
			mode: 0,
			token: get_token,
		};
		let str_val = JSON.stringify(data_set);
		let encode = Base64.encode(str_val);
		window.open(`${ANIMATION_CHOOSE_URL}/${encode}`, "_blank");
	};

	const dashboard = [
		<svg
			height="24"
			width="24"
			viewBox="0 0 32 32"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="m4 20v2h4.586l-6.586 6.586 1.414 1.414 6.586-6.586v4.586h2v-8z" />
			<path d="m24.0001 21h2v5h-2z" />
			<path d="m20.0001 16h2v10h-2z" />
			<path d="m16 18h2v8h-2z" />
			<path
				d="m28 2h-24a2.002 2.002 0 0 0 -2 2v12h2v-3h24.001l.001 15h-12.002v2h12a2.0027 2.0027
			 0 0 0 2-2v-24a2.0023 2.0023 0 0 0 -2-2zm-16 9h-8v-7h8zm2 0v-7h14l.0007 7z"
			/>
		</svg>,
	];

	const create = [
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24"
			viewBox="0 0 20 20"
			width="24"
		>
			<path d="M19.4 9H16V5.6c0-.6-.4-.6-1-.6s-1 0-1 .6V9h-3.4c-.6 0-.6.4-.6 1s0 1 .6 1H14v3.4c0 .6.4.6 1 .6s1 0 1-.6V11h3.4c.6 0 .6-.4.6-1s0-1-.6-1zm-12 0H.6C0 9 0 9.4 0 10s0 1 .6 1h6.8c.6 0 .6-.4.6-1s0-1-.6-1zm0 5H.6c-.6 0-.6.4-.6 1s0 1 .6 1h6.8c.6 0 .6-.4.6-1s0-1-.6-1zm0-10H.6C0 4 0 4.4 0 5s0 1 .6 1h6.8C8 6 8 5.6 8 5s0-1-.6-1z" />
		</svg>,
	];

	const questions = [
		<svg
			width="24"
			height="24"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.07505 4.10001C5.07505 2.91103 6.25727 1.92502 7.50005 1.92502C8.74283 1.92502 9.92505 2.91103 9.92505 4.10001C9.92505 5.19861 9.36782 5.71436 8.61854 6.37884L8.58757 6.4063C7.84481 7.06467 6.92505 7.87995 6.92505 9.5C6.92505 9.81757 7.18248 10.075 7.50005 10.075C7.81761 10.075 8.07505 9.81757 8.07505 9.5C8.07505 8.41517 8.62945 7.90623 9.38156 7.23925L9.40238 7.22079C10.1496 6.55829 11.075 5.73775 11.075 4.10001C11.075 2.12757 9.21869 0.775024 7.50005 0.775024C5.7814 0.775024 3.92505 2.12757 3.92505 4.10001C3.92505 4.41758 4.18249 4.67501 4.50005 4.67501C4.81761 4.67501 5.07505 4.41758 5.07505 4.10001ZM7.50005 13.3575C7.9833 13.3575 8.37505 12.9657 8.37505 12.4825C8.37505 11.9992 7.9833 11.6075 7.50005 11.6075C7.0168 11.6075 6.62505 11.9992 6.62505 12.4825C6.62505 12.9657 7.0168 13.3575 7.50005 13.3575Z"
				fill="currentColor"
			/>
		</svg>,
	];

	const assigned = [
		<svg
			height="24"
			width="24"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="m18 17a7.46 7.46 0 1 0 -7.45-7.46 7.46 7.46 0 0 0 7.45 7.46zm0-12.93a5.46 5.46 0 1 1 -5.45 5.45 5.46 5.46 0 0 1 5.45-5.45z" />
			<path d="m6 31.89v-6.12a16.13 16.13 0 0 1 12-5 16.61 16.61 0 0 1 8.71 2.33l1.35-1.51a18.53 18.53 0 0 0 -10.06-2.85 17.7 17.7 0 0 0 -13.79 6.06 1 1 0 0 0 -.21.6v6.49a2.06 2.06 0 0 0 2 2.11h12.39l-1.9-2z" />
			<path d="m30 31.89v.11h-3.15l-1.8 2h4.95a2.06 2.06 0 0 0 2-2.07v-5.73l-2 2.23z" />
			<path d="m34.76 18.62a1 1 0 0 0 -1.41.08l-11.62 13-5.2-5.59a1 1 0 0 0 -1.41-.11 1 1 0 0 0 -.06 1.42l6.69 7.2 13.09-14.62a1 1 0 0 0 -.08-1.38z" />
		</svg>,
	];

	const share = [
		<svg
			height="24"
			width="24"
			viewBox="0 0 32 32"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="m27 25h-6a3 3 0 0 0 -3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0 -3-3z" />
			<path d="m20 20a4 4 0 1 0 4-4 4 4 0 0 0 -4 4zm6 0a2 2 0 1 1 -2-2 2 2 0 0 1 2 2z" />
			<path d="m6 21v-1h-2v1a7 7 0 0 0 7 7h3v-2h-3a5 5 0 0 1 -5-5z" />
			<path d="m19 10h7v2h-7z" />
			<path d="m19 6h10v2h-10z" />
			<path d="m19 2h10v2h-10z" />
			<path d="m11 11h-6a3 3 0 0 0 -3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0 -3-3z" />
			<path d="m8 10a4 4 0 1 0 -4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1 -2 2 2 2 0 0 1 2-2z" />
		</svg>,
	];

	const live_session = [
		<svg
			height="24"
			width="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="m5.5 18c.27614237 0 .5.2238576.5.5s-.22385763.5-.5.5h-1c-1.38071187 0-2.5-1.1192881-2.5-2.5v-11c0-1.38071187 1.11928813-2.5 2.5-2.5h15c1.3807119 0 2.5 1.11928813 2.5 2.5v11c0 1.3807119-1.1192881 2.5-2.5 2.5h-1c-.2761424 0-.5-.2238576-.5-.5s.2238576-.5.5-.5h1c.8284271 0 1.5-.6715729 1.5-1.5v-11c0-.82842712-.6715729-1.5-1.5-1.5h-15c-.82842712 0-1.5.67157288-1.5 1.5v11c0 .8284271.67157288 1.5 1.5 1.5zm11.3747929 2.1690464c.2850495.3228081.0558558.8309536-.3747929.8309536h-9c-.43064874 0-.6598424-.5081455-.37479288-.8309536l4.49999998-5.0960856c.1990309-.2253951.5505549-.2253951.7495858 0zm-4.8747929-4.0097391-3.39144956 3.8406927h6.78289916z" />
		</svg>,
	];

	const playlist = [
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="34"
			viewBox="0 0 24 24"
			width="34"
		>
			<path d="M5 10h10c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm0-4h10c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm0 8h6c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm9 .88v4.23c0 .39.42.63.76.43l3.53-2.12c.32-.19.32-.66 0-.86l-3.53-2.12c-.34-.19-.76.05-.76.44z" />
		</svg>,
	];

	let location = useLocation();
	let [curretLocation, setCurrentLocation] = useState(location.pathname);
	let [activeCss, setActiveCss] = useState("");
	useEffect(() => {
		setCurrentLocation(location.pathname);
		setActiveCss(
			curretLocation === "/dashboard" ? "ant-menu-item-selected" : ""
		);
		setActiveCss(
			curretLocation === "/create" ? "ant-menu-item-selected" : ""
		);
	});
	let navigate = useNavigate();
	let userLogOut = () => {
		localStorage.removeItem("token");
		navigate("/");
	};

	return (
		<>
			<div className="brand">
				<Link to={""} onClick={showDrawer}>
					<img src={icon} alt="" />
				</Link>

				<hr />
				<Menu mode="inline">
					<Menu.Item key="8" className={`${activeCss}`}>
						<Tooltip title="Home Page" placement="right">
							<Link to="/home">
								{/* <HomeOutlined style={{ color: "white"}} /> */}
								<SvgHome />
							</Link>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="9" className={`${activeCss}`}>
						<Tooltip title="Create Question" placement="right">
							<Link to="/create">
								<span>{create}</span>
							</Link>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="10">
						<Tooltip title="My Question Bank" placement="right">
							<Link to="/my_question_bank">
								<span>{questions}</span>
							</Link>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="11">
						<Tooltip title="My Playlist" placement="right">
							<Link to="/playlist">
								<span>{playlist}</span>
							</Link>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="12">
						<Tooltip title="Create Animation" placement="right">
							<img
								src={create_animation_icon}
								alt="create_animation"
								className="create_animation_icon"
								onClick={() => {
									redirect();
								}}
							/>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="14" className={`${activeCss}`}>
						<Tooltip title="Liked Page" placement="right">
							<Link to="/liked_page">
								<LikedSvg />
							</Link>
						</Tooltip>
					</Menu.Item>
					<Menu.Item key="13" onClick={userLogOut}>
						<Tooltip title="Logout" placement="right">
							<Button type="link" className="drawer-icons">
								{<LogoutSvg />}
							</Button>
						</Tooltip>
					</Menu.Item>
				</Menu>
			</div>
			<Drawer
				className="sidenav-drawer"
				placement={placement}
				width={500}
				onClose={onClose}
				open={open}
				key={1}
			>
				<DrawerLayout onClose={onClose} />
			</Drawer>
		</>
	);
};

export default Sidenav;
