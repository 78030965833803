import React from "react";

const SvgPlaylist = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="34"
				viewBox="0 0 24 24"
				width="34"
				fill="white"
			>
				<path d="M5 10h10c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm0-4h10c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm0 8h6c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1zm9 .88v4.23c0 .39.42.63.76.43l3.53-2.12c.32-.19.32-.66 0-.86l-3.53-2.12c-.34-.19-.76.05-.76.44z" />
			</svg>
		</>
	);
};

export default SvgPlaylist;
