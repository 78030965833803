import React, { useEffect } from "react";
// import TagAnalysis from "./TagAnalysis";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import FetchCards from "./FetchCards";
import { getquestion } from "../../redux/reducers/questionSlice";
import { ViewPlaylist } from "../../redux/reducers/PlaylistSlice";
import { Empty } from "antd";

// const content = <TagAnalysis />;
const QuestionCards = (prop: any) => {
	const questions = useSelector((state: RootState) => state.question);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getquestion([]));
	}, []);

	useEffect(() => {
		dispatch(ViewPlaylist());
	}, []);

	const { isShown, setIsShown, setCardValue, setAnswer } = prop;

	return (
		<>
			{questions.question[0]?.data?.data?.length === 0 && (
				<div className="empty-questions">
					<Empty />
				</div>
			)}
			{questions.question[0]?.data?.data?.map((value, index) => {
				return (
					<FetchCards
						value={value}
						index={index}
						isShown={isShown}
						setIsShown={setIsShown}
						setCardValue={setCardValue}
						key={index}
						setAnswer={setAnswer}
					/>
				);
			})}
		</>
	);
};

export default QuestionCards;
