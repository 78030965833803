import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { DateTime } from "luxon";
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const CustomDatePicker = ({ startDate, setStartDate, setEndDate, flag }) => {
	// let date = Date();
	// let adjusted = new Date(startDate);
	// adjustedDate.setDate(adjustedDate.getDate() + 1);
	// let today = adjustedDate.toISOString().split("T")[0];
	const [value, setValue] = useState<any>("");
	// console.log("value", value);

	let changeDate = (e) => {
		// console.log("eeeee", e);
		setValue(e);
		// Clone the selected date to avoid modifying the original date object
		let adjustedDate = new Date(e);

		// Add one day to the adjusted date
		adjustedDate.setDate(adjustedDate.getDate() + 1);

		// Convert the adjusted date to an ISO date string
		let isoDate = adjustedDate.toISOString().split("T")[0];
		if (flag === 0) {
			// console.log("value", value);

			setStartDate(isoDate);
		}
		if (flag === 1) {
			setEndDate(isoDate);
		}
	};

	useEffect(() => {
		// if (startDate.length === 0) {
		setValue(startDate);
		// }
	}, [startDate]);

	return (
		<>
			{/* minDate={startDate} */}
			<DatePicker
				onChange={changeDate}
				value={value}
				// minDate={flag === 1 ? startDate : today}
			/>
		</>
	);
};

export default CustomDatePicker;
