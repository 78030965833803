import {
	ANIMATION,
	FILL_IN_THE_BLANK,
	IQ,
	MCQ,
	TRUE_FALSE,
} from "../constants/questionType";

const Validate = (ques, quesTrans) => {
	let error: any = {};

	if (ques.tags.length === 0) {
		error.tags = "Tag can't be blank";
	}
	if (ques.question_type === "") {
		error.question_type = "Question type can't be blank";
	}
	if (ques.question_level === "") {
		error.question_level = "Question level can't be blank";
	}
	if (quesTrans.langCode === "") {
		error.langCode = "Question language can't be blank";
	}
	if (quesTrans.title === "" || quesTrans.title === "<p><br></p>") {
		error.title = "Question title can't be blank";
	}
	if (
		ques.question_type === MCQ ||
		ques.question_type === IQ ||
		ques.question_type === FILL_IN_THE_BLANK ||
		ques.question_type === TRUE_FALSE ||
		ques.question_type === ANIMATION
	) {
		for (let index = 0; index < ques.options.length; index++) {
			for (
				let i = 0;
				i < ques.options[index].OptionTranslation.length;
				i++
			) {
				if (
					ques.options[index].OptionTranslation[i].title.trim()
						.length === 0 &&
					ques.options[index].OptionTranslation[i].imageUrl === ""
				) {
					// error[`option${index}`] = {
					// 	id: index,
					// 	msg: "Option value can't be null",
					// };
					error[
						`option${index}`
					] = `Option${index} value can't be null`;
				}
			}
		}
		let correctData = ques.options.map((val, index) => {
			if (val.isCorrect === 0) {
				return 1;
			} else {
				return 0;
			}
		});
		if (
			!correctData.includes(0) &&
			ques.question_type !== FILL_IN_THE_BLANK
		) {
			error.correct_answer = "Please select correct ans";
		}
	}

	return error;
};

export default Validate;
