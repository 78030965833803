import { Button, Form, Input, Space } from "antd";
import React from "react";
import SvgMinus from "../svg/SvgMinus";

const AddField = () => {
	const onFinish = (values: any) => {
		// console.log("Received values of form:", values);
	};

	return (
		<div>
			<Form
				name="dynamic_form_nest_item"
				onFinish={onFinish}
				autoComplete="off"
			>
				<Form.List name="users">
					{(fields, { add, remove }) => (
						<>
							<div className="label-label-for-matching">
								<label className="label-for-matching">
									Match The Following
								</label>
							</div>

							{fields.map(
								({ key, name, ...restField }, index) => (
									<Space
										key={index}
										style={{
											display: "flex",
											marginBottom: 8,
										}}
										align="baseline"
									>
										<Form.Item
											{...restField}
											name={[name, "first"]}
											rules={[
												{
													required: true,
													message: "Option A",
												},
											]}
										>
											<Input placeholder="Option A" />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, "last"]}
											rules={[
												{
													required: true,
													message: "Option B",
												},
											]}
										>
											<Input placeholder="Option B" />
										</Form.Item>
										<Button
											type="link"
											onClick={() => remove(name)}
										>
											<SvgMinus />
										</Button>
									</Space>
								)
							)}
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
								>
									+ Add field
								</Button>
								<Button
									type="primary"
									className="submitbtn-for-matching"
								>
									Add
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form>
		</div>
	);
};

export default AddField;
