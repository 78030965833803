import {
	Button,
	Card,
	Col,
	Comment,
	Input,
	Modal,
	Popover,
	Rate,
	Row,
	Space,
	Tag,
	Tooltip,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useState } from "react";
import { Image } from "antd";
import {
	// AppstoreAddOutlined,
	BarChartOutlined,
	EditOutlined,
	// FileAddFilled,
	LikeOutlined,
	ShareAltOutlined,
} from "@ant-design/icons";
import TagAnalysis from "./TagAnalysis";
import parse from "html-react-parser";
import Meta from "antd/lib/card/Meta";
import fallback from "../../assets/images/fallback.gif";
const content = <TagAnalysis />;
const FetchCards = (prop: any) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	// const showModal = () => {
	// 	setIsModalOpen(true);
	// };
	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const { index, value, setIsShown, setCardValue, setAnswer } = prop;

	const prefix = (
		<EditOutlined
			style={{
				fontSize: "20px",
				paddingRight: "5px",
				opacity: "0.5",
			}}
		/>
	);
	// console.log("value fetch card", value);
	// console.log(value?.options);

	return (
		<>
			{" "}
			<Card
				hoverable={true}
				key={index}
				className={
					index % 2 === 0
						? "question-fetch-card-odd"
						: "question-fetch-card-even"
				}
			>
				<Row
					gutter={[24, 6]}
					className="image-col"
					onMouseEnter={() => {
						setIsShown(true);
						setCardValue({
							id: value?._id,
							title: value?.question_traslation[0]?.title,
							option: value?.options,
						});
						setAnswer(false);
					}}
				>
					<Col xl={5} xs={24} md={12}>
						<Image width={150} src="" fallback={fallback} />
					</Col>
					<Col xl={19} xs={24} md={12}>
						<Row gutter={[24, 6]} className="question-fetch-tag">
							<Col xl={12} xs={24} md={24}>
								<Tag color="#108ee9">{value?.question_type}</Tag>
							</Col>
							<Col
								xl={3}
								xs={6}
								md={6}
								className={
									index % 2 === 0
										? "question-fetch-avatar-col-rating-number-odd"
										: "question-fetch-avatar-col-rating-number-even"
								}
							>
								4.2
							</Col>
							<Col
								xl={6}
								xs={12}
								md={12}
								className="question-fetch-avatar-col"
							>
								<Tooltip
									placement="top"
									title="4.2 out of 5"
									className="rating-tooltip"
								>
									<span>
										<Rate
											className="question-fetch-ratings"
											disabled
											defaultValue={4}
										/>
									</span>
								</Tooltip>
							</Col>
							<Col xl={3} xs={6} md={6}>
								<Popover
									content={content}
									title="Why is it Trending?"
									placement="leftBottom"
									className="tag-analysis-report"
								>
									<Button type="link">
										<BarChartOutlined />
									</Button>
								</Popover>
							</Col>
						</Row>

						<Row gutter={[24, 6]} className="question-fetch">
							<h1 className="question-fetch-type-heading">
								{parse(value?.question_traslation[0]?.title)}
							</h1>
						</Row>
						<Row gutter={[24, 6]} className="question-fetch">
							{value?.tags?.map((elem, i) => {
								return (
									<Tag color="geekblue" key={i}>
										{elem}
									</Tag>
								);
							})}
						</Row>
						<Row gutter={[24, 6]} className="question-fetch">
							<Col
								xl={12}
								xs={24}
								md={24}
								className="question-fetch-avatar-col"
							>
								<Comment
									author={<p>Han Solo</p>}
									avatar={
										<Avatar
											src="https://avatars.dicebear.com/api/open-peeps/stefan.svg"
											alt="Han Solo"
										/>
									}
									content={undefined}
								/>
							</Col>
							<Col xl={12} xs={24} className="question-fetch">
								<Space style={{ float: "right" }}>
									<Tooltip
										placement="top"
										title="Add Playlist "
									>
										{/* <Button onClick={showModal}>
											<AppstoreAddOutlined />
										</Button> */}
									</Tooltip>
									{/* <Tooltip
										placement="top"
										title="4 questions"
									>
										<Button className="question-number-fetch">
											<QuestionCircleOutlined />4
										</Button>
									</Tooltip> */}
									{/* <Tooltip
										placement="top"
										title="20 partcipants"
									>
										<Button className="question-user-number-fetch">
											<UsergroupAddOutlined />
											20
										</Button>
									</Tooltip> */}
									<Tooltip placement="top" title="like">
										<Button>
											<LikeOutlined />
										</Button>
									</Tooltip>
									<Tooltip placement="top" title="share now">
										<Button>
											<ShareAltOutlined />
										</Button>
									</Tooltip>
									{/* <Tooltip placement="top" title="settings">
										<Button>
											<SettingOutlined />
										</Button>
									</Tooltip> */}
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				{/* <Row gutter={[24, 6]} className="question-fetch">
							<Col xl={12} xs={24} md={24} className="question-fetch-avatar-col"
							>
								<Comment
									author={<a>Han Solo</a>}
									avatar={
										<Avatar
											src="https://joeschmoe.io/api/v1/random"
											alt="Han Solo"
										/>
									}
									datetime={
										<Tooltip title={value.created_time}>
											<span
												className={
													index % 2 === 0
														? "question-fetch-date-and-time-even"
														: "question-fetch-date-and-time-odd"
												}
											>
												{value.created_time}
											</span>
										</Tooltip>
									}
									content={undefined}
								/>
							</Col>
							<Col xl={12} xs={24} md={24} className="question-fetch">
								<Space style={{ float: "right" }}>
									<Tooltip
										placement="top"
										title="4 questions"
									>
										<Button className="question-number-fetch">
											<QuestionCircleOutlined />4
										</Button>
									</Tooltip>
									<Tooltip
										placement="top"
										title="20 partcipants"
									>
										<Button className="question-user-number-fetch">
											<UsergroupAddOutlined />
											20
										</Button>
									</Tooltip>
									<Tooltip placement="top" title="like">
										<Button>
											<LikeOutlined />
										</Button>
									</Tooltip>
									<Tooltip placement="top" title="share now">
										<Button>
											<ShareAltOutlined />
										</Button>
									</Tooltip>
									<Tooltip placement="top" title="settings">
										<Button>
											<SettingOutlined />
										</Button>
									</Tooltip>
								</Space>
							</Col>
						</Row> */}
			</Card>
			<Modal
				title="Add to Playlist"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Row>
					<Input placeholder="Create your playlist" prefix={prefix} />
				</Row>
				{/* <Row gutter={[24, 0]}>
				<Col xl={24} className="folders"> */}
				{/* <FolderOpenFilled style={{fontSize:"60px"}} /> */}
				{/* <Row gutter={[24,6]}>
                    	<Col xl={6} md={4} className="single-folder">
                    <Row justify="center"><SvgFolder/></Row>
                    <Row justify="center"> Playlist</Row>
                        </Col>
                        <Col xl={6} md={4} className="single-folder">
                    <Row justify="center"><SvgFolder/></Row>
                    <Row justify="center">My Playlist </Row>
                        </Col>
                        <Col xl={6} md={4} className="single-folder">
                    <Row justify="center"><SvgFolder/></Row>
                    <Row justify="center">Math Playlist</Row>
                        </Col>
                        <Col xl={6} md={4} className="single-folder">
                    <Row justify="center"><SvgFolder/></Row>
                    <Row justify="center">Eng playlist</Row>
                        </Col>
                     


						</Row> */}

				{/* </Col>
			</Row> */}
				<Row gutter={[24, 6]}>
					<Col xl={10} md={12} xs={12}>
						<Card
							hoverable={true}
							onClick={() => {}}
							className="playlist-card"
							style={{ width: 150 }}
							cover={
								<Image
									alt="example"
									src="https://picsum.photos/id/1/200/150"
								/>
							}
						>
							<Meta
								title="Playlist 1"
								description="This is the description"
							/>
						</Card>
					</Col>
					<Col xl={10} md={12} xs={12}>
						<Card
							hoverable={true}
							onMouseEnter={() => {
								setIsShown(true);
								// setCardValue(value._id);
							}}
							className="playlist-card"
							style={{ width: 150 }}
							cover={
								<Image
									alt="example"
									src="https://picsum.photos/id/1/200/150"
								/>
							}
						>
							<Meta
								title="Playlist 1"
								description="This is the description"
							/>
						</Card>
					</Col>
					{/* <Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col>
									<Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col>
									<Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col>
									<Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col>
									<Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col>
									<Col xl={3} md={8} xs={12}>
										<Card
											hoverable={true}
											onMouseEnter={() => {
												setIsShown(true);
												// setCardValue(value._id);
											}}
											className="playlist-card"
											style={{ width: 150 }}
											cover={
												<Image
													alt="example"
													src="https://picsum.photos/id/1/200/150"
												/>
											}
										>
											<Meta
												title="Playlist 1"
												description="This is the description"
											/>
										</Card>
									</Col> */}
				</Row>
			</Modal>
		</>
	);
};

export default FetchCards;
